import React, { Fragment } from 'react';
import './ClaimsAdmin.scss';
import { BTN_CLASSES } from "../../../css_constants";

import { isEmpty, isNil } from "../../../utils/helperFunctions";
import EmptyList from "../../EmptyList/EmptyList";
import DateUtility from "../../../utils/dateUtils";
import Modal from "../../../utils/Modal/Modal";
import Button from "../../../utils/Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faEye,
    faFileDownload,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import Attachments from "../../../utils/Attachments/Attachments";

const ClaimsList = (props) => {
    const {topic, itemList, selectedClaim, openModal, closeModal, toggleClaim, showClaimsModal, downloadClaimAttachment} = props;
    const claimType = topic === 'ZIEKTE' ? 'illness' : 'damage';

    const renderClaims = () => (
        itemList.map((item, key) => {
            let openStatusBtn = item.status
                ? (
                    <Button
                        className="openStatusBtn"
                        buttonStyle={BTN_CLASSES.LINK + " " + BTN_CLASSES.SUCCESS + " " + BTN_CLASSES.SMALL}
                        onClick={() => toggleClaim(item, claimType)}>
                        <FontAwesomeIcon icon={faCheckCircle}/>
                    </Button>
                )
                : null

            let closeStatusBtn = !item.status
                ? (
                    <Button
                        className="closeStatusBtn"
                        buttonStyle={BTN_CLASSES.LINK + " " + BTN_CLASSES.DANGER + " " + BTN_CLASSES.SMALL}
                        onClick={() => toggleClaim(item, claimType)}>
                        <FontAwesomeIcon icon={faTimesCircle}/>
                    </Button>
                )
                : null

            let detailViewBtn = (
                <Button
                    className="viewDetailBtn"
                    buttonStyle={BTN_CLASSES.LINK + " " + BTN_CLASSES.SMALL}
                    onClick={() => openModal(item, claimType)}>
                    <FontAwesomeIcon icon={faEye}/>
                </Button>
            );

            return (
                <Fragment key={`${claimType}_${key}`}>
                    <tr>
                        <td>{`${item.user.firstName} ${item.user.lastName}`}</td>
                        {topic === 'ZIEKTE'
                            ? <td>{DateUtility.toShortDate(item.period[0])}</td>
                            : <td>{DateUtility.toShortDate(item.date)}</td>
                        }
                        <td>
                            {openStatusBtn}
                            {closeStatusBtn}
                        </td>
                        <td>
                            {detailViewBtn}
                        </td>
                    </tr>
                </Fragment>
            );
        })
    );

    const renderModal = () => (
        <Modal show={showClaimsModal}
               close={() => closeModal(claimType)}
               title={`${selectedClaim.user.firstName} ${selectedClaim.user.lastName}`}>
            {topic === 'ZIEKTE'
                ? selectedClaim.period[0] !== selectedClaim.period[1]
                    ? <p>Ziek
                        van {DateUtility.toShortDate(selectedClaim.period[0])} tot {DateUtility.toShortDate(selectedClaim.period[1])}</p>
                    : <p>Ziek op {DateUtility.toShortDate(selectedClaim.period[0])}</p>
                : <p>Ongeval van {DateUtility.toShortDate(selectedClaim.date)}</p>
            }
            {!isNil(selectedClaim.carType) && selectedClaim.carType ? <p>Type wagen: {selectedClaim.carType}</p> : null}
            {!isNil(selectedClaim.service)
                ? selectedClaim.service
                    ? <p>Contact gehad met Service Center</p>
                    : <p>Geen contact gehad met Service Center</p>
                : null
            }
            {selectedClaim.status ? <p>Melding is volledig</p> : <p>Melding is niet volledig</p>}
            {selectedClaim.description
                ? <p>Opmerking: {selectedClaim.description}</p>
                : <p>Geen opmerking gegeven</p>
            }
            <Attachments attachments={selectedClaim.attachments}
                         downloadClaimAttachment={downloadClaimAttachment}
                         claimType={claimType}/>
        </Modal>
    );

    return (
        <div>
            <div className="legende">
                <h4>Legende</h4>
                <section>
                    <div className="openStatus"><FontAwesomeIcon icon={faCheckCircle}/></div>
                    <p>Melding volledig</p>
                </section>
                <section>
                    <div className="closeStatus"><FontAwesomeIcon icon={faTimesCircle}/></div>
                    <p>Melding niet volledig</p>
                </section>
                <section>
                    <div className="detailView"><FontAwesomeIcon icon={faEye}/></div>
                    <p>Bekijk details</p>
                </section>
                <section>
                    <div className="download"><FontAwesomeIcon icon={faFileDownload}/></div>
                    <p>Download bestand</p>
                </section>
            </div>

            {isEmpty(itemList)
                ? <EmptyList id={"AdminClaims.empty"}/>
                : <table className="admin-claims">
                    <thead>
                    <tr>
                        <th>Naam</th>
                        <th>Datum</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderClaims()}
                    </tbody>
                </table>
            }
            {selectedClaim && !isEmpty(selectedClaim) && renderModal()}
        </div>
    );
}

export default ClaimsList;
import React, {Component} from "react";
import {Container} from "../../../utils/Grid/Grid";
import Logo from "../../../assets/images/logo-up.png";
import './Header.scss'
import {Link} from "react-router-dom";
import TopBar from "./TopBar";
import {connect} from "react-redux";
import {logout, saveWellBeing} from "../../../redux/actions/auth.actions";
import NavBar from "./NavBar";
import Hamburger from "./Hamburger";
import {closeNavbar} from "../../../redux/actions/view.actions";
import { getNotifications, updateNotifications } from "../../../redux/actions/notification.actions";
import CryptoJs from "crypto-js";
import WellBeingBarometer from "../../../utils/WellBeingBarometer/WellBeingBarometer";

/**
 * Header component
 */
class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            efficyUrl: null
        };

        this.logout = this.logout.bind(this);
        this.openWellBeing = this.openWellBeing.bind(this);
        this.handleSaveWellBeing = this.handleSaveWellBeing.bind(this);
    }

    componentDidMount() {
        const {user, getNotifications} = this.props;
        getNotifications();

        const checker = "beeced4c-c95e-48d7-9b3d-8cd98f98a88a";
        const intranetChecker = "f70a2143-49bc-4aef-8f39-c603e30de80f";
        const encryptedIntranet = CryptoJs.AES.encrypt("EI", intranetChecker);
        const encrypted = CryptoJs.AES.encrypt(`${user.email};${user.efficyPassword};${encryptedIntranet}`, checker);
        const efficyUrl = `https://efficy.unipartners.be/EFFICY.DLL/home?page=HelpDeskLogon.htm&filebase=Helpdesk&language=NL&Database=Efficy&Custombase=intranet&ID=${encrypted}&PWD=${encryptedIntranet}`;
        this.setState({efficyUrl});
    }

    /**
     * Holds the logout function
     */
    logout() {
        const {logout} = this.props;
        logout();
    }


    openWellBeing() {
        this.setState({showModal: true})
    }

    handleSaveWellBeing(form) {
        const {saveWellBeing} = this.props;

        const data = form.fields.map(field => ({
            id: field.id,
            value: field.value,
        }))

        saveWellBeing({data});

        const win = window.open(this.state.efficyUrl, '_blank');
        win.focus();

        this.setState({showModal: false})
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {hideContent, user} = this.props;
        const {notificationList, updateNotifications} = this.props;
        const {showModal} = this.state;

        /** Returns TopBar, NavBar, Hamburger and UP logo */
        return (
            <header className={"header"}>
                <Container>
                    <Link to={"/home"}><img src={Logo} alt={"UniPartners, united around values"} className={"logo"}/></Link>

                    {!hideContent &&
                    <nav>
                        <TopBar notificationList={notificationList}
                                updateNotifications={updateNotifications}
                                logout={this.logout}/>

                        <NavBar openWellBeing={this.openWellBeing} efficyUrl={this.state.efficyUrl}/>
                        <Hamburger openWellBeing={this.openWellBeing} efficyUrl={this.state.efficyUrl}/>
                    </nav>
                    }

                </Container>

                {user.showBarometer &&
                <WellBeingBarometer showModal={showModal}
                                    closeModal={() => this.setState({showModal: false})}
                                    saveForm={this.handleSaveWellBeing}
                />
                }
            </header>
        );
    };
}

const mapStateToProps = ({notificationReducer, authReducer}) => {
    const {notificationList} = notificationReducer;
    const {user} = authReducer;

    return {notificationList, user};
};

const mapDispatchToProps = {
    logout, closeNavbar, getNotifications, updateNotifications, saveWellBeing
};


export default connect(mapStateToProps, mapDispatchToProps)(Header);
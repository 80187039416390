import React, {Component} from "react";
import 'react-image-crop/dist/ReactCrop.css';
import {connect} from "react-redux";
import {updateAccount} from "../../redux/actions/auth.actions";
import {injectIntl} from "react-intl";
import Comments from "../Comments/Comments";
import EmptyList from "../EmptyList/EmptyList";
import Stickers from "../Stickers/Stickers";

/**
 * Welcome to our team component
 */
class Welcome extends Component {
    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {user} = this.props;
        const {comments, stickers} = user;

        /** return Comments if there is at least one */
        return (
            <div>
                {stickers && stickers.length !== 0 &&
                <div className={"mt-60"} style={{marginTop: 0}}>
                    <h6>Welkom bij het team {user.firstName}</h6>
                    <Stickers stickers={stickers} addButton={false}/>
                </div>
                }

                {comments.length !== 0
                    ? <Comments comments={comments} addButton={false}/>
                    : <EmptyList id={"EmptyList.welcome"}/>
                }
            </div>
        )
    }
}

export default connect(null, {updateAccount})(injectIntl(Welcome));
import React, {Component} from 'react';
import {Col, Row} from "../../utils/Grid/Grid";
import Load from "../../utils/Loading/Load";
import {connect} from "react-redux";
import {deleteEvent, loadEvents, publishEvent, reloadEvents} from "../../redux/actions/event.actions";
import './Event.scss';
import OverviewItem from "../OverviewItem/OverviewItem";
import {injectIntl} from "react-intl";
import OverviewSidebar from "../OverviewSidebar/OverviewSidebar";
import EmptyList from "../EmptyList/EmptyList";
import DateUtility from "../../utils/dateUtils";
import {loadTags} from "../../redux/actions/tag.actions";

/**
 * Event overview component
 */
class EventList extends Component {
    constructor(props) {
        super(props);

        const selectedFilters = props.location.state ? props.location.state.selectedFilters : null;

        /** State of the component */
        this.state = {
            selectedFilters: selectedFilters || [],
            showArchive: false,
            showUnpublished: false,
            showLanguages: false
        };

        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.handleArchive = this.handleArchive.bind(this);
        this.handleUnpublished = this.handleUnpublished.bind(this);
        this.handleLanguages = this.handleLanguages.bind(this);
    }

    /**
     * Load events on component mount if non are loaded
     */
    componentDidMount() {
        const {loadEvents, eventList, loadTags, intl} = this.props;
        const {locale} = intl;

        document.title = `UniPartners Group | What's UP`;

        if (eventList.length === 0 || (eventList[0] && eventList[0].language !== locale))
            loadEvents();

        loadTags('events')
    }


    /**
     * See unpublished events
     */
    handleUnpublished() {
        const {showUnpublished} = this.state;
        const {reloadEvents} = this.props;

        reloadEvents(!showUnpublished ? 'unpublished' : 'published');
        this.setState(prevState => ({showUnpublished: !prevState.showUnpublished}))
    }

    /**
     * See events in all languages
     */
    handleLanguages() {
        const {showLanguages} = this.state;
        this.setState({showLanguages: !showLanguages})
    }


    /**
     * See the archive of events
     */
    handleArchive() {
        const {showArchive} = this.state;
        this.setState({showArchive: !showArchive})
    }


    /**
     * See events with selected tags
     * @param selectedOption
     */
    handleTagsChange(selectedOption) {
        const arr = [];
        if (selectedOption) {
            selectedOption.map(opt => {
                return arr.push(opt)
            })
        }
        this.setState({selectedFilters: arr})
    }


    /**
     * Function to check if all tags are added to an event
     * @param haystack
     * @returns {*}
     */
    containsAll(haystack) {
        const {selectedFilters} = this.state;
        const {tags} = haystack;

        const tagValues = tags.map(x => {
            return x;
        });

        return selectedFilters.every(selectedFilter => {
            return tagValues.includes(selectedFilter.value)
        });
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {isLoading} = this.props;

        /** Return renderList is events are loaded */
        return (
            <div>
                {isLoading
                    ? <Load/>
                    : this.renderList()
                }
            </div>
        )
    }


    /**
     * Component renderList method
     * @returns {*}
     */
    renderList() {
        const {selectedFilters, showArchive, showUnpublished, showLanguages} = this.state;
        let {user, role, permissions, eventList, tags, publishEvent, deleteEvent, intl} = this.props;
        const {messages} = intl;

        /** filter events for archive */
        eventList = eventList.filter(event => {
            const {lastDate, isCurrent} = DateUtility;
            const {days} = event;

            if (days.length !== 0) {
                if (showArchive && !isCurrent(lastDate(days)))
                    return event;
                else if (!showArchive && isCurrent(lastDate(days)))
                    return event;
            }

            return null;
        });

        /** filter events by tag */
        if (selectedFilters.length !== 0) {
            eventList = eventList.filter(event => {
                return this.containsAll(event) ? event : null;
            });
        }

        /** filter events by published state */
        if (!showUnpublished) {
            eventList = eventList.filter(article => {
                return article.publishedAt;
            })
        }

        /** filter events by language */
        if (!showLanguages) {
            eventList = eventList.filter(article => {
                return article.language === intl.locale;
            })
        }

        /** Return OverviewSidebar and mapping for OverviewItem */
        return (
            <Row className={"events"}>
                <Col lg={4} md={6} sm={12} className={eventList.length === 1 ? "tag-filter offset-lg-4" : "tag-filter"}>
                    <OverviewSidebar selectedFilters={selectedFilters}
                                     tags={tags.filter(x => x.type === 'events')}
                                     handleArchive={this.handleArchive}
                                     showArchive={showArchive}
                                     handleTagsChange={this.handleTagsChange}

                                     newLink={permissions.includes("events_add") && "evenement-toevoegen"}
                                     newText={permissions.includes("events_add") && "Evenement toevoegen"}
                                     handleUnpublished={permissions.includes("events_see_unpublished") && this.handleUnpublished}
                                     showUnpublished={permissions.includes("events_see_unpublished") && showUnpublished}
                                     handleLanguages={permissions.includes("events_add") && this.handleLanguages}
                                     showLanguages={permissions.includes("events_add") && showLanguages}
                                     messages={messages}
                    />
                </Col>

                {eventList.length === 0
                    ? <Col lg={8} md={6} sm={12}><EmptyList id={"EmptyList.events"}/></Col>
                    : eventList.map((event, i) => {
                        const {creator, organisors, name, days, location, publishedAt, translated, id, slug, hasForm, hasInvitations} = event;

                        let admin = {
                            translated: translated
                        };

                        const isAuthor = user.id === creator.id || organisors.find(x => x.id === user.id);
                        const hasPermission = role.name === 'admin' || role.name === 'dev' || role.name === 'editor' || role.name === 'staff' || (role.name === 'coceco' && isAuthor);
                        if (hasPermission && permissions.includes("events_publish"))
                            admin = {
                                ...admin,
                                published: (id) => publishEvent(id)
                            }

                        if (hasPermission && permissions.includes("events_delete"))
                            admin = {
                                ...admin,
                                delete: (id) => deleteEvent(id, this.props.history)
                            }

                        if (hasPermission && permissions.includes("events_add"))
                            admin = {
                                ...admin,
                                add: `/evenement-toevoegen/${id}`
                            }

                        if (hasPermission && permissions.includes("events_update"))
                            admin = {
                                ...admin,
                                update: `/evenement-wijzigen/${id}`
                            }

                        if (hasPermission && permissions.includes("events_see_results"))
                            admin = {
                                ...admin,
                                results: `/evenementen/${slug}/inschrijvingen`
                            }


                        return (
                            <Col lg={4} md={6} sm={12} key={i} className={"event"}>
                                <OverviewItem
                                    id={id}
                                    name={name}
                                    location={location}
                                    days={days}
                                    route={messages["routes.events"]}
                                    publishDate={publishedAt}
                                    admin={admin}
                                    slug={slug}
                                    hasInvitations={hasInvitations}
                                    organisers={organisors}
                                    hasForm={hasForm}
                                />
                            </Col>
                        )
                    })
                }
            </Row>
        )
    }
}


const mapStateToProps = ({authReducer, eventReducer, tagReducer}) => {
    const {user, role, permissions} = authReducer;
    const {eventList, isLoading} = eventReducer;
    const {tags} = tagReducer;
    return {user, role, permissions, eventList, tags, isLoading};
};


const mapDispatchToProps = {
    loadEvents, reloadEvents, publishEvent, deleteEvent, loadTags
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EventList));
import axios from "axios";
import {API_URL} from "../constants/defaults";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";
import {LOAD_FORM_RESULTS} from "../constants/subscriptionResult.constants";


export const loadFormResults = (type, formId = null) => (dispatch, getStore) => {
    const store = getStore();
    const {token} = store.authReducer;

    dispatch({type: LOAD_FORM_RESULTS, payload: {resultsLoading: true, results: []}});
    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.get(`${API_URL}/form-submissions/${type}/${formId}`, header)
        .then(({data}) => {
            dispatch({type: LOAD_FORM_RESULTS, payload: {resultsLoading: false, results: data}});
        })
        .catch(error => {
            dispatch({type: LOAD_FORM_RESULTS, payload: {resultsLoading: false, results: []}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "Formulier resultaten laden mislukt vanwegen een onverwachtte fout", type: 'error'})}});
        });
};


export const confirmRequest = (type, objectId, userId, confirm) => (dispatch, getStore) => {
    const store = getStore();
    const {token} = store.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(`${API_URL}/confirm-invitation`, {user: userId, type, id: objectId, confirm}, header)
        .then(({data}) => {
            dispatch({type: LOAD_FORM_RESULTS, payload: {resultsLoading: false, results: data}});
        })
        .catch(error => {
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "Aanvraag bevestigen mislukt vanwegen een onverwachtte fout", type: 'error'})}});
        });
};

export const sendCustomMail = (body) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(`${API_URL}/send-custom-email`, body, header)
        .then(({data}) => {
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "Je e-mail werd verzonden.", type: 'success'})}});
        })
        .catch(error => {
            console.log(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "E-mail verzenden mislukt vanwege een onverwachte fout.", type: 'error'})}});
        })
};
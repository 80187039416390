import React, {Component} from "react";
import {Col, Row} from "../../../utils/Grid/Grid";
import {BTN_CLASSES} from "../../../css_constants";
import Button from "../../../utils/Buttons/Button";
import {FormattedMessage} from "react-intl";
import Form from "../../../utils/Forms/Form";
import {connect} from "react-redux";
import {postTopping, updateTopping} from "../../../redux/actions/topping.actions";

const initialState = {
    form: {
        format: [
            {
                type: "col",
                breakpoint: 12,
                fields: ["topping", "description"]
            }, {
                type: "col",
                breakpoint: 6,
                fields: ["small"]
            }, {
                type: "col",
                breakpoint: 6,
                fields: ["large"]
            }, {
                type: "col",
                breakpoint: 12,
                fields: ["hasOptions"]
            }
        ],
        fields: [
            {
                name: "topping",
                type: "text",
                required: true,
                placeholder: "",
                label: "Naam",
                autocomplete: false,
                description: null
            }, {
                name: "description",
                type: "text",
                required: false,
                placeholder: "",
                label: "Beschrijving",
                autocomplete: false,
                description: null,
                valid: true
            }, {
                name: "hasOptions",
                type: "checkbox",
                required: false,
                label: "Heeft smos als optie",
                valid: true
            }, {
                name: "small",
                type: "number",
                required: true,
                placeholder: "",
                label: "Prijs klein",
                min: 0,
                step: 0.01,
                autocomplete: false,
                description: null
            }, {
                name: "large",
                type: "number",
                required: true,
                placeholder: "",
                label: "Prijs groot",
                min: 0,
                step: 0.01,
                autocomplete: false,
                description: null
            }
        ]
    },
    formValid: false,
    selected: null
}

class SandwichForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                format: [
                    {
                        type: "col",
                        breakpoint: 12,
                        fields: ["topping", "description"]
                    }, {
                        type: "col",
                        breakpoint: 6,
                        fields: ["small"]
                    }, {
                        type: "col",
                        breakpoint: 6,
                        fields: ["large"]
                    }, {
                        type: "col",
                        breakpoint: 12,
                        fields: ["hasOptions"]
                    }
                ],
                fields: [
                    {
                        name: "topping",
                        type: "text",
                        required: true,
                        placeholder: "",
                        label: "Naam",
                        autocomplete: false,
                        description: null
                    }, {
                        name: "description",
                        type: "text",
                        required: false,
                        placeholder: "",
                        label: "Beschrijving",
                        autocomplete: false,
                        description: null
                    }, {
                        name: "hasOptions",
                        type: "checkbox",
                        required: false,
                        label: "Heeft smos als optie",
                    }, {
                        name: "small",
                        type: "number",
                        required: true,
                        placeholder: "",
                        label: "Prijs klein",
                        min: 0,
                        step: 0.01,
                        autocomplete: false,
                        description: null
                    }, {
                        name: "large",
                        type: "number",
                        required: true,
                        placeholder: "",
                        label: "Prijs groot",
                        min: 0,
                        step: 0.01,
                        autocomplete: false,
                        description: null
                    }
                ]
            },
            formValid: false,
            selected: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedTopping} = this.props;
        const {selected} = this.state;

        if (selectedTopping && (!selected || selected !== selectedTopping)) {
            this.setState({selected: selectedTopping})
            Object.keys(selectedTopping).map(name => {
                this.setState(prevState => ({
                    form: {
                        ...prevState.form,
                        fields: prevState.form.fields.map(x => {
                            if (x.name !== name) return x;

                            return {
                                ...x,
                                value: selectedTopping[name],
                                valid: true,
                                error: ''
                            }
                        })
                    }
                }))
                return null;
            });
        }
    }

    handleSubmit() {
        const {postTopping, updateTopping} = this.props;
        const {form, selected} = this.state;

        const data = {
            topping: form.fields.find(x => x.name === 'topping').value,
            description: form.fields.find(x => x.name === 'description').value,
            small: form.fields.find(x => x.name === 'small').value,
            large: form.fields.find(x => x.name === 'large').value,
            hasOptions: form.fields.find(x => x.name === 'hasOptions').value === '1',
        }

        if (selected) {
            updateTopping(data, selected.id);
        } else {
            postTopping(data);
        }

        this.handleCancel();
    }

    handleCancel() {
        this.setState({
            form: {
                format: [
                    {
                        type: "col",
                        breakpoint: 12,
                        fields: ["topping", "description"]
                    }, {
                        type: "col",
                        breakpoint: 6,
                        fields: ["small"]
                    }, {
                        type: "col",
                        breakpoint: 6,
                        fields: ["large"]
                    }, {
                        type: "col",
                        breakpoint: 12,
                        fields: ["hasOptions"]
                    }
                ],
                fields: [
                    {
                        name: "topping",
                        type: "text",
                        required: true,
                        placeholder: "",
                        label: "Naam",
                        autocomplete: false,
                        description: null
                    }, {
                        name: "description",
                        type: "text",
                        required: false,
                        placeholder: "",
                        label: "Beschrijving",
                        autocomplete: false,
                        description: null,
                        valid: true
                    }, {
                        name: "hasOptions",
                        type: "checkbox",
                        required: false,
                        label: "Heeft smos als optie",
                        valid: true
                    }, {
                        name: "small",
                        type: "number",
                        required: true,
                        placeholder: "",
                        label: "Prijs klein",
                        min: 0,
                        step: 0.01,
                        autocomplete: false,
                        description: null
                    }, {
                        name: "large",
                        type: "number",
                        required: true,
                        placeholder: "",
                        label: "Prijs groot",
                        min: 0,
                        step: 0.01,
                        autocomplete: false,
                        description: null
                    }
                ]
            },
            formValid: false,
            selected: null
        }, this.props.unsetSelected());
    }

    render() {
        return (
            <div>
                <h1>Broodjes</h1>

                {this.renderForm()}
                {this.renderButtons()}
            </div>
        )
    }

    renderForm() {
        const {form} = this.state;

        return (
            <Row>
                <Form form={form}
                      formValid={(bool) => this.setState({formValid: bool})}
                      updateFields={(form) => this.setState({form: form})}
                />
            </Row>
        )
    }

    renderButtons() {
        const {formValid, selected} = this.state;

        /** Return the form buttons */
        return (
            <Col md={12} className={"right"}>
                <div className={BTN_CLASSES.GROUP}>
                    {selected &&
                    <Button buttonStyle={BTN_CLASSES.SECONDARY}
                            onClick={this.handleCancel}
                    >
                        <FormattedMessage id="form.button.cancel"/>
                    </Button>
                    }

                    <Button buttonStyle={BTN_CLASSES.PRIMARY}
                            onClick={this.handleSubmit}
                            disabled={!formValid}
                    >
                        <FormattedMessage id="form.button.save"/>
                    </Button>
                </div>
            </Col>
        )
    }
}

export default connect(null, {postTopping, updateTopping})(SandwichForm);
import React, {Component} from "react";
import {Calendar as BigCalendar, momentLocalizer} from 'react-big-calendar';
import {loadTrainings} from "../../redux/actions/training.actions";
import {loadEvents} from "../../redux/actions/event.actions";
import {connect} from "react-redux";
import moment from 'moment';
import 'moment/locale/nl-be';
import CalendarEvent from "./CalendarEvent";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.scss';
import {isNil} from "../../utils/helperFunctions";

/**
 * Calender component
 */
class Calendar extends Component {
    /**
     * Load trainings and events when component mounts and when non are loaded
     */
    componentDidMount() {
        const {loadTrainings, trainingList, loadEvents, eventList} = this.props;
        if (trainingList.length === 0)
            loadTrainings();

        if (eventList.length === 0)
            loadEvents();

        document.title = `UniPartners Group | Keep UP`;
    }


    /**
     * Set the className for a single event
     * @param event
     * @param start
     * @param end
     * @param isSelected
     * @returns {{className: string}}
     */
    eventStyleGetter(event, start, end, isSelected) {
        return {
            className: `calender__event ${event.type}`
        };
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {eventList, trainingList} = this.props;
        const localizer = momentLocalizer(moment);
        let events = [];

        /*
        const events = [
            {
                id: 0,
                title: 'D MOW',
                allDay: true,
                start: new Date(2020, 3, 30, 8, 0, 0),
                end: new Date(2020, 3, 30, 17, 0, 0),
                type: 'project'
            },
            {
                id: 11,
                title: 'Bank',
                start: new Date(2020, 4, 7, 8, 0, 0),
                end: new Date(2020, 4, 7, 17, 0, 0),
                type: 'project'
            },
            {
                id: 15,
                title: 'Verlof',
                allDay: true,
                start: new Date(2020, 4, 11, 18, 0, 0),
                end: new Date(2020, 4, 15, 21, 0, 0),
                type: 'leave',
                status: 'confirmed'
            },
            {
                id: 15,
                title: 'Verlof 2',
                allDay: true,
                start: new Date(2020, 4, 18, 18, 0, 0),
                end: new Date(2020, 4, 18, 21, 0, 0),
                type: 'leave',
                status: 'pending'
            },
        ];
        */

        /** Set events */
        for (let i = 0; i < eventList.length; i++) {
            const temp = eventList[i].days.map(day => ({
                id: i,
                title: eventList[i].name,
                slug: eventList[i].slug,
                start: new Date(day.startDate * 1000),
                end: new Date(day.endDate * 1000),
                type: 'event',
                status: eventList[i].status === 'not-invited' ? '' : (isNil(eventList[i].status) ? 'pending' : (eventList[i].status ? 'confirmed' : 'rejected'))
            }));

            events = [...events, ...temp];
        }

        /** Set trainings */
        for (let i = 0; i < trainingList.length; i++) {
            const temp = trainingList[i].days.map(day => ({
                id: i,
                title: trainingList[i].name,
                slug: trainingList[i].slug,
                start: new Date(day.startDate * 1000),
                end: new Date(day.endDate * 1000),
                type: 'training',
                status: trainingList[i].status === 'not-invited' ? '' : (isNil(trainingList[i].status) ? 'pending' : (trainingList[i].status ? 'confirmed' : 'rejected'))
            }));

            events = [...events, ...temp];
        }

        /** Set Dutch translations */
        const translation = {
            allDay: 'hele dag',
            previous: 'vorige',
            next: 'volgende',
            today: 'vandaag',
            month: 'maand',
            week: 'week',
            day: 'dag',
            agenda: 'agenda',
            date: 'datum',
            time: 'uur',
            event: 'evenement', // Or anything you want
            showMore: total => `+ ${total} meer items`
        };

        /** Set date formats */
        const formats = {
            dayFormat: (date, culture, localizer) => localizer.format(date, 'dd D', culture),
            weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dd', culture),
            dayRangeHeaderFormat: ({start, end}, culture, localizer) => localizer.format(start, 'D MMM YYYY', culture) + ' - ' + localizer.format(end, 'D MMM YYYY', culture),
            agendaHeaderFormat: ({start, end}, culture, localizer) => localizer.format(start, 'D MMM YYYY', culture) + ' - ' + localizer.format(end, 'D MMM YYYY', culture),
            dayHeaderFormat: (date, culture, localizer) => localizer.format(date, 'dddd D MMM YYYY', culture),
            agendaDateFormat: (date, culture, localizer) => localizer.format(date, 'dd D MMM', culture),
        };

        /** Return BigCalendar component */
        return (
            <div className="calendar__wrapper">
                <BigCalendar popup
                             views={['month', 'week', 'day', 'agenda']}
                             localizer={localizer}
                             messages={translation}
                             events={events}
                             culture={"nl"}
                             startAccessor="start"
                             endAccessor="end"
                             formats={formats}
                             components={{
                                 event: CalendarEvent
                             }}
                             //selectable={true}
                             //onSelectEvent={(event) => console.log(event)}
                             //onDoubleClickEvent={(event) => console.log(event)}
                             //onSelectSlot={(slotInfo) => console.log(slotInfo)}
                             className="calendar"
                             eventPropGetter={(this.eventStyleGetter)}
                />
            </div>
        );
    }
}


const mapStateToProps = ({eventReducer, trainingReducer}) => {
    const {eventList} = eventReducer;
    const {trainingList} = trainingReducer;
    return {eventList, trainingList};
};


const mapDispatchToProps = {
    loadTrainings, loadEvents
};


export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
import React, {Component} from "react";
import { Link } from "react-router-dom";
import EmptyList from "../../EmptyList/EmptyList";
import DateUtility from "../../../utils/dateUtils";

class AlertDropdown extends Component {
    constructor(props) {
        super(props)

        this.state = {
            listOpen: false,
        }

        this.ref = React.createRef();

        this.handleClickOutsideDropdown = this.handleClickOutsideDropdown.bind(this);
        this.handleScrollOutsideDropdown = this.handleScrollOutsideDropdown.bind(this);
        this.handleDropdown = this.handleDropdown.bind(this);
    }

    /**
     * Load stickers when none are loaded when component mounts
     */
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideDropdown, false);
        document.addEventListener('wheel', this.handleScrollOutsideDropdown, false);
    }


    /**
     * Remove eventlisteners when component unmounts
     */
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideDropdown, false);
        document.removeEventListener('wheel', this.handleScrollOutsideDropdown, false);
    }


    /**
     * Close add screen when user clicks outside box
     * @param e
     * @returns {boolean}
     */
    handleClickOutsideDropdown(e) {
        if (this.ref.current && this.ref.current.contains(e.target)) return true;

        this.setState({listOpen: false});
    }


    /**
     * Close add screen when user clicks outside box
     * @param e
     * @returns {boolean}
     */
    handleScrollOutsideDropdown(e) {
        if (this.ref.current && this.ref.current.contains(e.target)) return true;

        this.setState({listOpen: false});
    }

    handleClickLink() {
        this.setState({listOpen: false});
    }


    /**
     * Show add menu
     */
    handleDropdown() {
        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }));
    }


    render() {
        const {list} = this.props;
        const {listOpen} = this.state;

        return (
            <div className="dropdown alert">
                <ul className={`dropdown-menu ${listOpen ? 'open' : 'closed'}`} ref={this.ref}>
                    <li className="head">
                        <span>Waarschuwingen ({list.length})</span>
                    </li>

                    <ul className="notification-wrapper">
                        {list.length === 0
                            ? (
                                <li className="notification-box">
                                    <EmptyList id={"notifications.empty"}/>
                                </li>
                            ) : (
                                list.map((item, i) => {
                                    let title,
                                        message,
                                        path;

                                    switch (item.type) {
                                        case 'illness':
                                            title = 'Ziektemelding';
                                            message = `Je ziektemelding van ${DateUtility.toShortDate(item.accident.period[0])} is nog niet volledig. Breng deze ziektemelding zo snel mogelijk in orde.`;
                                            path = `/meld-ziekte/${item.id}`;
                                            break;
                                        case 'accident':
                                            title = 'Schademelding';
                                            message = `Je schademelding van ${DateUtility.toShortDate(item.accident.date)} is nog niet volledig. Breng deze schademelding zo snel mogelijk in orde.`;
                                            path = `/meld-schade/${item.id}`
                                            break;
                                        case 'timesheet':
                                            title = 'Timesheet';
                                            message = `Bedankt om je timesheet in orde te maken.`;
                                            path = '/timesheet'
                                            break;
                                        default:
                                            title = item.item.name;
                                            message = `Je hebt nog niet gereageerd op je uitnodiging voor "${item.item.name}". Schrijf je in uiterlijk voor ${item.item.maxAttendDate ? DateUtility.toShortDate(item.item.maxAttendDate) : DateUtility.toShortDate(item.item.days[0].startDate)}`;
                                            path = item.type === 'events' ? `/evenementen/${item.item.slug}` : `/opleidingen/${item.item.slug}`
                                            break;
                                    }

                                    return (
                                        <li key={i} className="notification-box">
                                            <Link to={path} onClick={() => this.setState({listOpen: false})}>
                                                <strong>{title}</strong>
                                                <div>{message}</div>
                                                <small>{DateUtility.toLongDate(item.date)} {DateUtility.toTime(item.date)}</small>
                                            </Link>
                                        </li>
                                    );
                                })
                            )
                        }
                    </ul>
                </ul>
            </div>
        )
    }
}

export default AlertDropdown;
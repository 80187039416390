import React, {Component} from "react";
import Form from "../../../utils/Forms/Form";
import Modal from "../../../utils/Modal/Modal";

class UrlModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                format: [
                    {
                        type: "group",
                        name: "group",
                        direction: "horizontal",
                        fields: ["url", "name"],
                    }
                ],
                fields: [
                    {
                        name: "url",
                        type: "url",
                        required: true,
                        placeholder: "",
                        label: "Link",
                        autocomplete: false,
                    }, {
                        name: "name",
                        type: "text",
                        required: false,
                        placeholder: "",
                        label: "Weergegeven naam",
                        autocomplete: false,
                        description: "Indien leeg zal de url weergegeven worden"
                    }
                ]
            },
            modal: false,
            valid: false
        }

        this.handleShow = this.handleShow.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    handleShow() {
        this.setState(prevState => ({modal: !prevState.modal}))
    }

    handleSave() {
        const {handleSave} = this.props;
        const {fields} = this.state.form;

        handleSave({
            name: fields.find(x => x.name === 'name').value,
            url: fields.find(x => x.name === 'url').value
        });

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                fields: [
                    {
                        name: "url",
                        type: "url",
                        required: true,
                        placeholder: "",
                        label: "Link",
                        autocomplete: false,
                    }, {
                        name: "name",
                        type: "text",
                        required: false,
                        placeholder: "",
                        label: "Weergegeven naam",
                        autocomplete: false,
                        description: "Indien leeg zal de url weergegeven worden"
                    }
                ]
            },
            modal: false,
            valid: false
        }))
    }

    render() {
        const {form, modal, valid} = this.state;

        return (
            <Modal show={modal}
                   className="attachments-form"
                   close={this.handleShow}
                   title={"Bijlagen"}
                   actions={[{
                       label: "Bewaren",
                       action: this.handleSave,
                       disabled: valid
                   }]}
            >
                    <Form form={form}
                          formValid={(bool) => this.setState({valid: bool})}
                          updateFields={(form) => this.setState({form: form})}
                    />
            </Modal>
        );
    }
}

export default UrlModal;
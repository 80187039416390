import React, {Component} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {loadUser} from "../../redux/actions/auth.actions";
import Login from "../Login/Login";
import Layout from "../Layout/Layout";
import {injectIntl} from "react-intl";
import Load from "../../utils/Loading/Load";
import Register from "../Login/Register";
import ForgotPass from "../Login/ForgotPass";

/**
 * Default App component
 */
class App extends Component {
    /**
     * Load the user when component loads
     */
    componentDidMount() {
        this.props.loadUser();
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {loggedIn, user, intl} = this.props;
        const {messages} = intl;

        /** Return Login for not-logged-in users. Load Layout for logged-in users */
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path={messages["routes.login"]} component={(props) => {
                        return (
                            loggedIn
                                ? <Redirect to={props.location.state ? props.location.state.from : "/"}/>
                                : <Login {...props}/>
                        )
                    }}/>
                    <Route exact path={"/registreer"} component={(props) => {
                        return (
                            loggedIn
                                ? <Redirect to={"/"}/>
                                : <Register {...props}/>
                        )
                    }}/>
                    <Route exact path={"/wachtwoord-vergeten"} component={(props) => {
                        return (
                            loggedIn
                                ? <Redirect to={"/"}/>
                                : <ForgotPass {...props}/>
                        )
                    }}/>
                    <Route path="/" component={(props) => {
                        return (
                            loggedIn
                                ? (
                                    user
                                        ? <Layout/>
                                        : <Load/>
                                )
                                : <Redirect to={{pathname: messages["routes.login"], state: {from: props.location}}}/>
                        )
                    }}/>
                </Switch>
            </BrowserRouter>
        );
    };
}


const mapStateToProps = ({authReducer}) => {
    const {loggedIn, user} = authReducer;
    return {loggedIn, user};
};


export default connect(mapStateToProps, {loadUser})(injectIntl(App));
import React from "react";
import {NavLink} from "react-router-dom";
import {SHARED_CLASSES} from "../../../css_constants";
import {connect} from "react-redux";
import {toggleNavbar} from "../../../redux/actions/view.actions";
import {FormattedMessage, injectIntl} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faFileInvoiceDollar, faGraduationCap, faHome} from "@fortawesome/free-solid-svg-icons";
import {faCalendarAlt} from "@fortawesome/free-regular-svg-icons";

/**
 * Navigation bar component
 * @param props
 * @returns {*}
 * @constructor
 */
const NavBar = (props) => {
    const {permissions, openWellBeing, efficyUrl, intl, toggleNavbar, user} = props;
    const {messages} = intl;


    /** Returns the links of the menu bar */
    return (
        <ul className={"navbar"}>

            <li className={SHARED_CLASSES.NAV_ITEM}>
                <NavLink to={messages["routes.home"]}
                         activeClassName={SHARED_CLASSES.ACTIVE}
                         className={SHARED_CLASSES.NAV_LINK}>
                    <FontAwesomeIcon icon={faHome} className={"icon"} fixedWidth/><FormattedMessage id={"menu.home"}>{(txt) => <span>{txt}</span>}</FormattedMessage>
                </NavLink>
            </li>


            {user.showBarometer && user.role.name !== 'staff'
                ? (
                    <li className={SHARED_CLASSES.NAV_ITEM}>
                        <button className={SHARED_CLASSES.NAV_LINK}
                                onClick={openWellBeing}
                        >
                            <FontAwesomeIcon icon={faFileInvoiceDollar} className={"icon"} fixedWidth/><FormattedMessage id={"menu.timesheet"}>{(txt) => <span>{txt}</span>}</FormattedMessage>
                        </button>
                    </li>
                ) : (
                    <li className={SHARED_CLASSES.NAV_ITEM}>
                        <a href={efficyUrl}
                            //activeClassName={SHARED_CLASSES.ACTIVE}
                           className={SHARED_CLASSES.NAV_LINK}
                           rel="noopener noreferrer"
                           target={"_blank"}
                        >
                            <FontAwesomeIcon icon={faFileInvoiceDollar} className={"icon"} fixedWidth/><FormattedMessage id={"menu.timesheet"}>{(txt) => <span>{txt}</span>}</FormattedMessage>
                        </a>
                    </li>
                )
            }

            {<li className={SHARED_CLASSES.NAV_ITEM}>
                <NavLink to={messages["routes.calendar"]}
                         activeClassName={SHARED_CLASSES.ACTIVE}
                         className={SHARED_CLASSES.NAV_LINK}>
                    <FontAwesomeIcon icon={faCalendarAlt} className={"icon"} fixedWidth/><FormattedMessage
                    id={"menu.calendar"}>{(txt) => <span>{txt}</span>}</FormattedMessage>
                </NavLink>
            </li>}

            {permissions.includes("trainings_see_published") &&
            <li className={`${SHARED_CLASSES.NAV_ITEM} trainings`}>
                <NavLink to={messages["routes.trainings"]}
                         activeClassName={SHARED_CLASSES.ACTIVE}
                         className={SHARED_CLASSES.NAV_LINK}>
                    <FontAwesomeIcon icon={faGraduationCap} className={"icon"} fixedWidth/><FormattedMessage id={"menu.trainings"}>{(txt) => <span>{txt}</span>}</FormattedMessage>
                </NavLink>
            </li>
            }

            <li className={SHARED_CLASSES.NAV_ITEM}>
                <button onClick={toggleNavbar}><FontAwesomeIcon icon={faBars} fixedWidth/></button>
            </li>
        </ul>
    )
};


const mapStateToProps = ({authReducer}) => {
    const {permissions, user} = authReducer;
    return {permissions, user};
};


export default connect(mapStateToProps, {toggleNavbar})(injectIntl(NavBar));

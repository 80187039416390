import React, {Component} from 'react';
import {Col, Row} from "../../utils/Grid/Grid";
import Load from "../../utils/Loading/Load";
import './Trainings.scss';
import OverviewItem from "../OverviewItem/OverviewItem";
import {connect} from "react-redux";
import {deleteTraining, loadTrainings, publishTraining, reloadTrainings} from "../../redux/actions/training.actions";
import {injectIntl} from "react-intl";
import DateUtility from "../../utils/dateUtils";
import OverviewSidebar from "../OverviewSidebar/OverviewSidebar";
import EmptyList from "../EmptyList/EmptyList";
import {loadCoces} from "../../redux/actions/coce.actions";
import {loadTags} from "../../redux/actions/tag.actions";

/**
 * Training overview component
 */
class TrainingList extends Component {
    constructor(props) {
        super(props);

        const selectedFilters = props.location.state ? props.location.state.selectedFilters : null;
        const firstLoad = props.location.state ? props.location.state.firstLoad : null;

        /** State of the component */
        this.state = {
            selectedFilters: selectedFilters || [],
            showArchive: false,
            firstLoad: firstLoad || true,
            showUnpublished: false,
            showLanguages: false
        };

        this.handleTagChange = this.handleTagChange.bind(this);
        this.handleArchive = this.handleArchive.bind(this);
        this.handleUnpublished = this.handleUnpublished.bind(this);
        this.handleLanguages = this.handleLanguages.bind(this);
    }


    /**
     * Load trainings and coces on component mount if non are loaded
     */
    componentDidMount() {
        const {loadTrainings, trainingList, loadCoces, loadTags, coces, location, intl} = this.props;
        const {locale} = intl;

        document.title = `UniPartners Group | Knowledge meets passion`;

        if (trainingList.length === 0 || (trainingList[0] && trainingList[0].language !== locale))
            loadTrainings();

        if (coces.length === 0)
            loadCoces();

        loadTags('trainings')

        this.setState({firstLoad: location.state ? location.state.firstLoad : true})
    }


    /**
     * Load selected user coce if overview is first loaded
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {firstLoad} = this.state;
        const {user} = this.props;

        if (user && user.coces && user.coces.length && firstLoad) {
            this.setState({
                selectedFilters: [{
                    value: user.coces[0],
                    label: user.coces[0],
                    type: "coce",
                }], firstLoad: false
            })
        }
    }


    /**
     * See unpublished trainings
     */
    handleUnpublished() {
        const {showUnpublished} = this.state;
        const {reloadTrainings} = this.props;

        reloadTrainings(!showUnpublished ? 'unpublished' : 'published');
        this.setState(prevState => ({showUnpublished: !prevState.showUnpublished}))
    }


    /**
     * See trainings in all languages
     */
    handleLanguages() {
        const {showLanguages} = this.state;
        this.setState({showLanguages: !showLanguages})
    }


    /**
     * See the archive of trainings
     */
    handleArchive() {
        const {showArchive} = this.state;
        this.setState({showArchive: !showArchive});
    }


    /**
     * See trainings with selected tags
     * @param selectedOption
     */
    handleTagChange(selectedOption) {
        const arr = [];
        if (selectedOption) {
            selectedOption.map(opt => {
                return arr.push(opt);
            });
        }
        this.setState({selectedFilters: arr});
    }


    /**
     * Function to check if all tags are added to a training
     * @param haystack
     * @returns {*}
     */
    containsAll(haystack) {
        const {selectedFilters} = this.state;
        const {tags, coce} = haystack;

        const tagValues = tags.map(x => {
            return x;
        });

        return selectedFilters.every(selectedFilter => {
            if (selectedFilter.type === "coce") {
                return selectedFilter.value === coce.name;
            }

            if (selectedFilter.type === "tag") {
                return tagValues.includes(selectedFilter.value)
            }

            return null;
        });
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {isLoading} = this.props;

        /** Return renderList if trainings are loaded */
        return (
            <div>
                {isLoading
                    ? <Load/>
                    : this.renderList()
                }
            </div>
        );
    }

    renderList() {
        const {selectedFilters, showArchive, showUnpublished, showLanguages} = this.state;
        let {user, role, permissions, trainingList, publishTraining, deleteTraining, tags, coces, intl} = this.props;
        const {messages} = intl;

        /** filter events for archive */
        trainingList = trainingList.filter(training => {
            const {lastDate, isCurrent} = DateUtility;
            const {days} = training;

            if (days.length > 0) {
                if (showArchive && !isCurrent(lastDate(days)))
                    return training;
                else if (!showArchive && isCurrent(lastDate(days)))
                    return training;
            }

            return null;
        });

        /** filter trainings by tag */
        if (selectedFilters.length > 0) {
            trainingList = trainingList.filter(training => {
                return this.containsAll(training) ? training : null;
            });
        }

        /** filter trainings by published state */
        if (!showUnpublished) {
            trainingList = trainingList.filter(training => {
                return training.publishedAt;
            })
        }

        /** filter trainings by language */
        if (!showLanguages) {
            trainingList = trainingList.filter(training => {
                return training.language === intl.locale;
            })
        }

        /** Return OverviewSidebar and mapping for OverviewItem */
        return (
            <Row className={"trainings"}>
                <Col lg={4} md={6} sm={12} className={trainingList.length === 1 ? "tag-filter offset-lg-4" : "tag-filter"}>
                    <OverviewSidebar coces={coces}
                                     selectedFilters={selectedFilters}
                                     tags={tags.filter(x => x.type === 'trainings')}
                                     handleArchive={this.handleArchive}
                                     showArchive={showArchive}
                                     handleTagsChange={this.handleTagChange}

                                     newLink={permissions.includes("trainings_add") && "opleiding-toevoegen"}
                                     newText={permissions.includes("trainings_add") && "Opleiding toevoegen"}
                                     handleUnpublished={permissions.includes("trainings_see_unpublished") && this.handleUnpublished}
                                     showUnpublished={permissions.includes("trainings_see_unpublished") && showUnpublished}
                                     handleLanguages={permissions.includes("trainings_add") && this.handleLanguages}
                                     showLanguages={permissions.includes("trainings_add") && showLanguages}
                                     messages={messages}
                    />
                </Col>

                {trainingList.length === 0
                    ? <Col lg={8} md={6} sm={12}><EmptyList id={"EmptyList.trainings"}/></Col>
                    : trainingList.map((training, i) => {
                        const {creator, organisors, name, days, location, logo, translated, publishedAt, id, slug, hasInvitations} = training;

                        let admin = {
                            translated: translated
                        };

                        const isAuthor = user.id === creator.id || organisors.find(x => x.id === user.id);
                        const hasPermission = role.name === 'admin' || role.name === 'dev' || role.name === 'editor' || role.name === 'staff' || (role.name === 'coceco' && isAuthor);
                        if (hasPermission && permissions.includes("trainings_publish"))
                            admin = {
                                ...admin,
                                published: (id) => publishTraining(id)
                            }

                        if (hasPermission && permissions.includes("trainings_delete"))
                            admin = {
                                ...admin,
                                delete: (id) => deleteTraining(id, this.props.history)
                            }

                        if (hasPermission && permissions.includes("trainings_add"))
                            admin = {
                                ...admin,
                                add: '/opleiding-toevoegen/' + id
                            }

                        if (hasPermission && permissions.includes("trainings_update"))
                            admin = {
                                ...admin,
                                update: '/opleiding-wijzigen/' + id
                            }

                        if (hasPermission && permissions.includes("trainings_see_results"))
                            admin = {
                                ...admin,
                                results: `/opleidingen/${slug}/inschrijvingen`
                            }

                        return (
                            <Col lg={4} md={6} sm={12} key={i} className={"training"}>
                                <OverviewItem id={id}
                                              logo={logo}
                                              name={name}
                                              location={location}
                                              days={days}
                                              route={messages["routes.trainings"]}
                                              publishDate={publishedAt}
                                              admin={admin}
                                              slug={slug}
                                              hasInvitations={hasInvitations}
                                              hasForm={true}
                                />
                            </Col>
                        )
                    })}
            </Row>
        )
    }
}


const mapStateToProps = ({authReducer, trainingReducer, tagReducer, coceReducer}) => {
    const {user, role, permissions} = authReducer;
    const {trainingList, isLoading} = trainingReducer;
    const {tags} = tagReducer;
    const {coces} = coceReducer;
    return {trainingList, user, role, permissions, tags, coces, isLoading};
};


const mapDispatchToProps = {
    loadTrainings, loadCoces, publishTraining, deleteTraining, loadTags, reloadTrainings
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TrainingList));
import {
    faFile,
    faFileAlt,
    faFileAudio,
    faFileExcel, faFileImage,
    faFilePdf,
    faFilePowerpoint, faFileVideo,
    faFileWord
} from "@fortawesome/free-regular-svg-icons";

export const getFileSize = (size) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (size === 0) return '0 Byte';

    const i = parseInt(Math.floor(Math.log(size) / Math.log(1024)));
    return `${Math.round(size / Math.pow(1024, i), 2)} ${sizes[i]}`;
}

export const getFileIcon = (mime) => {
    let icon = '';
    switch (mime) {
        case 'text/plain':
            icon = faFileAlt;
            break;
        case 'application/xls':
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            icon = faFileExcel;
            break;
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            icon = faFileWord;
            break;
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            icon = faFilePowerpoint;
            break;
        case 'application/pdf':
            icon = faFilePdf;
            break;
        case 'audio/mpeg':
            icon = faFileAudio;
            break;
        case 'image/jpeg':
        case 'image/png':
        case 'image/bmp':
        case 'image/gif':
            icon = faFileImage;
            break;
        case 'video/mp4':
        case 'video/wav':
        case 'video/wave':
        case 'video/quicktime':
            icon = faFileVideo;
            break;
        default:
            icon = faFile
    }

    return icon;
}

export const isValidFile = (file) => {
    const validFileExtensions = ["txt", "xls", "xlsx", "doc", "docx", "ppt", "pptx", "pdf", "mpeg", "jpg", "jpeg", "png", "bmp", "gif", "mp4", "wav", "wave", "m4v"];
    const validFileMimes = ['text/plain', 'application/xls', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf', 'audio/mpeg', 'image/jpeg', 'image/png', 'image/bmp', 'image/gif',
        'video/mp4', 'video/wav', 'video/wave', 'video/quicktime'];
    const fileExt = file.name.split('.').pop();

    return validFileExtensions.some(x => x === fileExt) && validFileMimes.some(x => x === file.type);
}
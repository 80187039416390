import React, { Component } from "react";
import { connect } from 'react-redux';
import { getNotifications, getNotificationById, updateNotifications } from "../../redux/actions/notification.actions";
import { pinArticle } from "../../redux/actions/news.actions";
import { addStickers } from "../../redux/actions/stickers.actions";
import { addComments } from "../../redux/actions/comment.actions";
import { getMedia } from "../../redux/actions/media.actions";
import { isEmpty, isNil } from '../../utils/helperFunctions';
import NotificationDetail from "./NotificationDetail/NotificationDetail";
import NotificationList from "./NotificationList/NotificationList";
import { injectIntl } from "react-intl";


class Notification extends Component {
    componentDidMount() {
        const {getNotifications, getNotificationById, notificationList, selectedNotification} = this.props;
        const {match} = this.props;

        if ( isNil(selectedNotification) && !isNil(match.params.id) ) {
            getNotificationById(match.params.id);
        } else if ( isEmpty(notificationList) && isNil(match.params.id) ) {
            getNotifications();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {getNotificationById, selectedNotification} = this.props;
        const {match} = this.props;

        if (selectedNotification && selectedNotification.id !== parseInt(match.params.id)) {
            getNotificationById(match.params.id);
        }
    }

    render() {
        const {isLoading, notificationList, selectedNotification, pinArticle} = this.props;
        const {addStickers, stickers} = this.props;
        const {addComments, comments} = this.props;
        const {getMedia, media} = this.props;
        const {match, intl} = this.props;
        const {messages} = intl;

        return (
            <div>
                {!isNil(match.params.id)
                    ? <NotificationDetail
                        isLoading={isLoading}
                        notification={selectedNotification}
                        pinArticle={pinArticle}
                        messages={messages}
                        addStickers={addStickers}
                        stickers={stickers}
                        addComments={addComments}
                        comments={comments}
                        media={media}
                        getMedia={getMedia}/>
                    : <NotificationList
                        isLoading={isLoading}
                        notificationList={notificationList}
                        messages={messages}
                        stickers={stickers}
                        comments={comments}/>
                }
            </div>
        );
    }
}

const mapStateToProps = ({notificationReducer, stickerReducer, commentReducer, mediaReducer}) => {
    const {isLoading, isLoaded, notificationList, selectedNotification} = notificationReducer;
    const {stickers} = stickerReducer;
    const {comments} = commentReducer;
    const {media} = mediaReducer;
    return {isLoading, isLoaded, notificationList, selectedNotification, stickers, comments, media};
}

const mapDispatchToProps = {
    getMedia, getNotifications, getNotificationById, updateNotifications, pinArticle, addStickers, addComments
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Notification));
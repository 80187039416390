import React from "react";
import {PDFDownloadLink, Document, Page, Text, View, Image, Font, StyleSheet} from '@react-pdf/renderer';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {BTN_CLASSES} from "../../../css_constants";
import Logo from "../../../assets/images/logo-up.jpg";
import DateUtility from "../../../utils/dateUtils";

/*Font.register({
    family: 'OpenSans', fonts: [
        {src: 'fonts/OpenSans-Light.ttf', fontWeight: 300},
        {src: 'fonts/OpenSans-LightItalic.ttf', fontWeight: 300, fontStyle: 'italic'},
        {src: 'fonts/OpenSans-Regular.ttf', fontWeight: 400},
        {src: 'fonts/OpenSans-Italic.ttf', fontWeight: 400, fontStyle: 'italic'},
        {src: 'fonts/OpenSans-SemiBold.ttf', fontWeight: 600},
        {src: 'fonts/OpenSans-SemiBoldItalic.ttf', fontWeight: 600, fontStyle: 'italic'},
        {src: 'fonts/OpenSans-Bold.ttf', fontWeight: 700},
        {src: 'fonts/OpenSans-BoldItalic.ttf', fontWeight: 700, fontStyle: 'italic'},
        {src: 'fonts/OpenSans-ExtraBold.ttf', fontWeight: 800},
        {src: 'fonts/OpenSans-ExtraBoldItalic.ttf', fontWeight: 800, fontStyle: 'italic'},
    ]
});*/

const styles = StyleSheet.create({
    page: {
        //fontFamily: 'OpenSans',
        fontSize: 12,
        fontWeight: 400
    },
    logo: {
        height: 50
    },
    section: {
        flexDirection: 'col',
        margin: 0,
        padding: 40,
        borderLeftWidth: 10,
        borderColor: '#AB182D',
        height: '100%'
    },
    header: {
        flexDirection: 'row',
        borderBottomWidth: 2,
        borderColor: '#9D9694',
        paddingBottom: 15
    },
    contact: {
        marginLeft: 120,
        color: '#9D9694',
    },

    tableHeader: {
        fontWeight: 700,
        borderBottomWidth: 2,
        fontSize: 11,
        marginTop: 30,
        borderColor: '#9D9694',
        flexDirection: 'row'
    },
    tableRow: {
        borderBottomWidth: 1,
        fontSize: 10,
        borderColor: '#9D9694',
        flexDirection: 'row'
    },
    tableFooter: {
        fontWeight: 700,
        borderTopWidth: 2,
        fontSize: 11,
        borderColor: '#9D9694',
        flexDirection: 'row'
    },
    title: {
        fontWeight: 700,
        fontSize: 12,
        marginTop: 20
    }
});

// TODO: set font in pfd when timesheet is pulled
const ResultsPdf = ({user, results, filter, toppings, objectType, object}) => {
    if (filter)
        results = results.filter(x => x.user.confirmed === 1);

    const renderFormResultDetail = (result) => {
        return (
            <View>
                {result && result.submissions.length > 0 && result.submissions.map((y, a) => (
                    <View key={a} style={{flexDirection: 'row'}}>
                        <Text style={{fontWeight: 'bold'}}>{y.field.label} : </Text>
                        <Text>{
                            y.value
                                ? (y.field.type === 'checkbox'
                                    ? 'ja'
                                    : (y.field.multiple
                                        ? y.value.map((z, i) => {
                                            return <Text>{z}{i + 1 !== y.value.length ? ', ' : ''}</Text>
                                        })
                                        : y.value))
                                : (y.field.type === 'checkbox' ? 'nee' : 'niet opgegeven')}</Text>
                    </View>
                ))}
            </View>
        )
    }

    const renderTrainingResultDetail = (result, date) => {
        return result.submissions.order && result.submissions.order.map((submission, i) => {
            if (submission.date === date) {
                const topping = toppings.find(x => x.id === submission.topping);
                let size, price, sup;

                switch (submission.size) {
                    case 'large_white':
                        size = 'Groot wit broodje';
                        price = 'large';
                        sup = 0.8;
                        break;
                    case 'large_dark':
                        size = 'Groot bruin broodje';
                        price = 'large';
                        sup = 0.8;
                        break;
                    case 'small_white':
                        size = 'Klein wit broodje';
                        price = 'small';
                        sup = 0.6;
                        break;
                    case 'small_dark':
                    default:
                        size = 'Klein bruin broodje';
                        price = 'small';
                        sup = 0.6;
                        break;
                }

                return (
                    <View key={i} style={{flexDirection: 'column', marginBottom: 10}}>
                        <View style={{flexDirection: 'row'}}>
                            <View style={{flexDirection: 'column', width: '67%'}}>
                                <Text>{topping ? size : 'Geen broodje'}</Text>
                                <Text style={{paddingLeft: '20px'}}>{topping && topping.topping ? `${topping.topping}` : ''}</Text>
                                <Text style={{paddingLeft: '20px'}}>{topping && topping.hasOptions && submission.extra ? 'Smos' : ''}</Text>
                                {result.submissions.remark !== 'null' ? <Text style={{paddingTop: '10px'}}>{result.submissions.remark}</Text> : null}
                            </View>
                            <View style={{width: '33%'}}>
                                <Text style={{
                                    textAlign: 'right',
                                    fontSize: 12
                                }}>{topping ? `${(parseFloat(topping[price]) + sup).toFixed(2).replace('.', ',')} EUR` : ''}</Text>
                            </View>
                        </View>
                    </View>
                );
            }
        })
    }

    const renderHeader = () => {
        return (
            <View style={styles.header}>
                <Image src={Logo} style={styles.logo}/>
                <View style={styles.contact}>
                    <Text>UniPartners {!user.department || user.department.name === 'IT' ? 'IT services' : (user.department.name === 'Finance' ? 'Finance' : 'Group')} NV</Text>
                    <Text>Kardinaal Cardijnstraat 7</Text>
                    <Text>2840 Terhagen</Text>
                </View>
            </View>
        )
    }

    const renderTableHeader = () => {
        return (
            <View style={styles.tableHeader}>
                <Text style={{flexGrow: 0, width: '25%'}}>Naam</Text>
                <Text style={{flexGrow: 0, width: objectType === 'training' ? '50%' : '75%'}}>
                    {objectType === 'trainings' ? 'Bestelling' : 'Formulier resultaat'}
                </Text>
                {objectType === 'trainings' &&
                <Text style={{flexGrow: 0, width: '25%', textAlign: 'right'}}>Prijs</Text>
                }
            </View>
        );
    }

    const renderFormTable = () => {
        return (
            <Page pageNumber={1} size="A4" orientation="portrait" style={styles.page}>
                <View style={styles.section}>
                    {renderHeader()}
                    {renderTableHeader()}

                    {results.map((result, i) => {
                        return (
                            <View key={i} style={styles.tableRow}>
                                <View style={{flexGrow: 0, width: '25%', flexDirection: 'row'}}>
                                    <Text>{`${result.user.lastName} ${result.user.firstName}`}</Text>
                                </View>
                                <View style={{flexGrow: 0, width: '75%', flexDirection: 'column'}}>
                                    {result.submissions && result.submissions.length !== 0
                                        ? renderFormResultDetail(result)
                                        : <Text>Geen resultaten</Text>
                                    }
                                </View>
                            </View>
                        )
                    })}
                </View>
            </Page>
        )
    }

    const renderTrainingFormTable = () => {
        return object.days.map((day, index) => {
            return (
                <Page key={index} pageNumber={index + 1} size="A4" orientation="portrait" style={styles.page}>
                    <View style={styles.section}>
                        {renderHeader()}
                        <Text style={styles.title}>{DateUtility.toShortDate(day.startDate)}</Text>
                        {renderTableHeader()}

                        {results.map((result, i) => {
                            return (
                                <View key={i} style={styles.tableRow}>
                                    <View style={{flexGrow: 0, width: '25%', flexDirection: 'row'}}>
                                        <Text>{`${result.user.lastName} ${result.user.firstName}`}</Text>
                                    </View>
                                    <View style={{flexGrow: 0, width: '75%', flexDirection: 'column'}}>
                                        {result.submissions && objectType === 'trainings' && result.submissions.length !== 0
                                            ? renderTrainingResultDetail(result, day.startDate)
                                            : <Text>Geen resultaten</Text>
                                        }
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                </Page>
            )
        })
    }

    return (
        <PDFDownloadLink document={(

            <Document>
                {objectType === 'trainings'
                    ? renderTrainingFormTable()
                    : renderFormTable()
                }
            </Document>

        )} className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`} fileName="resultaten.pdf">
            {({blob, url, loading, error}) => (loading ? <span><FontAwesomeIcon icon={faSpinner} spin fixedWidth/> {objectType === 'trainings' ? 'bestellingen' : 'resultaten'}</span> : <span><FontAwesomeIcon icon={faDownload} fixedWidth/> {objectType === 'trainings' ? 'bestellingen' : 'resultaten'}</span>)}
        </PDFDownloadLink>
    )
}

export default ResultsPdf;
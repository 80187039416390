import React, {Component} from 'react';
import {Container, Row, Col} from "../../utils/Grid/Grid";
import FormInput from "../../utils/Forms/FormInput";
import Logo from "../../assets/images/logo-up.png";
import Button from "../../utils/Buttons/Button";
import './Login.scss';
import {ALIGNMENT, BTN_CLASSES, BTN_TYPES, INPUT_TYPES, MARGINS} from "../../css_constants";
import {loginValidator} from "./LoginValidator";
import {connect} from "react-redux";
import {login} from "../../redux/actions/auth.actions";
import {injectIntl} from "react-intl";
import {Link} from "react-router-dom";
import Toasts from "../../utils/Toasts/Toasts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import DateUtility from "../../utils/dateUtils";

/**
 * Login component
 */
class Login extends Component {
    constructor(props) {
        super(props);

        /** State of the component */
        this.state = {
            email: '',
            password: '',
            emailValid: false,
            passwordValid: false,
            formValid: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = `UniPartners Group | United around values`;
    }


    /**
     * Handle change of the input fields
     * @param e
     */
    handleChange(e) {
        const {value, name} = e.target;
        this.setState({[name]: value}, this.validateField(name, value));
    }


    /**
     * Validate the input fields
     * @param name
     * @param value
     */
    validateField(name, value) {
        this.setState(loginValidator(this.state, this.props, name, value), this.validateForm);
    }


    /**
     * Validate the form
     */
    validateForm() {
        const {passwordValid, emailValid} = this.state;
        this.setState({formValid: (emailValid && passwordValid)});
    }


    /**
     * Submit the login form
     * @param e
     */
    handleSubmit(e) {
        e.preventDefault();

        const {email, password} = this.state;
        const {login} = this.props;

        login({email, password});
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {email, password, formValid} = this.state;
        const {formErrors, intl} = this.props;
        const {messages} = intl;

        /** Return the login form */
        return (
            <Container>
                {/*<LanguageSwitcher className={"login"} />*/}

                <Row style={{height: '100vh', paddingTop: '0'}}>
                    <Col xl={7} lg={8} md={9} sm={10} xs={11} style={{margin: MARGINS.AUTO}}>
                        <img src={Logo} alt={"UniPartners, united around values"} className={"login-logo"}/>

                        {DateUtility.isPast(new Date('2024-04-01 23:00:00').getTime() / 1000) &&
                        <div className="toasts inline">
                            <div className={`toast__container warning`}>
                                <div className={"toast__notification"}>
                                    <div className={"toast__icon"}>
                                        <FontAwesomeIcon icon={faExclamationTriangle}/>
                                    </div>

                                    <div className={"toast__body"}>
                                        <b>Opgelet!</b><br />
                                        Vanaf 2 april 2024 is intranet niet meer via deze links beschikbaar. Vanaf dan kan je inloggen via <a href="https://ketchup.unipartners.org">https://ketchup.unipartners.org</a>.<br /><br/>
                                        Je kan inloggen op KetchUP met je inloggegevens van O365.
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        <form name={"form"} onSubmit={this.handleSubmit} noValidate={true}>
                            <FormInput
                                type={INPUT_TYPES.EMAIL}
                                name={"email"}
                                placeholder={messages["login.email.placeholder"]}
                                label={messages["login.email.label"]}
                                required={true}
                                value={email}
                                onChange={this.handleChange}
                                error={formErrors.email}
                                valid={!formErrors.email}
                            />

                            <FormInput
                                type={INPUT_TYPES.PASSWORD}
                                name={"password"}
                                label={messages["login.password.label"]}
                                placeholder={messages["login.password.placeholder"]}
                                required={true}
                                value={password}
                                onChange={this.handleChange}
                                error={formErrors.password}
                                valid={!formErrors.password}
                            />

                            <div className={`${BTN_CLASSES.GROUP} ${ALIGNMENT.RIGHT} login`}>
                                <Button
                                    type={BTN_TYPES.SUBMIT}
                                    label={messages["login.button.label"]}
                                    buttonStyle={BTN_CLASSES.PRIMARY}
                                    styles={{marginTop: 20}}
                                    disabled={!formValid}
                                />

                                <Link
                                    to={"/wachtwoord-vergeten"}
                                    label={""}
                                    className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.LINK}`}
                                    style={{marginTop: 20, padding: '8px 25px'}}
                                >Wachtwoord vergeten?</Link>
                            </div>
                        </form>
                    </Col>
                </Row>

                <Toasts/>
            </Container>
        );
    };
}


const mapStateToProps = ({authReducer}) => {
    const {loggedIn, formErrors} = authReducer;
    return {loggedIn, formErrors};
};


export default connect(mapStateToProps, {login})(injectIntl(Login));

import React, {useEffect, useRef, useState} from "react";
import DateUtility from "../../../utils/dateUtils";
import userImage from "../../../assets/images/user.png";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import Stickers from "../../Stickers/Stickers";
import {faChevronDown, faChevronUp, faThumbtack} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Comments from "../../Comments/Comments";
import {BTN_CLASSES} from "../../../css_constants";
import Button from "../../../utils/Buttons/Button";

/**
 * Main feed component
 */
const MainFeedItem = ({id, title, body, author, elementMedia, picture, createdAt, pinned, stickers, comments, media, route, loadMore, ...props}) => {
    const {addStickers, addComments, getMedia, handlePin, highlightCommentId, type, element, messages} = props;
    const [show, setShow] = useState(false);
    const content = useRef();

    const elementHeight = content.current ? content.current.offsetHeight : null
    useEffect(() => {
        addStickers(type, id);
        addComments(type, id);
        if (elementMedia && elementMedia.length > 0) getMedia(elementMedia[0]);
    })

    /** Load images */
    let image = {file: picture};
    if (element.media && element.media.length > 0) {
        const imageId = element.media[0].id;
        image = media.find(x => x.id === imageId);
    }

    const addDefaultSrc = (ev) => {
        ev.target.src = userImage;
    }



    return (
        <div className={`feed_item ${stickers ? 'with__stickers' : 'without__stickers'} ${comments ? 'with__comments' : 'without__comments'}`}>
            <div className={`feed__body ${loadMore && elementHeight > 98 ? 'with__read-more' : 'without__read-more'}`}>
                {pinned !== undefined &&
                <Button buttonStyle={BTN_CLASSES.LINK}
                        className={`pin ${pinned ? 'active' : ''} ${image && image.file ? '' : 'no-img'}`}
                        onClick={() => handlePin(id, !pinned)}
                >
                    <FontAwesomeIcon icon={faThumbtack}/>
                </Button>
                }

                {image && image.file &&
                <div className={"feed__image"}><img onError={addDefaultSrc} src={image.file} alt={image.description}/></div>
                }

                <div className={`feed__content ${show ? 'unfolded' : 'folded'} ${image && image.file ? '' : ' no-img'} ${pinned !== undefined ? 'with__pin' : 'without__pin'}`}>
                    <div className={"item__header"}>
                        <Link to={`${route}/${element.slug ? element.slug : element.id}`} className={"item__title"}>{title}</Link>
                        <div className={"item__date"}>
                            <FormattedMessage id={"itemDetail.author"} values={{author: (author ? <Link to={`${messages['routes.employees']}/${author.id}`}>{author.firstName}</Link> : 'admin'), date: DateUtility.toShortDate(createdAt)}}/>
                        </div>

                        {loadMore && elementHeight > 98 &&
                        <div className={"item__collapse"} onClick={() => setShow(!show)}>
                            <FontAwesomeIcon icon={show ? faChevronUp : faChevronDown}/>
                        </div>
                        }
                    </div>
                    <div className={"item__body"} dangerouslySetInnerHTML={{__html: body.length !== 0 ? body.replace(/<img .*?>/g, '').replace(/<[^/>][^>]*><\/[^>]+>/, '') : 'Dit bericht heeft geen inhoud'}} ref={content}/>
                </div>

                {loadMore && elementHeight > 98 &&
                <div className={"feed__read-more"} onClick={() => setShow(!show)}>
                    <FontAwesomeIcon icon={show ? faChevronUp : faChevronDown}/> <FormattedMessage id={show ? "feed.read.less" : "feed.read.more"}/>
                </div>
                }
            </div>

            <Stickers stickers={stickers} addButton={true} obj={element} objType={type}/>
            <Comments highlightCommentId={highlightCommentId} comments={comments} addButton={true} obj={element} objType={type}/>
        </div>
    )
}

export default MainFeedItem;
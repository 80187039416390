import React from 'react';
import './FormInput.scss'
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";

/**
 * Form input element
 * @param type
 * @param name
 * @param label
 * @param placeholder
 * @param description
 * @param value
 * @param min
 * @param max
 * @param step
 * @param valid
 * @param onChange
 * @param onBlur
 * @param error
 * @param className
 * @param required
 * @param readOnly
 * @param autocomplete
 * @param intl
 * @returns {*}
 * @constructor
 */
const FormInput = ({ type, name, label, placeholder, description, value, min, max, step, valid, onChange, onBlur, error, className, styles, required, readOnly, autocomplete, intl }) => (
    <div className={'form-group ' + className} style={styles}>
        {label && <label htmlFor={name}>{typeof label === 'object' ? intl.formatMessage({id: label.label}, label.value) : label} { required ? <code>*</code> : null }</label>}

        <input type={type}
               name={name}
               id={name}
               value={value === null ? '' : value}
               min={min}
               max={max}
               step={step}
               placeholder={(placeholder && typeof placeholder === 'object' ? intl.formatMessage({id: placeholder.label}, placeholder.value) : placeholder) + (placeholder && required && !label ? " *" : "")}
               className={'form-control ' + (valid === '' ? '' : ( valid ? 'is-valid' : 'is-invalid'))}
               onChange={onChange}
               onBlur={onBlur}
               autoComplete={autocomplete}
               readOnly={readOnly}
        />
        <div className="description">{description}</div>
        <small className="invalid-feedback">{error}</small>
    </div>
);


FormInput.defaultProps = {
    label: '', placeholder: '', value: '', error: '', valid: '', required: false, autocomplete: 'false', readOnly: false, className: '', styles: {}
};


FormInput.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    //onChange: PropTypes.func.isRequired
};


export default injectIntl(FormInput);
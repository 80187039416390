import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getAttachmentBlob} from "../../redux/actions/attachment.actions";
import {connect} from "react-redux";
import './Attachments.scss';
import {BTN_CLASSES} from "../../css_constants";
import {FormattedMessage} from "react-intl";
import Button from "../Buttons/Button";
import {faGlobeEurope, faTrash} from "@fortawesome/free-solid-svg-icons";
import {isNil} from "../helperFunctions";
import {getFileIcon} from "../FileUtils";

/**
 * Attachment component
 */
class Attachments extends Component {
    /**
     * Handle download of attachment
     * @param attachment
     */
    downloadBlob(attachment) {
        const {getAttachmentBlob} = this.props;
        getAttachmentBlob(attachment);
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {attachments, links, removeAttachment, downloadClaimAttachment, claimType} = this.props;

        /** Return mapping of attachments */
        return (
            <div className={"attachments"}>
                <span><FormattedMessage id={"Archive.attachments"}/></span>

                {links && links.length > 0 && links.map((link, i) => {
                    return (
                        <div key={i} className={"attachment"}>
                            <span className={removeAttachment ? 'with__remove' : ''}>
                                <a href={link.url}
                                   target="_blank"
                                   rel="noreferrer"
                                   className={BTN_CLASSES.BTN + " " + BTN_CLASSES.LINK}
                                >
                                    <FontAwesomeIcon icon={faGlobeEurope} fixedWidth/>
                                    {link.name}
                                </a>
                            </span>

                            {removeAttachment &&
                            <Button buttonStyle={BTN_CLASSES.LINK}
                                    className={`${BTN_CLASSES.DANGER}`}
                                    onClick={() => removeAttachment(link.id, 'links')}
                            >
                                <FontAwesomeIcon icon={faTrash} fixedWidth/>
                            </Button>
                            }
                        </div>
                    )
                })}

                {attachments && attachments.length > 0 && attachments.map((attachment, i) => {
                    let icoon = getFileIcon(attachment.mime);

                    return (
                        <div key={i} className={"attachment"}>
                            <span className={removeAttachment ? 'with__remove' : ''}>
                                {isNil(claimType)
                                    ? (
                                        <button onClick={() => this.downloadBlob(attachment)}
                                                className={BTN_CLASSES.BTN + " " + BTN_CLASSES.LINK}
                                        >
                                            <FontAwesomeIcon icon={icoon} fixedWidth/>
                                            {attachment.description}
                                        </button>
                                    )
                                    : (
                                        <Button
                                            buttonStyle={BTN_CLASSES.LINK}
                                            onClick={() => downloadClaimAttachment(attachment, claimType)}>
                                            <FontAwesomeIcon icon={icoon} fixedWidth/>
                                            {attachment.description}
                                        </Button>
                                    )
                                }
                            </span>

                            {removeAttachment &&
                            <Button buttonStyle={BTN_CLASSES.LINK}
                                    className={`${BTN_CLASSES.DANGER} float-right`}
                                    onClick={() => removeAttachment(attachment.id, 'attachments')}
                            >
                                <FontAwesomeIcon icon={faTrash}/>
                            </Button>
                            }
                        </div>
                    )
                })}
            </div>
        )
    }
}


export default connect(null, {getAttachmentBlob})(Attachments);
import React, {Component} from "react";
import Form from "../../../utils/Forms/Form";
import Button from "../../../utils/Buttons/Button";
import {BTN_CLASSES} from "../../../css_constants";
import {Col, Row} from "../../../utils/Grid/Grid";
import {connect} from "react-redux";
import {loadTags} from "../../../redux/actions/tag.actions";
import {Link} from "react-router-dom";
import {loadTraining, postTraining} from "../../../redux/actions/training.actions";
import {loadInstructors, loadStaff} from "../../../redux/actions/user.actions";
import DateUtility from "../../../utils/dateUtils";
import Invitations from "../Invitations/Invitations";
import Load from "../../../utils/Loading/Load";
import UrlModal from "../UrlModal/UrlModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faGlobeEurope, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";

class AddTraining extends Component {
    constructor(props) {
        super(props);

        document.title = `UniPartners Group | Admin opleidingen | Nieuw`;

        this.state = {
            form: {
                validateForm: false,
                format: [
                    {
                        type: "col",
                        breakpoint: 8,
                        fields: ["name"]
                    }, {
                        type: "col",
                        breakpoint: 4,
                        fields: ["published", "isAddedToCv"]
                    }, {
                        type: "col",
                        breakpoint: 12,
                        fields: ["location", "description"]
                    }, {
                        type: "col",
                        breakpoint: 4,
                        fields: ["date"]
                    }, {
                        type: "col",
                        breakpoint: 8,
                        fields: ["attachments", "instructor", "organizers", "tags", "logo"]
                    }
                ],
                fields: [
                    {
                        name: "name",
                        type: "text",
                        required: true,
                        placeholder: "De naam van de opleiding",
                        label: "Titel",
                        autocomplete: false,
                        description: null
                    }, {
                        name: "description",
                        type: "tiny",
                        required: false,
                        placeholder: "De beschrijving van de opleiding",
                        label: "Beschrijving",
                        autocomplete: false,
                        description: (
                            <div style={{display: 'flex', marginTop: 5, marginBottom: 5}}>
                                <FontAwesomeIcon icon={faExclamationTriangle} style={{height: 42.5, width: 'auto', margin: '5px 10px 5px 0', color: '#ef7d00'}} />
                                <div>
                                    <b>OPGELET!</b><br />
                                    De inhoud van deze opleiding wordt via intranet en de nieuwsbrief binnen heel UniPartners gedeeld. Deel hier geen gevoelige informatie.<br />
                                    Gebruik het mail-formulier op het inschrijvingen-overzicht om belangrijke informatie te delen met de genodigden.
                                </div>
                            </div>
                        )
                    }, {
                        name: "published",
                        type: "checkbox",
                        required: false,
                        label: "Publiceer de opleiding",
                    }, {
                        name: "isAddedToCv",
                        type: "checkbox",
                        required: false,
                        label: "Toevoegen aan CV",
                    }, {
                        name: "attachments",
                        type: "dropzone",
                        required: false,
                        label: "",
                        description: null,
                        files: []
                    }, {
                        name: "logo",
                        type: "file",
                        required: false,
                        label: "Logo",
                        description: null
                    }, {
                        name: "tags",
                        type: "creatable",
                        required: false,
                        multiple: true,
                        placeholder: "De tags van de opleiding",
                        label: "Tags",
                        autocomplete: false,
                        description: null,
                        options: []
                    }, {
                        name: "location",
                        type: "creatable",
                        required: true,
                        multiple: false,
                        placeholder: "De locatie van de opleiding",
                        label: "Locatie",
                        autocomplete: false,
                        description: null,
                        options: [
                            "Grote vergaderzaal, Gent",
                            "Kleine vergaderzaal, Gent",
                            "Opleidingslokaal PC, Terhagen",
                            "Opleidingslokaal Theater, Terhagen",
                            "Vergaderzaal 1e verdieping, Terhagen",
                            "Vergaderzaal Orangerie, Terhagen"
                        ]
                    }, {
                        name: "instructor",
                        type: "select",
                        required: false,
                        multiple: false,
                        placeholder: "De lesgever van de opleiding",
                        label: "Lesgever",
                        autocomplete: false,
                        description: null,
                        options: []
                    }, {
                        name: "organizers",
                        type: "select",
                        required: false,
                        multiple: true,
                        placeholder: "De organisatoren van de opleiding",
                        label: "Organisatoren",
                        autocomplete: false,
                        description: null,
                        options: []
                    }, {
                        name: "date",
                        type: "datepicker",
                        required: true,
                        label: "",
                        description: null,
                        value: [],
                        include: ["time", "bread"]
                    }, {
                        name: "time",
                        type: "timerange",
                        required: false,
                        label: `Start -en eindtijd voor `,
                        description: "Voor een open einde, maak start- en eindtijd gelijk",
                        sameIsValid: true
                    }, {
                        name: "bread",
                        type: "checkbox",
                        required: false,
                        label: "Broodjes",
                        include: ["reason"]
                    }, {
                        name: "reason",
                        type: "text",
                        required: false,
                        placeholder: "Waarom geen broodjes",
                        label: "Reden",
                        autocomplete: false,
                        description: null
                    }
                ]
            },
            formValid: false,
            links: [],

            invitations: null,
            loading: false
        };

        this.invitations = React.createRef();
        this.urls = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const {loadTags, loadTraining, loadStaff, loadInstructors, match} = this.props;
        const {id} = match.params;

        /**
         * Load tags
         */
        loadTags();

        /**
         *  Load coceco's
         */
        loadStaff();

        /**
         * Load instructors
         */
        loadInstructors();

        /**
         * Load original article if you want to clone
         */
        if (id) loadTraining(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tags, selectedTraining, match, staff, instructors} = this.props;
        const {toMoment} = DateUtility;
        const {id} = match.params;

        /**
         * Set tags
         */
        if (prevState.form.fields.find(x => x.name === "tags").options.length !== tags.length) {
            const fields = this.state.form.fields.map(field => {
                if (field.name !== "tags") return field;

                return {
                    ...field,
                    options: tags.map(tag => (tag.tag))
                }
            });

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields
                    }
                }
            });
        }

        /**
         * Set Coceco's
         */
        if (prevState.form.fields.find(x => x.name === "organizers").options.length !== staff.length) {
            const fields = this.state.form.fields.map(field => {
                if (field.name !== "organizers") return field;

                return {
                    ...field,
                    options: staff.map(coceco => ({value: coceco.id, label: `${coceco.firstName} ${coceco.lastName}`}))
                }
            });

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields
                    }
                }
            });
        }

        /**
         * Set instructors
         */
        if (prevState.form.fields.find(x => x.name === "instructor").options.length !== instructors.length) {
            const fields = this.state.form.fields.map(field => {
                if (field.name !== "instructor") return field;

                return {
                    ...field,
                    options: instructors.map(instructor => ({value: instructor.id, label: `${instructor.firstName} ${instructor.lastName}`}))
                }
            });

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields
                    }
                }
            });
        }

        /**
         * Set single language
         */
        if (selectedTraining && id && prevState.form.fields.find(x => x.name === 'language').options.length === 2) {
            const fields = this.state.form.fields.map(field => {
                if (field.name !== "language" && field.name !== "logo" && field.type !== "dropzone" && field.type !== "datepicker") return field;

                if (field.type === "dropzone") {
                    return {
                        ...field,
                        description: "Afbeeldingen zijn geladen bij de basis opleiding"
                    }
                }

                if (field.type === "datepicker") {
                    return {
                        ...field,
                        value: selectedTraining.days.map(day => {
                            const {startDate, endDate} = day;

                            return {
                                day: toMoment(startDate),
                                startTime: toMoment(startDate),
                                endTime: toMoment(endDate),
                            }
                        }),
                        description: "De dag(en) is/zijn geladen bij de basis opleiding"
                    }
                }

                if (field.name === "language") {
                    const language = selectedTraining.language === 'nl' ? 'Frans' : 'Nederlands';
                    return {
                        ...field,
                        options: [language],
                        value: language
                    }
                }

                if (field.name === "logo") {
                    return {
                        ...field,
                        description: "Het logo is geladen bij de basis opleiding"
                    }
                }

                return null;
            });

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields
                    }
                }
            });
        }
    }

    handleSubmit() {
        const {postTraining, match} = this.props;
        const {form, links, invitations} = this.state;
        const {id} = match.params;

        this.setState({loading: true});

        const data = new FormData();
        for (const field of form.fields) {
            if (field.type !== 'dropzone' && field.type !== 'file' && !(field.type === 'creatable' || field.type === 'select') && field.type !== 'datepicker' && field.type !== 'timerange') {
                data.set(field.name, field.value)
            } else if ((field.type === 'creatable' || field.type === 'select') && field.multiple) {
                for (const value of field.value) {
                    data.append(`${field.name}[]`, (value.hasOwnProperty('value') ? value.value : value))
                }
            } else if ((field.type === 'creatable' || field.type === 'select') && !field.multiple) {
                data.append(`${field.name}`, (field.value ? (field.value.hasOwnProperty('value') ? field.value.value : field.value) : field.value))
            } else if (field.type === 'datepicker') {
                for (const value of field.value) {
                    const date = new Date(value.day);
                    const start = new Date(value.startTime)
                    const end = new Date(value.endTime)

                    const object = {
                        day: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
                        startTime: `${start.getHours()}:${start.getMinutes()}:00`,
                        endTime: `${end.getHours()}:${end.getMinutes()}:00`,
                        bread: value.bread,
                        reason: value.reason
                    };

                    data.append(`${field.name}[]`, JSON.stringify(object));
                }
            } else if (field.type === 'file' && field.hasOwnProperty('files')) {
                for (const file of field.files) {
                    data.append(`${field.name}[]`, file)
                }
            } else if (field.type !== 'timerange' && field.type !== 'file') {
                for (const file of field.files) {
                    data.append(`${field.name}[]`, file)
                }
            }
        }

        if (links.length !== 0) {
            for (let i = 0; i <= links.length - 1; i++) {
                data.append(`links[${i}][name]`, links[i].name)
                data.append(`links[${i}][url]`, links[i].url)
            }
        }

        if (invitations) {
            data.append('invitations[maxAttendDate]', invitations.maxAttendDate);
            data.append('invitations[maxAttendees]', invitations.maxAttendees);

            for (const user of invitations.users) {
                data.append(`invitations[invitees][]`, (user.hasOwnProperty('value') ? user.value : user))
            }
        }

        postTraining(data, this.props.history, id);
    }

    render() {
        const {form, loading} = this.state;

        return (
            loading
                ? <Load showPatience/>
                : (
                    <Row>
                        <Form form={form}
                              formValid={(bool) => this.setState({formValid: bool})}
                              updateFields={(form) => this.setState({form: form})}
                        />

                        {this.renderButtons()}

                        <Invitations ref={this.invitations} setInvitations={(invitations) => this.setState({invitations})}/>
                        <UrlModal ref={this.urls} handleSave={(obj) => this.setState(prevState => ({links: [...prevState.links, obj]}))}/>
                    </Row>
                )
        )
    }

    renderButtons() {
        const {formValid, invitations, links} = this.state;

        return (
            <Col md={12}>
                <Row>
                    <Col xl={4} lg={6} md={8}>
                        {links.length !== 0 &&
                        <div className="uploaded-files">
                            {links.map((link, index) => (
                                <div className="file" key={index}>
                                    <div>
                                        <FontAwesomeIcon icon={faGlobeEurope} fixedWidth/>
                                        {link.name && link.name !== '' ? link.name : link.url}
                                    </div>
                                    <Button buttonStyle={BTN_CLASSES.LINK}
                                            buttonSize={BTN_CLASSES.SMALL}
                                            className={BTN_CLASSES.DANGER}
                                            onClick={() => this.setState(prevState => ({links: prevState.links.filter(x => x.name !== link.name && x.url !== link.url)}))}
                                    >
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </Button>
                                </div>

                            ))}

                            <div className="delete">
                                <Button buttonStyle={BTN_CLASSES.LINK}
                                        buttonSize={BTN_CLASSES.SMALL}
                                        className={BTN_CLASSES.DANGER}
                                        onClick={() => this.setState({links: []})}
                                >
                                    <FontAwesomeIcon icon={faTrash}/> Verwijder alles
                                </Button>
                            </div>
                        </div>
                        }

                        <div className={`${BTN_CLASSES.GROUP} flex`}>
                            <Button label={"URL's als bijlage toevoegen"}
                                    buttonStyle={BTN_CLASSES.SECONDARY}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    onClick={() => this.urls.current.handleShow()}
                                    styles={{width: '100%'}}
                            />
                            <Button label={"Deelnemers uitnodigen"}
                                    buttonStyle={BTN_CLASSES.SECONDARY}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    className={invitations ? BTN_CLASSES.SUCCESS : ''}
                                    onClick={() => this.invitations.current.handleShow()}
                            />
                        </div>
                    </Col>

                    <Col md={12} style={{margin: "1rem 0", textAlign: "right"}}>
                        <div className={BTN_CLASSES.GROUP}>
                            <Link to={`/opleidingen`}
                                  className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.SECONDARY}`}
                            >
                                Annuleren
                            </Link>

                            <Button buttonStyle={BTN_CLASSES.PRIMARY}
                                    disabled={!formValid}
                                    onClick={this.handleSubmit}
                            >
                                Bewaar opleiding
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Col>
        )
    }
}

const mapStateToProps = ({userReducer, tagReducer, trainingReducer}) => {
    const {instructors, staff} = userReducer;
    const {tags} = tagReducer;
    const {selectedTraining} = trainingReducer;
    return {tags, staff, selectedTraining, instructors};
};

const mapDispatchToProps = {
    loadTraining, postTraining, loadTags, loadStaff, loadInstructors
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTraining);
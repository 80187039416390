import React from 'react';
import { isEmpty, isNil } from "../../../utils/helperFunctions";
import Load from "../../../utils/Loading/Load";
import EmptyList from "../../EmptyList/EmptyList";
import MainFeedItem from "../../Home/MainFeed/MainFeedItem";
import SubFeedItem from "../../Home/Sidebar/SubFeed/SubFeedItem";
import MediaMainFeedItem from "../../Home/MainFeed/MediaMainFeedItem";
import AttachmentMainFeedItem from "../../Home/MainFeed/AttachmentMainFeedItem";
import PictureSubFeedItem from "../../Home/Sidebar/SubFeed/PictureSubFeedItem";
import userImage from "../../../assets/images/user.png";


const NotificationDetail = (props) => {
    const {isLoading,
        notification,
        pinArticle,
        messages,
        addStickers,
        stickers,
        addComments,
        comments,
        getMedia,
        media} = props;

    const renderMainFeedItem = () => {
        const {item, comment} = notification;
        const substrLength = item.type.length - 12
        const mainFeedItemType = item.type.substr(0, substrLength).toUpperCase();

        if ( !isNil(mainFeedItemType) && !isEmpty(mainFeedItemType) && stickers && comments ) {
            switch ( mainFeedItemType ) {
                case 'USER':
                    const {title, createdAt, user} = item
                    const {id, firstName, lastName, picture} = user;

                    // Load stickers and comments specific for NewsItem
                    addStickers('users', id);
                    addComments('users', id);

                    return (
                        <MainFeedItem id={id}
                                      title={title}
                                      body={`<b>${firstName} ${lastName}</b><div>${user.function}</div>`}
                                      picture={picture}
                                      createdAt={createdAt}
                                      element={user}
                                      type={"users"}
                                      loadMore={false}
                                      getMedia={getMedia}
                                      media={props.media}
                                      addStickers={addStickers}
                                      stickers={stickers["users"][id]}
                                      addComments={addComments}
                                      comments={comments["users"][id]}
                                      messages={messages}
                                      route={messages['routes.employees']}
                                      key={item.id}
                                      highlightCommentId={comment ? comment.id : 0}
                        />
                    );
                case 'NEWS':
                    const {newsItem} = item;

                    // Load stickers and comments specific for NewsItem
                    addStickers('news', newsItem.id);
                    addComments('news', newsItem.id);

                    // Load images
                    if ( newsItem.media && newsItem.media.length > 0 ) getMedia(newsItem.media[ 0 ]);

                    return (
                        <MainFeedItem id={newsItem.id}
                                      title={newsItem.title}
                                      body={newsItem.body}
                                      author={newsItem.author}
                                      elementMedia={media}
                                      createdAt={newsItem.createdAt}
                                      element={newsItem}
                                      type={"news"}
                                      loadMore={true}
                                      pinned={newsItem.pinned}
                                      handlePin={pinArticle}
                                      getMedia={getMedia}
                                      media={props.media}
                                      addStickers={addStickers}
                                      stickers={stickers["news"][newsItem.id]}
                                      addComments={addComments}
                                      comments={comments["news"][newsItem.id]}
                                      messages={messages}
                                      route={messages['routes.news']}
                                      key={newsItem.i}
                                      highlightCommentId={comment ? comment.id : 0}
                        />
                    );
                case 'EVENT':
                    const {event} = item;

                    /** load stickers and comments */
                    addStickers('events', event.id);
                    addComments('events', event.id);

                    if (event.media && event.media.length > 0) getMedia(event.media[0]);

                    return (
                        <MainFeedItem id={event.id}
                                      title={event.name}
                                      body={event.description}
                                      author={event.creator}
                                      elementMedia={media}
                                      createdAt={event.createdAt}
                                      element={event}
                                      type={"events"}
                                      loadMore={true}
                                      getMedia={getMedia}
                                      media={props.media}
                                      addStickers={addStickers}
                                      stickers={stickers["events"][event.id]}
                                      addComments={addComments}
                                      comments={comments["events"][event.id]}
                                      messages={messages}
                                      route={messages['routes.events']}
                                      key={event.id}
                                      highlightCommentId={comment ? comment.id : 0}
                        />
                    );
                case 'TRAINING':
                    const {training} = item;

                    /** load stickers and comments */
                    addStickers('trainings', training.id);
                    addComments('trainings', training.id);

                    /** load media */
                    if (training.media && training.media.length > 0) getMedia(training.media[0]);

                    return (
                        <MainFeedItem id={training.id}
                                      title={training.name}
                                      body={training.description}
                                      author={training.creator}
                                      elementMedia={media}
                                      createdAt={training.createdAt}
                                      element={training}
                                      type={"trainings"}
                                      loadMore={true}
                                      getMedia={getMedia}
                                      media={props.media}
                                      addStickers={addStickers}
                                      stickers={stickers["trainings"][training.id]}
                                      addComments={addComments}
                                      comments={comments["trainings"][training.id]}
                                      messages={messages}
                                      route={messages['routes.trainings']}
                                      key={training.id}
                                      highlightCommentId={comment ? comment.id : 0}
                        />
                    );
                case 'EVENTMEDIA':
                    /** load stickers and comments */
                    addStickers('mainfeed', item.id);
                    addComments('mainfeed', item.id);

                    /** load media */
                    media.map(obj => {
                        getMedia(obj);
                        return null;
                    });

                    return (
                        <MediaMainFeedItem id={item.id}
                                           title={item.title}
                                           elementMedia={item.event.media}
                                           element={item.event}
                                           type={"mainfeed"}

                                           getMedia={getMedia}

                                           addStickers={addStickers}
                                           stickers={stickers["mainfeed"][item.id]}
                                           addComments={addComments}
                                           comments={comments["mainfeed"][item.id]}
                                           route={messages['routes.events']}
                                           feedItem={item}
                                           key={item.id}
                                           highlightCommentId={comment ? comment.id : 0}
                        />
                    );
                case 'TRAININGMEDIA':
                    /** load stickers and comments */
                    addStickers('mainfeed', item.id);
                    addComments('mainfeed', item.id);

                    /** load media */
                    media.map(obj => {
                        getMedia(obj);
                        return null;
                    });

                    return (
                        <MediaMainFeedItem id={item.id}
                                           title={item.title}
                                           elementMedia={item.media}
                                           element={item.training}
                                           type={"mainfeed"}

                                           getMedia={getMedia}

                                           addStickers={addStickers}
                                           stickers={stickers["mainfeed"][item.id]}
                                           addComments={addComments}
                                           comments={comments["mainfeed"][item.id]}
                                           route={messages['routes.trainings']}
                                           feedItem={item}
                                           key={item.id}
                                           highlightCommentId={comment ? comment.id : 0}
                        />
                    );
                case 'EVENTATTACHMENT':
                    /** load stickers and comments */
                    addStickers('mainfeed', item.id);
                    addComments('mainfeed', item.id);

                    return (
                        <AttachmentMainFeedItem id={item.id}
                                                title={item.title}
                                                attachments={item.attachments}
                                                links={item.links}
                                                element={item.event}
                                                type={"mainfeed"}
                                                addStickers={addStickers}
                                                stickers={stickers["mainfeed"][item.id]}
                                                addComments={addComments}
                                                comments={comments["mainfeed"][item.id]}
                                                route={messages['routes.events']}
                                                feedItem={item}
                                                key={item.id}
                                                highlightCommentId={comment ? comment.id : 0}
                        />
                    );
                case 'TRAININGATTACHMENT':
                    /** load stickers and comments */
                    addStickers('mainfeed', item.id);
                    addComments('mainfeed', item.id);

                    return (
                        <AttachmentMainFeedItem id={item.id}
                                                title={item.title}
                                                attachments={item.attachments}
                                                links={item.links}
                                                element={item.training}
                                                type={"mainfeed"}
                                                addStickers={addStickers}
                                                stickers={stickers["mainfeed"][item.id]}
                                                addComments={addComments}
                                                comments={comments["mainfeed"][item.id]}
                                                route={messages['routes.trainings']}
                                                feedItem={item}
                                                key={item.id}
                                                highlightCommentId={comment ? comment.id : 0}
                        />
                    );
                default:
                    return (
                        <div>
                            <p>A single notification with feedType: {notification.item.type}</p>
                        </div>
                    );
            }
        }
    }

    const renderSubFeedItem = () => {
        const {id, item} = notification;
        const uniqueKey = `${id}_${item.id}`;

        /** Load stickers */
        addStickers('subfeed', item.id);

        const addDefaultSrc = (ev) => ev.target.src = userImage;

        return (
            <div>
                {item.type === 'pictureUpdate'
                    ? <PictureSubFeedItem key={uniqueKey}
                                          index={uniqueKey}
                                          feedItem={item}
                                          messages={messages}
                                          stickers={stickers["subfeed"][id]}
                                          addStickers={addStickers}
                                          type={"subfeed"}
                                          addDefaultSrc={addDefaultSrc}
                    />
                    : <SubFeedItem key={uniqueKey}
                                   index={item.id}
                                   feedItem={item}
                                   messages={messages}
                                   stickers={stickers["subfeed"][id]}
                                   addStickers={addStickers}
                                   type={"subfeed"}
                    />
                }
            </div>
        );
    }

    return (
        <div>
            {isLoading
                ? <Load/>
                : isNil(notification)
                    ? <div><EmptyList id={"404.notification"}/></div>
                    : notification.item.type.endsWith('MainFeedItem')
                        ? <div className={"main_feeds"}>{renderMainFeedItem()}</div>
                        : <div className={"sub_feeds"}>{renderSubFeedItem()}</div>
            }
        </div>
    );
}

export default NotificationDetail;

import {DELETE_TOPPING, LOAD_TOPPINGS} from "../constants/topping.constants";

let defaultState = {
    toppings: [],
    selectedTopping: null,
    toppingsLoading: false,
};

const loadToppings = (state, {toppings, toppingsLoading}) => ({...state, toppings, toppingsLoading});

const deleteTopping = (state, {id}) => {
    const temp = state.toppings.filter(x => x.id !== id);

    return {state, toppings: temp, selectedTopping: null};
};


const trainingReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_TOPPINGS:
            return loadToppings(state, payload);
        case DELETE_TOPPING:
            return deleteTopping(state, payload);
        default:
            return state;
    }
};

export default trainingReducer;
import {ADD_FEEDITEM, LOAD_MAIN_FEED, PIN_FEEDITEM} from "../constants/mainFeed.constants";

let defaultState = {
    mainFeed: [],
    hasMore: true,
    isLoading: false
};


const loadMainFeed = (state, { feeds, isLoading }) => {
    let mainFeed = state.mainFeed;
    if (feeds)
        mainFeed = [
            ...state.mainFeed,
            ...feeds
        ];

    const hasMore = feeds ? !(feeds.length === 0 || feeds.length < 10) : true;
    return { ...state, mainFeed, isLoading, hasMore };
};

const pinFeedItem = (state, {article}) => {
    const list = state.mainFeed.map(item => {
        if (item.newsItem) {
            if (item.newsItem.id === article.id) {
                return {
                    ...item,
                    newsItem: article
                };
            }
        }

        return item;
    });

    return {...state, mainFeed: list};
};


const addFeedItem = (state, {feeditem}) => {
    let {mainFeed} = state;

    if (feeditem)
        mainFeed.unshift(feeditem);

    return { ...state, mainFeed };
};


const mainFeedReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_MAIN_FEED:
            return loadMainFeed(state, payload);
        case PIN_FEEDITEM:
            return pinFeedItem(state, payload);
        case ADD_FEEDITEM:
            return addFeedItem(state, payload);
        default:
            return state;
    }
};

export default mainFeedReducer;
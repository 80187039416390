import React, {Component} from 'react';
import {connect} from "react-redux";
import {resetUserAccount, selectUser} from "../../redux/actions/user.actions";
import Load from "../../utils/Loading/Load";
import {FormattedMessage, injectIntl} from "react-intl";
import {Col, Row} from "../../utils/Grid/Grid";
import dateUtils from "../../utils/dateUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBirthdayCake, faBusinessTime, faEnvelope, faLongArrowAltLeft, faPhone, faUsers} from "@fortawesome/free-solid-svg-icons";
import {faFacebookSquare, faInstagramSquare, faLinkedin, faTwitterSquare, faYoutubeSquare} from '@fortawesome/free-brands-svg-icons'
import {Link} from "react-router-dom";
import EmptyList from "../EmptyList/EmptyList";
import Stickers from "../Stickers/Stickers";
import {addStickers} from "../../redux/actions/stickers.actions";
import {BTN_CLASSES} from "../../css_constants";
import Button from "../../utils/Buttons/Button";
import CryptoJs from "crypto-js";

/**
 * Employee detail component
 */
class EmployeeDetail extends Component {
    constructor(props) {
        super(props);

        this.resetAccount = this.resetAccount.bind(this);
    }

    /**
     * Load the selected user on component mount
     */
    componentDidMount() {
        const {selectUser, match} = this.props;
        const {id} = match.params;
        selectUser(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedUser} = this.props;

        document.title = `UniPartners Group | United around ${selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : 'loading...'}`;
    }


    resetAccount() {
        const {resetUserAccount, selectedUser} = this.props;
        resetUserAccount({id: selectedUser.id});
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {usersLoading, selectedUser} = this.props;

        /** Return renderUser when user is loaded */
        return (
            <div>
                {usersLoading
                    ? <Load/>
                    : (selectedUser
                            ? this.renderUser()
                            : <EmptyList id={"404.employee"}/>
                    )
                }
            </div>
        )
    }


    /**
     * Component renderUser method
     * @returns {*}
     */
    renderUser() {
        const {permissions, selectedUser, addStickers, stickers, intl} = this.props;
        const {firstName, lastName, email, phoneNumbers, birthDay, biography, startDate, picture, socialMedia, subFeedItems, coces, role, efficyPassword, settings} = selectedUser;
        let {answers} = selectedUser;
        const yearsOfService = dateUtils.timeBetween(startDate);
        const {messages} = intl;

        const pictureFeedItem = subFeedItems.find(x => x.type === 'pictureUpdate');
        const bioFeedItem = subFeedItems.find(x => x.type === 'bioUpdate');
        const interviewFeedItem = subFeedItems.find(x => x.type === 'interviewUpdate');

        /** load stickers */
        if (pictureFeedItem) addStickers('subfeed', pictureFeedItem.id);
        if (bioFeedItem) addStickers('subfeed', bioFeedItem.id);
        if (interviewFeedItem) addStickers('subfeed', interviewFeedItem.id);

        answers = answers.filter(x => x.answer.length !== 0)

        const checker = "beeced4c-c95e-48d7-9b3d-8cd98f98a88a";
        const intranetChecker = "f70a2143-49bc-4aef-8f39-c603e30de80f";
        const encryptedIntranet = CryptoJs.AES.encrypt("EI", intranetChecker);
        const encrypted = CryptoJs.AES.encrypt(`${email};${efficyPassword};${encryptedIntranet}`, checker);
        const efficyUrl = `https://efficy.unipartners.be/EFFICY.DLL/home?page=HelpDeskLogon.htm&filebase=Helpdesk&language=NL&Database=Efficy&Custombase=intranet&ID=${encrypted}&PWD=${encryptedIntranet}`;

        console.log(birthDay, (email && settings.showEmail), phoneNumbers.length > 0, socialMedia.length > 0);
        const showSidebar = selectedUser.role.name === 'instructor' ? (birthDay || (email && settings.showEmail) || phoneNumbers.length > 0 || socialMedia.length > 0) : true;

        /** Return user detail component */
        return (
            <Row className={"employee-detail"}>
                <Col lg={8} md={7}>
                    <h1>
                        {firstName} {lastName}
                        {selectedUser.function &&
                        <div dangerouslySetInnerHTML={{__html: selectedUser.function.replaceAll("\n", '<br />')}}/>
                        }
                    </h1>

                    {biography && biography.length !== 0 &&
                    <div>
                        <FormattedMessage id={"Account.biography"}>{txt => <h2>{txt}</h2>}</FormattedMessage>
                        <div dangerouslySetInnerHTML={{__html: biography.replaceAll("\n", '<br />')}}/>

                        {bioFeedItem &&
                        <Stickers stickers={stickers.subfeed[bioFeedItem.id]} addButton={true} obj={bioFeedItem} objType={"subfeed"} className={"left"}/>
                        }
                    </div>
                    }


                    {answers && answers.length > 0 &&
                    <div className={"mt-60"}>
                        <FormattedMessage id={"Account.moreAbout"} values={{name: firstName}}>{txt => <h2>{txt}</h2>}</FormattedMessage>
                        <Row className={"interview"}>
                            {answers.map((x, i) => {
                                if (x.answer)
                                    return (
                                        <Col className={"question"} lg={6} key={i}>
                                            <b>{x.question}</b>
                                            <div dangerouslySetInnerHTML={{__html: x.answer.replaceAll("\n", '<br />')}}/>
                                        </Col>
                                    )
                                else
                                    return null;
                            })}
                        </Row>

                        {interviewFeedItem &&
                        <Stickers stickers={stickers.subfeed[interviewFeedItem.id]} addButton={true} obj={interviewFeedItem} objType={"subfeed"} className={"left"}/>
                        }
                    </div>
                    }
                </Col>


                <Col lg={4} md={5}>
                    <div className={"detail-sidebar"}>
                        {role.name !== 'instructor' &&
                        <div className={`${BTN_CLASSES.GROUP} flex`}>
                            {permissions.includes("users_reset_account") &&
                            <Button buttonStyle={BTN_CLASSES.PRIMARY}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    onClick={this.resetAccount}
                                    label={"Reset account"}
                            />
                            }
                            {permissions.includes("users_see_timesheet") &&
                            <a href={efficyUrl}
                               target={"_blank"}
                               rel={"noopener noreferrer"}
                               className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`}
                            >Timesheet</a>
                            }
                        </div>
                        }


                        {picture &&
                        <div className={"image"}>
                            <img src={picture} alt={`${firstName} ${lastName}`}/>

                            {pictureFeedItem &&
                            <Stickers stickers={stickers.subfeed[pictureFeedItem.id]} addButton={true} obj={pictureFeedItem} objType={"subfeed"}/>
                            }
                        </div>
                        }


                        {showSidebar &&
                        <div className={"details"}>
                            {selectedUser.role.name !== 'instructor' &&
                            <>
                                <div className={"coce"}><FontAwesomeIcon icon={faUsers}/> {coces.map((x, i) => {
                                    if (x === 'AND') x = 'STAF';
                                    return i === 0 ? x : `, ${x}`
                                })}</div>
                                <div className={"service"}><FontAwesomeIcon icon={faBusinessTime} fixedWidth/> <FormattedMessage id={"Employee.service"} values={{itemCount: yearsOfService}}/></div>
                            </>
                            }

                            {birthDay &&
                            <div className={"birthday"}><FontAwesomeIcon icon={faBirthdayCake} fixedWidth/> {dateUtils.toShortDate(birthDay, true)}</div>
                            }

                            {email && (role.name !== 'instructor' || settings.showEmail) &&
                            <a className={"email"} href={`mailto:${email}`}><FontAwesomeIcon icon={faEnvelope} fixedWidth/> {email}</a>
                            }

                            {phoneNumbers.length > 0 && phoneNumbers.map((number, i) => (
                                <a key={i} className={"telephone"} href={`tel:${number.number}`}><FontAwesomeIcon icon={faPhone} fixedWidth/> {number.number}</a>
                            ))}

                            {socialMedia.length > 0 &&
                            <ul className={"social-media"}>
                                {socialMedia.map((social, i) => {
                                    let icon = null;
                                    switch (social.type) {
                                        case 'Facebook':
                                            icon = faFacebookSquare;
                                            break;
                                        case 'Twitter':
                                            icon = faTwitterSquare;
                                            break;
                                        case 'LinkedIn':
                                            icon = faLinkedin;
                                            break;
                                        case 'Instagram':
                                            icon = faInstagramSquare;
                                            break;
                                        case 'YouTube':
                                            icon = faYoutubeSquare;
                                            break;
                                        default:
                                            icon = faFacebookSquare;
                                            break;
                                    }

                                    if (social.link)
                                        return <li key={i}><a href={social.link} target={"_blank"} className={social.type} rel="noopener noreferrer"><FontAwesomeIcon icon={icon}/></a></li>

                                    return null;
                                })}
                            </ul>
                            }
                        </div>
                        }


                        <div className={"right"} style={{marginTop: "20px"}}>
                            <Link to={{pathname: messages["routes.employees"], state: {selectedCoce: this.props.history.location.coce}}}>
                                <FontAwesomeIcon icon={faLongArrowAltLeft}/> <FormattedMessage id={"General.backToOverview"}/>
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}


const mapStateToProps = ({authReducer, userReducer, stickerReducer}) => {
    const {permissions} = authReducer;
    const {selectedUser, usersLoading} = userReducer;
    const {stickers} = stickerReducer;
    return {permissions, selectedUser, stickers, usersLoading};
};


const mapDispatchToProps = {
    selectUser, addStickers, resetUserAccount
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EmployeeDetail));
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadInstructors, resetUserAccount} from "../../../redux/actions/user.actions";
import Load from "../../../utils/Loading/Load";
import EmptyList from "../../EmptyList/EmptyList";
import InstructorSingle from "./InstructorSingle";
import FormInput from "../../../utils/Forms/FormInput";

const InstructorList = ({select}) => {
    const dispatch = useDispatch();
    const instructors = useSelector(state => state.userReducer.instructors);
    const isLoading = useSelector(state => state.userReducer.instructorsLoading);
    const [search, setSearch] = useState('');

    const resetAccount = id => {
        dispatch(resetUserAccount({id}));
    }

    useEffect(() => {
        dispatch(loadInstructors());
        document.title = `UniPartners Group | Admin lesgevers`;
    }, [dispatch]);

    return (
        <div>
            {isLoading
                ? <Load/>
                : (instructors.length === 0
                    ? <EmptyList id="instructorList.empty"/>
                    : renderList(instructors, select, search, setSearch, resetAccount))
            }
        </div>
    );
}

const renderList = (instructors, select, search, setSearch, resetAccount) => {
    instructors = instructors.filter(x => x.firstName.toLowerCase().includes(search.toLowerCase()) || x.lastName.toLowerCase().includes(search.toLowerCase()) || x.email.toLowerCase().includes(search.toLowerCase()));

    return (
        <>
            <FormInput type={"text"}
                       placeholder={"zoeken..."}
                       name={"search"}
                       onChange={(e) => setSearch(e.target.value)}
                       value={search}
                       autocomplete={"off"}
            />

            {instructors.map((x, i) => <InstructorSingle key={i} select={select} instructor={x} resetAccount={resetAccount}/>)}
        </>
    )
}

export default InstructorList;
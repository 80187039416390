import React, {Component} from "react";
import {connect} from "react-redux";
import Form from "../Forms/Form";
import Modal from "../Modal/Modal";

class WellBeingBarometer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valid: false,
            form: {
                format: [{
                    type: 'group',
                    name: 'group1',
                    fields: props.user.showBarometer.map((x, i) => `q${i}` )
                }],
                fields: props.user.showBarometer.map((x, i) => ({
                    id: x.id,
                    name: `q${i}`,
                    type: x.type === 'text' ? 'text' : 'radio',
                    value: "",
                    required: true,
                    label: x.question,
                    description: null,
                    options: x.type === 'text' ? null : (x.type === 'grade' ? ["Goed", "Matig", "Slecht"] : (x.type === 'yes-no' ? ['Ja', 'Nee'] : x.options)),
                    className: x.type === 'grade' ? 'project' : (x.type === 'radio' ? '' : 'inline'),
                }))
            }
        }
    }

    render() {
        const {showModal, closeModal, saveForm} = this.props
        const {form, valid} = this.state;

        return (
            <Modal close={closeModal}
                   title="Welzijnsbarometer"
                   show={showModal}
                   actions={[{
                       label: "Bewaren",
                       action: () => saveForm(form),
                       disabled: valid
                   }]}
                   cancel={false}
            >
                <Form form={form}
                      formValid={(bool) => this.setState({valid: bool})}
                      updateFields={(form) => this.setState({form: form})}/>
            </Modal>
        )
    }
}

const mapStateToProps = ({authReducer}) => {
    const {user} = authReducer;
    return {user};
};

export default connect(mapStateToProps)(WellBeingBarometer);
import React from 'react';
import './Grid.scss';

/**
 * Container component
 * @param props
 * @returns {*}
 * @constructor
 */
export const Container = (props) => {
    let string = ['container'];
    props.className && string.push(props.className);

    /** Return container as a section */
    return <section className={string.join(' ')} style={props.style}>{props.children}</section>
};


/**
 * Row component
 * @param props
 * @returns {*}
 * @constructor
 */
export const Row = (props) => {
    let string = ['row'];
    props.className && string.push(props.className);

    /** Return div as a div */
    return <div className={string.join(' ')} style={props.style}>{props.children}</div>
};


/**
 * Col component
 * @param props
 * @returns {*}
 * @constructor
 */
export const Col = (props) => {
    let string = [];
    props.xs && string.push("col-xs-" + props.xs);
    props.sm && string.push("col-sm-" + props.sm);
    props.md && string.push("col-md-" + props.md);
    props.lg && string.push("col-lg-" + props.lg);
    props.xl && string.push("col-xl-" + props.xl);
    props.xsOrder && string.push("order-xs-" + props.xsOrder);
    props.smOrder && string.push("order-sm-" + props.smOrder);
    props.mdOrder && string.push("order-md-" + props.mdOrder);
    props.lgOrder && string.push("order-lg-" + props.lgOrder);
    props.xlOrder && string.push("order-xl-" + props.xlOrder);

    props.xsHide && string.push("d-sx-none");
    props.smHide && string.push("d-sm-none");
    props.mdHide && string.push("d-md-none");
    props.lgHide && string.push("d-lg-none");
    props.xlHide && string.push("d-xl-none");

    props.className && string.push(props.className);

    /** Return col as a div */
    return <div className={string.join(' ')} style={props.style}>{props.children}</div>
};
import React from "react";
import {Col} from "../../utils/Grid/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArchive, faEye, faEyeSlash, faPlusSquare, faThumbtack} from "@fortawesome/free-solid-svg-icons";
import {FormattedMessage} from "react-intl";
import "./OverviewSidebar.scss";
import SelectInput from "../../utils/Forms/SelectInput";
import Button from "../../utils/Buttons/Button";
import {BTN_CLASSES} from "../../css_constants";
import {Link} from "react-router-dom";
import FormInput from "../../utils/Forms/FormInput";

/**
 * Overview sidebar component
 * @param tags
 * @param coces
 * @param selectedFilters
 * @param handleTagsChange
 * @param pinned
 * @param showPinned
 * @param handlePinChange
 * @param handleUnpublished
 * @param showUnpublished
 * @param handleLanguages
 * @param showLanguages
 * @param showArchive
 * @param handleArchive
 * @param newLink
 * @param newText
 * @param intl
 * @returns {*}
 * @constructor
 */
const OverviewSidebar = ({tags, coces, selectedFilters, handleTagsChange, handleSearch, searchValue, pinned, showPinned, handlePinChange, handleUnpublished, showUnpublished, handleLanguages, showLanguages, showArchive, handleArchive, newLink, newText, messages}) => {
    let options = [],
        coceOptions = [],
        tagOptions = [];

    if (coces) {
        coces.map(coce => (
            coceOptions.push({value: coce.name, label: coce.name, type: "coce"})
        ));
    }

    if (tags) {
        tags.map(tag => (
            tagOptions.push({value: tag.tag, label: tag.tag, type: "tag"})
        ));
    }

    if (coces) {
        options = [{
            label: 'CoCes',
            options: coceOptions
        }, {
            label: 'Tags',
            options: tagOptions
        }];
    } else {
        options = tagOptions
    }

    /** Returns the view of the sidebar */
    return (
        <Col className={"overview-sidebar"}>
            <div className={"wrapper"}>
                {handlePinChange &&
                <div>
                    <FormattedMessage id={"tagFilter.pinned"} values={{itemCount: pinned}}>
                        {txt => <Button className={`btn btn-link ${showPinned ? 'active' : ''}`} onClick={handlePinChange}><FontAwesomeIcon icon={faThumbtack} fixedWidth/> {txt}</Button>}
                    </FormattedMessage>
                    <hr/>
                </div>
                }

                {handleArchive &&
                <div>
                    <FormattedMessage id={`tagFilter.${showArchive ? 'current' : 'archive'}`}>
                        {txt => <Button buttonStyle={BTN_CLASSES.LINK} onClick={handleArchive}><FontAwesomeIcon icon={faArchive} fixedWidth/> {txt}</Button>}
                    </FormattedMessage>
                    <hr/>
                </div>
                }

                {handleSearch &&
                <FormInput type={"text"}
                           placeholder={"zoeken..."}
                           name={"search"}
                           onChange={(e) => handleSearch(e.target.value)}
                           value={searchValue}
                />
                }

                {handleTagsChange &&
                <SelectInput placeholder={`- - ${messages["tagFilter.dropdown"]} - -`}
                             name={"filter"}
                             isMulti={true}
                             isClearable={true}
                             options={options}
                             onChange={handleTagsChange}
                             value={selectedFilters}
                />
                }

                <div className="admin">
                    {newLink &&
                    <Link to={newLink}><FontAwesomeIcon icon={faPlusSquare} fixedWidth/> {newText}</Link>
                    }

                    {handleUnpublished &&
                    <div>
                        <Button className={`btn btn-link ${showUnpublished ? 'active' : ''}`} onClick={handleUnpublished}>
                            <FontAwesomeIcon icon={showUnpublished ? faEye : faEyeSlash} fixedWidth/> {showUnpublished ? 'Ongepubliceerde items' : 'Toon ongepubliceerde items'}
                        </Button>
                    </div>
                    }

                    {/*handleLanguages &&
                    <div>
                        <Button className={`btn btn-link ${showLanguages ? 'active' : ''}`} onClick={handleLanguages}>
                            <FontAwesomeIcon icon={faLanguage} fixedWidth/> {showLanguages ? 'Alle items worden weergegeven' : 'Toon items in alle talen'}
                        </Button>
                    </div>
                    */}
                </div>
            </div>
        </Col>
    );
};


export default OverviewSidebar;
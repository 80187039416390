import {API_URL} from "../constants/defaults";
import axios from "axios";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";
import {DELETE_TOPPING, LOAD_TOPPINGS} from "../constants/topping.constants";

export const loadToppings = () => (dispatch, getState) => {
    dispatch({type: LOAD_TOPPINGS, payload: {toppings: [], toppingsLoading: true}});

    const state = getState();
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.get(`${API_URL}/toppings`, header)
        .then(({data}) => {
            dispatch({type: LOAD_TOPPINGS, payload: {toppings: data, toppingsLoading: false}});
        })
        .catch(error => {
            console.log(error)
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: 'Broodjeslijst laden mislukt vanwegen een onverwachte fout.', type: 'error'})}})
        });
};


export const postTopping = (data) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = { headers: {'Authorization': "Bearer " + token }};
    axios.post(`${API_URL}/toppings`, data, header)
        .then(({data}) => {
            dispatch({type: LOAD_TOPPINGS, payload: {toppings: data, toppingsLoading: false}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: ('Je broodje werd succesvol bewaard.'), type: 'success'})}});
        })
        .catch(error => {
            const message = handlePostErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};

export const updateTopping = (data, id) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = { headers: {'Authorization': "Bearer " + token }};
    axios.post(`${API_URL}/toppings/${id}`, data, header)
        .then(({data}) => {
            dispatch({type: LOAD_TOPPINGS, payload: {toppings: data, toppingsLoading: false}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: ('Je broodje werd succesvol bewaard.'), type: 'success'})}});
        })
        .catch(error => {
            const message = handlePostErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};

export const deleteTopping = (id) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = { headers: {'Authorization': "Bearer " + token} };
    axios.delete(`${API_URL}/toppings/${id}`, header)
        .then(() => {
            dispatch({type: DELETE_TOPPING, payload: {id}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "Je broodje werd succesvol verwijderd.", type: 'success'})}});
        })
        .catch(error => {
            const message = handleDeleteErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};


const handlePostErrors = (error) => {
    if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.code && error.response.data.error.code === 403) {
            return "Je hebt niet voldoende rechten om broodjes te bewaren.";
        }
        if (error.response.data.error.code && error.response.data.error.code === 422) {
            return "De opgegeven data was ongeldig.";
        }
    }
    return "Broodje bewaren mislukt vanwegen een onverwachte fout.";
};

const handleDeleteErrors = (error) => {
    if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.code && error.response.data.error.code === 403) {
            return "Je hebt niet voldoende rechten om een broodje te verwijderen.";
        }
        if (error.response.data.error.code && error.response.data.error.code === 422) {
            return "De opgegeven data was ongeldig.";
        }
    }
    return "Broodje verwijderen mislukt vanwegen een onverwachte fout.";
};
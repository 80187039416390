import {LOAD_EVENTS, LOAD_EVENT, ADD_EVENT, UPDATE_EVENT, DELETE_EVENT, DELETE_EVENT_MEDIA, RELOAD_EVENTS} from "../constants/event.constants";

let defaultState = {
    eventList: [],
    isLoading: false,
    selectedEvent: null
};

const loadEvents = (state, {events, isLoading}) => {
    let eventList = [];
    events.map(event => {
        return eventList.push({
            ...event,
            isLoaded: false
        });
    });
    return {...state, eventList, isLoading};
};

const reloadEvents = (state, {events, isLoading}) => {
    const array = events.map(event => ({
        ...event,
        isLoaded: false
    }));

    const eventList = [
        ...array
    ];

    return {...state, eventList, isLoading};
};

const loadEvent = (state, {selectedEvent, isLoading}) => {
    let {eventList} = state;

    if (!isLoading) {
        eventList = eventList.map(x => {
            if (x.id !== selectedEvent.id) return x;
            return {
                ...selectedEvent,
                isLoaded: true
            }
        });
    }

    return {...state, eventList, selectedEvent, isLoading}
};


const addEvent = (state, {event}) => {
    const eventList = [event, ...state.eventList];

    return {...state, eventList};
};

const updateEvent = (state, {event}) => {
    const temp = state.eventList.map(x => {
        if (x.id !== event.id) return x;
        return event;
    });

    return {...state, eventList: temp, selectedEvent: event};
};

const deleteEvent = (state, {id}) => {
    const temp = state.eventList.filter(x => x.id !== id);

    return {state, eventList: temp, selectedEvent: null};
};

const deleteEventMedia = (state, {id, type}) => {
    type = type === 'images' ? 'media' : type;
    const eventList = state.eventList.map(x => {
        if (x.id !== state.selectedEvent.id) return x;

        return {
            ...x,
            [type]: x[type].filter(y => y.id !== id)
        }
    });

    let media = state.selectedEvent[type];
    media = media.filter(x => x.id !== id);

    return {
        ...state,
        eventList: eventList,
        selectedEvent: {
            ...state.selectedEvent,
            [type]: media
        }
    };
};

const eventReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_EVENTS:
            return loadEvents(state, payload);
        case RELOAD_EVENTS:
            return reloadEvents(state, payload);
        case LOAD_EVENT:
            return loadEvent(state, payload);
        case ADD_EVENT:
            return addEvent(state, payload);
        case UPDATE_EVENT:
            return updateEvent(state, payload);
        case DELETE_EVENT:
            return deleteEvent(state, payload);
        case DELETE_EVENT_MEDIA:
            return deleteEventMedia(state, payload);
        default:
            return state;
    }
};

export default eventReducer;
import React from 'react';
import './FormInput.scss'
import Select from "react-select";
import PropTypes from "prop-types";

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            border: `1px solid ${data.type === 'coce' ? 'rgba(171,24,45, .4)' : 'rgb(157,150,148, .4)'}`,
    }}
};

/**
 * Select component
 * @param name
 * @param label
 * @param placeholder
 * @param options
 * @param value
 * @param description
 * @param valid
 * @param onChange
 * @param onBlur
 * @param error
 * @param className
 * @param required
 * @param isMulti
 * @param isClearable
 * @returns {*}
 * @constructor
 */
const SelectInput = ({ name, label, placeholder, options, value, description, valid, onChange, onBlur, error, className, required, isMulti, isFixed, isClearable }) => (
    <div className={'form-group ' + className}>
        {label && <label htmlFor={name}>{label} { required ? <code>*</code> : null }</label>}

        <Select value={value}
                options={options}
                className={"select " + (valid === '' ? '' : ( valid ? 'is-valid' : 'is-invalid'))}
                classNamePrefix="select"
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                isMulti={isMulti ? isMulti : false}
                isClearable={isClearable ? isClearable : false}
                styles={{menuPortal: provided => ({...provided, zIndex: 999999}), ...colourStyles}}
                menuPortalTarget={document.body}
                menuPosition={isFixed ? 'fixed' : 'absolute'}
        />
        <div className="description">{description}</div>
        <small className="invalid-feedback">{error}</small>
    </div>
);


SelectInput.defaultProps = {
    label: '', placeholder: '', value: '', error: '', valid: '', required: false, isMulti: false, isClearable: false, className: '', isFixed: false,
};


SelectInput.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};


export default SelectInput;
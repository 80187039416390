import {ADD_STICKER, ADD_STICKERS, DELETE_STICKER, DELETE_STICKERS, LOAD_STICKERS, UPDATE_STICKER} from "../constants/stickers.constants";

let defaultState = {
    availableStickers: [],
    stickers: {
        users: {},
        trainings: {},
        events: {},
        news: {},
        comments: {},
        mainfeed: {},
        subfeed: {},
    }
};


const loadStickers = (state, {stickers}) => (
    {...state, availableStickers: stickers}
);

const addStickers = (state, {type, id, stickers}) => {
    return {
        ...state,
        stickers: {
            ...state.stickers,
            [type]: {
                ...state.stickers[type],
                [id]: stickers
            }
        }
    };
};

const addSticker = (state, {type, id, sticker}) => {
    return {
        ...state,
        stickers: {
            ...state.stickers,
            [type]: {
                ...state.stickers[type],
                [id]: [
                    ...state.stickers[type][id],
                    sticker
                ]
            }
        }
    }
};

const updateSticker = (state, {type, id, sticker}) => {
    const array = state.stickers[type][id].map(x => {
        if (x.id === sticker.id) return sticker;
        return x;
    });

    return {
        ...state,
        stickers: {
            ...state.stickers,
            [type]: {
                ...state.stickers[type],
                [id]: array
            }
        }
    }
};

const deleteSticker = (state, {type, id, stickerId}) => {
    const sticker = state.stickers[type][id].find(x => x.sticker.id !== id);
    const array = state.stickers[type][id].filter(x => x.id !== sticker.id);

    return {
        ...state,
        stickers: {
            ...state.stickers,
            [type]: {
                ...state.stickers[type],
                [id]: array
            }
        }
    }
};

const deleteStickers = (state, {type, id}) => {
    let arr = state.stickers;
    delete arr[type][id];

    return { ...state, stickers: arr };
};


const stickerReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_STICKERS:
            return loadStickers(state, payload);
        case ADD_STICKERS:
            return addStickers(state, payload);
        case ADD_STICKER:
            return addSticker(state, payload);
        case UPDATE_STICKER:
            return updateSticker(state, payload);
        case DELETE_STICKER:
            return deleteSticker(state, payload);
        case DELETE_STICKERS:
            return deleteStickers(state, payload);
        default:
            return state;
    }
};

export default stickerReducer;
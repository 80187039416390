import React, {Component} from "react";
import {BTN_CLASSES} from "../../css_constants";
import 'react-image-crop/dist/ReactCrop.css';
import {Col, Row} from "../../utils/Grid/Grid";
import Button from "../../utils/Buttons/Button";
import {connect} from "react-redux";
import {updateAccount} from "../../redux/actions/auth.actions";
import FormInput from "../../utils/Forms/FormInput";
import FieldValidator from "../../utils/FieldValidator";
import FormCheckbox from "../../utils/Forms/FormCheckbox";
import Stickers from "../Stickers/Stickers";
import {FormattedMessage, injectIntl} from "react-intl";

/**
 * Update account component
 */
class UpdateAccount extends Component {
    constructor(props) {
        super(props);

        /** Initial state of the component */
        this.initialState = {
            phoneNumbers: [],
            socialMedia: [],
            password: "",
            formValid: true
        };

        /** State of the component */
        this.state = {
            ...this.initialState
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }


    /**
     * Set social media and telephone numbers to state on mount
     */
    componentDidMount() {
        const {user} = this.props;
        const {socialMedia, phoneNumbers} = user;

        let socials = [
            {type: "Facebook", link: "", valid: true, error: ''},
            {type: "Instagram", link: "", valid: true, error: ''},
            {type: "LinkedIn", link: "", valid: true, error: ''},
            {type: "Twitter", link: "", valid: true, error: ''},
            {type: "YouTube", link: "", valid: true, error: ''}
        ];

        socials = socials.map(social => {
            const temp = socialMedia.find(x => x.type === social.type);
            return {
                valid: true,
                error: '',
                type: social.type,
                link: temp ? temp.link : null
            }
        });

        this.initialState = {socialMedia: [...socials], phoneNumbers: phoneNumbers.map(x => x)};
        this.setState({...this.initialState});
    }


    /**
     * Handle checkbox change
     * @param id
     */
    handleCheckbox(id) {
        let {phoneNumbers} = this.state;
        phoneNumbers = phoneNumbers.map(x => {
            if (x.id !== id) return x;
            return {
                ...x,
                visible: !x.visible
            }
        });

        const {updateAccount} = this.props;

        updateAccount({
            phoneNumbers: phoneNumbers
        });
        this.setState({phoneNumbers});
    }


    /**
     * Handle text field change
     * @param e
     */
    handleChange(e) {
        let {socialMedia} = this.state;
        const {value, name} = e.target;

        socialMedia = socialMedia.map(x => {
            if (x.type !== name) return x;
            return {
                ...x,
                link: value
            }
        });

        this.setState({socialMedia}, () => this.validateField(name, value));
    };


    handlePasswordChange(e) {
        const {value} = e.target;

        this.setState({password: value}, () => this.validateForm());
    };


    /**
     * Validate social media field
     * @param name
     * @param value
     */
    validateField(name, value) {
        this.setState(FieldValidator.isUrl(this.state, name, value), () => this.validateForm());
    }


    /**
     * Validate social media form
     */
    validateForm() {
        const {socialMedia} = this.state;

        const errors = socialMedia.filter(x => x.valid === false);

        this.setState({formValid: errors.length === 0})
    }


    /**
     * Handle submit of social media form
     * @param e
     */
    handleSubmit(e) {
        e.preventDefault();
        const {socialMedia, password} = this.state;
        const {updateAccount} = this.props;

        updateAccount({socialMedia, password});
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {password, socialMedia, phoneNumbers, formValid} = this.state;
        const {user, intl} = this.props;
        const {subFeedItems, firstName} = user;
        const {messages} = intl;

        const birthday = subFeedItems.find(x => x.type === 'birthday');
        const service = subFeedItems.find(x => x.type === 'yearsOfService');

        /** return telephone numbers, social media form and birthday and years-of-service stickers */
        return (
            <div>
                <Row className={""}>
                    <Col md={6}>
                        <h2><FormattedMessage id={"Account.phoneNumber"} values={{itemCount: phoneNumbers.length}}/></h2>

                        {phoneNumbers.map((number, i) => (
                            <div key={i}>
                                <b><i>{number.number}</i></b>
                                <FormCheckbox label={messages["Account.phoneNumber.label"]}
                                              onChange={() => this.handleCheckbox(number.id)}
                                              checked={number.visible}
                                              name={number.id.toString()}
                                />
                            </div>
                        ))}

                        {birthday && birthday.stickers && birthday.stickers.length !== 0 &&
                        <div className={"mt-60"}>
                            <h6>Gelukkige verjaardag {firstName}</h6>
                            <Stickers stickers={birthday.stickers} addButton={false}/>
                        </div>
                        }

                        {service && service.stickers && service.stickers.length !== 0 &&
                        <div className={"mt-60"}>
                            <h6>Proficiat met jaren dienst {firstName}</h6>
                            <Stickers stickers={service.stickers} addButton={false}/>
                        </div>
                        }
                    </Col>

                    <Col md={6}>
                        <h2><FormattedMessage id={"Account.socialMedia"} values={{itemCount: phoneNumbers.length}}/></h2>

                        {socialMedia.map((social, i) => (
                            <FormInput type={"text"} valid={social.valid} error={social.error}
                                       onChange={this.handleChange}
                                       placeholder={{label: "Account.socialMedia.label", value: {name: social.type}}}
                                       name={social.type}
                                       label={social.type}
                                       value={social.link}
                                       key={i}
                            />
                        ))}

                        <h2 style={{marginTop: '50px'}}>Wijzig je wachtwoord</h2>

                        <FormInput type={"password"} valid={true} error={""}
                                   onChange={this.handlePasswordChange}
                                   placeholder={"Vul in om je wachtwoord te wijzigen"}
                                   name={"password"}
                                   value={password}
                                   autocomplete={"new-password"}
                        />

                        <div className={"right"}>
                            <div className={BTN_CLASSES.GROUP}>
                                <Button buttonStyle={BTN_CLASSES.SECONDARY}
                                        label={messages["form.button.cancel"]}
                                        onClick={() => this.setState({...this.initialState})}
                                />
                                <Button buttonStyle={BTN_CLASSES.PRIMARY}
                                        label={messages["form.button.save"]}
                                        disabled={!formValid}
                                        onClick={this.handleSubmit}
                                />
                            </div>
                        </div>
                    </Col>

                </Row>
            </div>
        )
    }
}


export default connect(null, {updateAccount})(injectIntl(UpdateAccount));
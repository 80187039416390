import {LOAD_INVITATION, DELETE_INVITATION, CONFIRM_INVITATION} from "../constants/invitation.constants";

let defaultState = {
    invitations: {
        trainings: {},
        events: {}
    }
};


const loadInvitation = (state, {type, id, invitation}) => {
    return {
        ...state,
        invitations: {
            ...state.invitations,
            [type]: {
                ...state.invitations[type],
                [id]: invitation
            }
        }
    }
};

const updateInvitation = (state, {type, id, invitation}) => {
    const invitations = state.invitations[type][id].map(x => {
        if (x.user.id !== invitation.user.id) return x;
        return invitation;
    });

    return {
        ...state,
        invitations: {
            ...state.invitations,
            [type]: {
                ...state.invitations[type],
                [id]: invitations
            }
        }
    }
};

const deleteInvitation = (state, {type, id}) => {
    let arr = state.invitations;
    delete arr[type][id];

    return { ...state, invitations: arr };
};


const invitationReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_INVITATION:
            return loadInvitation(state, payload);
        case CONFIRM_INVITATION:
            return updateInvitation(state, payload);
        case DELETE_INVITATION:
            return deleteInvitation(state, payload);
        default:
            return state;
    }
};

export default invitationReducer;
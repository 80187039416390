export const isNil = item => {
    return item === null || item === undefined;
}

export const isEmpty = item => {
    return item === '' // String
        || Object.keys(item).length === 0 // Object
        || item.length === 0; // Array
}

export const htmlToText = str => {
    const div = document.createElement("div");
    div.innerHTML = str;

    return div.innerText;
}


export const truncate = (str, size) => {
    const innerText = htmlToText(str);
    const substrEnd = size <= 46 ? size : 47;

    return innerText.length > size ? innerText.substring(0, substrEnd) + "..." : innerText;
}

export const breadSizeHelper = (str) => {
    const temp = {
        small_white: 'Klein wit broodje',
        large_white: 'Groot wit broodje',
        small_dark: 'Klein bruin broodje',
        large_dark: 'Groot bruin broodje',
    }

    return temp[str]
}

export const handleErrors = (error) => {
    if (error.response) {
        if (error.response.status && error.response.status === 401) {
            return 'Je bent niet bevoegd om de notificaties te bekijken.';
        }
        if (error.response.status && error.response.status === 403) {
            return 'Je hebt niet voldoende rechten om de notificaties te bekijken.';
        }
        if (error.response.status && error.response.status === 404) {
            return 'De gevraagde notificatie werd niet gevonden.';
        }
    }
    return 'Er trad een onverwachte fout op.';
}

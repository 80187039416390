import React, {Component} from "react";
import Form from "../../../utils/Forms/Form";
import Button from "../../../utils/Buttons/Button";
import {BTN_CLASSES} from "../../../css_constants";
import {Col, Row} from "../../../utils/Grid/Grid";
import {connect} from "react-redux";
import {loadTags} from "../../../redux/actions/tag.actions";
import {Link} from "react-router-dom";
import {loadEvent, postEvent} from "../../../redux/actions/event.actions";
import DateUtility from "../../../utils/dateUtils";
import CustomForm from "../CustomForm/CustomForm";
import Invitations from "../Invitations/Invitations";
import Modal from "../../../utils/Modal/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDrumstickBite,
    faGlassCheers,
    faEdit,
    faTools,
    faTimes,
    faTrash,
    faGlobeEurope, faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import Load from "../../../utils/Loading/Load";
import UrlModal from "../UrlModal/UrlModal";
import {loadStaff} from "../../../redux/actions/user.actions";

class AddEvent extends Component {
    constructor(props) {
        super(props);
        document.title = `UniPartners Group | Admin evenementen | Nieuw`;

        this.initialState = {
            form: {
                format: [
                    {
                        type: "col",
                        breakpoint: 8,
                        fields: ["name"]
                    }, {
                        type: "col",
                        breakpoint: 4,
                        fields: ["published"]
                    }, {
                        type: "col",
                        breakpoint: 12,
                        fields: ["location", "description"]
                    }, {
                        type: "col",
                        breakpoint: 4,
                        fields: ["date"]
                    }, {
                        type: "col",
                        breakpoint: 8,
                        fields: ["attachments", "organizers", "tags"]
                    }
                ],
                fields: [
                    {
                        name: "name",
                        type: "text",
                        required: true,
                        placeholder: "De naam van het evenement",
                        label: "Titel",
                        autocomplete: false,
                        description: null
                    }, {
                        name: "description",
                        type: "tiny",
                        required: false,
                        placeholder: "De beschrijving van het evenement",
                        label: "Beschrijving",
                        autocomplete: false,
                        description: (
                            <div style={{display: 'flex', marginTop: 5, marginBottom: 5}}>
                                <FontAwesomeIcon icon={faExclamationTriangle} style={{height: 42.5, width: 'auto', margin: '5px 10px 5px 0', color: '#ef7d00'}} />
                                <div>
                                    <b>OPGELET!</b><br />
                                    De inhoud van dit evenement wordt via intranet en de nieuwsbrief binnen heel UniPartners gedeeld. Deel hier geen gevoelige informatie.<br />
                                    Gebruik het mail-formulier op het inschrijvingen-overzicht om belangrijke informatie te delen met de genodigden.
                                </div>
                            </div>
                        )
                    }, {
                        name: "published",
                        type: "checkbox",
                        required: false,
                        label: "Publiceer het evenement",
                    }, {
                        name: "attachments",
                        type: "dropzone",
                        required: false,
                        label: "",
                        description: null,
                        files: []
                    }, {
                        name: "tags",
                        type: "creatable",
                        required: false,
                        multiple: true,
                        placeholder: "De tags van het evenement",
                        label: "Tags",
                        autocomplete: false,
                        description: null,
                        options: []
                    }, {
                        name: "location",
                        type: "creatable",
                        required: true,
                        multiple: false,
                        placeholder: "De locatie van het evenement",
                        label: "Locatie",
                        autocomplete: false,
                        description: null,
                        options: [
                            "Grote vergaderzaal, Gent",
                            "Kleine vergaderzaal, Gent",
                            "Opleidingslokaal PC, Terhagen",
                            "Opleidingslokaal Theater, Terhagen",
                            "Vergaderzaal 1e verdieping, Terhagen",
                            "Vergaderzaal Orangerie, Terhagen"
                        ]
                    }, {
                        name: "organizers",
                        type: "select",
                        required: false,
                        multiple: true,
                        placeholder: "De organizatoren van het evenement",
                        label: "Organizatoren",
                        autocomplete: false,
                        description: null,
                        options: []
                    }, {
                        name: "date",
                        type: "datepicker",
                        required: true,
                        label: "",
                        description: null,
                        value: [],
                        include: ["time"]
                    }, {
                        name: "time",
                        type: "timerange",
                        required: false,
                        label: `Start -en eindtijd voor `,
                        description: "Voor een open einde, maak start- en eindtijd gelijk",
                        sameIsValid: true
                    }
                ]
            },
            formValid: false,
        };


        this.state = {
            ...this.initialState,
            links: [],

            invitations: null,
            customForm: null,

            showCustomForm: false,
            showModal: false,
            loading: false,
        };

        this.invitations = React.createRef();
        this.urls = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const {loadTags, loadStaff, loadEvent, match} = this.props;
        const {id} = match.params;

        /**
         * Load tags
         */
        loadTags();

        /**
         *  Load staff
         */
        loadStaff();

        /**
         * Load original article if you want to clone
         */
        if (id) loadEvent(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tags, staff, selectedEvent, match} = this.props;
        const {toMoment} = DateUtility;
        const {id} = match.params;

        /**
         * Set tags
         */
        if (tags && prevState.form.fields.find(x => x.name === "tags").options.length !== tags.length) {
            const fields = this.state.form.fields.map(field => {
                if (field.name !== "tags") return field;

                return {
                    ...field,
                    options: tags.map(tag => (tag.tag))
                }
            });

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields
                    }
                }
            });
        }

        /**
         * Set Coceco's
         */
        if (prevState.form.fields.find(x => x.name === "organizers").options.length !== staff.length) {
            const fields = this.state.form.fields.map(field => {
                if (field.name !== "organizers") return field;

                return {
                    ...field,
                    options: staff.map(member => ({value: member.id, label: `${member.firstName} ${member.lastName}`}))
                }
            });

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields
                    }
                }
            });
        }

        /**
         * Set single language
         */
        if (selectedEvent && id && prevState.form.fields.find(x => x.name === 'language').options.length === 2) {
            const fields = this.state.form.fields.map(field => {
                if (field.name !== "language" && field.type !== "dropzone" && field.type !== "datepicker") return field;

                if (field.type === "dropzone") {
                    return {
                        ...field,
                        description: "Afbeeldingen zijn geladen bij het basis evenement"
                    }
                }

                if (field.type === "datepicker") {
                    return {
                        ...field,
                        value: selectedEvent.days.map(day => {
                            const {startDate, endDate} = day;

                            return {
                                day: toMoment(startDate),
                                startTime: toMoment(startDate),
                                endTime: toMoment(endDate),
                            }
                        }),
                        description: "De dag(en) is/zijn geladen bij het basis evenement"
                    }
                }

                if (field.name === "language") {
                    const language = selectedEvent.language === 'nl' ? 'Frans' : 'Nederlands';
                    return {
                        ...field,
                        options: [language],
                        value: language
                    }
                }

                return null;
            });

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields
                    }
                }
            });
        }
    }

    handleSubmit() {
        const {postEvent, match} = this.props;
        const {form, links, invitations, customForm} = this.state;
        const {id} = match.params;

        this.setState({loading: true});

        const data = new FormData();
        for (const field of form.fields) {
            if (field.type !== 'dropzone' && !(field.type === 'creatable' || field.type === 'select') && field.type !== 'datepicker' && field.type !== 'timerange') {
                data.set(field.name, field.value)
            } else if ((field.type === 'creatable' || field.type === 'select') && field.multiple) {
                for (const value of field.value) {
                    data.append(`${field.name}[]`, (value.hasOwnProperty('value') ? value.value : value))
                }
            } else if ((field.type === 'creatable' || field.type === 'select') && !field.multiple) {
                data.append(`${field.name}`, (field.value ? (field.value.hasOwnProperty('value') ? field.value.value : field.value) : field.value))
            } else if (field.type === 'datepicker') {
                for (const value of field.value) {
                    const date = new Date(value.day);
                    const start = new Date(value.startTime)
                    const end = new Date(value.endTime)

                    const object = {
                        day: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
                        startTime: `${start.getHours()}:${start.getMinutes()}:00`,
                        endTime: `${end.getHours()}:${end.getMinutes()}:00`,
                    };

                    data.append(`${field.name}[]`, JSON.stringify(object));
                }
            } else if (field.type !== 'timerange') {
                for (const file of field.files) {
                    data.append(`${field.name}[]`, file)
                }
            }
        }

        if (links.length !== 0) {
            for (let i = 0; i <= links.length - 1; i++) {
                data.append(`links[${i}][name]`, links[i].name)
                data.append(`links[${i}][url]`, links[i].url)
            }
        }

        if (invitations) {
            data.append('invitations[maxAttendDate]', invitations.maxAttendDate);
            data.append('invitations[maxAttendees]', invitations.maxAttendees);

            for (const user of invitations.users) {
                data.append(`invitations[invitees][]`, (user.hasOwnProperty('value') ? user.value : user))
            }
        }

        if (customForm && customForm.fields.length !== 0) {
            data.append('form[name]', customForm.formName);
            data.append('form[size]', customForm.formSize);

            for (const format of customForm.format) {
                data.append('form[format][]', JSON.stringify(format));
            }

            for (const field of customForm.fields) {
                data.append('form[fields][]', JSON.stringify(field));
            }
        }

        postEvent(data, this.props.history, id);
    }

    render() {
        const {form, customForm, showCustomForm, loading} = this.state;

        return !showCustomForm
            ? (
                loading
                    ? <Load showPatience/>
                    : (
                        <Row>
                            <Form form={form}
                                  formValid={(bool) => this.setState({formValid: bool})}
                                  updateFields={(form) => this.setState({form: form})}
                            />

                            {this.renderButtons()}

                            <Invitations ref={this.invitations} setInvitations={(invitations) => this.setState({invitations})}/>
                            <UrlModal ref={this.urls} handleSave={(obj) => this.setState(prevState => ({links: [...prevState.links, obj]}))}/>

                            {this.renderDefaultFormModal()}
                        </Row>
                    )
            )
            : <CustomForm customForm={customForm} handleForm={(form) => this.setState({showCustomForm: false, customForm: form ? form : customForm})}/>
    }

    renderButtons() {
        const {formValid, customForm, invitations, links} = this.state;

        return (
            <Col md={12}>
            <Row>
                <Col xl={4} lg={6} md={8}>
                    {links.length !== 0 &&
                    <div className="uploaded-files">
                        {links.map((link, index) => (
                            <div className="file" key={index}>
                                <div>
                                    <FontAwesomeIcon icon={faGlobeEurope} fixedWidth/>
                                    {link.name && link.name !== '' ? link.name : link.url}
                                </div>
                                <Button buttonStyle={BTN_CLASSES.LINK}
                                        buttonSize={BTN_CLASSES.SMALL}
                                        className={BTN_CLASSES.DANGER}
                                        onClick={() => this.setState(prevState => ({links: prevState.links.filter(x => x.name !== link.name && x.url !== link.url)}))}
                                >
                                    <FontAwesomeIcon icon={faTimes}/>
                                </Button>
                            </div>

                        ))}

                        <div className="delete">
                            <Button buttonStyle={BTN_CLASSES.LINK}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    className={BTN_CLASSES.DANGER}
                                    onClick={() => this.setState({links: []})}
                            >
                                <FontAwesomeIcon icon={faTrash}/> Verwijder alles
                            </Button>
                        </div>
                    </div>
                    }

                    <div className={`${BTN_CLASSES.GROUP} flex`}>
                        <Button label={"URL's als bijlage toevoegen"}
                                buttonStyle={BTN_CLASSES.SECONDARY}
                                buttonSize={BTN_CLASSES.SMALL}
                                onClick={() => this.urls.current.handleShow()}
                                styles={{width: '100%'}}
                        />
                        <Button label={"Deelnemers uitnodigen"}
                                buttonStyle={BTN_CLASSES.SECONDARY}
                                buttonSize={BTN_CLASSES.SMALL}
                                className={invitations ? BTN_CLASSES.SUCCESS : ''}
                                onClick={() => this.invitations.current.handleShow()}
                        />

                        <Button label={"Formulier maken"}
                                buttonStyle={BTN_CLASSES.SECONDARY}
                                buttonSize={BTN_CLASSES.SMALL}
                                className={customForm && customForm.fields.length !== 0 ? BTN_CLASSES.SUCCESS : ''}
                                onClick={() => this.setState({showModal: true})}
                        />
                    </div>
                </Col>

                <Col md={12} style={{margin: "1rem 0", textAlign: "right"}}>
                    <div className={BTN_CLASSES.GROUP}>
                        <Link to={`/evenementen`}
                              className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.SECONDARY}`}
                        >
                            Annuleren
                        </Link>

                        <Button buttonStyle={BTN_CLASSES.PRIMARY}
                                disabled={!formValid}
                                onClick={this.handleSubmit}
                        >
                            Bewaar evenement
                        </Button>
                    </div>
                </Col>
            </Row>
            </Col>
        )
    }

    loadCustomForm(type) {
        const {customForm} = this.state;
        let form = null;

        switch (type) {
            case 'bbq':
                form = {
                    formSize: "medium",
                    formName: "Schrijf je in voor onze barbecue",

                    format: [
                        {
                            type: "group",
                            name: "group1",
                            direction: "horizontal",
                            fields: ["qty", "allergies", "vegetarian"],
                        }
                    ],
                    fields: [
                        {
                            type: "number",
                            name: "qty",
                            label: "Aantal personen",
                            placeholder: "",
                            description: "",
                            required: true,
                            step: 1,
                            min: 1
                        }, {
                            type: "text",
                            name: "allergies",
                            label: "Allergieën",
                            placeholder: "",
                            description: "Heb je allergieën? Laat het ons weten",
                            required: false,
                        }, {
                            type: "checkbox",
                            name: "vegetarian",
                            label: "Ik ben vegetarisch",
                            placeholder: "",
                            description: "",
                            required: false,
                        },
                    ]
                }
                break;
            case 'party':
                form = {
                    formSize: "medium",
                    formName: "Schrijf je in voor ons personeelsfeest",

                    format: [
                        {
                            type: "group",
                            name: "group1",
                            direction: "horizontal",
                            fields: ["partner", "vegetarian", "allergies"],
                        }
                    ],
                    fields: [
                        {
                            type: "checkbox",
                            name: "partner",
                            label: "Ik breng mijn partner mee",
                            placeholder: "",
                            description: "",
                            required: false,
                        }, {
                            type: "checkbox",
                            name: "vegetarian",
                            label: "Ik ben vegetarisch",
                            placeholder: "",
                            description: "",
                            required: false,
                        }, {
                            type: "text",
                            name: "allergies",
                            label: "Allergieën",
                            placeholder: "",
                            description: "Heb je allergieën? Laat het ons weten",
                            required: false,
                        }
                    ]
                }
                break;
            case 'edit':
                form = customForm;
                break;
            default:
                form = {
                    formSize: "medium",
                    formName: "Nieuw formulier",

                    validateForm: false,
                    steps: 1,
                    step: 1,
                    currentIndex: 0,
                    previousIndex: 0,
                    nextIndex: 0,
                    skipped: [],

                    format: [
                        {
                            type: "group",
                            name: "group1",
                            direction: "horizontal",
                            fields: [],
                        }
                    ],
                    fields: []
                }
                break;
        }

        this.setState({customForm: form, showModal: false, showCustomForm: true});
    }

    renderDefaultFormModal() {
        const {showModal, customForm} = this.state;

        return (
            <Modal show={showModal}
                   close={() => this.setState({showModal: false})}
                   title="Formuliertype kiezen"
                   medium={!!customForm}
            >
                <Row className="add_field">
                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.loadCustomForm("bbq")}
                        >
                            <FontAwesomeIcon icon={faDrumstickBite}/>
                            Barbecue
                        </Button>
                    </div>

                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.loadCustomForm("party")}
                        >
                            <FontAwesomeIcon icon={faGlassCheers}/>
                            Personeelsfeest
                        </Button>
                    </div>

                    {customForm &&
                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.loadCustomForm("edit")}
                        >
                            <FontAwesomeIcon icon={faEdit}/>
                            Verder werken
                        </Button>
                    </div>
                    }

                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.loadCustomForm("custom")}
                        >
                            <FontAwesomeIcon icon={faTools}/>
                            {customForm ? 'Nieuw formulier' : 'Eigen formulier'}
                        </Button>
                    </div>
                </Row>
            </Modal>
        )
    }
}

const mapStateToProps = ({tagReducer, eventReducer, userReducer}) => {
    const {tags} = tagReducer;
    const {selectedEvent} = eventReducer;
    const {staff} = userReducer;
    return {tags, selectedEvent, staff};
};

const mapDispatchToProps = {
    loadEvent, postEvent, loadTags, loadStaff
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
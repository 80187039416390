import axios from "axios";
import {API_URL} from "../constants/defaults";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";
import {LOAD_TAGS} from "../constants/tag.constants";


export const loadTags = (type = null) => (dispatch, getStore) => {
    const store = getStore();
    const {token} = store.authReducer;

    const header = { headers: {'Authorization': "Bearer " + token} };
    axios.get(`${API_URL}${type ? `/${type}` : ''}/tags`, header)
        .then(({data}) => {
            dispatch({type: LOAD_TAGS, payload: { tags: data}});
        })
        .catch(error => {
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "Tags laden mislukt vanwegen een onverwachtte fout", type: 'error'})}});
        });
};
import React from 'react';
import './FormInput.scss'
import {Col} from "../Grid/Grid";

/**
 * Form column component
 * @param breakpoint
 * @param name
 * @param children
 * @returns {*}
 * @constructor
 */
const FormTab = ({name, children, index, currentIndex}) => {
    if (index !== currentIndex) {
        return null
    }

    return (
        <Col md={12} className={name}>
            {children}
        </Col>
    )
};


export default FormTab;
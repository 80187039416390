import {API_URL} from "../constants/defaults";
import axios from "axios";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";
import {DELETE_DOCUMENT_BLOB, LOAD_DOCUMENT_BLOB, LOAD_DOCUMENTS} from "../constants/document.constants";


export const loadDocuments = () => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const {messages} = state.intl;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.get(API_URL + "/documents", header)
        .then(({data}) => {
            dispatch({type: LOAD_DOCUMENTS, payload: {documents: data}})
        })
        .catch(error => {
            console.log(error);
            const message = handleErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}})
        })
};


export const getDocumentBlob = (object) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const {messages} = state.intl;
    const {documentBlobs} = state.documentReducer;

    const obj = documentBlobs.find(x => x.id === object.id);
    if (!obj) {
        dispatch({type: LOAD_DOCUMENT_BLOB, payload: {blob: null, id: object.id}});
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(API_URL + "/documents/" + object.id + "/data", header)
            .then(({data}) => {
                dispatch({type: LOAD_DOCUMENT_BLOB, payload: {attachment: data, id: object.id}});

                let a = document.createElement('a');
                a.href = data.file;
                a.download = data.filename;
                a.click();
            })
            .catch(error => {
                console.log(error);
                const message = handleErrors(messages, error);
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}})
                dispatch({type: DELETE_DOCUMENT_BLOB, payload: {id: object.id}})
            })
    }
};


const handleErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["attachment.response.error"];
};
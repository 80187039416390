// Alignments
export const ALIGNMENT = {
    LEFT: "left",
    CENTER: "center",
    RIGHT: "right"
};

// Buttons
export const BTN_TYPES = {
    SUBMIT: "submit",
    RESET: "reset",
    BUTTON: "button"
};

export const BTN_CLASSES = {
    BTN: "btn",
    BLOCK: "btn-block",
    PRIMARY: "btn-primary",
    SECONDARY: "btn-secondary",
    DANGER: "btn-danger",
    SUCCESS: "btn-success",
    SMALL: "btn-small",
    GROUP: "btn-group",
    LINK: "btn-link"
};

// Inputs
export const INPUT_TYPES = {
    TEXT: "text",
    EMAIL: "email",
    PASSWORD: "password",
    NUMBER: "number",
    FILE: "file",
    URL: "url"
};

// Margins
export const MARGINS = {
    AUTO: "auto"
};

export const SHARED_CLASSES = {
    ACTIVE: "active",
    NAV_ITEM: "navbar-item",
    NAV_LINK: "navbar-link"
};

// Units
export const UNITS = {
    PC: "%",
    PX: "px",
    VH: "vh",
    VW: "vw"
};

import axios from 'axios';
import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATION_BY_ID,
    LOADING_NOTIFICATIONS,
    UPDATE_NOTIFICATIONS,
    LOADED_NOTIFICATIONS
} from '../constants/notification.constants'
import { ADD_TOAST } from "../constants/toast.constants";
import { API_URL } from "../constants/defaults";
import createToast from "../../factories/createToast";
import { handleErrors, isNil } from "../../utils/helperFunctions";

// Curried fn
export const getNotifications = () => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const _HEADER = {headers: {'Authorization': `Bearer ${token}`}};

    dispatch({type: LOADING_NOTIFICATIONS});
    axios.get(`${API_URL}/notifications`, _HEADER)
        .then(({data}) => dispatch({type: GET_NOTIFICATIONS, payload: {notifications: data}}))
        .catch(error => {
            const message = handleErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        });
}

export const getNotificationById = (id) => (dispatch, getState) => {
    const state = getState();
    const {isLoaded, selectedNotification} = state.notificationReducer;
    const {token} = state.authReducer;
    const _HEADER = {headers: {'Authorization': `Bearer ${token}`}};

    dispatch({type: LOADING_NOTIFICATIONS});
    !isNil(selectedNotification) && selectedNotification.id === parseInt(id) && isLoaded
        ? dispatch({type: LOADED_NOTIFICATIONS})
        : axios.get(`${API_URL}/notifications/${id}`, _HEADER)
            .then(({data}) => {
                dispatch({type: GET_NOTIFICATION_BY_ID, payload: {notification: data}})
            })
            .catch(error => {
                const message = handleErrors(error);
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            });
}

export const updateNotifications = (ids) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const _HEADER = {headers: {'Authorization': `Bearer ${token}`}};

    axios.post(`${API_URL}/notifications`, {ids}, _HEADER)
        .then(({data}) => dispatch({type: UPDATE_NOTIFICATIONS, payload: {notifications: data}}))
        .catch(error => {
            const message = handleErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        });
}
import {API_URL} from "../constants/defaults";
import axios from "axios";
import {LOAD_COCES, LOAD_COCECOS, LOAD_COMPANIES} from "../constants/coce.constants";
import {LOAD_USERS} from "../constants/user.constants";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";


export const loadCoces = (loadPictures = true) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;
    const {isLoading} = state.coceReducer;

    if (!isLoading) {
        dispatch({type: LOAD_COCES, payload: {coces: null, isLoading: true}});
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(API_URL + "/coces", header)
            .then(({data}) => {
                dispatch({type: LOAD_COCES, payload: {coces: data, isLoading: false}});
                dispatch({type: LOAD_USERS, payload: {users: [], usersLoading: true}});

                return axios.get(`${API_URL}/users${loadPictures ? '' : '?load-images=false'}`, header);
            })
            .then(({data}) => {
                return dispatch({type: LOAD_USERS, payload: {users: data.data, usersLoading: false}});
            })
            .catch(error => {
                const message = handleErrors(messages, error);
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
                dispatch({type: LOAD_USERS, payload: {users: [], usersLoading: false}});
                console.log(error);
            });
    }
};

export const loadCocecos = () => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.get(API_URL + '/coces/cocecos', header)
        .then(({data}) => {
            dispatch({type: LOAD_COCECOS, payload: {cocecos: data}});
        })
        .catch(error => {
            const message = handleErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}})
            console.log(error)
        });
};

export const loadCompanies = () => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;
    const {companiesLoading} = state.coceReducer;

    if (!companiesLoading) {
        dispatch({type: LOAD_COMPANIES, payload: {companies: [], companiesLoading: true}});
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(API_URL + "/departments", header)
            .then(({data}) => {
                dispatch({type: LOAD_COMPANIES, payload: {companies: data, companiesLoading: false}});
            })
            .catch(error => {
                const message = handleErrors(messages, error);
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
                console.log(error);
            });
    }
}

const handleErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.error && error.response.data.error) {
        if (error.response.data.error.code && error.response.data.error.code === 403) {
            return messages["coce.rights.error"];
        }
    }
    return messages["coce.response.error"];
};
import React, {Component} from "react";
import {connect} from "react-redux";
import {deleteArticle, loadNews, loadNewsByTag, pinArticle, publishArticle, reloadNews, searchNews} from "../../redux/actions/news.actions";
import {getMedia} from "../../redux/actions/media.actions";
import Load from "../../utils/Loading/Load";
import {Col, Row} from "../../utils/Grid/Grid";
import './News.scss';
import {injectIntl} from "react-intl";
import OverviewSidebar from "../OverviewSidebar/OverviewSidebar";
import EmptyList from "../EmptyList/EmptyList";
import {loadTags} from "../../redux/actions/tag.actions";
import SingleArticle from "./SingleArticle";

/**
 * News overview component
 */
class NewsList extends Component {
    constructor(props) {
        super(props);

        const selectedFilters = props.location.state ? props.location.state.selectedFilters : null;

        /** State of the component */
        this.state = {
            selectedFilters: selectedFilters || [],
            showPinned: false,
            showUnpublished: false,
            showLanguages: false,

            searchValue: null
        };

        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.handlePinChange = this.handlePinChange.bind(this);
        this.handleUnpublished = this.handleUnpublished.bind(this);
        this.handleLanguages = this.handleLanguages.bind(this);

        this.handleScroll = this.handleScroll.bind(this);
    }


    /**
     * Load news on component mount if non are loaded
     */
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        document.title = `UniPartners Group | UPdate`;

        const {reloadNews, loadTags} = this.props;

        reloadNews('published');
        loadTags('news')
    }


    /**
     * remove eventlistener when component unmounts
     */
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
    }


    /**
     * Load extra feed items when user scrolled to bottom of the page and there is more to bee seen
     */
    handleScroll() {
        const wrappedElement = document.getElementById('body');

        const {loadNews, hasMore, newsList} = this.props;
        const {selectedFilters, searchValue} = this.state;

        if (wrappedElement.getBoundingClientRect().bottom <= window.innerHeight + 250) {
            if (hasMore && !searchValue && selectedFilters.length === 0) {
                loadNews(newsList.length);
            }
        }
    }


    /**
     * Load media of news articles
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {newsList} = this.props;

        if (newsList && prevProps.newsList.length !== newsList.length) {
            newsList.map(article => {
                const {getMedia} = this.props;
                if (article.media && article.media.length > 0)
                    getMedia(article.media[0]);

                return null;
            });
        }
    }


    /**
     * See pinned news articles
     */
    handlePinChange() {
        const {showPinned} = this.state;
        this.setState({showPinned: !showPinned})
    }


    /**
     * See unpublished news
     */
    handleUnpublished() {
        const {showUnpublished} = this.state;
        const {reloadNews} = this.props;

        reloadNews(!showUnpublished ? 'unpublished' : 'published');
        this.setState(prevState => ({showUnpublished: !prevState.showUnpublished}))
    }


    /**
     * See news in all languages
     */
    handleLanguages() {
        const {showLanguages} = this.state;
        this.setState({showLanguages: !showLanguages})
    }


    /**
     * See news with selected tags
     * @param selectedOption
     */
    handleTagsChange(selectedOption) {
        const {loadNewsByTag} = this.props;

        let arr = [];
        if (selectedOption) {
            arr = selectedOption.map(opt => {
                return opt;
            });
        }

        loadNewsByTag({tags: arr.map(x => x.value)});
        this.setState({selectedFilters: arr, searchValue: null});
    }


    handleSearch(searchValue) {
        const {searchNews} = this.props;
        searchNews(searchValue);

        this.setState({searchValue, selectedFilters: []});
    }


    /**
     * Function to check if all tags are added to an article
     * @param haystack
     * @returns {*}
     */
    containsAll(haystack) {
        const {selectedFilters} = this.state;
        const {tags} = haystack;

        const tagValues = tags.map(x => {
            return x;
        });

        return selectedFilters.every(selectedFilter => {
            return tagValues.includes(selectedFilter.value)
        });
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        let {isLoading, newsList} = this.props;

        /** Return renderList if news articles are loaded */
        return (
            <div>
                {isLoading && newsList.length === 0
                    ? <Load/>
                    : this.renderList()
                }
            </div>
        );
    };


    /**
     * Component renderList method
     * @returns {*}
     */
    renderList() {
        let {permissions, newsList, isLoading, hasMore, tags, intl} = this.props;
        const {selectedFilters, showPinned, showUnpublished, showLanguages, searchValue} = this.state;

        /** filter articles for pinned */
        const pinnedList = newsList.filter(article => {
            return article.pinned;
        });

        /** show pinned of filter on tags */
        if (showPinned) {
            newsList = pinnedList;
        }else if (!searchValue) {
            if (selectedFilters.length !== 0) {
                newsList = newsList.filter(article => {
                    return this.containsAll(article) ? article : null;
                });
            }
        }

        /** filter events by published state */
        if (!showUnpublished && !searchValue) {
            newsList = newsList.filter(article => {
                return article.publishedAt;
            })
        }

        /** filter articles by language */
        if (!showLanguages) {
            newsList = newsList.filter(article => {
                return article.language === intl.locale;
            })
        }

        /** Return OverviewSidebar and mapping for renderArticle */
        return (
            <Row className={"articles"}>
                <Col sm={12} md={6} lg={4} className={"tag-filter"}>
                    <OverviewSidebar selectedFilters={selectedFilters}
                                     tags={tags.filter(x => x.type === 'news')}
                                     showPinned={showPinned}
                                     pinned={pinnedList.length}
                                     handlePinChange={this.handlePinChange}
                                     handleTagsChange={this.handleTagsChange}
                                     handleSearch={searchValue => this.handleSearch(searchValue)}
                                     searchValue={searchValue}

                                     newLink={permissions.includes("news_add") && "artikel-toevoegen"}
                                     newText={permissions.includes("news_add") && "Artikel toevoegen"}
                                     handleUnpublished={permissions.includes("news_see_unpublished") && this.handleUnpublished}
                                     showUnpublished={permissions.includes("news_see_unpublished") && showUnpublished}
                                     handleLanguages={permissions.includes("news_add") && this.handleLanguages}
                                     showLanguages={permissions.includes("news_add") && showLanguages}
                                     messages={intl.messages}
                    />
                </Col>

                {newsList.length === 0
                    ? <Col sm={12} md={6} lg={8}><EmptyList id={"EmptyList.news"}/></Col>
                    : newsList.map((article, i) => (
                        this.renderArticle(article, i)
                    ))}

                {isLoading && newsList.length !== 0 &&
                <Load className={"col-md-12"}/>
                }

                {!hasMore && newsList.length !== 0 && !isLoading &&
                <Col sm={12} className={"no-more-mainfeed"}>
                    Je bereikte het einde van het overzicht
                </Col>
                }
            </Row>
        )
    }

    /**
     * Component renderArticle method
     * @param article
     * @param i
     * @returns {*}
     */
    renderArticle(article, i) {
        const {user, permissions, role, pinArticle, publishArticle, deleteArticle, media, intl} = this.props;
        const {author, title, slug, id, pinned, publishedAt} = article;


        /** Load image */
        let image = null;
        if (article.media && article.media.length > 0) {
            const imageId = article.media[0].id;
            image = media.find(x => x.id === imageId);
        }

        /** Return single overview article */
        return (
            <SingleArticle id={id}
                           slug={slug}
                           title={title}
                           image={image}
                           pinned={pinned}
                           publishedAt={publishedAt}
                           pinArticle={pinArticle}
                           publishArticle={publishArticle}
                           deleteArticle={deleteArticle}
                           permissions={permissions}
                           role={role}
                           isAuthor={user.id === author.id}
                           messages={intl.messages}
                           sm={i === 0 ? 12 : 6}
                           md={i === 0 ? 6 : 4}
                           lg={i === 0 ? 8 : 4}
                           key={i}
            />
        )

    }
}


const mapStateToProps = ({authReducer, newsReducer, tagReducer, mediaReducer}) => {
    const {user, permissions, role} = authReducer;
    const {newsList, hasMore, isLoading} = newsReducer;
    const {tags} = tagReducer;
    const {media} = mediaReducer;
    return {permissions, hasMore, isLoading, newsList, tags, media, role, user};
};


const mapDispatchToProps = {
    loadNews, getMedia, pinArticle, publishArticle, deleteArticle, loadTags, reloadNews, searchNews, loadNewsByTag
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NewsList));
import {LOAD_QUESTIONS, SELECT_DAMAGE, SELECT_ILLNESS} from "../constants/app.constants";
import {API_URL} from "../constants/defaults";
import axios from "axios";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";
import {UPDATE_NOTIFICATIONS} from "../constants/notification.constants";

export const loadQuestions = () => (dispatch, getState) => {
    dispatch({type: LOAD_QUESTIONS, payload: {questions: [], questionsLoading: true}});
    const state = getState();
    const {locale, messages} = state.intl;
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.get(API_URL + '/questions?lang=' + locale, header)
        .then(({data}) => {
            dispatch({type: LOAD_QUESTIONS, payload: {questions: data, questionsLoading: false}});
        })
        .catch(error => {
            const message = handleErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}})
            console.log(error)
        });
};


export const selectIllness = (id) => (dispatch, getState) => {
    const state = getState();
    const {user} = state.authReducer;
    const illness = user.illnesses.find(x => x.id === id);

    dispatch({type: SELECT_ILLNESS, payload: {illness: illness}});
}


export const selectDamage = (id) => (dispatch, getState) => {
    const state = getState();
    const {user} = state.authReducer;
    const damage = user.damages.find(x => x.id === id);

    dispatch({type: SELECT_DAMAGE, payload: {damage: damage}});
}


export const setNotifications = (ids) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(API_URL + '/notifications', {ids}, header)
        .then(({data}) => {
            dispatch({type: UPDATE_NOTIFICATIONS, payload: {notifications: data}});
        })
        .catch(error => {
            const message = handleErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}})
            console.log(error)
        });
}

const handleErrors = (messages, error) => {
    return "Notificaties wijzigen mislukt vanwegen een onverwachte fout.";
};
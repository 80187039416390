import React, { Component } from "react";
import { BTN_CLASSES } from "../../css_constants";
import Button from "../../utils/Buttons/Button";
import { connect } from "react-redux";
import Form from "../../utils/Forms/Form";
import Modal from "../../utils/Modal/Modal";
import {
  confirmInvitation,
  sendMail,
} from "../../redux/actions/invitation.actions";
import { sendForm } from "../../redux/actions/form.actions";
import "./Subscription.scss";
import FormTextarea from "../../utils/Forms/FormTextarea";
import { Col, Row } from "../../utils/Grid/Grid";
import DateUtility from "../../utils/dateUtils";
import { FormattedMessage, injectIntl } from "react-intl";
import FormCheckbox from "../../utils/Forms/FormCheckbox";
import SelectInput from "../../utils/Forms/SelectInput";
import { loadToppings } from "../../redux/actions/topping.actions";
import { breadSizeHelper } from "../../utils/helperFunctions";

/**
 * Subscription component
 */
class Subscription extends Component {
  constructor(props) {
    super(props);

    const { intl } = props;

    /** State of the component */
    this.state = {
      form: null,
      formModal: false,
      formValid: false,
      updateForm: true,
      mailModal: false,
      mailType: null,
      notInvited: intl.formatMessage(
        { id: "subscription.mail.notInvited" },
        {
          type:
            props.objType === "events"
              ? intl.messages["subscription.event"]
              : intl.messages["subscription.training"],
          name: props.obj.name,
        }
      ),
      fullInvited: intl.formatMessage(
        { id: "subscription.mail.fullInvited" },
        {
          type:
            props.objType === "events"
              ? intl.messages["subscription.event"]
              : intl.messages["subscription.training"],
          name: props.obj.name,
        }
      ),
      fullNotInvited: intl.formatMessage(
        { id: "subscription.mail.fullNotInvited" },
        {
          type:
            props.objType === "events"
              ? intl.messages["subscription.event"]
              : intl.messages["subscription.training"],
          name: props.obj.name,
        }
      ),

      order: null,
    };

    this.acceptInvitation = this.acceptInvitation.bind(this);
    this.declineInvitation = this.declineInvitation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMailChange = this.handleMailChange.bind(this);
    this.handleMailSubmit = this.handleMailSubmit.bind(this);
    this.handleOrderCheckbox = this.handleOrderCheckbox.bind(this);
    this.handleOrderSelect = this.handleOrderSelect.bind(this);
    this.handleOrderTextarea = this.handleOrderTextarea.bind(this);
  }

  /**
   * Sets the form is no form is loaded and one is available
   */
  componentDidMount() {
    const { loadToppings, obj, objType } = this.props;
    const { form } = this.state;

    if (!form && this.props.form) {
      this.setState({ form: this.props.form });
    }

    if (!form && objType === "trainings") {
      loadToppings();

      this.setState({
        order: {
          days: obj.days.map((x) => {
            return {
              date: x.startDate,
              acceptOrders: !!x.bread,
              reason: x.reason,
              order: true,
              size: null,
              sizeTouched: false,
              sizeValid: !x.bread,
              topping: null,
              toppingTouched: false,
              toppingValid: !x.bread,
              extra: false,
            };
          }),
          updated: false,
          remark: null,
        },
        formValid: obj.days.filter((x) => x.bread === 0).length !== 0,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { toppings, obj, objType } = this.props;
    const { form, order } = this.state;

    if (!form && this.props.form) {
      this.setState({ form: this.props.form });
    }

    if (
      !form &&
      objType === "trainings" &&
      toppings.length !== 0 &&
      obj &&
      obj.order &&
      order &&
      !order.updated
    ) {
      this.setState((prevState) => ({
        order: {
          ...prevState.order,
          days: prevState.order.days.map((x) => {
            const day = obj.order.days.find((y) => y.date === x.date);
            const top = day ? toppings.find((y) => y.id === day.topping) : null;

            return {
              ...x,
              order: !!(day && top),
              size:
                day && top
                  ? { value: day.size, label: breadSizeHelper(day.size) }
                  : null,
              sizeValid: day && top,
              topping:
                day && top
                  ? {
                      value: day.topping,
                      label: top.topping,
                      extra: top.hasOptions,
                      desc: top.description,
                    }
                  : null,
              toppingValid: day && top,
              extra: day && top ? day.extra : false,
            };
          }),
          updated: true,
          remark: obj.order.remark,
        },
      }));
    }
  }

  /**
   * Handles accepting an invitation
   * @returns {null}
   */
  acceptInvitation() {
    const { confirmInvitation, user, obj, objType } = this.props;

    /** Show the form */
    if (obj.hasForm || objType === "trainings") {
      this.setState({ formModal: true });
      return null;
    } else {
      confirmInvitation({
        type: objType,
        id: obj.id,
        userId: user.id,
        confirmed: true,
      });
      this.setState({ formModal: false });
    }
  }

  /**
   * Handles declining an invitation
   */
  declineInvitation() {
    const { confirmInvitation, user, obj, objType } = this.props;
    confirmInvitation({
      type: objType,
      id: obj.id,
      userId: user.id,
      confirmed: false,
    });
    this.setState({ formModal: false });
  }

  /**
   * Handles submitting the form
   */
  handleSubmit() {
    const { sendForm, obj, objType } = this.props;
    let data;

    if (objType === "trainings") {
      data = this.handleSubmitTrainingForm();
    } else {
      data = this.handleSubmitForm();
    }

    sendForm(objType, obj.id, data);
    this.setState({ formModal: false });
  }

  handleSubmitTrainingForm() {
    const { user, obj } = this.props;
    const { order } = this.state;

    const data = new FormData();
    data.set("userId", user.id);
    data.set("trainingId", obj.id);
    data.set("remark", order.remark);

    for (const day of order.days.filter((x) => x.acceptOrders)) {
      data.append(
        "results[]",
        day.order
          ? JSON.stringify({
              date: day.date,
              size: day.size.value,
              topping: day.topping.value,
              extra: day.topping.extra ? day.extra : false,
            })
          : JSON.stringify({
              date: day.date,
              size: null,
              topping: null,
              extra: null,
            })
      );
    }

    return data;
  }

  handleSubmitForm() {
    const { user } = this.props;
    const { form } = this.state;

    const data = new FormData();
    data.set("userId", user.id);
    data.set("formId", form.id);

    for (const field of form.fields) {
      if (
        field.type !== "col" &&
        field.type !== "group" &&
        field.type !== "tab"
      ) {
        if (field.type === "select" && field.multiple) {
          for (const value of field.value) {
            data.append(
              `results[${field.name}][]`,
              value.hasOwnProperty("value") ? value.value : value
            );
          }
        } else if (field.type === "select" && !field.multiple) {
          data.append(
            `results[${field.name}]`,
            field.value.hasOwnProperty("value")
              ? field.value.value
              : field.value
          );
        } else {
          data.append(`results[${field.name}]`, field.value);
        }
      }
    }

    return data;
  }

  /**
   * Showing or hiding the mail for information modal
   * @param e
   */
  handleMailChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  /**
   * Handles the sending of the mail
   */
  handleMailSubmit() {
    const { sendMail, user, obj, objType, intl } = this.props;
    const { mailType } = this.state;

    sendMail({
      type: objType,
      id: obj.id,
      userId: user.id,
      mail: {
        subject: intl.formatMessage(
          { id: "subscription.mail.subject" },
          {
            name: obj.name,
            type:
              objType === "events"
                ? intl.messages["subscription.event"]
                : intl.messages["subscription.training"],
            userName: `${user.firstName} ${user.lastName}`,
          }
        ),
        body: this.state[mailType],
      },
    });
    this.setState({ mailModal: false });
  }

  /**
   * Component render method
   * @returns {*}
   */
  render() {
    let { invitations, user, author, obj, objType } = this.props;
    const { isPast } = DateUtility;
    const { form } = this.state;
    let invitation = null,
      subscriptions = null;

    /** find your invitation and subscription if invitations are available */
    if (invitations) {
      invitation = invitations.find((x) => x.user.id === user.id);
      subscriptions = invitations.filter((x) => x.confirmed === true).length;
    }

    /** Return the buttons for the form, accepting or declining or mailing depending on the form */
    return (
      <div>
        {invitations && invitations.length !== 0 ? (
          (obj.maxAttendDate && isPast(obj.maxAttendDate)) ||
          (!obj.maxAttendDate && isPast(obj.days[0].startDate)) ? ( // Inschrijvingsperiode voorbij
            invitation && invitation.confirmed !== null ? (
              <button
                className={`overDue${
                  invitation.confirmed ? " success" : " danger"
                }`}
                onClick={() =>
                  this.setState({
                    formModal: invitation.confirmed,
                    updateForm: false,
                  })
                }
              >
                {invitation.confirmed
                  ? "Je bent ingeschreven"
                  : "Je bent niet ingeschreven"}
              </button>
            ) : (
              <div className={"overDue"}>
                <FormattedMessage id={"subscription.overdue"} />
              </div>
            )
          ) : !invitation ? (
            obj.maxAttendees && subscriptions >= obj.maxAttendees ? ( // Volzet
              <div className={"contactOrganizer"}>
                <FormattedMessage id={"subscription.full"} />
                <Button
                  buttonStyle={BTN_CLASSES.PRIMARY}
                  buttonSize={BTN_CLASSES.SMALL}
                  block={BTN_CLASSES.BLOCK}
                  onClick={() =>
                    this.setState({
                      mailModal: true,
                      mailType: "fullNotInvited",
                    })
                  }
                >
                  <FormattedMessage
                    id={"subscription.contact"}
                    values={{
                      userName: `${author.firstName} ${author.lastName}`,
                    }}
                  />
                </Button>
              </div>
            ) : (
              <div className={"contactOrganizer"}>
                <FormattedMessage id={"subscription.notInvited"} />
                <Button
                  buttonStyle={BTN_CLASSES.PRIMARY}
                  buttonSize={BTN_CLASSES.SMALL}
                  block={BTN_CLASSES.BLOCK}
                  onClick={() =>
                    this.setState({ mailModal: true, mailType: "notInvited" })
                  }
                >
                  <FormattedMessage
                    id={"subscription.contact"}
                    values={{
                      userName: `${author.firstName} ${author.lastName}`,
                    }}
                  />
                </Button>
              </div>
            )
          ) : obj.maxAttendees &&
            subscriptions >= obj.maxAttendees &&
            invitation.confirmed === null ? ( // Volzet
            <div className={"contactOrganizer"}>
              <FormattedMessage id={"subscription.full"} />
              <Button
                buttonStyle={BTN_CLASSES.PRIMARY}
                buttonSize={BTN_CLASSES.SMALL}
                block={BTN_CLASSES.BLOCK}
                onClick={() =>
                  this.setState({ mailModal: true, mailType: "fullInvited" })
                }
              >
                <FormattedMessage
                  id={"subscription.contact"}
                  values={{
                    userName: `${author.firstName} ${author.lastName}`,
                  }}
                />
              </Button>
            </div>
          ) : (
            this.renderButtons(invitation.confirmed, invitation.request)
          )
        ) : (obj.maxAttendDate && isPast(obj.maxAttendDate)) ||
          (!obj.maxAttendDate && obj.days && isPast(obj.days[0].startDate)) ? ( // Inschrijvingsperiode voorbij
          <div className={"overDue"}>
            <FormattedMessage id={"subscription.overdue"} />
          </div>
        ) : (
          ((form && form.length !== 0) || objType === "trainings") &&
          this.renderFormButton()
        )}

        {form && form.length !== 0 && this.renderForm()}
        {objType === "trainings" && this.renderTrainingForm()}
        {this.renderMail()}
      </div>
    );
  }

  /**
   * Component renderButtons method
   * @param confirmed
   * @param requested
   * @returns {*}
   */
  renderButtons(confirmed, requested) {
    let success = BTN_CLASSES.SUCCESS;
    let error = BTN_CLASSES.DANGER;

    if (confirmed === false) success = BTN_CLASSES.SECONDARY;
    if (confirmed === true) error = BTN_CLASSES.SECONDARY;

    /** Return accept or decline buttons */
    return (
      <div className={`${BTN_CLASSES.GROUP} flex`}>
        <Button
          buttonStyle={success}
          buttonSize={BTN_CLASSES.SMALL}
          onClick={this.acceptInvitation}
          disabled={requested || (requested !== null && !confirmed)}
        >
          <FormattedMessage id={"subscription.buttons.confirm"} />
        </Button>

        <Button
          buttonStyle={error}
          buttonSize={BTN_CLASSES.SMALL}
          onClick={this.declineInvitation}
          disabled={requested || (requested !== null && !confirmed)}
        >
          <FormattedMessage id={"subscription.buttons.decline"} />
        </Button>
      </div>
    );
  }

  /**
   * Component renderFormButton method
   * @returns {*}
   */
  renderFormButton() {
    /** Returns the form button */
    return (
      <div style={{ margin: ".5rem 0" }}>
        <Button
          buttonStyle={BTN_CLASSES.PRIMARY}
          buttonSize={BTN_CLASSES.SMALL}
          block={BTN_CLASSES.BLOCK}
          onClick={() => this.setState({ formModal: true })}
        >
          <FormattedMessage id={"subscription.buttons.form"} />
        </Button>
      </div>
    );
  }

  /**
   * Component renderForm method
   * @returns {*}
   */
  renderForm() {
    const { formValid, formModal, updateForm, form } = this.state;

    /** Returns the form modal */
    return (
      <Modal
        show={formModal}
        close={() => this.setState({ formModal: false, updateForm: true })}
        title={form.formName}
        medium={form.formSize === "medium"}
        large={form.formSize === "large"}
        actions={
          updateForm
            ? [
                {
                  label: "Verstuur formulier",
                  action: this.handleSubmit,
                  disabled: formValid,
                },
              ]
            : []
        }
      >
        {form && form.format.length !== 0 && form.format[0].type === "col" ? (
          <Row>
            <Form
              form={form}
              formValid={(bool) => this.setState({ formValid: bool })}
              updateFields={(form) => this.setState({ form })}
            />
          </Row>
        ) : (
          <Form
            form={form}
            formValid={(bool) => this.setState({ formValid: bool })}
            updateFields={(form) => this.setState({ form })}
          />
        )}
      </Modal>
    );
  }

  handleOrderCheckbox(e, date, name) {
    this.setState(
      (prevState) => ({
        order: {
          ...prevState.order,
          days: prevState.order.days.map((x) => {
            if (x.date !== date) return x;

            return {
              ...x,
              [name]: !x[name],
              sizeValid:
                x.size !== null && x.size !== undefined ? true : x.order,
              toppingValid:
                x.topping !== null && x.topping !== undefined ? true : x.order,
            };
          }),
        },
      }),
      this.checkValid
    );
  }

  handleOrderSelect(selectedOption, date, name) {
    this.setState(
      (prevState) => ({
        order: {
          ...prevState.order,
          days: prevState.order.days.map((x) => {
            if (x.date !== date) return x;

            return {
              ...x,
              [name]: selectedOption,
              [`${name}Valid`]:
                selectedOption !== null && selectedOption !== undefined,
              extra: selectedOption && selectedOption.extra ? x.extra : null,
            };
          }),
        },
      }),
      this.checkValid
    );
  }

  handleOrderTextarea(e) {
    const { value, name } = e.target;

    this.setState((prevState) => ({
      order: {
        ...prevState.order,
        [name]: value,
      },
    }));
  }

  checkValid() {
    const invalid = this.state.order.days.filter(
      (x) => x.sizeValid === false || x.toppingValid === false
    );
    this.setState({ formValid: invalid.length === 0 });
  }

  handleOrderTouched(date, name) {
    this.setState((prevState) => ({
      order: {
        ...prevState.order,
        days: prevState.order.days.map((x) => {
          if (x.date !== date) return x;

          return {
            ...x,
            [name]: true,
          };
        }),
      },
    }));
  }

  renderTrainingForm() {
    const { formModal, formValid, order } = this.state;
    const { toppings } = this.props;

    // the content to be shown in the popover on the modal
    const infoContent = `
        Selecteer een broodje om de beschrijving ervan te bekijken.
    `;

    // console.log("ORDER DAYS", order.days);
    /** Returns the form modal */
    return (
      <Modal
        show={formModal}
        showInfo={order && order.days.some((day) => day.acceptOrders)}
        infoContent={infoContent}
        close={() => this.setState({ formModal: false })}
        title={"temp"}
        large={true}
        actions={[
          {
            label: "Verstuur formulier",
            action: this.handleSubmit,
            disabled: formValid,
          },
        ]}
      >
        <Row>
          {order && (
            <>
              {order.days.map((day, i) => {
                if (day.acceptOrders) {
                  return (
                    <Col lg={3} md={4} sm={6} key={i}>
                      <p>
                        <b>{DateUtility.toLongDate(day.date)}</b>
                      </p>
                      <FormCheckbox
                        name={`order_${i}`}
                        checked={day.order}
                        label="Bestel broodje"
                        onChange={(e) =>
                          this.handleOrderCheckbox(e, day.date, "order")
                        }
                      />
                      {day.order && (
                        <>
                          <SelectInput
                            name={`size_${i}`}
                            options={[
                              {
                                value: "small_white",
                                label: "Klein wit broodje",
                              },
                              {
                                value: "large_white",
                                label: "Groot wit broodje",
                              },
                              {
                                value: "small_dark",
                                label: "Klein bruin broodje",
                              },
                              {
                                value: "large_dark",
                                label: "Groot bruin broodje",
                              },
                            ]}
                            onChange={(e) =>
                              this.handleOrderSelect(e, day.date, "size")
                            }
                            value={day.size}
                            label={"Broodje"}
                            placeholder={"Selecteer je broodje"}
                            required={true}
                            isMulti={false}
                            error={"Broodje is een verplicht veld"}
                            valid={day.sizeTouched ? day.sizeValid : true}
                            onBlur={() =>
                              this.handleOrderTouched(day.date, "sizeTouched")
                            }
                          />
                          <SelectInput
                            name={`topping_${i}`}
                            options={toppings.map((x) => {
                              return {
                                value: x.id,
                                label: x.topping,
                                extra: x.hasOptions,
                                desc: x.description,
                              };
                            })}
                            onChange={(e) =>
                              this.handleOrderSelect(e, day.date, "topping")
                            }
                            value={day.topping}
                            label={"Beleg"}
                            placeholder={"Selecteer je beleg"}
                            description={day.topping ? day.topping.desc : null}
                            required={true}
                            isMulti={false}
                            error={"Beleg is een verplicht veld"}
                            valid={day.toppingTouched ? day.toppingValid : true}
                            onBlur={() =>
                              this.handleOrderTouched(
                                day.date,
                                "toppingTouched"
                              )
                            }
                          />

                          {day.topping && day.topping.extra && (
                            <FormCheckbox
                              name={`extra_${i}`}
                              value={1}
                              checked={day.extra}
                              label="Smos"
                              onChange={(e) =>
                                this.handleOrderCheckbox(e, day.date, "extra")
                              }
                            />
                          )}
                        </>
                      )}
                    </Col>
                  );
                } else {
                  return (
                    <Col lg={3} md={4} sm={6} key={i}>
                      <p>
                        <b>{DateUtility.toLongDate(day.date)}</b>
                      </p>
                      <p>{day.reason}</p>
                    </Col>
                  );
                }
              })}

              <Col md={12}>
                <FormTextarea
                  name={"remark"}
                  onChange={this.handleOrderTextarea}
                  value={order.remark}
                  label={"Opmerking"}
                  placeholder={
                    "Geef hier je speciale wensen of opmerkingen door"
                  }
                  required={false}
                  error={""}
                  valid={true}
                  rows={5}
                />
              </Col>
            </>
          )}
        </Row>
      </Modal>
    );
  }

  /**
   * Component renderMail method
   * @returns {*}
   */
  renderMail() {
    let { user, author, obj, objType, intl } = this.props;
    const { mailModal, mailType } = this.state;
    const mailBody = this.state[mailType];
    const { currentDate, toLongDate, toTime } = DateUtility;
    const currentTimestamp = currentDate() / 1000;

    /** Returns the mail modal */
    return (
      <Modal
        show={mailModal}
        close={() => this.setState({ mailModal: false })}
        large={true}
        title={intl.formatMessage(
          { id: "subscription.mail.subject" },
          {
            userName: `${user.firstName} ${user.lastName}`,
            type:
              objType === "events"
                ? intl.messages["subscription.event"]
                : intl.messages["subscription.training"],
            name: obj.name,
          }
        )}
        actions={[
          {
            label: intl.formatMessage({
              id: "subscription.buttons.formSubmit",
            }),
            action: this.handleMailSubmit,
            disabled: mailBody ? mailBody.length !== 0 : false,
          },
        ]}
      >
        <Row className={"send-mail"}>
          <Col md={8}>
            <img
              src={
                "https://ui-avatars.com/api/?name=" +
                user.firstName +
                " " +
                user.lastName +
                "&background=9D9694&color=ffffff"
              }
              alt={user.firstName + " " + user.lastName}
              className="avatar"
            />
            <div className="sender">
              <b>
                {user.firstName} {user.lastName}
              </b>
              <br />
              {user.email}
            </div>
          </Col>

          <Col md={4} className={"right"}>
            {toLongDate(currentTimestamp)}, <b>{toTime(currentTimestamp)}</b>
          </Col>

          <Col md={12}>
            <hr />

            <FormattedMessage
              id={"subscription.mail.salutation"}
              values={{ name: author.firstName }}
            >
              {(txt) => <p>{txt}</p>}
            </FormattedMessage>

            <FormTextarea
              onChange={this.handleMailChange}
              name={mailType}
              value={mailBody}
              autoFocus={true}
              placeholder={{ label: "subscription.mail.placeholder" }}
            />

            <p style={{ color: "#9D9694" }}>
              <FormattedMessage id={"subscription.mail.greeting"} />
              <br />
              {user.firstName} {user.lastName}
              <br />
            </p>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = ({ authReducer, toppingReducer }) => {
  const { user } = authReducer;
  const { toppings } = toppingReducer;
  return { user, toppings };
};

const mapDispatchToProps = {
  confirmInvitation,
  sendForm,
  sendMail,
  loadToppings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Subscription));

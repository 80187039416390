import React, {Component} from "react";
import FormInput from "./FormInput";
import FormTextarea from "./FormTextarea";
import SelectInput from "./SelectInput";
import FormCheckbox from "./FormCheckbox";
import FormRadio from "./FormRadio";
import FieldValidator from "../FieldValidator";
import FormGroup from "./FormGroup";
import FormCol from "./FormCol";
import Dropzone from "./Dropzone";
import CreatableInput from "./CreatableInput";
import FormTinyMCE from "./FormTinyMCE";
import FormDatepicker from "./FormDatepicker";
import {DateUtils} from 'react-day-picker';
import FormTimeRange from "./FormTimeRange";
import moment from "moment";
import FormTab from "./FormTab";
import ProgressBar from "./ProgressBar";

/**
 * Form component
 */
class Form extends Component {
    constructor(props) {
        super(props);

        this.handleBlur = this.handleBlur.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleFileInput = this.handleFileInput.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleRadio = this.handleRadio.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
    }

    /**
     * Validate the form on component mount
     */
    componentDidMount() {
        let {form} = this.props;

        form.fields = form.fields.map(x => {
            x = {...x, value: x.value ? x.value : '', touched: false};
            const valid = FieldValidator.validateField(x);
            return {...x, ...valid}
        });

        this.props.updateFields(form);
        this.validateForm();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.form.validateForm === true) this.validateForm();
    }


    /**
     * Handle input change
     * @param e
     * @param index
     */
    handleInput(e, index) {
        const {value, name} = e.target;
        let {form} = this.props;

        if (name === 'reason') {
            form.fields = form.fields.map(x => {
                if (x.name !== 'date') return x;

                x = {
                    ...x,
                    value: x.value.map((y, i) => {
                        if (i !== index) return y;

                        return {
                            ...y,
                            reason: value
                        }
                    })
                };

                const valid = FieldValidator.validateField(x);
                return {
                    ...x,
                    ...valid
                }
            });
        } else {
            form.fields = form.fields.map(x => {
                if (x.name !== name) return x;
                x = {...x, value};

                const valid = FieldValidator.validateField(x);
                return {
                    ...x,
                    ...valid
                }
            });
        }

        this.props.updateFields(form);
        this.validateForm();
    }


    /**
     * Handle file input change
     * @param e
     */
    handleFileInput(e) {
        let {form} = this.props;

        form.fields = form.fields.map(x => {
            if (x.type !== 'file') return x;
            x = {
                ...x,
                files: e.target.files,
                value: e.target.value
            };

            return x
        });

        this.props.updateFields(form);
        this.validateForm();
    }


    /**
     * Handle select change
     * @param name
     * @param selectedOption
     */
    handleSelect(name, selectedOption) {
        let {form} = this.props;


        let value = Array.isArray(selectedOption) ? [] : null;
        if (selectedOption) {
            if (Array.isArray(selectedOption)) {
                value = [];
                selectedOption.map(opt => {
                    return opt.__isNew__ ? value.push(opt.value) : value.push(opt);
                });
            } else {
                value = selectedOption.__isNew__ ? selectedOption.value : selectedOption;
            }
        }

        form.fields = form.fields.map(x => {
            if (x.name !== name) return x;
            x = {...x, value};

            const valid = FieldValidator.validateField(x);
            return {
                ...x,
                ...valid,
            }
        });

        this.props.updateFields(form);
        this.validateForm();
    }


    /**
     * Handle checkbox change
     * @param e
     * @param index
     */
    handleCheckbox(e, index) {
        const {name, value} = e.target;
        let {form} = this.props;

        if (name === 'bread') {
            form.fields = form.fields.map(x => {
                if (x.name !== 'date') return x;

                x = {
                    ...x,
                    value: x.value.map((y, i) => {
                        if (i !== index) return y;

                        return {
                            ...y,
                            bread: y.bread ? 0 : value
                        }
                    })
                };

                const valid = FieldValidator.validateField(x);
                return {
                    ...x,
                    ...valid
                }
            });
        } else {
            form.fields = form.fields.map(x => {
                if (x.name !== name) return x;
                x = {...x, value: x.value ? 0 : value};

                const valid = FieldValidator.validateField(x);
                return {
                    ...x,
                    ...valid
                }
            });
        }

        this.props.updateFields(form);
        this.validateForm();
    }


    /**
     * Handle radio change
     * @param e
     */
    handleRadio(e) {
        const {name, value} = e.target;
        let {form} = this.props;

        form.fields = form.fields.map(x => {
            if (x.name !== name) return x;
            x = {...x, value};

            const valid = FieldValidator.validateField(x);
            return {
                ...x,
                ...valid
            }
        });


        this.props.updateFields(form);
        this.validateForm();
    }


    /**
     * Handle files from dropzone
     * @param array
     */
    onFilesAdded(array) {
        let {form} = this.props;

        form.fields = form.fields.map(x => {
            if (x.type !== 'dropzone') return x;
            x = {
                ...x,
                files: array
            };

            return x
        });

        this.props.updateFields(form);
        this.validateForm();
    }


    /**
     * Handle date change
     * @param e
     * @param day
     * @param selected
     * @param disabled
     */
    handleDateChange(e, day, {selected, disabled}) {
        const {name} = e.target;
        let {form} = this.props;

        if (!disabled) {
            form.fields = form.fields.map(x => {
                if (x.name !== name) return x;

                let value = x.value;
                if (x.multiple !== false) {
                    if (selected) {
                        let selectedIndex = -1;
                        value.map((dayA, index) => {
                            if (DateUtils.isSameDay(new Date(dayA.day), new Date(day))) {
                                return selectedIndex = index;
                            }
                            return null
                        });
                        value.splice(selectedIndex, 1);
                    } else {
                        const start_time = moment('2017-01-01 18:00:00.000').format('YYYY-MM-DDTHH:mm:ss.SSS') //'2017-01-01T17:00:00.000Z';
                        const end_time = moment('2017-01-01 22:00:00.000').format('YYYY-MM-DDTHH:mm:ss.SSS') //'2017-01-01T20:00:00.000Z';

                        value.length === 0 ?
                            value.push({day, startTime: start_time, endTime: end_time, bread: true, reason: null}) :
                            value.push({day, startTime: value[value.length - 1].startTime, endTime: value[value.length - 1].endTime, bread: value[value.length - 1].bread, reason: value[value.length - 1].reason});

                        value.sort(function (a, b) {
                            return new Date(a.day) - new Date(b.day);
                        });
                    }
                } else {
                    value = [{day}];
                }

                x = {...x, value};
                const valid = FieldValidator.validateField(x);
                return {
                    ...x,
                    ...valid
                }
            });
        }

        this.props.updateFields(form);
        this.validateForm();
    }


    /**
     * Handle time change
     * @param index
     * @param e
     */
    handleTimeChange(index, e) {
        const {startTime, endTime} = e.target;
        let {form} = this.props;

        form.fields = form.fields.map(x => {
            if (x.name !== 'date') return x;

            x = {
                ...x,
                value: x.value.map((y, i) => {
                    if (i !== index) return y;
                    return {
                        ...y,
                        startTime: moment(startTime).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                        endTime: moment(endTime).format('YYYY-MM-DDTHH:mm:ss.SSS')
                    }
                })
            };

            const valid = FieldValidator.validateField(x);
            return {
                ...x,
                ...valid
            }
        });

        this.props.updateFields(form);
        this.validateForm();
    }


    /**
     * Handle blurring od element
     * @param e
     */
    handleBlur(e) {
        const {name} = e.target;
        let {form} = this.props;

        form.fields = form.fields.map(x => {
            if (x.name !== name) return x;

            return {
                ...x,
                touched: true
            }
        });

        this.props.updateFields(form);
        this.validateForm();
    }


    /**
     * Validate the form
     */
    validateForm() {
        let {form} = this.props;
        let {currentIndex, format, fields} = form;

        if (format && format[0] && format[0] && format[0].type === "tab") {
            const currentTab = format[currentIndex];

            const currentFields = fields.filter(x => currentTab.fields.includes(x.name));

            const currentOptions = currentTab.options.filter(currentOption => {
                return currentFields.find(x => x.name === currentOption.field && x.value === currentOption.value);
            });

            const skip = currentOptions.map(x => x.skip).flat();
            const allTabs = format.filter(x => !skip.includes(x.name));

            form.skipped = form.skipped.filter(x => {
                return ![...new Set(currentTab.options.map(option => option.skip).flat())].includes(x);
            });


            form.steps = allTabs.length - form.skipped.length;
            form.validateForm = false;
            form.skipped = [...new Set([...form.skipped, ...skip])];


            const nextTab = format.filter((x, i) => i > currentIndex && !form.skipped.includes(x.name));
            form.nextIndex = format.indexOf(nextTab[0]);


            const valid = !currentFields.find(x => x.valid !== true);
            this.props.formValid(valid)
        } else {
            const valid = !this.props.form.fields.find(x => x.valid !== true);
            this.props.formValid(valid)
        }
    }


    /**
     * Component render method
     * @returns {*[]}
     */
    render() {
        const {form} = this.props;

        /** Returns mapping of loadElement */
        return form.format.map((format, i) => {
            return this.loadElement(format, i)
        });
    };


    /**
     * Component loadElement method
     * @param formatElement
     * @param i
     * @param index
     * @returns {*}
     */
    loadElement(formatElement, i, index) {
        const {form} = this.props;

        /** Return render element by type */
        switch (formatElement.type) {
            case 'col':
                return (
                    <FormCol key={i} name={formatElement.name} breakpoint={formatElement.breakpoint}>
                        {formatElement.fields.map((element, index) => {
                            const field = form.fields.find(x => x.name === element);
                            return this.loadElement(field, index);
                        })}
                    </FormCol>
                );

            case 'group':
                return (
                    <FormGroup key={i} name={formatElement.name} label={formatElement.label} direction={formatElement.direction}>
                        {formatElement.fields.map((element, index) => {
                            const field = form.fields.find(x => x.name === element);
                            return this.loadElement(field, index);
                        })}
                    </FormGroup>
                );

            case 'tab':
                return (
                    <FormTab key={i} index={i} currentIndex={form.currentIndex} name={formatElement.name}>
                        {form.steps > 1 &&
                        <ProgressBar steps={form.steps} currentStep={form.step}/>
                        }


                        {formatElement.fields.map((element, index) => {
                            const field = form.fields.find(x => x.name === element);
                            return this.loadElement(field, index);
                        })}
                    </FormTab>
                );

            case 'dropzone':
                return <Dropzone onFilesAdded={this.onFilesAdded}
                                 files={formatElement.files}
                                 count={formatElement.files.length}
                                 description={formatElement.description}
                                 key={i}
                />;

            case 'datepicker':
                return <FormDatepicker name={formatElement.name}
                                       label={formatElement.label}
                                       value={formatElement.value}
                                       valid={formatElement.touched ? formatElement.valid : true}
                                       description={formatElement.description}
                                       onChange={this.handleDateChange}
                                       onBlur
                                       error={formatElement.error}
                                       className={formatElement.className}
                                       required={formatElement.required}
                                       disabledDays={formatElement.disabled ? formatElement.disabled : formatElement.disabledDays}
                                       key={i}
                >
                    {formatElement.include && formatElement.include.length !== 0 &&
                    formatElement.value.map((obj, index) => {
                        return formatElement.include.map((element, int) => {
                            let field = form.fields.find(x => x.name === element);

                            const temp = {...field, value: obj, disabled: formatElement.disabled && formatElement.disabled.length > 0};
                            return this.loadElement(temp, `${index}${int}`, index);
                        })
                    })}
                </FormDatepicker>;

            case 'timerange':
                return <FormTimeRange name={formatElement.name}
                                      label={formatElement.label}
                                      value={formatElement.value}
                                      valid={formatElement.touched ? formatElement.valid : true}
                                      onChange={(startTime, endTime) => this.handleTimeChange(index, {target: {startTime, endTime}})}
                                      onBlur={this.handleBlur}
                                      error={formatElement.error}
                                      className={formatElement.className}
                                      required={formatElement.required}
                                      sameIsValid={formatElement.sameIsValid}
                                      description={formatElement.description}
                                      disabled={formatElement.disabled}
                                      key={i}
                />;

            case 'textarea':
                return <FormTextarea key={i}
                                     name={formatElement.name}
                                     onChange={this.handleInput}
                                     value={formatElement.value}
                                     label={formatElement.label}
                                     placeholder={formatElement.placeholder}
                                     description={formatElement.description}
                                     required={formatElement.required}
                                     error={formatElement.error}
                                     valid={formatElement.touched ? formatElement.valid : true}
                                     onBlur={this.handleBlur}
                                     rows={8}
                />;

            case 'tiny':
                return <FormTinyMCE key={i}
                                    name={formatElement.name}
                                    onChange={this.handleInput}
                                    value={formatElement.value}
                                    label={formatElement.label}
                                    required={formatElement.required}
                                    description={formatElement.description}
                                    error={formatElement.error}
                                    valid={formatElement.touched ? formatElement.valid : true}
                                    onBlur={this.handleBlur}
                />;

            case 'select':
                return <SelectInput key={i}
                                    name={formatElement.name}
                                    options={formatElement.options ? formatElement.options.map(x => {
                                        if (x.hasOwnProperty('label') && x.hasOwnProperty('value')) {
                                            return {label: x.label, value: x.value, ...x}
                                        } else {
                                            return {label: x, value: x}
                                        }
                                    }) : []}
                                    onChange={this.handleSelect.bind(this, formatElement.name)}
                                    value={formatElement.value
                                        ? (
                                            Array.isArray(formatElement.value)
                                                ? formatElement.value.map(x => {
                                                    if (x.hasOwnProperty('label') && x.hasOwnProperty('value')) {
                                                        return {label: x.label, value: x.value, ...x}
                                                    } else {
                                                        return {label: x, value: x}
                                                    }
                                                })
                                                : (formatElement.value.hasOwnProperty('label') && formatElement.value.hasOwnProperty('value') ? {label: formatElement.value.label, value: formatElement.value.value} : {
                                                    label: formatElement.value,
                                                    value: formatElement.value
                                                })
                                        )
                                        : null
                                    }
                                    label={formatElement.label}
                                    placeholder={formatElement.placeholder}
                                    required={formatElement.required}
                                    description={formatElement.description}
                                    isMulti={formatElement.multiple}
                                    error={formatElement.error}
                                    valid={formatElement.touched ? formatElement.valid : true}
                                    onBlur={this.handleBlur}
                                    isFixed={formatElement.isFixed}
                />;

            case 'creatable':
                return <CreatableInput key={i}
                                       name={formatElement.name}
                                       options={formatElement.options ? formatElement.options.map(x => {
                                           if (x && x.hasOwnProperty('label') && x.hasOwnProperty('value')) {
                                               return {label: x.label, value: x.value}
                                           } else {
                                               return {label: x, value: x}
                                           }
                                       }) : []}
                                       onChange={this.handleSelect.bind(this, formatElement.name)}
                                       value={formatElement.value
                                           ? (
                                               Array.isArray(formatElement.value)
                                                   ? formatElement.value.map(x => {
                                                       if (x.hasOwnProperty('label') && x.hasOwnProperty('value')) {
                                                           return {label: x.label, value: x.value}
                                                       } else {
                                                           return {label: x, value: x}
                                                       }
                                                   })
                                                   : (formatElement.value.hasOwnProperty('label') && formatElement.value.hasOwnProperty('value') ? {label: formatElement.value.label, value: formatElement.value.value} : {
                                                       label: formatElement.value,
                                                       value: formatElement.value
                                                   })
                                           )
                                           : null
                                       }
                                       label={formatElement.label}
                                       placeholder={formatElement.placeholder} required={formatElement.required}
                                       description={formatElement.description}
                                       isMulti={formatElement.multiple}
                                       error={formatElement.error}
                                       valid={formatElement.touched ? formatElement.valid : true}
                                       onBlur={this.handleBlur}
                                       isFixed={formatElement.isFixed}
                />;

            case 'radio':
                return <FormRadio key={i}
                                  name={formatElement.name}
                                  className={formatElement.className}
                                  label={formatElement.label}
                                  options={formatElement.options}
                                  required={formatElement.required}
                                  description={formatElement.description}
                                  value={formatElement.value}
                                  onChange={this.handleRadio}
                                  error={formatElement.error}
                                  valid={formatElement.touched ? formatElement.valid : true}
                                  onBlur={this.handleBlur}
                />;

            case 'checkbox':
                return <FormCheckbox key={i}
                                     name={formatElement.name}
                                     checked={formatElement.value && typeof (formatElement.value) === 'object' ? !!formatElement.value.bread : !!formatElement.value}
                                     label={formatElement.label}
                                     description={formatElement.description}
                                     onChange={(e) => this.handleCheckbox(e, index)}
                                     required={formatElement.required}
                                     error={formatElement.error}
                                     valid={formatElement.touched ? formatElement.valid : true}
                                     onBlur={this.handleBlur}
                                     disabled={formatElement.disabled}
                                     index={index}
                >
                    {formatElement.include && formatElement.include.length !== 0 && formatElement.value && !formatElement.value.bread &&
                        formatElement.include.map((element, int) => {
                            let field = form.fields.find(x => x.name === element);

                            const temp = {...field, value: formatElement.value}
                            return this.loadElement(temp, `${index}${int}`, index);
                        })
                    }
                </FormCheckbox>;

            case 'file':
                return <FormInput key={i}
                                  type={formatElement.type}
                                  name={formatElement.name}
                                  onChange={this.handleFileInput}
                                  value={formatElement.value}
                                  label={formatElement.label}
                                  placeholder={formatElement.placeholder}
                                  description={formatElement.description}
                                  required={formatElement.required}
                                  error={formatElement.error}
                                  valid={formatElement.touched ? formatElement.valid : true}
                                  onBlur={this.handleBlur}
                />;

            case 'number':
                return <FormInput key={i}
                                  type={formatElement.type}
                                  name={formatElement.name}
                                  onChange={this.handleInput}
                                  value={formatElement.value}
                                  label={formatElement.label}
                                  placeholder={formatElement.placeholder}
                                  description={formatElement.description}
                                  required={formatElement.required}
                                  min={formatElement.min}
                                  max={formatElement.max}
                                  step={formatElement.step}
                                  error={formatElement.error}
                                  valid={formatElement.touched ? formatElement.valid : true}
                                  onBlur={this.handleBlur}
                />;

            default:
                return <FormInput key={i}
                                  type={formatElement.type}
                                  name={formatElement.name}
                                  onChange={(e) => this.handleInput(e, index)}
                                  value={formatElement.value && typeof (formatElement.value) === 'object' ? formatElement.value.reason : formatElement.value}
                                  label={formatElement.label}
                                  placeholder={formatElement.placeholder}
                                  description={formatElement.description}
                                  required={formatElement.required}
                                  error={formatElement.error}
                                  valid={formatElement.touched ? formatElement.valid : true}
                                  onBlur={this.handleBlur}
                                  readOnly={formatElement.readOnly}
                />;
        }
    }
}


export default Form;
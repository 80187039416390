import React, { Component } from "react";
import NL from "../../langs/nl";
import FR from "../../langs/fr";
import { store } from "../../redux/store";
import { updateIntl } from "react-intl-redux";
import "./CrownBar.scss";
import { injectIntl } from "react-intl";
import { Container } from "../../utils/Grid/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookF,
    faInstagram,
    faLinkedin, faTiktok, faTwitter
} from '@fortawesome/free-brands-svg-icons'
import {
    faCarCrash,
    faChalkboardTeacher,
    faFileInvoice,
    faHamburger,
    faNotesMedical,
    faTasks
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { SHARED_CLASSES } from "../../css_constants";

/**
 * Language switcher component
 */
class CrownBar extends Component {
    /**
     * Handle language change
     * @param lang
     */
    onChangeLanguage(lang) {
        let messages = null;
        switch ( lang ) {
            case 'nl':
                messages = NL;
                break;
            case 'fr':
                messages = FR;
                break;
            default:
                messages = NL;
                break;
        }

        store.dispatch(updateIntl({
            locale: lang,
            messages,
        }));
    }

    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {permissions} = this.props;
        const adminPermissions = permissions.includes('claims_illnesses_admin')
            || permissions.includes('claims_damages_admin')
            || permissions.includes('sandwiches_admin')
            || permissions.includes('instructors_admin')
            || permissions.includes('permissions_admin');

        /**
         * Return language buttons
         *
         */
        return (
            <div className={`crownBar`}>
                <Container>
                    <div>
                        <div className={`socials ${adminPermissions ? '' : 'noAdmin'}`}>
                            <a href="https://www.facebook.com/UniPartnersNV" target={"_blank"}
                               rel="noopener noreferrer"><FontAwesomeIcon
                                icon={faFacebookF} fixedWidth={true}/></a>
                            <a href="https://www.instagram.com/unipartners_belgium" target={"_blank"}
                               rel="noopener noreferrer"><FontAwesomeIcon
                                icon={faInstagram} fixedWidth={true}/></a>
                            <a href="https://www.tiktok.com/@unipartners_consultancy" target={"_blank"}
                               rel="noopener noreferrer"><FontAwesomeIcon
                                icon={faTiktok} fixedWidth={true}/></a>
                            <a href="https://www.linkedin.com/company/unipartnersgroup" target={"_blank"}
                               rel="noopener noreferrer"><FontAwesomeIcon
                                icon={faLinkedin} fixedWidth={true}/></a>
                        </div>
                        {//adminPermissions &&
                        <div className={'admin'}>
                            {permissions.includes('claims_illnesses_admin') &&
                            <NavLink to={'/admin/ziekte'} activeClassName={SHARED_CLASSES.ACTIVE}><FontAwesomeIcon icon={faNotesMedical} fixedWidth={true}/><span>ZIEKTE</span></NavLink>
                            }
                            {permissions.includes('claims_damages_admin') &&
                            <NavLink to={'/admin/schade'} activeClassName={SHARED_CLASSES.ACTIVE}><FontAwesomeIcon icon={faCarCrash} fixedWidth={true}/><span>SCHADE</span></NavLink>
                            }
                            {permissions.includes('sandwiches_admin') &&
                            <NavLink to={'/admin/broodjes'} activeClassName={SHARED_CLASSES.ACTIVE}><FontAwesomeIcon icon={faHamburger} fixedWidth={true}/><span>BROODJES</span></NavLink>
                            }
                            {permissions.includes('instructors_admin') &&
                            <NavLink to={'/admin/lesgevers'} activeClassName={SHARED_CLASSES.ACTIVE}><FontAwesomeIcon icon={faChalkboardTeacher} fixedWidth={true}/><span>LESGEVERS</span></NavLink>
                            }
                            {permissions.includes('permissions_admin') &&
                            <NavLink to={'/admin/permissies'} activeClassName={SHARED_CLASSES.ACTIVE}><FontAwesomeIcon icon={faTasks} fixedWidth={true}/><span>PERMISSIES</span></NavLink>
                            }

                            <a href={'https://ess.partena.be/UNIH_400017'} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFileInvoice} fixedWidth={true}/><span>PARTENA</span></a>
                        </div>
                        }

                    </div>
                    {/*<div className={'languageSwitcher'}>
                        <button className={locale === 'nl' ? 'active' : null}
                                onClick={() => this.onChangeLanguage('nl')}>NL
                        </button>
                        <button className={locale === 'fr' ? 'active' : null}
                                onClick={() => this.onChangeLanguage('fr')}>FR
                        </button>
                    </div>*/}
                </Container>
            </div>
        )
    }
}


export default injectIntl(CrownBar);
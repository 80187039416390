import axios from "axios";
import {API_URL} from "../constants/defaults";
import {ADD_ARTICLE, DELETE_ARTICLE, DELETE_ARTICLE_MEDIA, LOAD_ARTICLE, LOAD_RANDOM, LOAD_NEWS, PIN_ARTICLE, UPDATE_ARTICLE, RELOAD_NEWS} from "../constants/news.constrants";
import {PIN_FEEDITEM} from "../constants/mainFeed.constants";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";

export const loadNews = (start) => (dispatch, getStore) => {
    const store = getStore();
    const {locale, messages} = store.intl;
    const {isLoading} = store.newsReducer;
    const {token} = store.authReducer;

    if (!isLoading) {
        dispatch({type: LOAD_NEWS, payload: {articles: [], isLoading: true}});
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(`${API_URL}/news?lang=${locale}&start=${start}`, header)
            .then(({data}) => {
                dispatch({type: LOAD_NEWS, payload: {articles: data, isLoading: false}});
            })
            .catch(error => {
                dispatch({type: LOAD_NEWS, payload: {articles: [], isLoading: false}});
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["news.response.error"], type: 'error'})}});
            });
    }
};

export const reloadNews = (type) => (dispatch, getStore) => {
    const store = getStore();
    const {messages} = store.intl;
    const {token} = store.authReducer;

    dispatch({type: RELOAD_NEWS, payload: {articles: [], isLoading: true}});
    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.get(`${API_URL}/news${type === "unpublished" ? "/unpublished" : ""}`, header)
        .then(({data}) => {
            dispatch({type: RELOAD_NEWS, payload: {articles: data, isLoading: false}});
        })
        .catch(error => {
            dispatch({type: RELOAD_NEWS, payload: {articles: [], isLoading: false}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["news.response.error"], type: 'error'})}});
        });
}

export const loadNewsByTag = (tags, start) => (dispatch, getStore) => {
    const store = getStore();
    const {messages} = store.intl;
    const {token} = store.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(`${API_URL}/news/filterTags?start=${start}`, tags, header)
        .then(({data}) => {
            dispatch({type: RELOAD_NEWS, payload: {articles: data, isLoading: false}});
        })
        .catch(error => {
            dispatch({type: RELOAD_NEWS, payload: {articles: [], isLoading: false}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["news.response.error"], type: 'error'})}});
        });
}

export const searchNews = (searchValue, start) => (dispatch, getStore) => {
    const store = getStore();
    const {messages} = store.intl;
    const {token} = store.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.get(`${API_URL}/news/search?search=${searchValue}&start=${start}`, header)
        .then(({data}) => {
            dispatch({type: RELOAD_NEWS, payload: {articles: data, isLoading: false}});
        })
        .catch(error => {
            dispatch({type: RELOAD_NEWS, payload: {articles: [], isLoading: false}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["news.response.error"], type: 'error'})}});
        });
}

export const loadRandom = (tag) => (dispatch, getStore) => {
    const store = getStore();
    const {locale, messages} = store.intl;
    const {isLoading} = store.newsReducer;
    const {token} = store.authReducer;

    if (!isLoading) {
        dispatch({type: LOAD_RANDOM, payload: {articles: [], isLoading: true}});
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(`${API_URL}/news/tag/${tag}?lang=${locale}`, header)
            .then(({data}) => {
                dispatch({type: LOAD_RANDOM, payload: {articles: data, isLoading: false}});
            })
            .catch(error => {
                dispatch({type: LOAD_RANDOM, payload: {articles: [], isLoading: false}});
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["news.response.error"], type: 'error'})}});
            });
    }
}

export const loadArticle = (slug) => (dispatch, getState) => {
    const state = getState();
    const {locale, messages} = state.intl;
    const {token} = state.authReducer;
    const {newsList} = state.newsReducer;
    dispatch({type: LOAD_ARTICLE, payload: {selectedArticle: null, isLoading: true}});

    const article = newsList.find(x => x.slug === slug);
    const header = { headers: {'Authorization': "Bearer " + token} };
    if (article && article.isLoaded) {
        dispatch({type: LOAD_ARTICLE, payload: {selectedArticle: article, isLoading: false}});
    } else {
        axios.get(API_URL + '/news/' + slug + '?lang='+locale, header)
            .then(({data}) => {
                dispatch({type: LOAD_ARTICLE, payload: {selectedArticle: data, isLoading: false}});
            })
            .catch(error => {
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["article.response.error"], type: 'error'})}});
                console.log(error)
            })
    }
};

export const pinArticle = (id, pinned) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;

    const header = { headers: {'Authorization': "Bearer " + token} };
    axios.put(API_URL + '/news/' + id + '/pin', {pinned}, header)
        .then(({data}) => {
            dispatch({type: PIN_ARTICLE, payload: {article: data}});
            dispatch({type: PIN_FEEDITEM, payload: {article: data}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: (pinned ? messages["pin.add.response.success"] : messages["pin.delete.response.success"]), type: 'success'})}});
        })
        .catch(error => {
            const message = handlePinErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};


export const postArticle = (data, history, id) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = { headers: {'Content-Type': 'multipart/form-data', 'Authorization': "Bearer " + token }};
    axios.post(API_URL + '/news' + (id ? `/${id}` : ''), data, header)
        .then(({data}) => {
            dispatch({type: ADD_ARTICLE, payload: {article: data}});
            //dispatch({type: ADD_FEEDITEM, payload: {feeditem: data.mainfeed}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: ('Je nieuwsartikel werd succesvol bewaard.'), type: 'success'})}});
            history.push('/nieuws/' + data.slug);
        })
        .catch(error => {
            const message = handlePostErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};

export const updateArticle = (data, history, id) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = { headers: {'Content-Type': 'multipart/form-data', 'Authorization': "Bearer " + token }};
    axios.post(API_URL + '/news/' + id + '/update', data, header)
        .then(({data}) => {
            dispatch({type: UPDATE_ARTICLE, payload: {article: data}});
            //dispatch({type: ADD_FEEDITEM, payload: {feeditem: data.mainfeed}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: ('Je nieuwsartikel werd succesvol bewaard.'), type: 'success'})}});
            history.push('/nieuws/' + data.slug);
        })
        .catch(error => {
            const message = handlePostErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};

export const publishArticle = (id) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = { headers: {'Authorization': "Bearer " + token} };
    axios.put(API_URL + '/news/' + id + '/publish', {}, header)
        .then(({data}) => {
            dispatch({type: UPDATE_ARTICLE, payload: {article: data}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "Je nieuwsartikel werd succesvol gepubliseerd.", type: 'success'})}});
        })
        .catch(error => {
            const message = handlePublishErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};

export const deleteArticle = (id, history) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = { headers: {'Authorization': "Bearer " + token} };
    axios.delete(API_URL + '/news/' + id, header)
        .then(() => {
            dispatch({type: DELETE_ARTICLE, payload: {id}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "Je nieuwsartikel werd succesvol verwijderd.", type: 'success'})}});

            if (history) history.push('/nieuws');
        })
        .catch(error => {
            const message = handleDeleteErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};

export const deleteMedia = (id, type) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = { headers: {'Authorization': "Bearer " + token} };
    axios.delete(API_URL + '/' + type + '/' + id, header)
        .then(() => {
            dispatch({type: DELETE_ARTICLE_MEDIA, payload: {id, type}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "Je bijlage werd succesvol verwijderd.", type: 'success'})}});
        })
        .catch(error => {
            const message = handleDeleteMediaErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};


const handlePostErrors = (error) => {
    if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.code && error.response.data.error.code === 403) {
            return "Je hebt niet voldoende rechten om een nieuwsartikel te plaatsen.";
        }
        if (error.response.data.error.code && error.response.data.error.code === 422) {
            return "De opgegeven data was ongeldig.";
        }
    }
    return "Artikel plaatsen mislukt vanwegen een onverwachte fout.";
};

const handlePublishErrors = (error) => {
    if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.code && error.response.data.error.code === 403) {
            return "Je hebt niet voldoende rechten om een nieuwsartikel te publiseren.";
        }
        if (error.response.data.error.code && error.response.data.error.code === 422) {
            return "De opgegeven data was ongeldig.";
        }
    }
    return "Artikel publiseren mislukt vanwegen een onverwachte fout.";
};

const handleDeleteErrors = (error) => {
    if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.code && error.response.data.error.code === 403) {
            return "Je hebt niet voldoende rechten om een nieuwsartikel te verwijderen.";
        }
        if (error.response.data.error.code && error.response.data.error.code === 422) {
            return "De opgegeven data was ongeldig.";
        }
    }
    return "Artikel verwijderen mislukt vanwegen een onverwachte fout.";
};

const handleDeleteMediaErrors = (error) => {
    if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.code && error.response.data.error.code === 403) {
            return "Je hebt niet voldoende rechten om een bijlage te verwijderen.";
        }
        if (error.response.data.error.code && error.response.data.error.code === 422) {
            return "De opgegeven data was ongeldig.";
        }
    }
    return "Bijlage verwijderen mislukt vanwegen een onverwachte fout.";
};

const handlePinErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["pin.response.error"];
};
import React, {Component, Fragment} from 'react';
import FormCheckbox from "../../../utils/Forms/FormCheckbox";
import {connect} from "react-redux";
import {editPermissions, getPermissions, getRoles} from "../../../redux/actions/permissionsAdmin.actions";
import Load from '../../../utils/Loading/Load';
import './Permissions.scss';

class Permissions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            permissions: [],
            roles: []
        }
    }

    componentDidMount() {
        const {getRoles, getPermissions} = this.props;
        document.title = `UniPartners Group | Admin permissies`;

        getRoles();
        getPermissions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.permissions !== this.props.permissions) {
            this.setState({permissions: this.props.permissions});
        }

        if (this.state.roles !== this.props.roles) {
            this.setState({roles: this.props.roles});
        }
    }

    submitInputHandler = (role, permission) => {
        const {roles} = this.state;
        const {editPermissions} = this.props;

        const data = roles.map(x => {
            if (x.id !== role.id) return {role: x.id, permissions: x.permissions.map(p => p.id)};

            if (x.permissions.find(p => p.id === permission.id)) {
                return {
                    role: x.id,
                    permissions: x.permissions.filter(p => p.id !== permission.id).map(p => p.id),
                }
            } else {
                return {
                    role: x.id,
                    permissions: [
                        ...x.permissions.map(p => p.id),
                        permission.id
                    ]
                }
            }
        });

        editPermissions({permissions: data});
    }

    renderRoles = (roles) => (
        roles.reverse().map(({id, displayName}, key) => {
            return (
                <Fragment key={`role_${id}${key}`}>
                    <th>{displayName}</th>
                </Fragment>
            );
        })
    );

    renderPermissionsPerRole = (roles, permissions) => (
        permissions.map((permission, key) => {
            return (
                <Fragment key={`permission_${permission.id}${key}`}>
                    <tr>
                        <td>
                            {permission.displayName}<br/>
                            <small>{permission.description}</small>
                        </td>

                        {roles && roles.map((role, key) => {
                            return (
                                <Fragment key={`role_permissions_${role.id}${key}`}>
                                    <td title={role.displayName}>
                                        <FormCheckbox name={`${role.id}_${permission.id}`}
                                                      onChange={() => this.submitInputHandler(role, permission)}
                                                      checked={role.permissions
                                                          ? role.permissions.find(rolePermission => rolePermission.name === permission.name)
                                                          : false}
                                                      value={permission.id}
                                                      disabled={role.name === 'admin' || role.name === 'dev'}
                                        />
                                    </td>
                                </Fragment>
                            )
                        })}
                    </tr>
                </Fragment>
            );
        })
    );

    renderPermissionsTable = (roles, permissions) => (
        <table className="admin-permissions">
            <thead>
            <tr>
                <th>Naam</th>
                {this.renderRoles(roles)}
            </tr>
            </thead>
            <tbody>
            {this.renderPermissionsPerRole(roles, permissions)}
            </tbody>
        </table>
    );

    render() {
        const {isLoading} = this.props;
        const {permissions, roles} = this.state;

        return (
            <div>
                <h1>Permissies</h1>

                {isLoading
                    ? <Load/>
                    : this.renderPermissionsTable(roles, permissions)
                }
            </div>
        );
    }
}

const mapStateToProps = ({permissionsAdminReducer}) => {
    const {roles, permissions} = permissionsAdminReducer;

    return {roles, permissions}
}

const mapDispatchToProps = {
    getRoles, getPermissions, editPermissions
}

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);
import React from "react";
import './EmptyList.scss';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

/**
 * Message for empty list component
 * @param id
 * @returns {*}
 * @constructor
 */
const EmptyList = ({id}) => {
    /** Return message */
    return (
        <div className="empty-list">
            <FormattedMessage id={id}/>
        </div>
    )
};


EmptyList.propTypes = {
    id: PropTypes.string.isRequired,
};

export default EmptyList;
import React, {Component} from 'react'
import './FormInput.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faTimes, faTrash, faUpload} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {getFileIcon, getFileSize, isValidFile} from "../FileUtils";
import Button from "../Buttons/Button";
import {BTN_CLASSES} from "../../css_constants";


/**
 * Dropzone component
 */
class Dropzone extends Component {
    constructor(props) {
        super(props);

        this.fileInputRef = React.createRef();

        /** State of the component */
        this.state = {
            highlight: false,
            errors: []
        };

        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.onFilesRemoved = this.onFilesRemoved.bind(this);
        this.onAllFilesRemoved = this.onAllFilesRemoved.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }


    /**
     * Open the file dialog on click
     */
    openFileDialog() {
        const {disabled} = this.props;
        this.setState({errors: []});

        if (disabled) return;
        this.fileInputRef.current.click()
    }


    onFilesAdded(e) {
        const {disabled, onFilesAdded} = this.props;
        const {files} = e.target;
        this.setState({errors: []});

        if (disabled) return;
        if (onFilesAdded) {
            const array = this.fileListToArray(files);
            onFilesAdded(array);
        }
    }

    onFilesRemoved(name) {
        const {disabled, onFilesAdded, files} = this.props;
        this.setState({errors: []});

        if (disabled) return;
        if (onFilesAdded) {
            const array = files.filter(x => x.name !== name);
            onFilesAdded(array);
        }
    }


    onAllFilesRemoved() {
        const {disabled, onFilesAdded} = this.props;
        this.setState({errors: []});

        if (disabled) return;
        if (onFilesAdded) {
            onFilesAdded([]);
        }
    }


    onDragOver(e) {
        e.preventDefault();
        const {disabled} = this.props;

        if (disabled) return;
        this.setState({highlight: true});
    }


    onDragLeave() {
        this.setState({highlight: false})
    }


    onDrop(e) {
        e.preventDefault();
        const {disabled, onFilesAdded} = this.props;
        this.setState({errors: []});

        if (disabled) return;

        const files = e.dataTransfer.files;
        if (onFilesAdded) {
            const array = this.fileListToArray(files);
            onFilesAdded(array);
        }
        this.setState({highlight: false});
    }


    fileListToArray(list) {
        const array = this.props.files;
        for (let i = 0; i < list.length; i++) {
            const item = list.item(i);
            const size = Math.round((item.size / 1024));

            if (size >= 10240 || !isValidFile(item)) {
                if (size >= 10240) {
                    this.setState(prevState => ({
                        errors: [
                            ...prevState.errors,
                            `${item.name} is te groot.`
                        ]
                    }))
                }

                if (!isValidFile(item)) {
                    this.setState(prevState => ({
                        errors: [
                            ...prevState.errors,
                            `${item.name} is niet toegelaten.`
                        ]
                    }))
                }
            } else {
                array.push(item);
            }
        }

        return array;
    }


    render() {
        const {count, disabled, description, label, required, name, files} = this.props;
        const {highlight} = this.state;

        /** Return the dropzone */
        return (
            <div className="form-group">
                {label && <label htmlFor={name}>{label} {required ? <code>*</code> : null}</label>}

                {files.length !== 0 &&
                <div className="uploaded-files">
                    {files.map((file, index) => (
                        <div className="file" key={index}>
                            <div>
                                <FontAwesomeIcon icon={getFileIcon(file.type)} fixedWidth/>
                                {file.name} {`(${getFileSize(file.size)})`}
                            </div>
                            <Button buttonStyle={BTN_CLASSES.LINK}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    className={BTN_CLASSES.DANGER}
                                    onClick={() => this.onFilesRemoved(file.name)}
                            >
                                <FontAwesomeIcon icon={faTimes}/>
                            </Button>
                        </div>

                    ))}

                    <div className="delete">
                        <Button buttonStyle={BTN_CLASSES.LINK}
                                buttonSize={BTN_CLASSES.SMALL}
                                className={BTN_CLASSES.DANGER}
                                onClick={this.onAllFilesRemoved}
                        >
                            <FontAwesomeIcon icon={faTrash}/> Verwijder alles
                        </Button>
                    </div>
                </div>
                }

                <div className={`dropzone ${highlight ? 'highlight' : ''}`}
                     onDragOver={this.onDragOver}
                     onDragLeave={this.onDragLeave}
                     onDrop={this.onDrop}
                    //onClick={this.openFileDialog}
                     style={{cursor: disabled ? 'default' : 'pointer'}}
                >
                    <input ref={this.fileInputRef}
                           type="file"
                           multiple
                           onClick={e => e.target.value = ''}
                           onChange={(e) => this.onFilesAdded(e)}
                    />
                    <span id="uploadIcon"><FontAwesomeIcon icon={faUpload}/></span>
                    <span id="cameraIcon"><FontAwesomeIcon icon={faCamera}/></span>
                    <span><FormattedMessage id="form.dropzone" values={{count}}/></span>
                </div>

                <div className="description">
                    Enkel bestanden kleiner dan 10MB zijn toegelaten
                    {description}
                </div>
                <small className="invalid-feedback">{this.state.errors.map((x, i) => <div key={i}>{x}</div>)}</small>
            </div>
        )
    }
}


Dropzone.propTypes = {
    onFilesAdded: PropTypes.func.isRequired,
};


export default Dropzone;
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faHamburger, faTrash} from "@fortawesome/free-solid-svg-icons";
import {BTN_CLASSES} from "../../../css_constants";
import './Sandwich.scss';
import {useDispatch} from "react-redux";
import {deleteTopping} from "../../../redux/actions/topping.actions";

const SandwichSingle = ({topping, select}) => {
    const dispatch = useDispatch();

    return (
        <div className={"sandwich"}>
            <div className={"sandwich__icon"}><FontAwesomeIcon icon={faHamburger}/></div>

            <div className={"sandwich__header"}>
                <div className={`${BTN_CLASSES.LINK} sandwich__title`} onClick={() => select(topping)}>{topping.topping}</div>
            </div>

            <div className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL} ${BTN_CLASSES.DANGER}`}
                 onClick={() => dispatch(deleteTopping(topping.id))}
            >
                <FontAwesomeIcon icon={faTrash}/>
            </div>
            <div className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`}
                 onClick={() => select(topping)}
            >
                <FontAwesomeIcon icon={faEdit} fixedWidth/>
            </div>
        </div>
    );
}

export default SandwichSingle;
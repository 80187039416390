import {ADD_TOAST, REMOVE_TOAST} from "../constants/toast.constants";

let defaultState = {
    toasts: []
};

const addToast = (state, {toast}) => (
    {...state, toasts: [...state.toasts, toast]}
);

const removeToast = (state, {id}) => (
    {...state, toasts: state.toasts.filter(x => x.id !== id)}
);

const toastReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case ADD_TOAST:
            return addToast(state, payload);
        case REMOVE_TOAST:
            return removeToast(state, payload);
        default:
            return state;
    }
};

export default toastReducer;

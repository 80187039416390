import React, {Component} from "react";
import {BTN_CLASSES} from "../../css_constants";
import 'react-image-crop/dist/ReactCrop.css';
import {Col, Row} from "../../utils/Grid/Grid";
import Button from "../../utils/Buttons/Button";
import FormTextarea from "../../utils/Forms/FormTextarea";
import {connect} from "react-redux";
import {updateAccount} from "../../redux/actions/auth.actions";
import Stickers from "../Stickers/Stickers";
import {injectIntl} from "react-intl";

/**
 * Update biography component
 */
class UpdateBio extends Component {
    constructor(props) {
        super(props);

        /** Inital state of the component */
        this.initialState = {
            rows: 10,
            minRows: 11,
            maxRows: 15,
            biography: props.user.biography
        };

        /** State of the component */
        this.state = {
            ...this.initialState
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Handle change of the bio textarea
     * @param e
     */
    handleChange(e) {
        const textareaLineHeight = 24;
        const {minRows, maxRows} = this.state;
        const {value} = e.target;

        const previousRows = e.target.rows;
        e.target.rows = minRows;

        const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            e.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            e.target.rows = maxRows;
            e.target.scrollTop = e.target.scrollHeight;
        }

        this.setState({rows: currentRows < maxRows ? currentRows : maxRows, biography: value});
    };


    /**
     * Handle submitting of the bio form
     * @param e
     */
    handleSubmit(e) {
        e.preventDefault();
        const {updateAccount} = this.props;
        const {biography} = this.state;

        updateAccount({biography});
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {rows, biography} = this.state;
        const {user, intl} = this.props;
        const {subFeedItems} = user;
        const {messages} = intl;
        const update = subFeedItems.find(x => x.type === 'bioUpdate');

        /** return bio form */
        return (
            <Row>
                <Col md={12} className={"bio"}>
                    <FormTextarea
                        name={"biorgaphy"}
                        label={{label: "Account.biography.label"}}
                        rows={rows}
                        value={biography}
                        required={false}
                        onChange={this.handleChange}
                    />

                    {update &&
                    <Stickers stickers={update.stickers} addButton={false}/>
                    }
                </Col>

                <Col md={12} className={"right"}>
                    <div className={BTN_CLASSES.GROUP}>
                        <Button buttonStyle={BTN_CLASSES.SECONDARY}
                                label={messages["form.button.cancel"]}
                                onClick={() => this.setState({...this.initialState})}
                        />
                        <Button buttonStyle={BTN_CLASSES.PRIMARY}
                                label={messages["form.button.save"]}
                                onClick={this.handleSubmit}
                        />
                    </div>
                </Col>
            </Row>
        )
    }
}


export default connect(null, {updateAccount})(injectIntl(UpdateBio));
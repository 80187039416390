import React, {Component} from "react";
import Form from "../../../utils/Forms/Form";
import Button from "../../../utils/Buttons/Button";
import {BTN_CLASSES} from "../../../css_constants";
import {Col, Row} from "../../../utils/Grid/Grid";
import {connect} from "react-redux";
import {loadTags} from "../../../redux/actions/tag.actions";
import {Link} from "react-router-dom";
import {getMedia} from "../../../redux/actions/media.actions";
import Attachments from "../../../utils/Attachments/Attachments";
import EmptyList from "../../EmptyList/EmptyList";
import Load from "../../../utils/Loading/Load";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faGlobeEurope, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import '../Admin.scss';
import DateUtility from "../../../utils/dateUtils";
import {deleteMedia, loadTraining, updateTraining} from "../../../redux/actions/training.actions";
import {loadInstructors, loadStaff} from "../../../redux/actions/user.actions";
import {loadInvitation} from "../../../redux/actions/invitation.actions";
import Invitations from "../Invitations/Invitations";
import UrlModal from "../UrlModal/UrlModal";

class UpdateTraining extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                format: [],
                fields: []
            },
            links: [],
            formValid: false,

            invitations: null,
            loading: false
        };

        this.invitations = React.createRef();
        this.urls = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const {loadTags, loadStaff, loadInstructors, loadTraining, loadInvitation, match} = this.props;
        const {id} = match.params;

        /**
         * Load tags
         */
        loadTags();

        /**
         *  Load coceco's
         */
        loadStaff();

        /**
         * Load instructors
         */
        loadInstructors();

        /**
         * Load original training
         */
        loadTraining(id);

        /**
         * Load Training
         */
        loadInvitation('trainings', id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tags, selectedTraining, staff, instructors} = this.props;
        let {invitations} = this.props;
        const {toMoment, isCurrent, lastDate} = DateUtility;

        document.title = `UniPartners Group | Admin opleidingen | ${selectedTraining ? selectedTraining.name : 'loading...'}`;

        /**
         * Set form
         */
        if (prevState.form.fields.length === 0 && selectedTraining && staff && instructors && (invitations && invitations.length !== 0 && this.state.invitations === null)) {
            const {days} = selectedTraining;
            invitations = invitations && invitations["trainings"] ? invitations["trainings"][selectedTraining.id] : invitations;

            let fields, format;
            if (!days || (days && (days.length === 0 || (days.length !== 0 && isCurrent(lastDate(days)))))) {
                format = [
                    {
                        type: "col",
                        breakpoint: 8,
                        fields: ["name"]
                    }, {
                        type: "col",
                        breakpoint: 4,
                        fields: ["published", "isAddedToCv"]
                    }, {
                        type: "col",
                        breakpoint: 12,
                        fields: ["location", "description"]
                    }, {
                        type: "col",
                        breakpoint: 6,
                        fields: ["date"]
                    }, {
                        type: "col",
                        breakpoint: 6,
                        fields: ["attachments", "instructor", "organizers", "tags", "logo"]
                    }
                ];

                fields = [
                    {
                        name: "name",
                        type: "text",
                        required: true,
                        placeholder: "De naam van de opleiding",
                        label: "Titel",
                        autocomplete: false,
                        description: null,
                        error: "",
                        valid: true,
                        value: selectedTraining.name
                    }, {
                        name: "description",
                        type: "tiny",
                        required: false,
                        placeholder: "De beschrijving van de opleiding",
                        label: "Beschrijving",
                        autocomplete: false,
                        description: (
                            <div style={{display: 'flex', marginTop: 5, marginBottom: 5}}>
                                <FontAwesomeIcon icon={faExclamationTriangle} style={{height: 42.5, width: 'auto', margin: '5px 10px 5px 0', color: '#ef7d00'}} />
                                <div>
                                    <b>OPGELET!</b><br />
                                    De inhoud van deze opleiding wordt via intranet en de nieuwsbrief binnen heel UniPartners gedeeld. Deel hier geen gevoelige informatie.<br />
                                    Gebruik het mail-formulier op het inschrijvingen-overzicht om belangrijke informatie te delen met de genodigden.
                                </div>
                            </div>
                        ),
                        error: "",
                        valid: true,
                        value: selectedTraining.description
                    }, {
                        name: "published",
                        type: "checkbox",
                        required: false,
                        label: "Publiceer de opleiding",
                        error: "",
                        valid: true,
                        value: selectedTraining.publishedAt ? 1 : 0
                    }, {
                        name: "isAddedToCv",
                        type: "checkbox",
                        required: false,
                        label: "Toevoegen aan CV",
                        error: "",
                        valid: true,
                        value: selectedTraining.isAddedToCv ? 1 : 0
                    }, {
                        name: "attachments",
                        type: "dropzone",
                        required: false,
                        label: "",
                        description: null,
                        error: "",
                        valid: true,
                        files: []
                    }, {
                        name: "logo",
                        type: "file",
                        required: false,
                        label: "Logo",
                        description: null,
                        error: "",
                        valid: true,
                    }, {
                        name: "language",
                        type: "radio",
                        value: (selectedTraining.language === "nl" ? "Nederlands" : "Frans"),
                        required: false,
                        label: "De taal van de opleiding",
                        error: "",
                        valid: true,
                        options: [selectedTraining.language === "nl" ? "Nederlands" : "Frans"]
                    }, {
                        name: "tags",
                        type: "creatable",
                        required: false,
                        multiple: true,
                        placeholder: "De tags van de opleiding",
                        label: "Tags",
                        autocomplete: false,
                        description: null,
                        options: tags.map(tag => (tag.tag)),
                        error: "",
                        valid: true,
                        value: selectedTraining.tags
                    }, {
                        name: "location",
                        type: "creatable",
                        required: true,
                        multiple: false,
                        placeholder: "De locatie van de opleiding",
                        label: "Locatie",
                        autocomplete: false,
                        description: null,
                        options: [
                            "Grote vergaderzaal, Gent",
                            "Kleine vergaderzaal, Gent",
                            "Opleidingslokaal PC, Terhagen",
                            "Opleidingslokaal Theater, Terhagen",
                            "Vergaderzaal 1e verdieping, Terhagen",
                            "Vergaderzaal Orangerie, Terhagen"
                        ],
                        error: "",
                        valid: true,
                        value: selectedTraining.location.replace('<br />', ', ')
                    }, {
                        name: "instructor",
                        type: "select",
                        required: false,
                        multiple: false,
                        placeholder: "De lesgever van de opleiding",
                        label: "Lesgever",
                        autocomplete: false,
                        description: null,
                        options: instructors.map(instructor => ({value: instructor.id, label: `${instructor.firstName} ${instructor.lastName}`})),
                        error: "",
                        valid: true,
                        value: selectedTraining.instructor ? {value: selectedTraining.instructor.id, label: `${selectedTraining.instructor.firstName} ${selectedTraining.instructor.lastName}`} : null
                    }, {
                        name: "organizers",
                        type: "select",
                        required: false,
                        multiple: true,
                        placeholder: "De organisatoren van de opleiding",
                        label: "Organisatoren",
                        autocomplete: false,
                        description: null,
                        options: staff.map(coceco => ({value: coceco.id, label: `${coceco.firstName} ${coceco.lastName}`})),
                        error: "",
                        valid: true,
                        value: selectedTraining.organisors.map(x => {
                            return {
                                value: x.id,
                                label: `${x.firstName} ${x.lastName}`
                            }
                        })
                    }, {
                        name: "date",
                        type: "datepicker",
                        required: true,
                        label: "",
                        description: null,
                        value: selectedTraining.days.map(day => {
                            const {startDate, endDate, bread, reason} = day;

                            return {
                                day: toMoment(startDate),
                                startTime: toMoment(startDate),
                                endTime: toMoment(endDate),
                                bread,
                                reason
                            }
                        }),
                        include: ["time", "bread"],
                        error: "",
                        valid: true,
                        disabled: invitations && invitations.filter(x => x.confirmed !== null).length === 0 ? [] : [new Date(), { after: new Date(), before: new Date() }]
                    }, {
                        name: "time",
                        type: "timerange",
                        required: false,
                        label: `Start -en eindtijd voor `,
                        description: "Voor een open einde, maak start- en eindtijd gelijk",
                        sameIsValid: true,
                        valid: true
                    }, {
                        name: "bread",
                        type: "checkbox",
                        required: false,
                        label: "Broodjes",
                        valid: true,
                        include: ["reason"]
                    }, {
                        name: "reason",
                        type: "text",
                        required: false,
                        placeholder: "Waarom geen broodjes",
                        label: "Reden",
                        valid: true,
                        autocomplete: false,
                        description: null
                    }
                ]
            } else {
                format = [
                    {
                        type: "col",
                        breakpoint: 6,
                        fields: ["attachments"]
                    }
                ];

                fields = [
                    {
                        name: "attachments",
                        type: "dropzone",
                        required: false,
                        label: "",
                        description: null,
                        error: "",
                        valid: true,
                        files: []
                    }
                ];
            }

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields,
                        format
                    }
                }
            });
        }

        /**
         * Load media and attachments for training
         */
        if (selectedTraining && selectedTraining.media && selectedTraining.media.length > 0) {
            selectedTraining.media.map(obj => {
                const {getMedia} = this.props;
                return getMedia(obj);
            });
        }


        /** Set invitations */
        if (selectedTraining && invitations && invitations.length !== 0 && this.state.invitations === null) {
            invitations = invitations && invitations["trainings"] ? invitations["trainings"][selectedTraining.id] : invitations;

            if (invitations && invitations.length !== 0) {
                this.setState({
                    invitations: {
                        maxAttendees: selectedTraining.maxAttendees,
                        maxAttendDate: DateUtility.toMoment(selectedTraining.maxAttendDate),
                        users: invitations.map(x => ({value: x.user.id, label: `${x.user.firstName} ${x.user.lastName}`})),
                    }
                });
            }
        }
    }

    handleSubmit() {
        const {updateTraining, match} = this.props;
        const {form, links, invitations} = this.state;
        const {id} = match.params;

        this.setState({loading: true});

        const data = new FormData();
        for(const field of form.fields) {
            if (field.type !== 'dropzone' && field.type !== 'file' && !(field.type === 'creatable' || field.type === 'select') && field.type !== 'datepicker' && field.type !== 'timerange') {
                data.set(field.name, field.value)
            } else if ((field.type === 'creatable' || field.type === 'select') && field.multiple) {
                for (const value of field.value) {
                    data.append(`${field.name}[]`, (value.hasOwnProperty('value') ? value.value : value))
                }
            } else if ((field.type === 'creatable' || field.type === 'select') && !field.multiple) {
                data.append(`${field.name}`, (field.value ? (field.value.hasOwnProperty('value') ? field.value.value : field.value) : field.value))
            } else if( field.type === 'datepicker' ) {
                for (const value of field.value) {
                    const date = new Date(value.day);
                    const start = new Date(value.startTime)
                    const end = new Date(value.endTime)

                    const object = {
                        day: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
                        startTime: `${start.getHours()}:${start.getMinutes()}`,
                        endTime: `${end.getHours()}:${end.getMinutes()}`,
                        bread: value.bread,
                        reason: value.reason
                    };

                    data.append(`${field.name}[]`, JSON.stringify(object));
                }
            } else if( field.type === 'file' && field.hasOwnProperty('files')) {
                for (const file of field.files) {
                    data.append(`${field.name}[]`, file)
                }
            } else if( field.type !== 'timerange' && field.type !== 'file') {
                for (const file of field.files) {
                    data.append(`${field.name}[]`, file)
                }
            }
        }

        if (links.length !== 0) {
            for (let i = 0; i <= links.length - 1; i++) {
                data.append(`links[${i}][name]`, links[i].name)
                data.append(`links[${i}][url]`, links[i].url)
            }
        }

        if (invitations) {
            data.append('invitations[maxAttendDate]', invitations.maxAttendDate);
            data.append('invitations[maxAttendees]', invitations.maxAttendees);

            for (const user of invitations.users) {
                data.append(`invitations[invitees][]`, (user.hasOwnProperty('value') ? user.value : user))
            }
        }

        updateTraining(data, this.props.history, id);
    }

    render() {
        const {loading} = this.state;

        return (
            loading
                ? <Load showPatience/>
                : this.renderFormBody()
        )
    }

    renderFormBody() {
        const {form, links, formValid, invitations} = this.state;
        const {selectedTraining, media} = this.props;
        const {name, days} = selectedTraining || [];
        const {isCurrent, lastDate} = DateUtility;

        return (
            <Row>
                {!(!days || (days && (days.length === 0 || (days.length !== 0 && isCurrent(lastDate(days)))))) &&
                    <Col md={12}>
                        <h1>{name}</h1>
                    </Col>
                }

                <Form form={form}
                      formValid={(bool) => this.setState({formValid: bool})}
                      updateFields={(form) => this.setState({form: form})}
                />

                <Col xl={4} lg={6} md={7}>
                    {links.length !== 0 &&
                    <div className="uploaded-files">
                        {links.map((link, index) => (
                            <div className="file" key={index}>
                                <div>
                                    <FontAwesomeIcon icon={faGlobeEurope} fixedWidth/>
                                    {link.name && link.name !== '' ? link.name : link.url}
                                </div>
                                <Button buttonStyle={BTN_CLASSES.LINK}
                                        buttonSize={BTN_CLASSES.SMALL}
                                        className={BTN_CLASSES.DANGER}
                                        onClick={() => this.setState(prevState => ({links: prevState.links.filter(x => x.name !== link.name && x.url !== link.url)}))}
                                >
                                    <FontAwesomeIcon icon={faTimes}/>
                                </Button>
                            </div>

                        ))}

                        <div className="delete">
                            <Button buttonStyle={BTN_CLASSES.LINK}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    className={BTN_CLASSES.DANGER}
                                    onClick={() => this.setState({links: []})}
                            >
                                <FontAwesomeIcon icon={faTrash}/> Verwijder alles
                            </Button>
                        </div>
                    </div>
                    }

                    <div className={`${BTN_CLASSES.GROUP} flex`}>
                        <Button label={"URL's als bijlage toevoegen"}
                                buttonStyle={BTN_CLASSES.SECONDARY}
                                buttonSize={BTN_CLASSES.SMALL}
                                onClick={() => this.urls.current.handleShow()}
                                styles={{width: '100%'}}
                        />

                        {(!days || (days && (days.length === 0 || (days.length !== 0 && isCurrent(lastDate(days)))))) &&
                        <Button label={"Deelnemers uitnodigen"}
                                buttonStyle={BTN_CLASSES.SECONDARY}
                                buttonSize={BTN_CLASSES.SMALL}
                                className={invitations ? BTN_CLASSES.SUCCESS : ''}
                                onClick={() => this.invitations.current.handleShow()}
                        />
                        }

                        {selectedTraining &&
                        <Link to={`/opleidingen/${selectedTraining.slug}/inschrijvingen`}
                              className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`}
                        >Inschrijvingen</Link>
                        }
                    </div>
                </Col>

                <Col xl={3} lg={1} mdHide smHide xsHide/>

                {selectedTraining && this.renderAttachments()}

                <Col md={12} style={{margin: ".5rem 0", textAlign: "right"}}>
                    <div className={BTN_CLASSES.GROUP}>
                        <Link to={`/opleidingen/${selectedTraining ? selectedTraining.slug : ''}`}
                              className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.SECONDARY}`}
                        >
                            Annuleren
                        </Link>

                        <Button buttonStyle={BTN_CLASSES.PRIMARY}
                                disabled={!formValid}
                                onClick={this.handleSubmit}
                        >
                            Bewaar
                        </Button>
                    </div>
                </Col>

                {selectedTraining && selectedTraining.media.length !== 0 && media && media.length !== 0 &&
                this.renderImages()
                }

                <Invitations ref={this.invitations} setInvitations={(invitations) => this.setState({invitations})} invitations={invitations}/>
                <UrlModal ref={this.urls} handleSave={(obj) => this.setState(prevState => ({links: [...prevState.links, obj]}))}/>
            </Row>
        )
    }

    renderAttachments() {
        const {selectedTraining} = this.props;
        const {attachments, links} = selectedTraining;

        return (
            <Col md={6}>
                {(attachments && attachments.length > 0) || (links && links.length > 0)
                    ? <Attachments attachments={attachments} links={links} removeAttachment={(this.props.deleteMedia)}/>
                    : <EmptyList id={"Attachments.empty"}/>
                }

            </Col>
        )
    }

    renderImages() {
        const {media, selectedTraining} = this.props;

        return (
            <Col md={12}>
                <div className="images">
                    {selectedTraining.media.map((obj, i) => {
                        const image = media.find(x => x.id === obj.id);
                        if (image) {
                            return (
                                <div className={"image"} key={i}>
                                    {image.loading
                                        ? <Load/>
                                        : <img src={image.file} alt={image.description} onError={this.addDefaultSrc}/>
                                    }
                                    <Button buttonStyle={BTN_CLASSES.DANGER} onClick={() => this.props.deleteMedia(image.id, 'images')}><FontAwesomeIcon icon={faTrash}/></Button>
                                </div>
                            )
                        }
                        return null;
                    })}
                </div>

                <div className={"description"}>Afbeeldingen worden gedeeld in alle talen van deze opleiding</div>
            </Col>
        )
    }
}

const mapStateToProps = ({tagReducer, userReducer, trainingReducer, mediaReducer, invitationReducer}) => {
    const {instructors, staff} = userReducer;
    const {tags} = tagReducer;
    const {selectedTraining} = trainingReducer;
    const {media} = mediaReducer;
    const {invitations} = invitationReducer;
    return {tags, selectedTraining, media, instructors, staff, invitations};
};

const mapDispatchToProps = {
    loadTraining, updateTraining, loadTags, loadStaff, loadInstructors, getMedia, deleteMedia, loadInvitation
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTraining);
import React from 'react';
import './Claims.scss';
import {BTN_CLASSES} from "../../../../css_constants";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";

/**
 * Claims component
 * @param props
 * @returns {*}
 * @constructor
 */
const Claims = ({intl, permissions, car}) => {
    const {messages} = intl;

    /** Returns claim buttons */
    return (
        (permissions.includes("claims_illnesses_add") || permissions.includes("claims_damages_add")) &&
        <div className={`${BTN_CLASSES.GROUP} claims flex`}>
            {permissions.includes("claims_illnesses_add") &&
            <Link to={messages[ "routes.illness" ]}
                  className={`btn ${BTN_CLASSES.PRIMARY}`}>{messages[ "menu.illness" ]}</Link>
            }
            {car && permissions.includes("claims_damages_add") &&
            <Link to={messages[ "routes.damage" ]}
                  className={`btn ${BTN_CLASSES.PRIMARY}`}>{messages[ "menu.damage" ]}</Link>
            }
        </div>
    );
};


export default injectIntl(Claims);
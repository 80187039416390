import React, {Component} from "react";
import {confirmRequest, loadFormResults, sendCustomMail} from "../../../redux/actions/subscriptionResult.actions";
import {connect} from "react-redux";
import Load from "../../../utils/Loading/Load";
import EmptyList from "../../EmptyList/EmptyList";
import DocumentDetail from "../../Documents/DocumentDetail";
import {loadEvent} from "../../../redux/actions/event.actions";
import {loadArticle} from "../../../redux/actions/news.actions";
import {loadTraining} from "../../../redux/actions/training.actions";
import FormRadio from "../../../utils/Forms/FormRadio";
import './SubmissionResults.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEnvelope, faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import {loadForm, sendForm} from "../../../redux/actions/form.actions";
import {confirmInvitation, loadInvitation} from "../../../redux/actions/invitation.actions";
import Form from "../../../utils/Forms/Form";
import Modal from "../../../utils/Modal/Modal";
import {BTN_CLASSES} from "../../../css_constants";
import Button from "../../../utils/Buttons/Button";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {Col, Row} from "../../../utils/Grid/Grid";
import DateUtility from "../../../utils/dateUtils";
import FormTextarea from "../../../utils/Forms/FormTextarea";
import FormInput from "../../../utils/Forms/FormInput";
import AttendingPdf from "../Pdf/AttendingPdf";
import ResultsPdf from "../Pdf/ResultsPdf";
import {loadToppings} from "../../../redux/actions/topping.actions";
import FormCheckbox from "../../../utils/Forms/FormCheckbox";
import SelectInput from "../../../utils/Forms/SelectInput";
import {breadSizeHelper} from "../../../utils/helperFunctions";


class SubmissionResults extends Component {
    constructor(props) {
        super(props);

        this.mailSubject = '';
        this.state = {
            object: null,
            objectType: null,
            filter: 'alles',

            showForm: false,
            form: null,
            formValid: false,
            selectedUser: null,

            order: null,

            mailModal: false,
            mailBody: '',
            mailSubject: '',
            mailTo: null,

            showReceivers: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMailSubmit = this.handleMailSubmit.bind(this);
        this.renderTrainingResultDetail = this.renderTrainingResultDetail.bind(this);
        this.handleOrderCheckbox = this.handleOrderCheckbox.bind(this);
        this.handleOrderSelect = this.handleOrderSelect.bind(this);
        this.handleOrderTextarea = this.handleOrderTextarea.bind(this);
    }

    componentDidMount() {
        const {loadToppings, loadEvent, loadTraining, loadArticle, match} = this.props;
        const {slug, type} = match.params;

        switch (type) {
            case 'evenementen':
                loadEvent(slug);
                break;
            case 'opleidingen':
                loadTraining(slug);
                loadToppings();
                break;
            default:
                loadArticle(slug);
                break;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {match, selectedEvent, selectedTraining, selectedArticle} = this.props;
        const {type} = match.params;
        let object, objectType, order;

        switch (type) {
            case 'evenementen':
                object = selectedEvent;
                objectType = 'events'
                this.mailSubject = `Extra informatie: evenement ${selectedEvent ? selectedEvent.name : ''}`;
                document.title = `UniPartners Group | Admin evenementen | Inschrijvingen ${selectedEvent ? selectedEvent.name : 'loading...'}`;
                order = null;
                break;
            case 'opleidingen':
                object = selectedTraining;
                objectType = 'trainings';
                this.mailSubject = `Extra informatie: opleiding ${selectedTraining ? selectedTraining.name : ''}`;
                document.title = `UniPartners Group | Admin opleidingen | Inschrijvingen ${selectedTraining ? selectedTraining.name : 'loading...'}`;
                order = selectedTraining ? {
                    days: selectedTraining.days.map(x => {
                        return {
                            date: x.startDate,
                            acceptOrders: !!x.bread,
                            reason: x.reason,
                            order: true,
                            size: null,
                            sizeTouched: false,
                            sizeValid: !x.bread,
                            topping: null,
                            toppingTouched: false,
                            toppingValid: !x.bread,
                            extra: false,
                        }
                    }),
                    updated: false,
                    remark: null,
                } : null
                break;
            default:
                object = selectedArticle;
                objectType = 'news';
                this.mailSubject = '';
                document.title = `UniPartners Group | Admin nieuws | Resultaten ${selectedTraining ? selectedTraining.title : 'loading...'}`;
                order = null;
                break;
        }

        if (this.state.object !== object)
            this.setState({object, objectType, order, mailSubject: this.mailSubject}, this.loadResults);
    }

    loadResults() {
        const {loadFormResults, loadForm, loadInvitation} = this.props;
        const {objectType, object} = this.state;

        loadFormResults(objectType, object.id);
        loadForm(objectType, object.id);

        if (objectType !== 'news')
            loadInvitation(objectType, object.id)
    }

    selectUser(user) {
        const {object, objectType} = this.state;
        const {results} = this.props;

        const userResult = results.find(x => x.user.id === user.id);


        if (objectType === 'trainings') {
            const {order} = this.state;
            const {toppings} = this.props;

            let newOrder = order;
            if (userResult.submissions.length !== 0) {
                newOrder = {
                    days: order.days.map(x => {
                        const day = userResult.submissions.order.find(y => y.date === x.date);
                        const top = day ? toppings.find(y => y.id === day.topping) : null;

                        return {
                            ...x,
                            order: !!(day && top),
                            size: day && top ? {value: day.size, label: breadSizeHelper(day.size)} : null,
                            sizeValid: day && top,
                            topping: day && top ? {value: day.topping, label: top.topping, extra: top.hasOptions, desc: top.description} : null,
                            toppingValid: day && top,
                            extra: day && top ? day.size : false,
                        }
                    }),
                    updated: true,
                    remark: userResult.remark
                }
            }

            this.setState({
                showForm: true,
                selectedUser: user,
                order: newOrder,
                formValid: object.days.filter(x => x.bread === 0).length !== 0
            })
        } else {
            const {forms} = this.props;
            let fields = null;

            let form = object && forms.length !== 0 ? forms[objectType][object.id] : null;
            if (form.length !== 0) {
                fields = form.fields.map(x => {
                    const result = userResult.submissions.find(y => y.field.name === x.name);

                    return {
                        ...x,
                        value: result ? result.value : null
                    }
                });
            }

            this.setState({
                showForm: true,
                selectedUser: user,
                form: {
                    ...form,
                    fields
                }
            });
        }
    }

    handleSubmit() {
        const {sendForm} = this.props;
        const {object, objectType} = this.state;
        let data;

        if (objectType === 'trainings') {
            data = this.handleSubmitTrainingForm();
        } else {
            data = this.handleSubmitForm();
        }

        data.set("type", objectType);
        data.set("id", object.id);

        sendForm(objectType, object.id, data, true);
        this.setState({
            showForm: false,
            form: null,
            formValid: false,
            selectedUser: null,
            order: objectType === 'trainings' ? {
                days: object.days.map(x => {
                    return {
                        date: x.startDate,
                        acceptOrders: !!x.bread,
                        reason: x.reason,
                        order: true,
                        size: null,
                        sizeTouched: false,
                        sizeValid: !x.bread,
                        topping: null,
                        toppingTouched: false,
                        toppingValid: !x.bread,
                        extra: false,
                    }
                }),
                updated: false,
                remark: null
            } : null
        });
    }

    handleSubmitTrainingForm() {
        const {order, selectedUser, object} = this.state;

        const data = new FormData();
        data.set("userId", selectedUser.id);
        data.set("trainingId", object.id);
        data.set('remark', order.remark);

        for (const day of order.days.filter(x => x.acceptOrders)) {
            data.append(
                'results[]',
                day.order
                    ? JSON.stringify({date: day.date, size: day.size.value, topping: day.topping.value, extra: day.topping.extra ? day.extra : false})
                    : JSON.stringify({date: day.date, size: null, topping: null, extra: null})
            )
        }

        return data;
    }

    handleSubmitForm() {
        const {form, selectedUser} = this.state;

        const data = new FormData();
        data.set("userId", selectedUser.id);
        data.set("formId", form.id);

        for (const field of form.fields) {
            if (field.type !== 'col' && field.type !== 'group' && field.type !== 'tab') {
                if (field.type === 'select' && field.multiple) {
                    for (const value of field.value) {
                        data.append(`results[${field.name}][]`, (value.hasOwnProperty('value') ? value.value : value));
                    }
                } else if (field.type === 'select' && !field.multiple) {
                    data.append(`results[${field.name}]`, (field.value.hasOwnProperty('value') ? field.value.value : field.value));
                } else {
                    data.append(`results[${field.name}]`, field.value);
                }
            }
        }

        return data;
    }

    handleMailSubmit() {
        const {sendCustomMail, user} = this.props;
        const {mailBody, mailSubject, mailTo, object, objectType} = this.state;

        sendCustomMail({
            type: objectType,
            id: object.id,
            userId: user.id,
            mail: {
                subject: mailSubject,
                body: mailBody,
                to: mailTo
            }
        });

        this.setState({
            mailModal: false,
            mailBody: '',
            mailTo: null,
            mailSubject: this.mailSubject
        });
    }

    declineInvitation() {
        this.sendConfirmation(false);
    }

    acceptInvitation() {
        const {objectType, form} = this.state;

        if ((form && form.fields && form.fields.length !== 0) || objectType === 'trainings') {
            this.setState(prevState => ({selectedUser: {...prevState.selectedUser, confirmed: 1}}))
        } else {
            this.sendConfirmation(true);
        }
    }

    sendConfirmation(accept) {
        const {confirmInvitation} = this.props;
        const {selectedUser, object, objectType} = this.state;

        confirmInvitation({
            type: objectType,
            id: object.id,
            userId: selectedUser.id,
            confirmed: accept
        }, true);

        this.setState({
            showForm: false,
            form: null,
            formValid: false,
            selectedUser: null
        });
    }

    render() {
        const {resultsLoading, results, object, objectType} = this.props;

        return (
            resultsLoading && !object && !objectType
                ? <Load/>
                : (results.length === 0
                ? this.renderEmptyResults()
                : this.renderFormResults())
        )

    }

    renderEmptyResults() {
        const {objectType} = this.state;

        return (
            <div className="submission-result">
                <h1>Inschrijvingen</h1>

                <Row>
                    <Col md={6}/>
                    <Col md={6}>
                        <div className={"right"} style={{marginTop: 15}}>
                            <Link to={`/${objectType === 'events' ? 'evenementen' : (objectType === 'trainings' ? 'opleidingen' : 'nieuws')}/${this.props.match.params.slug}`}><FontAwesomeIcon icon={faLongArrowAltLeft}/> Terug
                                naar {objectType === 'events' ? 'evenement' : 'opleiding'}</Link>
                        </div>
                    </Col>
                </Row>

                <EmptyList id={"results.empty"}/>
            </div>
        );
    }

    renderFormResults() {
        const {filter, object, objectType} = this.state;
        const {results, confirmRequest, user, toppings} = this.props;


        const requests = results.filter(x => x.user.request === 1);
        let filteredResults = results.filter(x => x.user.request !== 1);

        const confirmed = filteredResults.filter(x => x.user.confirmed === 1)
        const notConfirmed = filteredResults.filter(x => x.user.confirmed === 0)
        const undecided = filteredResults.filter(x => x.user.confirmed === null)

        if (object && object.hasInvitations && filter !== 'alles') {
            if (filter === 'aanwezig') filteredResults = confirmed;
            if (filter === 'niet aanwezig') filteredResults = notConfirmed;
            if (filter === 'onbevestigd') filteredResults = undecided;
        }

        return (
            <div className="submission-result">
                <h1>Inschrijvingen</h1>

                <div style={{marginBottom: 50}}>
                    {requests.map((x, i) => {
                        let status = (
                            <div className="pills">
                                <button className="pill success" onClick={() => confirmRequest(objectType, object.id, x.user.id, true)}>Bevestigen</button>
                                <button className="pill danger" onClick={() => confirmRequest(objectType, object.id, x.user.id, false)}>Verwerpen</button>
                            </div>
                        );
                        return this.renderUserResult(x.user, null, status, i);
                    })}
                </div>

                {object && object.hasInvitations &&
                <div className="selection">
                    <FormRadio name=""
                               label=""
                               options={[{value: 'alles', label: 'alles'}, {label: `aanwezig (${confirmed.length})`, value: 'aanwezig'}, {label: `niet aanwezig (${notConfirmed.length})`, value: 'niet aanwezig'}, {label: `onbevestigd (${undecided.length})`, value: 'onbevestigd'}]}
                               value={filter}
                               onChange={(e) => this.setState({filter: e.target.value})}
                    />
                </div>
                }

                <Row>
                    <Col md={6}>
                        <div className={`${BTN_CLASSES.GROUP}`}>
                            {object && object.hasInvitations && <AttendingPdf user={user} results={results} objectType={objectType} object={object}/>}
                            {<ResultsPdf user={user} results={results} filter={object && object.hasInvitations} toppings={toppings} objectType={objectType} object={object}/>}
                            {object && object.hasInvitations &&
                            <Button buttonStyle={BTN_CLASSES.PRIMARY} buttonSize={BTN_CLASSES.SMALL} title="Stuur instructiemail" onClick={() => this.setState({showReceivers: true})}><FontAwesomeIcon icon={faEnvelope} fixedWidth/></Button>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={"right"} style={{marginTop: 15}}>
                            <Link to={`/${objectType === 'events' ? 'evenementen' : (objectType === 'trainings' ? 'opleidingen' : 'nieuws')}/${this.props.match.params.slug}`}><FontAwesomeIcon icon={faLongArrowAltLeft}/> Terug
                                naar {objectType === 'events' ? 'evenement' : (objectType === 'trainings' ? 'opleiding' : 'artikel')}</Link>
                        </div>
                    </Col>
                </Row>

                {filteredResults.length !== 0
                    ? filteredResults.map((x, i) => {
                        let status = '';
                        if (x.user.confirmed !== undefined) {
                            status = <div className="pills">
                                <div className="pill warning">onbevestigd</div>
                                <button className="pill" onClick={() => this.selectUser(x.user)}><FontAwesomeIcon icon={faEdit} fixedWidth/></button>
                            </div>;
                            if (x.user.confirmed === 1) status = <div className="pills">
                                <div className="pill success">aanwezig</div>
                                <button className="pill" onClick={() => this.selectUser(x.user)}><FontAwesomeIcon icon={faEdit} fixedWidth/></button>
                            </div>;
                            if (x.user.confirmed === 0) status = <div className="pills">
                                <div className="pill danger">niet aanwezig</div>
                                <button className="pill" onClick={() => this.selectUser(x.user)}><FontAwesomeIcon icon={faEdit} fixedWidth/></button>
                            </div>;
                        }

                        return this.renderUserResult(x.user, x.submissions, status, i);
                    })
                    : <EmptyList id={"results.empty"}/>
                }


                {object && object.hasInvitations && this.renderFormModal()}
                {object && object.hasInvitations && this.renderMailReceivers()}
                {object && object.hasInvitations && this.renderMail()}
            </div>
        );
    }

    renderUserResult(user, submissions, pills, i) {
        const {objectType, object} = this.state;

        return <DocumentDetail title={`${user.lastName} ${user.firstName}`}
                               children={
                                   object && submissions &&
                                   (submissions.length !== 0
                                       ? (
                                           objectType === 'trainings'
                                               ? this.renderTrainingResultDetail(submissions)
                                               : this.renderEventResultDetail(submissions)
                                       )
                                       : <div>Geen resultaten</div>)
                               }
                               pill={pills}
                               key={i}
        />
    }

    renderTrainingResultDetail(submissions) {
        const {object} = this.state;
        const {toppings} = this.props;

        return (
            <>
                {object.days.map((objectDay, i) => {
                    if (!objectDay.bread) return null;
                    let topping, size;
                    const submission = submissions.order.find(x => x.date === objectDay.startDate);
                    if (submission) {
                        topping = toppings.find(x => x.id === submission.topping);

                        switch (submission.size) {
                            case 'large_white': size = 'Groot wit broodje'; break;
                            case 'large_dark': size = 'Groot bruin broodje'; break;
                            case 'small_white': size = 'Klein wit broodje'; break;
                            case 'small_dark': default: size = 'Klein bruin broodje'; break;
                        }
                    }

                    return (
                        <div key={i}>
                            <b>{DateUtility.toLongDate(objectDay.date)} : </b>
                            {`${topping ? size : 'Geen broodje'} ${topping && topping.topping ? ` - ${topping.topping}` : ''} ${topping && topping.hasOptions && submission.extra ? ' - smos' : ''}`}
                        </div>
                    )
                })}

                <div>
                    <b>Opmerking : </b>
                    {submissions.remark && submissions.remark !== '' && submissions.remark !== 'null' ? submissions.remark : 'Geen opmerkingen'}
                </div>
            </>
        );
    }

    renderEventResultDetail(submissions) {
        return submissions.map((y, a) => {
            return (
                <div key={a}>
                    <b>{y.field.label} : </b>
                    {y.value
                        ? (y.field.type === 'checkbox'
                            ? 'ja'
                            : (y.field.multiple
                                ? y.value.map((z, i) => {
                                    return <span key={i}>{z}{i + 1 !== y.value.length ? ', ' : ''}</span>
                                })
                                : <span dangerouslySetInnerHTML={{__html: y.value.replaceAll("\n", "<br/>")}}/>))
                        : (y.field.type === 'checkbox' ? 'nee' : 'niet opgegeven')}<br/>
                </div>
            )
        })
    }

    renderFormModal() {
        const {objectType, form, selectedUser} = this.state;

        let success = BTN_CLASSES.SUCCESS;
        let error = BTN_CLASSES.DANGER;

        if (selectedUser && selectedUser.confirmed === 0) success = BTN_CLASSES.SECONDARY;
        if (selectedUser && selectedUser.confirmed === 1) error = BTN_CLASSES.SECONDARY;

        return (
            form && objectType === 'events'
                ? this.renderForm(success, error)
                : this.renderTrainingForm(success, error)
        )
    }

    handleOrderCheckbox(e, date, name) {
        this.setState(prevState => ({
            order: {
                ...prevState.order,
                days: prevState.order.days.map(x => {
                    if (x.date !== date) return x;

                    return {
                        ...x,
                        [name]: !x[name],
                        sizeValid: x.size !== null && x.size !== undefined ? true : x.order,
                        toppingValid: x.topping !== null && x.topping !== undefined ? true : x.order
                    }
                })
            }
        }), this.checkValid);
    }

    handleOrderSelect(selectedOption, date, name) {
        this.setState(prevState => ({
            order: {
                ...prevState.order,
                days: prevState.order.days.map(x => {
                    if (x.date !== date) return x;

                    return {
                        ...x,
                        [name]: selectedOption,
                        [`${name}Valid`]: selectedOption !== null && selectedOption !== undefined,
                        extra: selectedOption && selectedOption.extra ? x.extra : null
                    }
                })
            }
        }), this.checkValid);
    }

    handleOrderTextarea(e) {
        const {value, name} = e.target;

        this.setState(prevState => ({
            order: {
                ...prevState.order,
                [name]: value
            }
        }))
    }

    checkValid() {
        const invalid = this.state.order.days.filter(x => x.sizeValid === false || x.toppingValid === false);
        this.setState({formValid: invalid.length === 0})
    }

    handleOrderTouched(date, name) {
        this.setState(prevState => ({
            order: {
                ...prevState.order,
                days: prevState.order.days.map(x => {
                    if (x.date !== date) return x;

                    return {
                        ...x,
                        [name]: true
                    }
                })
            }
        }));
    }

    renderTrainingForm(success, error) {
        const {order, showForm, formValid, selectedUser} = this.state;
        const {toppings} = this.props;

        return (
            <Modal show={showForm}
                   close={() => this.setState({showForm: false, selectedUser: null, order: null})}
                   title={selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : ''}
                   large={true}
                   actions={[{
                       label: "Verstuur formulier",
                       action: this.handleSubmit,
                       disabled: formValid
                   }]}
            >
                <div className={`${BTN_CLASSES.GROUP} flex`}>
                    <Button buttonStyle={success}
                            buttonSize={BTN_CLASSES.SMALL}
                            onClick={() => this.acceptInvitation()}
                    >
                        <FormattedMessage id={"subscription.buttons.confirm"}/>
                    </Button>

                    <Button buttonStyle={error}
                            buttonSize={BTN_CLASSES.SMALL}
                            onClick={() => this.declineInvitation()}
                    >
                        <FormattedMessage id={"subscription.buttons.decline"}/>
                    </Button>
                </div>

                {selectedUser && selectedUser.confirmed === 1 &&
                <Row>
                    {order &&
                    <>
                        {order.days.map((day, i) => {
                            if (day.acceptOrders) {
                                return (
                                    <Col lg={3} md={4} sm={6} key={i}>
                                        <p><b>{DateUtility.toLongDate(day.date)}</b></p>
                                        <FormCheckbox name={`order_${i}`}
                                                      checked={day.order}
                                                      label="Bestel broodje"
                                                      onChange={(e) => this.handleOrderCheckbox(e, day.date, 'order')}
                                        />
                                        {day.order &&
                                        <>
                                            <SelectInput name={`size_${i}`}
                                                         options={[
                                                             {value: "small_white", label: "Klein wit broodje"},
                                                             {value: "large_white", label: "Groot wit broodje"},
                                                             {value: "small_dark", label: "Klein bruin broodje"},
                                                             {value: "large_dark", label: "Groot bruin broodje"}
                                                         ]}
                                                         onChange={(e) => this.handleOrderSelect(e, day.date, 'size')}
                                                         value={day.size}
                                                         label={"Broodje"}
                                                         placeholder={"Selecteer je broodje"}
                                                         required={true}
                                                         isMulti={false}
                                                         error={"Broodje is een verplicht veld"}
                                                         valid={day.sizeTouched ? day.sizeValid : true}
                                                         onBlur={() => this.handleOrderTouched(day.date, 'sizeTouched')}
                                            />
                                            <SelectInput name={`topping_${i}`}
                                                         options={toppings.map(x => {
                                                             return {value: x.id, label: x.topping, extra: x.hasOptions, desc: x.description}
                                                         })}
                                                         onChange={(e) => this.handleOrderSelect(e, day.date, 'topping')}
                                                         value={day.topping}
                                                         label={"Beleg"}
                                                         placeholder={"Selecteer je beleg"}
                                                         description={day.topping ? day.topping.desc : null}
                                                         required={true}
                                                         isMulti={false}
                                                         error={"Beleg is een verplicht veld"}
                                                         valid={day.toppingTouched ? day.toppingValid : true}
                                                         onBlur={() => this.handleOrderTouched(day.date, 'toppingTouched')}
                                            />

                                            {day.topping && day.topping.extra &&
                                            <FormCheckbox name={`extra_${i}`}
                                                          value={1}
                                                          checked={day.extra}
                                                          label="Smos"
                                                          onChange={(e) => this.handleOrderCheckbox(e, day.date, 'extra')}
                                            />}
                                        </>}
                                    </Col>
                                )
                            } else {
                                return (
                                    <Col lg={3} md={4} sm={6} key={i}>
                                        <p><b>{DateUtility.toLongDate(day.date)}</b></p>
                                        <p>{day.reason}</p>
                                    </Col>
                                )
                            }
                        })}

                        <Col md={12}>
                            <FormTextarea name={"remark"}
                                          onChange={this.handleOrderTextarea}
                                          value={order.remark}
                                          label={"Opmerking"}
                                          placeholder={"Geef hier je speciale wensen of opmerkingen door"}
                                          required={false}
                                          error={""}
                                          valid={true}
                                          rows={5}
                            />
                        </Col>
                    </>
                    }

                </Row>
                }
            </Modal>
            );
    }

    renderForm(success, error) {
        const {form, showForm, formValid, selectedUser} = this.state;

        return (
            <Modal show={showForm}
                   close={() => this.setState({showForm: false})}
                   title={form.formName ?? 'temp'}
                   medium={form.formSize === "medium"}
                   large={form.formSize === "large"}
                   actions={selectedUser && selectedUser.confirmed === 1 ? [{
                       label: "Verstuur formulier",
                       action: this.handleSubmit,
                       disabled: formValid
                   }] : []}
            >
                <div className={`${BTN_CLASSES.GROUP} flex`}>
                    <Button buttonStyle={success}
                            buttonSize={BTN_CLASSES.SMALL}
                            onClick={() => this.acceptInvitation()}
                    >
                        <FormattedMessage id={"subscription.buttons.confirm"}/>
                    </Button>

                    <Button buttonStyle={error}
                            buttonSize={BTN_CLASSES.SMALL}
                            onClick={() => this.declineInvitation()}
                    >
                        <FormattedMessage id={"subscription.buttons.decline"}/>
                    </Button>
                </div>

                {selectedUser && selectedUser.confirmed === 1 &&
                form && form.format && form.format.length !== 0 &&
                (form.format[0].type === 'col'
                        ? <Row><Form form={form} formValid={(bool) => this.setState({formValid: bool})} updateFields={(form) => this.setState({form})}/></Row>
                        : <Form form={form} formValid={(bool) => this.setState({formValid: bool})} updateFields={(form) => this.setState({form})}/>
                )
                }
            </Modal>
        );
    }

    renderMailReceivers() {
        const {showReceivers} = this.state;

        return (
            <Modal show={showReceivers}
                   close={() => this.setState({showReceivers: false})}
                   title="Formuliertype kiezen"
                   medium={true}
            >
                <Row className="add_field">
                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.setState({showReceivers: false, mailModal: true, mailTo: 'present'})}
                                styles={{position: 'relative', paddingTop: 20}}
                        >
                            <div className="pills">
                                <div className={"pill success"}/>
                            </div>
                            Aanwezig
                        </Button>
                    </div>

                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.setState({showReceivers: false, mailModal: true, mailTo: 'present-unconfirmed'})}
                                styles={{position: 'relative', paddingTop: 20}}
                        >
                            <div className="pills">
                                <div className={"pill success"}/>
                                <div className={"pill warning"}/>
                            </div>
                            Aanwezig en onbevestigd
                        </Button>
                    </div>

                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.setState({showReceivers: false, mailModal: true, mailTo: 'unconfirmed'})}
                                styles={{position: 'relative', paddingTop: 20}}
                        >
                            <div className="pills">
                                <div className={"pill warning"}/>
                            </div>
                            Onbevestigd
                        </Button>
                    </div>

                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.setState({showReceivers: false, mailModal: true, mailTo: 'all'})}
                                styles={{position: 'relative', paddingTop: 20}}
                        >
                            <div className="pills">
                                <div className={"pill success"}/>
                                <div className={"pill danger"}/>
                                <div className={"pill warning"}/>
                            </div>
                            Iedereen
                        </Button>
                    </div>
                </Row>
            </Modal>
        )
    }

    renderMail() {
        let {user} = this.props;
        const {mailModal, mailBody, mailSubject, mailTo, objectType} = this.state;
        const {currentDate, toLongDate, toTime} = DateUtility;
        const currentTimestamp = currentDate() / 1000;

        /** Returns the mail modal */
        return (
            <Modal show={mailModal}
                   close={() => this.setState({mailModal: false, mailBody: '', mailTo: null, mailSubject: this.mailSubject})}
                   large={true}
                   title={"Stuur een e-mail aan iedereen " + (mailTo === 'present' ? 'aanwezig' : (mailTo === 'present-unconfirmed' ? 'aanwezig en onbevestigd' : (mailTo === 'unconfirmed' ? 'onbevestigd' : '')))}
                   actions={[{
                       label: "Verstuur je bericht",
                       action: this.handleMailSubmit,
                       disabled: mailBody ? mailBody.length !== 0 : false
                   }]}
            >
                <Row className={"send-mail"}>
                    <Col md={8}>
                        <img src={"https://ui-avatars.com/api/?name=" + user.firstName + " " + user.lastName + "&background=9D9694&color=ffffff"} alt={user.firstName + " " + user.lastName} className="avatar"/>
                        <div className="sender">
                            <b>{user.firstName} {user.lastName}</b><br/>
                            {user.email}
                        </div>
                    </Col>

                    <Col md={4} className={"right"}>
                        {toLongDate(currentTimestamp)}, <b>{toTime(currentTimestamp)}</b>
                    </Col>

                    <Col md={12}>
                        <hr/>

                        <FormInput type="text" name="mailSubject" value={mailSubject} onChange={(e) => this.setState({mailSubject: e.target.value})} placeholder={`Onderwerp: indien leeg zal '${this.mailSubject}' gebruikt worden`}/>

                        <FormTextarea name={"mailBody"}
                                      value={mailBody}
                                      onChange={(e) => this.setState({mailBody: e.target.value})}
                                      autoFocus={true}
                                      placeholder={`Schrijf hier je bericht\nEen persoonlijk aanhef en de afsluiting worden automatisch gegenereerd\n\nGebruik deze functie niet om ${objectType === 'events' ? 'het evenement' : 'de opleiding'} te annuleren.\nOm ${objectType === 'events' ? 'het evenement' : 'de opleiding'} te annuleren, maak de publicatie ongedaan of klik op verwijderen.`}
                        />

                        <p style={{color: '#9D9694'}}>
                            <FormattedMessage id={"subscription.mail.greeting"}/><br/>
                            {user.firstName} {user.lastName}<br/>
                        </p>
                    </Col>
                </Row>
            </Modal>
        )
    }
}

const mapStateToProps = ({authReducer, toppingReducer, subscriptionResultReducer, eventReducer, trainingReducer, newsReducer, formReducer, invitationReducer}) => {
    const {user} = authReducer;
    const {toppings} = toppingReducer;
    const {selectedEvent} = eventReducer;
    const {selectedTraining} = trainingReducer;
    const {selectedArticle} = newsReducer;
    const {forms} = formReducer;
    const {invitations} = invitationReducer;
    const {results, resultsLoading, resultsLoaded} = subscriptionResultReducer;
    return {user, toppings, results, resultsLoading, resultsLoaded, selectedEvent, selectedTraining, selectedArticle, forms, invitations};
};


const mapDispatchToProps = {
    loadFormResults, loadEvent, loadTraining, loadArticle, confirmRequest, loadForm, loadInvitation, sendForm, confirmInvitation, sendCustomMail, loadToppings
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionResults);

import React, {Component} from "react";
import userImage from "../../../assets/images/user.png";
import EmptyList from "../../EmptyList/EmptyList";
import DateUtility from "../../../utils/dateUtils";
import {connect} from "react-redux";
import {BTN_CLASSES, BTN_TYPES} from "../../../css_constants";
import Button from "../../../utils/Buttons/Button";
import { Link } from "react-router-dom";
import { truncate } from "../../../utils/helperFunctions";

class NotificationDropdown extends Component {
    constructor(props) {
        super(props)

        this.state = {
            listOpen: false,
            viewAll: false,
        }

        this.ref = React.createRef();

        this.handleClickOutsideDropdown = this.handleClickOutsideDropdown.bind(this);
        this.handleScrollOutsideDropdown = this.handleScrollOutsideDropdown.bind(this);
        this.handleDropdown = this.handleDropdown.bind(this);
    }

    /**
     * Load stickers when none are loaded when component mounts
     */
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideDropdown, false);
        document.addEventListener('wheel', this.handleScrollOutsideDropdown, false);
    }


    /**
     * Remove eventlisteners when component unmounts
     */
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideDropdown, false);
        document.removeEventListener('wheel', this.handleScrollOutsideDropdown, false);
    }


    /**
     * Close add screen when user clicks outside box
     * @param e
     * @returns {boolean}
     */
    handleClickOutsideDropdown(e) {
        const {updateNotifications, list} = this.props;
        const {listOpen} = this.state;
        if (this.ref.current && this.ref.current.contains(e.target)) return true;

        let filtered = list.filter(x => x.seen === false);

        if (listOpen && filtered.length !== 0) updateNotifications(list.map(x => x.id));
        this.setState({listOpen: false});
    }


    /**
     * Close add screen when user clicks outside box
     * @param e
     * @returns {boolean}
     */
    handleScrollOutsideDropdown(e) {
        const {updateNotifications, list} = this.props;
        const {listOpen} = this.state;
        if (this.ref.current && this.ref.current.contains(e.target)) return true;

        let filtered = list.filter(x => x.seen === false);

        if (listOpen && filtered.length !== 0) updateNotifications(list.map(x => x.id));
        this.setState({listOpen: false});
    }


    /**
     * Show add menu
     */
    handleDropdown() {
        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }));
    }
    /**
     * Loading the default user image
     * @param ev
     */
    addDefaultSrc(ev) {
        ev.target.src = userImage;
    }

    render() {
        let {list} = this.props;
        const {listOpen, viewAll} = this.state;

        if (!viewAll) {
            list = list.filter(x => x.seen === false);
        }

        return (
            <div className="dropdown notification">
                <ul className={`dropdown-menu ${listOpen ? 'open' : 'closed'}`} ref={this.ref}>
                    <li className="head">
                        <span>Notificaties ({list.filter(x => x.seen === false).length})</span>
                        {/*<a href="#">Markeer als gelezen</a>*/}
                    </li>

                    <ul className="notification-wrapper">
                    {list.length === 0
                        ? (
                            <li className="notification-box">
                                <EmptyList id={"notifications.empty"}/>
                            </li>
                        ) : (
                            list.map((item, i) => {
                                let author = null,
                                    picture,
                                    date = null,
                                    message = null;

                                switch (item.type) {
                                    case 'sticker':
                                        author = item.sticker && item.sticker.author ? `${item.sticker.author.firstName} ${item.sticker.author.lastName}` : "onbekend";
                                        picture = item.sticker && item.sticker.author ? item.sticker.author.picture : null;
                                        date = item.publishedAt;
                                        let event = null;

                                        if (item.comment) {
                                            event = `bij je opmerking "${truncate(item.comment.body, 50)}"`;
                                        }
                                        else if (item.user) {
                                            event = 'bij je welkomstbericht';
                                        }
                                        else if (item.subFeed) {
                                            switch (item.subFeed.type) {
                                                case 'birthday':
                                                    event = 'voor je verjaardag';
                                                    break;
                                                case 'yearsOfService':
                                                    event = 'voor je jaren dienst';
                                                    break;
                                                case 'bioUpdate':
                                                    event = 'bij je biografie';
                                                    break;
                                                case 'pictureUpdate':
                                                    event = 'bij je nieuwe profielfoto';
                                                    break;
                                                case 'interviewUpdate':
                                                    event = 'bij je interview';
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }

                                        const sticker = item.sticker ? (item.sticker.sticker.image ? `<img src="${item.sticker.sticker.image}" alt="${item.sticker.sticker.content}" class="emoji" />` : item.sticker.sticker.content) : ''
                                        message = `plaatste ${sticker} ${event}.`;
                                        break;

                                    case 'comment':
                                        author = `${item.comment.author.firstName} ${item.comment.author.lastName}`;
                                        picture = item.comment.author.picture;
                                        date = item.comment.createdAt;
                                        message = `plaatste een opmerking bij je welkomstbericht.`;
                                        break;

                                    case 'mention':
                                        author = `${item.comment.author.firstName} ${item.comment.author.lastName}`;
                                        picture = item.comment.author.picture;
                                        date = item.comment.createdAt;
                                        message = `heeft je vernoemd in een opmerking.`;
                                        break;

                                    default:
                                        break;
                                }
                                return (
                                    <li key={i} className={`notification-box ${item.seen ? 'seen' : 'unseen'}`}>
                                        <img src={picture ? picture : ""} onError={this.addDefaultSrc} className="w-50 rounded-circle" alt={author}/>
                                        <Link to={`/notifications/${item.id}`} onClick={() => this.setState({listOpen: false})}>
                                            <strong>{author}</strong>
                                            <div dangerouslySetInnerHTML={{__html: message}}/>
                                            <small>{DateUtility.toLongDate(date)} {DateUtility.toTime(date)}</small>
                                        </Link>
                                    </li>
                                );
                            })
                        )
                    }
                    </ul>

                    <li className="footer">
                        <Button buttonStyle={BTN_CLASSES.LINK}
                                type={BTN_TYPES.BUTTON}
                                onClick={() => this.setState(prevState => ({viewAll: !prevState.viewAll}))}
                        >
                            {viewAll ? 'Bekijk minder' : 'Bekijk alles'}
                        </Button>
                    </li>
                </ul>
            </div>
        )
    }
}

export default connect(null, null, null, {forwardRef: true})(NotificationDropdown);
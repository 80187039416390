import axios from "axios";
import {API_URL} from "../constants/defaults";
import {DELETE_FORM, LOAD_FORM} from "../constants/form.constants";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";
import {LOAD_FORM_RESULTS} from "../constants/subscriptionResult.constants";
import {CONFIRM_INVITATION} from "../constants/invitation.constants";

export const loadForm = (type, id) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;
    const {forms} = state.formReducer;

    if (!forms[type][id]) {
        dispatch({type: LOAD_FORM, payload: {type, id, form: null}});
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(`${API_URL}/${type}/${id}/form`, header)
            .then(({data}) => {
                dispatch({type: LOAD_FORM, payload: {type, id, form: data}});
            })
            .catch(error => {
                const message = handleLoadErrors(messages, error);
                dispatch({type: DELETE_FORM, payload: {type, id}});
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}})
                console.log(error);
            });
    }
};

export const sendForm = (type, id, body, setResult = false) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(`${API_URL}/form-submissions`, body, header)
        .then(({data}) => {
            if (setResult)
                dispatch({type: LOAD_FORM_RESULTS, payload: {resultsLoading: false, results: data}});
            else if (data !== 'done')
                dispatch({type: CONFIRM_INVITATION, payload: {type, id, invitation: data}});

            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["forms.post.response.success"], type: 'success'})}})
        })
        .catch(error => {
            console.log(error);
            const message = handlePostErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}})
        })
};

const handleLoadErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["forms.load.response.error"];
};

const handlePostErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["forms.post.response.error"];
};
import axios from "axios";
import {API_URL} from "../constants/defaults";
import {LOAD_SUB_FEED} from "../constants/subFeed.constants";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";

export const loadSubFeed = (start) => (dispatch, getState) => {
    const state = getState();
    const {messages, locale} = state.intl;
    const {isLoading} = state.subFeedReducer;
    const {token} = state.authReducer;

    if (!isLoading) {
        dispatch({type: LOAD_SUB_FEED, payload: {feeds: [], isLoading: true}});
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(`${API_URL}/subfeed?lang=${locale}&start=${start}`, header)
            .then(({data}) => {
                dispatch({type: LOAD_SUB_FEED, payload: {feeds: data, isLoading: false}});
            })
            .catch(error => {
                const message = handleErrors(messages, error);
                dispatch({type: LOAD_SUB_FEED, payload: {feeds: [], isLoading: false}});
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            });
    }
};

const handleErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.error && error.response.data.error) {
        if (error.response.data.error.code && error.response.data.error.code === 403) {
            return messages["subFeed.rights.error"];
        }
    }
    return messages["subFeed.response.error"];
};
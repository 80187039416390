import React, {useEffect, useState} from 'react';
import {Col, Row} from "../../utils/Grid/Grid";
import Carousel from "../../utils/Carousel/Carousel";
import DateUtility from "../../utils/dateUtils";
import Attachments from "../../utils/Attachments/Attachments";
import './ItemDetail.scss';
import OverviewItem from "../OverviewItem/OverviewItem";
import {FormattedMessage} from "react-intl";
import Stickers from "../Stickers/Stickers";
import Comments from "../Comments/Comments";
import {Link} from "react-router-dom";
import {BTN_CLASSES} from "../../css_constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faLongArrowAltLeft, faPowerOff, faThumbtack, faTrash} from "@fortawesome/free-solid-svg-icons";
import Button from "../../utils/Buttons/Button";
import Subscription from "../Subscription/Subscription";
import {useDispatch, useSelector} from "react-redux";
import {addStickers} from "../../redux/actions/stickers.actions";
import {addComments} from "../../redux/actions/comment.actions";
import {loadForm} from "../../redux/actions/form.actions";
import {loadInvitation} from "../../redux/actions/invitation.actions";
import Modal from "../../utils/Modal/Modal";

/**
 * Item Detail Component
 * @param obj
 * @param objType
 * @param id
 * @param title
 * @param pinned
 * @param pinArticle
 * @param body
 * @param location
 * @param isAddedToCv
 * @param organisors
 * @param days
 * @param media
 * @param attachments
 * @param links
 * @param tags
 * @param coce
 * @param author
 * @param publishDate
 * @param stickers
 * @param instructor
 * @param translated
 * @param admin
 * @returns {*}
 * @constructor
 */
const ItemDetail = ({obj, objType, id, title, pinned, pinArticle, body, location, hasForm, hasInvitation, isAddedToCv, organisors, days, media, attachments, links, tags, coce, author, publishDate, instructor, translated, admin, messages, ...props}) => {
    const {toShortDate, isCurrent, lastDate} = DateUtility;

    const dispatch = useDispatch();
    const stickers = useSelector(state => state.stickerReducer.stickers[objType][id]);
    const comments = useSelector(state => state.commentReducer.comments[objType][id]);
    const form = useSelector(state => state.formReducer.forms[objType][id]);
    const invitations = useSelector(state => state.invitationReducer.invitations[objType] ? state.invitationReducer.invitations[objType][id] : null);

    const [showPublicationModal, setShowPublicationModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);

    const url = messages[`routes.${objType}`];

    const showSidebar = (attachments && attachments.length > 0) || (links && links.length > 0) || hasInvitation || hasForm || isAddedToCv || organisors || location || (days && days.length > 0) || instructor || stickers;

    useEffect(() => {
        dispatch(addStickers(objType, id));
        dispatch(addComments(objType, id));
        dispatch(loadForm(objType, id));

        if (objType !== "news") dispatch(loadInvitation(objType, id));
    }, [dispatch, objType, id])

    /** Returns the view of an event, training or news with all it's components */
    return (
        <Row>
            <Col xsOrder={1} md={12}>
                {media &&
                <Carousel objMedia={media}/>
                }

                <div className={"author"}>
                    <FormattedMessage id={"itemDetail.author"} values={{author: (author ? <Link to={`${messages['routes.employees']}/${author.id}`}>{author.firstName}</Link> : 'admin'), date: toShortDate(publishDate)}}/>

                    {pinned !== undefined
                        ? pinned
                            ? (
                                <Button buttonStyle={BTN_CLASSES.LINK}
                                        className={`pin ${pinned ? 'active' : ''}`}
                                        onClick={() => pinArticle(id, !pinned)}
                                >
                                    <FontAwesomeIcon icon={faThumbtack}/> <FormattedMessage id={"itemDetail.unpinArticle"}/>
                                </Button>
                            ) : (
                                <Button buttonStyle={BTN_CLASSES.LINK}
                                        className={`pin ${pinned ? 'active' : ''}`}
                                        onClick={() => pinArticle(id, !pinned)}
                                >
                                    <FontAwesomeIcon icon={faThumbtack}/> <FormattedMessage id={"itemDetail.pinArticle"}/>
                                </Button>
                            )
                        : null}
                </div>
            </Col>

            <Col lg={showSidebar ? 8 : 12} xl={showSidebar ? 9 : 12} xsOrder={3} lgOrder={2}>
                <h1>{title}</h1>
                <div className={"item-body"} dangerouslySetInnerHTML={{__html: body}}/>

                {tags && tags.length > 0 &&
                (<div className={"tags"}>
                    <ul>
                        {coce &&
                        <li><Link to={{
                            pathname: url,
                            state: {
                                selectedFilters: [{value: coce.id, label: coce.name, type: "coce"}],
                                firstLoad: false
                            }
                        }}>#{coce.name}</Link></li>
                        }
                        {tags.map((tag, i) => (
                            <li key={i}><Link to={{
                                pathname: url,
                                state: {
                                    selectedFilters: [{value: tag, label: tag, type: "tag"}],
                                    firstLoad: false
                                }
                            }}>#{tag}</Link></li>
                        ))}
                    </ul>
                </div>)
                }

                {comments &&
                <div className={"detail__comments"}>
                    <Comments comments={comments} addButton={true} obj={obj} objType={objType}/>
                </div>
                }
            </Col>

            {showSidebar &&
            <Col lg={4} xl={3} xsOrder={2} lgOrder={3}>
                <div className={"detail-sidebar"}>
                    <div className={BTN_CLASSES.GROUP + " flex"}>
                        {admin && admin.publish && (!days || (days && (days.length === 0 || (days.length !== 0 && isCurrent(lastDate(days)))))) &&
                        <>
                            <Button buttonStyle={BTN_CLASSES.PRIMARY}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    className={publishDate ? 'active' : null}
                                    onClick={() => publishDate ? setShowPublicationModal(true) : admin.publish(id)}
                            >
                                <FontAwesomeIcon icon={faPowerOff} fixedWidth/>
                            </Button>

                            <Modal cancel={false}
                                   show={showPublicationModal}
                                   close={() => setShowPublicationModal(!showPublicationModal)}
                                   title="Weet je het zeker?"
                                   actions={[{
                                       label: "Ja",
                                       action: () => {
                                           admin.publish(id);
                                           setShowPublicationModal(false);
                                       },
                                       disabled: true
                                   }, {
                                       label: "Nee",
                                       action: () => setShowPublicationModal(false),
                                       buttonType: 'btn-secondary',
                                       disabled: true
                                   }]}>
                                Door op <i>Ja</i> te klikken, zal de publicatie ongedaan gemaakt worden. {objType !== 'news' ? 'Alle uitgenodigden zullen hiervan op de hoogte gebracht worden.' : ''}<br />
                                Weet je zeker dat je wil doorgaan?
                            </Modal>
                        </>
                        }

                        {admin && admin.update &&
                        <Link to={admin.update} className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`}><FontAwesomeIcon icon={faEdit} fixedWidth/></Link>
                        }

                        {admin && admin.delete &&
                        <>
                            <Button buttonStyle={BTN_CLASSES.PRIMARY}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    onClick={() => setShowRemoveModal(true)}
                            >
                                <FontAwesomeIcon icon={faTrash} fixedWidth/>
                            </Button>

                            <Modal cancel={false}
                                   show={showRemoveModal}
                                   close={() => setShowRemoveModal(!showRemoveModal)}
                                   title="Weet je het zeker?"
                                   actions={[{
                                       label: "Ja",
                                       action: () => {
                                           admin.delete(id);
                                           setShowRemoveModal(false);
                                       },
                                       disabled: true
                                   }, {
                                       label: "Nee",
                                       action: () => setShowRemoveModal(false),
                                       buttonType: 'btn-secondary',
                                       disabled: true
                                   }]}>
                                Door op <i>Ja</i> te klikken, zal dit item verwijderd worden. {objType !== 'news' && publishDate ? 'Alle ingeschrevenen zullen hiervan op de hoogte gebracht worden en de inschrijvingen worden verwijderd. ' : ''}Deze actie kan niet ongedaan gemaakt worden.<br />
                                Weet je zeker dat je wil doorgaan?
                            </Modal>
                        </>
                        }


                        {/*admin && admin.add && !admin.translated &&
                        <Link to={admin.add} className={`first ${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`}><FontAwesomeIcon icon={faClone} fixedWidth/></Link>
                        */}

                        {admin && admin.results && (hasForm || hasInvitation) &&
                        <Link to={admin.results} className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`}>{objType === 'news' ? 'Resultaten' : 'Inschrijvingen'}</Link>
                        }
                    </div>

                    {(hasInvitation || hasForm) &&
                    <Subscription invitations={invitations} form={form} author={author} obj={obj} objType={objType}/>
                    }

                    {(location || (days && days.length > 0) || instructor || (organisors && organisors.length > 0)) &&
                    <OverviewItem location={location} days={days} instructor={instructor} organisers={organisors} isAddedToCv={isAddedToCv}/>
                    }

                    {((attachments && attachments.length > 0) || (links && links.length > 0)) &&
                    <Attachments attachments={attachments} links={links}/>
                    }

                    {stickers && <Stickers stickers={stickers} addButton={true} obj={obj} objType={objType}/>}

                    <div className={"right"} style={{marginTop: "20px"}}>
                        <Link to={url}><FontAwesomeIcon icon={faLongArrowAltLeft}/> <FormattedMessage id={"General.backToOverview"}/></Link>
                    </div>
                </div>
            </Col>
            }
        </Row>
    )
};

export default ItemDetail;
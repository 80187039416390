import React, {Component} from 'react';
import {connect} from "react-redux";
import {loadMainFeed} from "../../../redux/actions/mainFeed.actions";
import {getMedia} from "../../../redux/actions/media.actions";
import {addStickers} from "../../../redux/actions/stickers.actions"
import {addComments} from "../../../redux/actions/comment.actions";
import MainFeedItem from "./MainFeedItem";
import Load from "../../../utils/Loading/Load";
import {FormattedMessage, injectIntl} from "react-intl";
import MediaMainFeedItem from "./MediaMainFeedItem";
import './MainFeedItem.scss';
import AttachmentMainFeedItem from "./AttachmentMainFeedItem";
import {pinArticle} from "../../../redux/actions/news.actions";
import EmptyList from "../../EmptyList/EmptyList";

/**
 * MainFeed component
 */
class MainFeed extends Component {
    constructor(props) {
        super(props);

        /** Main feed type function map */
        this.funcMap = {
            UserMainFeedItem: this.renderUserMainFeedItem,
            NewsMainFeedItem: this.renderNewsMainFeedItem,
            EventMainFeedItem: this.renderEventMainFeedItem,
            TrainingMainFeedItem: this.renderTrainingMainFeedItem,
            EventMediaMainFeedItem: this.renderEventMediaMainFeedItem,
            TrainingMediaMainFeedItem: this.renderTrainingMediaMainFeedItem,
            EventAttachmentMainFeedItem: this.renderEventAttachmentMainFeedItem,
            TrainingAttachmentMainFeedItem: this.renderTrainingAttachmentMainFeedItem,
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handlePin = this.handlePin.bind(this);
    }


    /**
     * Load the main feed on component mount if none is loaded
     */
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);

        const {loadMainFeed, mainFeed, permissions} = this.props;
        //if (permissions.includes("mainfeed_see") && mainFeed.length === 0) loadMainFeed(mainFeed.length);

        if (permissions.includes("mainfeed_see")) loadMainFeed(mainFeed.length);
    }


    /**
     * remove eventlistener when component unmounts
     */
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
    }


    /**
     * Get render method according to type
     * @param feedItem
     * @param i
     * @returns {*}
     * @private
     */
    _getRenderMethod(feedItem, i) {
        const method = this.funcMap[feedItem.type];

        if (typeof method === 'function') {
            return method(feedItem, i, this, this.handlePin);
        }
    }


    /**
     * Load extra feed items when user scrolled to bottom of the page and there is more to bee seen
     */
    handleScroll() {
        const wrappedElement = document.getElementById('body');

        const {loadMainFeed, hasMore, mainFeed, permissions} = this.props;

        if (wrappedElement.getBoundingClientRect().bottom <= window.innerHeight + 250) {
            if (hasMore && permissions.includes("mainfeed_see"))
                loadMainFeed(mainFeed.length);
        }
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {mainFeed, hasMore, isLoading} = this.props;

        /** Return _getRenderMethod when feed is loaded */
        return (
            <div>
                <div className={"main_feeds"}>
                    {isLoading && mainFeed.length === 0
                        ? <Load/>
                        : mainFeed.length === 0
                            ? <EmptyList id={"EmptyList.feed"}/>
                            : mainFeed.map((feedItem, i) => (
                                this._getRenderMethod(feedItem, i)
                            ))
                    }
                </div>

                {isLoading && mainFeed.length !== 0 &&
                <Load/>
                }

                {!hasMore && mainFeed.length !== 0 && !isLoading &&
                <div className={"no-more-mainfeed"}>
                    <FormattedMessage id={"feed.end"}/>
                </div>
                }

            </div>
        );
    };


    /**
     * Handle when user wants to pin a news article
     * @param id
     * @param pinned
     */
    handlePin(id, pinned) {
        const {pinArticle} = this.props;

        pinArticle(id, pinned);
        let mainFeed = this.state.mainFeed.map(x => {
            if (!x.newsItem || x.newsItem.id !== id) return x;
            return {
                ...x,
                newsItem: {
                    ...x.newsItem,
                    pinned: pinned
                }
            }
        });

        this.setState({mainFeed});
    }


    /**
     * Component renderUserMainFeedItem method
     * @param feedItem
     * @param i
     * @param props
     * @returns {*}
     */
    renderUserMainFeedItem(feedItem, i, {props}) {
        const {intl, addStickers, addComments, stickers, comments} = props;
        const {user, title, createdAt} = feedItem;
        const {id, firstName, lastName, picture} = user;


        /** return MainFeedItem */
        return (
            <MainFeedItem id={id}
                          title={title}
                          body={`<b>${firstName} ${lastName}</b><div>${user.function}</div>`}
                          picture={picture}
                          createdAt={createdAt}
                          element={user}
                          type={"users"}
                          loadMore={false}
                          getMedia={getMedia}
                          media={props.media}
                          addStickers={addStickers}
                          stickers={stickers["users"][id]}
                          addComments={addComments}
                          comments={comments["users"][id]}
                          messages={intl.messages}
                          route={intl.messages['routes.employees']}
                          key={i}
            />
        );
    };


    /**
     * Component renderNewsMainFeedItem method
     * @param feedItem
     * @param i
     * @param props
     * @param handlePin
     * @returns {*}
     */
    renderNewsMainFeedItem(feedItem, i, {props}, handlePin) {
        const {getMedia, addStickers, addComments, stickers, comments, intl} = props;
        const {newsItem} = feedItem;
        const {id, title, body, author, media, createdAt, pinned} = newsItem;


        /** return MainFeedItem */
        return (
            <MainFeedItem id={id}
                          title={title}
                          body={body}
                          author={author}
                          elementMedia={media}
                          createdAt={createdAt}
                          element={newsItem}
                          type={"news"}
                          loadMore={true}
                          pinned={pinned}
                          handlePin={handlePin}
                          getMedia={getMedia}
                          media={props.media}
                          addStickers={addStickers}
                          stickers={stickers["news"][id]}
                          addComments={addComments}
                          comments={comments["news"][id]}
                          messages={intl.messages}
                          route={intl.messages['routes.news']}
                          key={i}
            />
        )
    }


    /**
     * Component renderEventMainFeedItem method
     * @param feedItem
     * @param i
     * @param props
     * @returns {*}
     */
    renderEventMainFeedItem(feedItem, i, {props}) {
        const {getMedia, addStickers, addComments, stickers, comments, intl} = props;
        const {event} = feedItem;
        const {id, creator, createdAt, media, name, description} = event;

        /** return MainFeedItem */
        return (
            <MainFeedItem id={id}
                          title={name}
                          body={description}
                          author={creator}
                          elementMedia={media}
                          createdAt={createdAt}
                          element={event}
                          type={"events"}
                          loadMore={true}
                          getMedia={getMedia}
                          media={props.media}
                          addStickers={addStickers}
                          stickers={stickers["events"][id]}
                          addComments={addComments}
                          comments={comments["events"][id]}
                          messages={intl.messages}
                          route={intl.messages['routes.events']}
                          key={i}
            />
        )
    }


    /**
     * Component renderTrainingMainFeedItem method
     * @param feedItem
     * @param i
     * @param props
     * @returns {*}
     */
    renderTrainingMainFeedItem(feedItem, i, {props}) {
        const {getMedia, addStickers, addComments, stickers, comments, intl} = props;
        const {training} = feedItem;
        const {id, creator, createdAt, name, media, description} = training;

        /** return MainFeedItem */
        return (
            <MainFeedItem id={id}
                          title={name}
                          body={description}
                          author={creator}
                          elementMedia={media}
                          createdAt={createdAt}
                          element={training}
                          type={"trainings"}
                          loadMore={true}
                          getMedia={getMedia}
                          media={props.media}
                          addStickers={addStickers}
                          stickers={stickers["trainings"][id]}
                          addComments={addComments}
                          comments={comments["trainings"][id]}
                          messages={intl.messages}
                          route={intl.messages['routes.trainings']}
                          key={i}
            />
        )
    }


    /**
     * Component renderEventMediaMainFeedItem method
     * @param feedItem
     * @param i
     * @param props
     * @returns {*}
     */
    renderEventMediaMainFeedItem(feedItem, i, {props}) {
        const {getMedia, addStickers, addComments, stickers, comments, intl} = props;
        const {id, event, title} = feedItem;
        const {media = []} = event || [];

        /** return MediaMainFeedItem */
        return media.length !== 0
            ? (<MediaMainFeedItem id={id}
                                  title={title}
                                  elementMedia={media}
                                  element={event}
                                  type={"mainfeed"}

                                  getMedia={getMedia}

                                  addStickers={addStickers}
                                  stickers={stickers["mainfeed"][id]}
                                  addComments={addComments}
                                  comments={comments["mainfeed"][id]}
                                  route={intl.messages['routes.events']}
                                  feedItem={feedItem}
                                  key={i}
            />)
            : null
    }


    /**
     * Component renderTrainingMediaMainFeedItem method
     * @param feedItem
     * @param i
     * @param props
     * @returns {*}
     */
    renderTrainingMediaMainFeedItem(feedItem, i, {props}) {
        const {getMedia, addStickers, addComments, stickers, comments, intl} = props;
        const {id, training, title} = feedItem;
        const {media = []} = training || [];

        /** return MediaMainFeedItem */
        return media.length !== 0
            ? (<MediaMainFeedItem id={id}
                                  title={title}
                                  element={training}
                                  type={"mainfeed"}
                                  elementMedia={media}

                                  getMedia={getMedia}

                                  addStickers={addStickers}
                                  stickers={stickers["mainfeed"][id]}
                                  addComments={addComments}
                                  comments={comments["mainfeed"][id]}
                                  route={intl.messages['routes.trainings']}
                                  feedItem={feedItem}
                                  key={i}
            />)
            : null
    }


    /**
     * Component renderEventAttachmentMainFeedItem method
     * @param feedItem
     * @param i
     * @param props
     * @returns {*}
     */
    renderEventAttachmentMainFeedItem(feedItem, i, {props}) {
        const {addStickers, addComments, stickers, comments, intl} = props;
        const {id, title, attachments, links, event} = feedItem;

        /** return AttachmentMainFeedItem */
        return (
            <AttachmentMainFeedItem id={id}
                                    title={title}
                                    attachments={attachments}
                                    links={links}
                                    element={event}
                                    type={"mainfeed"}
                                    addStickers={addStickers}
                                    stickers={stickers["mainfeed"][id]}
                                    addComments={addComments}
                                    comments={comments["mainfeed"][id]}
                                    route={intl.messages['routes.events']}
                                    feedItem={feedItem}
                                    key={i}
            />
        )
    }


    /**
     * Component renderTrainingAttachmentMainFeedItem method
     * @param feedItem
     * @param i
     * @param props
     * @returns {*}
     */
    renderTrainingAttachmentMainFeedItem(feedItem, i, {props}) {
        const {addStickers, addComments, stickers, comments, intl} = props;
        const {id, title, attachments, links, training} = feedItem;

        console.log(feedItem)
        /** return AttachmentMainFeedItem */
        return (
            <AttachmentMainFeedItem id={id}
                                    title={title}
                                    attachments={attachments}
                                    links={links}
                                    element={training}
                                    type={"mainfeed"}
                                    addStickers={addStickers}
                                    stickers={stickers["mainfeed"][id]}
                                    addComments={addComments}
                                    comments={comments["mainfeed"][id]}
                                    route={intl.messages['routes.trainings']}
                                    feedItem={feedItem}
                                    key={i}
            />
        )
    }
}


const mapStateToProps = ({mainFeedReducer, stickerReducer, commentReducer, authReducer, mediaReducer}) => {
    const {mainFeed, hasMore, isLoading} = mainFeedReducer;
    const {stickers} = stickerReducer;
    const {comments} = commentReducer;
    const {permissions} = authReducer;
    const {media} = mediaReducer;
    return {mainFeed, hasMore, media, isLoading, stickers, comments, permissions};
};


const mapDispatchToProps = {
    loadMainFeed, getMedia, addStickers, addComments, pinArticle
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MainFeed));
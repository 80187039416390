import {
    LOAD_USER,
    LOGIN_USER,
    LOGOUT_USER,
    REMOVE_ALERT,
    UPDATE_ACCOUNT,
} from "../constants/auth.constants";

let defaultState = {
    user: null,
    userLoading: false,
    userId: null,
    loggedIn: false,
    token: null,
    role: null,
    car: null,
    permissions: [],
    formErrors: {email: '', password: ''}
};

const loginUser = (state, {userId, token, userLoading, formErrors}) => (
    {...state, userId, loggedIn: token !== null, token, userLoading, formErrors}
);

const loadUser = (state, {user, userLoading}) => (
    {...state, user, userLoading, role: user.role, permissions: user.permissions, car: user.car}
);

const logoutUser = (state, {formErrors}) => (
    {...state, user: null, userLoading: false, userId: null, loggedIn: false, token: null, role: null, permissions: [], car: null, formErrors}
);

const updateAccount = (state, {user}) => {
    return {...state, user}
};

const removeAlert = (state, {type, id}) => {
    return {
        ...state,
        user: {
            ...state.user,
            alerts: state.user.alerts.filter(x => {
                if (x.type !== type) return x;
                if (x.id !== id) return x;
                return null;
            })
        }
    }
};


const authReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOGIN_USER:
            return loginUser(state, payload);
        case LOAD_USER:
            return loadUser(state, payload);
        case LOGOUT_USER:
            return logoutUser(state, payload);
        case UPDATE_ACCOUNT:
            return updateAccount(state, payload);
        case REMOVE_ALERT:
            return removeAlert(state, payload);
        default:
            return state;
    }
};

export default authReducer;
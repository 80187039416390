import {ADD_COMMENT, ADD_COMMENTS, DELETE_COMMENTS} from "../constants/comment.constants";

let defaultState = {
    comments: {
        users: {},
        trainings: {},
        events: {},
        news: {},
        mainfeed: {}
    }
};


const addComments = (state, {type, id, comments}) => {
    return {
        ...state,
        comments: {
            ...state.comments,
            [type]: {
                ...state.comments[type],
                [id]: comments
            }
        }
    };
};

const addComment = (state, {type, id, comment}) => (
    {
        ...state,
        comments: {
            ...state.comments,
            [type]: {
                ...state.comments[type],
                [id]: [
                    ...state.comments[type][id],
                    comment
                ]
            }
        }
    }
);

const deleteComments = (state, {type, id}) => {
    let arr = state.comments;
    delete arr[type][id];

    return {...state, comments: arr};
};


const commentReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case ADD_COMMENTS:
            return addComments(state, payload);
        case ADD_COMMENT:
            return addComment(state, payload);
        case DELETE_COMMENTS:
            return deleteComments(state, payload);
        default:
            return state;
    }
};

export default commentReducer;
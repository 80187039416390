import {
    EDIT_PERMISSIONS,
    GET_PERMISSIONS,
    GET_ROLES,
    LOADING_ROLES_PERMISSIONS
} from "../constants/permissionsAdmin.constants";
import { isEmpty } from "../../utils/helperFunctions";

const initialState = {
    isLoading: false,
    isLoaded: false,
    roles: [],
    permissions: [],
}

const loadingRolesAndPermissions = (state) => (
    {...state, isLoading: true}
);

const getRoles = (state, {roles}) => {
    if (isEmpty(state.permissions)) {
        return {...state, roles: roles ? roles : []}
    } else {
        return {...state, isLoading: false, roles: roles ? roles : []}
    }
}

const getPermissions = (state, {permissions}) => {
    if (isEmpty(state.roles)) {
        return {...state, permissions: permissions ? permissions : []}
    } else {
        return {...state, isLoading: false, permissions: permissions ? permissions : []}
    }
}

const editPermissions = (state, {roles}) => {
    return {...state, roles}
}

const permissionsAdminReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case LOADING_ROLES_PERMISSIONS:
            return loadingRolesAndPermissions(state);
        case GET_ROLES:
            return getRoles(state, payload);
        case GET_PERMISSIONS:
            return getPermissions(state, payload);
        case EDIT_PERMISSIONS:
            return editPermissions(state, payload);
        default:
            return state;
    }
}

export default permissionsAdminReducer;
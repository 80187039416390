import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChalkboardTeacher, faEdit, faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {BTN_CLASSES} from "../../../css_constants";
import './Instructor.scss';

const InstructorSingle = ({instructor, select, resetAccount}) => {
    return (
        <div className={"instructor-admin"}>
            <div className={"instructor__icon"}><FontAwesomeIcon icon={faChalkboardTeacher}/></div>

            <div className={"instructor__header"}>
                <div className={`${BTN_CLASSES.LINK} instructor__title`} onClick={() => select(instructor)}>{`${instructor.firstName} ${instructor.lastName}`}</div>
            </div>

            <div className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.SECONDARY} ${BTN_CLASSES.SMALL}`}
                 onClick={() => resetAccount(instructor.id)}
            >
                <FontAwesomeIcon icon={faUnlockAlt} fixedWidth/>
            </div>

            <div className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`}
                 onClick={() => select(instructor)}
            >
                <FontAwesomeIcon icon={faEdit} fixedWidth/>
            </div>
        </div>
    );
}

export default InstructorSingle;
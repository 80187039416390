import {
    DOWNLOAD_CLAIM_ATTACHMENT,
    GET_ALL_DAMAGE_CLAIMS,
    GET_ALL_ILLNESS_CLAIMS,
    LOADED_ADMIN_CLAIMS,
    LOADING_ADMIN_CLAIMS,
    TOGGLE_ADMIN_CLAIM_STATE,
} from "../constants/claimsAdmin.constants";

const initialState = {
    isLoading: false,
    isLoaded: false,
    illnessList: [],
    damageList: [],
}

const loadingAdminClaims = (state) => (
    {...state, isLoading: true}
);

const loadedAdminClaims = (state) => (
    {...state, isLoading: false}
);

const getAllIllnessClaims = (state, {illnesses}) => (
    {...state,
        illnessList: illnesses ? illnesses : [],
        isLoading: false,
        isLoaded: true,
    }
);

const getAllDamageClaims = (state, {damages}) => (
    {...state,
        damageList: damages ? damages : [],
        isLoading: false,
        isLoaded: true,
    }
);

const toggleClaimState = (state, {claim, claimType}) => {
    if ( claimType.toUpperCase() === 'ILLNESS' ) {
        const updatedIllnessList = [...state.illnessList];
        const illnessClaimIndex = updatedIllnessList.findIndex(illness => illness.id === claim.id);
        updatedIllnessList[illnessClaimIndex] = claim;

        return {...state,
            illnessList: updatedIllnessList,
            isLoading: false
        }
    } else {
        const updatedDamageList = [...state.damageList];
        const damageClaimIndex = updatedDamageList.findIndex(damage => damage.id === claim.id);
        updatedDamageList[damageClaimIndex] = claim;

        return {...state,
            damageList: updatedDamageList,
            isLoading: false
        }
    }
}

const downloadClaimAttachment = (state) => ({...state});

const claimsAdminReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case LOADING_ADMIN_CLAIMS:
            return loadingAdminClaims(state);
        case LOADED_ADMIN_CLAIMS:
            return loadedAdminClaims(state);
        case GET_ALL_ILLNESS_CLAIMS:
            return getAllIllnessClaims(state, payload);
        case GET_ALL_DAMAGE_CLAIMS:
            return getAllDamageClaims(state, payload);
        case TOGGLE_ADMIN_CLAIM_STATE:
            return toggleClaimState(state, payload);
        case DOWNLOAD_CLAIM_ATTACHMENT:
            return downloadClaimAttachment(state, payload);
        default:
            return state;
    }
}

export default claimsAdminReducer;
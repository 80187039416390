import React, {Component} from 'react';
import Load from "../../utils/Loading/Load";
import ItemDetail from "../ItemDetail/ItemDetail";
import {deleteEvent, loadEvent, publishEvent} from "../../redux/actions/event.actions";
import {getMedia} from "../../redux/actions/media.actions";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";

/**
 * Event detail component
 */
class EventDetail extends Component {
    /**
     * Load event when none is loaded on component mount
     */
    componentDidMount() {
        const {loadEvent, match} = this.props;
        const {slug} = match.params;
        loadEvent(slug);
    }


    /**
     * Select the event and load its media is non is selected
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {selectedEvent, loadEvent, match} = this.props;
        const {slug} = match.params;

        document.title = `UniPartners Group | Meet UP | ${selectedEvent ? selectedEvent.name : 'loading...'}`;

        if (selectedEvent && selectedEvent.media && selectedEvent.media.length > 0) {
            selectedEvent.media.map(obj => {
                const {getMedia} = this.props;
                getMedia(obj);
                return null;
            });
        }

        if (selectedEvent && selectedEvent.slug !== slug) {
            loadEvent(slug);
        }
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {isLoading, selectedEvent} = this.props;

        /** Return renderEvent when event is selected */
        return (
            <div>
                {isLoading
                    ? <Load/>
                    : (selectedEvent &&
                        this.renderEvent()
                    )
                }
            </div>
        )
    }


    /**
     * Component renderEvent method
     * @returns {*}
     */
    renderEvent() {
        const {role, user, permissions, selectedEvent, publishEvent, deleteEvent, intl} = this.props;
        const {id, name, slug, description, creator, organisors, publishedAt, location, days, tags, media, attachments, links, translated, hasForm, hasInvitations} = selectedEvent;

        const isAuthor = user.id === creator.id || organisors.find(x => x.id === user.id);
        const hasPermission = role.name === 'admin' || role.name === 'dev' || role.name === 'editor' || role.name === 'staff' || (role.name === 'coceco' && isAuthor);
        /** Set admin variables */
        const admin = {
            publish: hasPermission && permissions.includes("events_publish") && ((id) => publishEvent(id)),
            delete: hasPermission && permissions.includes("events_delete") && ((id) => deleteEvent(id, this.props.history)),
            add: hasPermission && permissions.includes("events_add") && (`/evenement-toevoegen/${id}`),
            update: hasPermission && permissions.includes("events_update") && (`/evenement-wijzigen/${id}`),
            results: hasPermission && permissions.includes("events_see_results") && (`/evenementen/${slug}/inschrijvingen`),
            translated: translated,
        };

        /** Return ItemDetail */
        return (
            <ItemDetail id={id}
                        title={name}
                        body={description}
                        media={media}
                        attachments={attachments}
                        links={links}
                        tags={tags}
                        author={creator}
                        publishDate={publishedAt}
                        messages={intl.messages}
                        obj={selectedEvent}
                        objType={"events"}
                        admin={admin}
                        organisors={organisors}
                        days={days}
                        location={location}
                        hasForm={hasForm}
                        hasInvitation={hasInvitations}
            />
        )
    }
}


const mapStateToProps = ({authReducer, eventReducer, mediaReducer, attachmentReducer}) => {
    const {role, user, permissions} = authReducer;
    const {selectedEvent, isLoading} = eventReducer;
    const {blobs} = mediaReducer;
    const {attachmentBlobs} = attachmentReducer;
    return {role, user, permissions, selectedEvent, isLoading, blobs, attachmentBlobs};
};


const mapDispatchToProps = {
    loadEvent, getMedia, publishEvent, deleteEvent
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EventDetail));
import {DELETE_FORM, LOAD_FORM, SEND_FORM} from "../constants/form.constants";

let defaultState = {
    forms: {
        trainings: [],
        events: [],
        news: []
    }
};


const loadForm = (state, {type, id, form}) => {
    if (form) {
        return {
            ...state,
            forms: {
                ...state.forms,
                [type]: {
                    ...state.forms[type],
                    [id]: form
                }
            }
        }
    }

    return {...state};
};

const updateForm = (state, {type, id, form}) => {
    const forms = Object.keys(state.forms[type]).map(x => {
        if (x !== id) return x;
        return form;
    });

    return {
        ...state,
        forms: {
            ...state.forms,
            [type]: forms
        }
    }
};

const deleteForm = (state, {type, id}) => {
    let arr = state.forms;
    delete arr[type][id];

    return { ...state, forms: arr };
};


const formReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_FORM:
            return loadForm(state, payload);
        case SEND_FORM:
            return updateForm(state, payload)
        case DELETE_FORM:
            return deleteForm(state, payload);
        default:
            return state;
    }
};

export default formReducer;
import {DELETE_MEDIA, LOAD_MEDIA} from "../constants/media.constrants";

let defaultState = {
    media: [],
    componentErrors: []
};

const loadMedia = (state, {media, id}) => {
    const search = state.media.find(x => x.id === id);

    let temp = [];
    if (search) {
        temp = state.media.map(x => {
            if (x.id === id) return {id, ...media, loading: !media};
            return x;
        });
    } else {
        temp = [
            ...state.media,
            {
                id,
                ...media,
                loading: !media
            }
        ];
    }

    return ({...state, media: temp})
};


const deleteMedia = (state, {id}) => {
    let arr = state.media.slice();
    const index = arr.indexOf(state.media.find(x => x.id === id));
    arr.splice(index, 1);

    return { ...state, media: arr };
};


const mediaReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_MEDIA:
            return loadMedia(state, payload);
        case DELETE_MEDIA:
            return deleteMedia(state, payload);
        default:
            return state;
    }
};

export default mediaReducer;

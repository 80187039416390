import React, {Component} from "react";
import IllnessDetail from "./IllnessDetail";
import {Col, Row} from "../../utils/Grid/Grid";
import EmptyList from "../EmptyList/EmptyList";
import Button from "../../utils/Buttons/Button";
import {BTN_CLASSES, BTN_TYPES} from "../../css_constants";
import DateUtility from "../../utils/dateUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faNotesMedical, faSearch} from "@fortawesome/free-solid-svg-icons";
import './Claims.scss';
import {connect} from "react-redux";
import {selectIllness} from "../../redux/actions/app.actions";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";

/**
 * Illness list component
 */
class IllnessList extends Component {
    constructor(props) {
        super(props);

        /** State of the component */
        this.state = {
            openIllness: props.user.illnesses.filter(x => !x.status),
            historyIllness: props.user.illnesses.filter(x => x.status),
            viewHistory: false
        }
    }

    componentDidMount() {
        document.title = `UniPartners Group | Check UP`;
    }

    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {openIllness, historyIllness, viewHistory} = this.state;

        /** Return mapped renderIllness and IllnessDetail component */
        return (
            <Row>
                <Col lg={8}>
                    <h1><FormattedMessage id="illness.topTitle"/></h1>
                    <FormattedMessage id="illness.description">
                        {message => <p dangerouslySetInnerHTML={{__html: message}}/>}
                    </FormattedMessage>

                    <IllnessDetail {...this.props}/>
                </Col>

                <Col lg={4} md={6} className="claims">
                    {(!viewHistory && openIllness && openIllness.length > 0) || (viewHistory && historyIllness && historyIllness.length > 0)
                        ? !viewHistory
                            ? (
                                openIllness.map((x, i) => (
                                    this.renderIllness(x, i)
                                ))
                            ) : (
                                historyIllness.map((x, i) => (
                                    this.renderIllness(x, i)
                                ))
                            )
                        : <EmptyList id={"Illness.empty"}/>
                    }

                    {historyIllness && historyIllness.length !== 0 &&
                    <Button type={BTN_TYPES.BUTTON}
                            buttonStyle={BTN_CLASSES.PRIMARY}
                            block={true}
                            label={viewHistory ? <FormattedMessage id="claims.current"/> : <FormattedMessage id="claims.history"/>}
                            onClick={() => this.setState({viewHistory: !viewHistory})}
                    />
                    }
                </Col>
            </Row>
        );
    };


    /**
     * Component renderIllness method
     * @param illness
     * @param i
     * @returns {*}
     */
    renderIllness(illness, i) {
        const {period, status, id} = illness;
        const {selectIllness, intl} = this.props;
        const {messages} = intl;

        /** return single illness */
        return (
            <div className={"illness"} key={i}>
                <div className={"illness__icon"}><FontAwesomeIcon icon={faNotesMedical}/></div>

                <div className={"illness__header"}>
                    <Link to={messages["routes.illness"] + `/${id}`} className={`${BTN_CLASSES.LINK} illness__title`} onClick={() => selectIllness(id)}>{DateUtility.toLongDate(period[0])}</Link>
                </div>

                <Link to={messages["routes.illness"] + `/${id}`} className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`} onClick={() => selectIllness(id)}><FontAwesomeIcon icon={status ? faSearch : faEdit}/></Link>
            </div>
        )
    }
}


const mapStateToProps = ({authReducer}) => {
    const {user} = authReducer;
    return {user};
};


export default connect(mapStateToProps, {selectIllness})(injectIntl(IllnessList));
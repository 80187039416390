import {LOAD_COCECOS, LOAD_COCES, LOAD_COMPANIES} from "../constants/coce.constants";

let defaultState = {
    coces: [],
    companies: [],
    cocecos: [],
    isLoading: false,
    companiesLoading: false,
    componentErrors: []
};


const loadCoces = (state, {coces, isLoading}) => (
    {...state, coces, isLoading}
);

const loadCocecos = (state, {cocecos}) => (
    {...state, cocecos}
);

const loadCompanies = (state, {companies, companiesLoading}) => (
    {...state, companies, companiesLoading}
);


const coceReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_COCES:
            return loadCoces(state, payload);
        case LOAD_COCECOS:
            return loadCocecos(state, payload);
        case LOAD_COMPANIES:
            return loadCompanies(state, payload);
        default:
            return state;
    }
};

export default coceReducer;

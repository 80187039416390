import {LOAD_USERS, UPDATE_USER, SELECT_USER, LOAD_INSTRUCTORS, LOAD_STAFF} from "../constants/user.constants";

let defaultState = {
    users: [],
    instructors: [],
    staff: [],
    selectedUser: null,
    usersLoading: false,
    instructorsLoading: true,
    componentErrors: []
};

const loadUsers = (state, {users, usersLoading}) => (
    {
        ...state,
        users: users.map(x => (
            {...x, isLoaded: false}
        )),
        usersLoading
    }
);

const loadInstructors = (state, {instructors, instructorsLoading}) => (
    {...state, instructors, instructorsLoading}
);

const loadStaff = (state, {staff}) => (
    {...state, staff}
);

const updateUser = (state, { user }) => {
    let users = state.users.map(u => {
        if (u.id !== user.id) return u;
        return user;
    });

    return { ...state, users };
};

const selectUser = (state, {selectedUser, usersLoading}) => {
    return {...state, selectedUser, usersLoading}
};


const userReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_USERS:
            return loadUsers(state, payload);
        case UPDATE_USER:
            return updateUser(state, payload);
        case SELECT_USER:
            return selectUser(state, payload);
        case LOAD_INSTRUCTORS:
            return loadInstructors(state, payload);
        case LOAD_STAFF:
            return loadStaff(state, payload);
        default:
            return state;
    }
};

export default userReducer;

import React, {Component} from "react";
import {connect} from "react-redux";
import userImage from '../../assets/images/user.png';
import {loadCoces, loadCompanies} from "../../redux/actions/coce.actions";
import Button from "../../utils/Buttons/Button";
import {BTN_CLASSES, SHARED_CLASSES, UNITS} from "../../css_constants";
import './Employee.scss';
import Load from "../../utils/Loading/Load";
import EmptyList from "../EmptyList/EmptyList";
import {Col, Row} from "../../utils/Grid/Grid";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";

/**
 * Employee overview component
 */
class Employee extends Component {
    constructor(props) {
        super(props);

        /** State of te component */
        this.state = {
            selectedCoce: null,
            searchValue: ''
        }
    }


    /**
     * Load coces if the are not yet loaded and Set the default coce on compnnent mount
     */
    componentDidMount() {
        const {loadCoces, loadCompanies, companies, coces, user} = this.props;
        if (coces.length === 0)
            loadCoces();

        if (companies.length === 0)
            loadCompanies();

        if (this.props.location.state && this.props.location.state.selectedCoce) {
            this.setState({selectedCoce: this.props.location.state.selectedCoce});
        } else {
            if (user && user.coces && user.coces.length !== 0) {
                this.setState({selectedCoce: user.coces[0]})
            }
        }
    }


    /**
     * Set the user coce id no coce is selected when component updates
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedCoce} = this.state;
        const {user} = this.props;

        document.title = `UniPartners Group | United around ${selectedCoce === 'ALL' ? '' : (selectedCoce === 'AND' ? 'staff ' : `${selectedCoce} `).toLowerCase()}colleagues`;

        if (user && user.coces && user.coces.length && !selectedCoce) {
            this.setState({selectedCoce: user.coces[0]})
        }
    }


    /**
     * Loading the default user image
     * @param ev
     */
    addDefaultSrc(ev) {
        ev.target.src = userImage;
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {selectedCoce, searchValue} = this.state;
        const {coces, users, isLoading, usersLoading} = this.props;
        let filtered = [], cocecos = [];

        /** Filter users according to component state */
        if (coces && coces.length > 0) {
            if (searchValue === '') {
                if (selectedCoce === 'ALL') {
                    filtered = users.filter(user => user.role.name !== 'instructor');
                } else if(selectedCoce === 'COCECOS') {
                    filtered = users.filter(user => user.role.name === 'coceco')
                } else {
                    filtered = users.filter(user => {
                        const temp = user.coces.filter(coce => {
                            return coce === selectedCoce
                        });
                        return temp.length ? temp : (user.department === selectedCoce ? user : null)
                    });
                }
            } else {
                filtered = users.filter(({firstName, lastName, role}) => (
                    (firstName.toLowerCase().includes(searchValue.toLowerCase()) || lastName.toLowerCase().includes(searchValue.toLowerCase())) && role.name !== 'instructor'
                ));
            }

            const coce = coces.find(x => x.name === selectedCoce);
            cocecos = coce ? coce.cocecos : [];
        }

        /** Return renderNavBar when coces finish loading and renderOverview when users finish loading */
        return (
            <div>

                {isLoading
                    ? <Load/>
                    : coces && coces.length > 0 && this.renderNavBar()
                }

                {cocecos.length !== 0 && searchValue === '' &&
                this.renderOverview(cocecos, 'coceco')
                }

                {!isLoading &&
                (usersLoading
                    ? <Load/>
                    : !isLoading && !usersLoading &&
                    filtered.length > 0
                        ? this.renderOverview(filtered)
                        : <EmptyList id={"Employee.empty"}/>)
                }

            </div>
        );
    };


    /**
     * Component renderOverview method
     * @param employees
     * @param type
     * @returns {*}
     */
    renderOverview(employees, type) {
        /** return mapped renderUser */
        return (
            <Row className={`employees`}>
                {employees.map((contact, i) => (
                    this.renderUser(contact, type, i)
                ))}
            </Row>
        )
    }


    /**
     * Component renderUser method
     * @param user
     * @param type
     * @param i
     * @returns {*}
     */
    renderUser(user, type, i) {
        const {permissions, intl} = this.props;
        const {selectedCoce} = this.state;
        const {messages} = intl;

        /** Return single overview user */
        return (
            <Col md={6} lg={4} key={i}>
                <div className={`employee ${type ? type : 'member'}`}>
                    <img onError={this.addDefaultSrc} src={user.picture ? user.picture : ""} alt={`${user.firstName} ${user.lastName}`}/>
                    <div className={"employee__details"}>
                        {(permissions.includes("users_see_detail") || permissions.includes("users_see_full_detail"))
                            ? <Link to={{pathname: messages["routes.employees"] + '/' + user.id, coce: selectedCoce}} className={"user_name"}>{`${user.firstName} ${user.lastName}`}</Link>
                            : `${user.firstName} ${user.lastName}`
                        }
                        <div className={"function"}>{type === 'coceco' ? `CoCeCo ${selectedCoce}` : (user.function ? user.function : "\u00A0")}</div>
                    </div>
                    <div className={"employee_contacts"}>
                        <a href={`mailto: ${user.email}`}>{user.email}</a>
                    </div>
                </div>
            </Col>
        )
    }


    /**
     * Component renderNavBar method
     * @returns {*}
     */
    renderNavBar() {
        const {selectedCoce, searchValue} = this.state;
        const {coces, companies} = this.props;

        /** Return coce toolbar */
        return (
            <div>
                <div className={'coceNavBar'}>
                    <Button label={"ALL"}
                            buttonStyle={BTN_CLASSES.SECONDARY}
                            buttonSize={BTN_CLASSES.SMALL}
                            className={selectedCoce === "ALL" ? SHARED_CLASSES.ACTIVE : null}
                            styles={{margin: 5 + UNITS.PX}}
                            onClick={() => this.setState({selectedCoce: "ALL"})}
                    />

                    {coces.map((coce, i) => {
                        return (
                            <Button label={coce.name === 'AND' ? 'STAF' : coce.name}
                                    buttonStyle={BTN_CLASSES.SECONDARY}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    className={selectedCoce === coce.name ? SHARED_CLASSES.ACTIVE : null}
                                    styles={{margin: 5 + UNITS.PX}}
                                    key={i}
                                    onClick={() => this.setState({selectedCoce: coce.name})}
                            />
                        )
                    })}

                    <Button label={"COCECO'S"}
                            buttonStyle={BTN_CLASSES.SECONDARY}
                            buttonSize={BTN_CLASSES.SMALL}
                            className={selectedCoce === "COCECOS" ? SHARED_CLASSES.ACTIVE : null}
                            styles={{margin: 5 + UNITS.PX}}
                            onClick={() => this.setState({selectedCoce: "COCECOS"})}
                    />
                </div>

                <div className={'coceNavBar'}>
                    {companies.map((company, i) => {
                        return (
                            <Button label={company.name === "RACS" ? company.name : (company.name === "UniPartners" ? `${company.name} Group` : `UniPartners ${company.name}`)}
                                    buttonStyle={BTN_CLASSES.SECONDARY}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    className={selectedCoce === company.name ? SHARED_CLASSES.ACTIVE : null}
                                    styles={{margin: 5 + UNITS.PX}}
                                    key={i}
                                    onClick={() => this.setState({selectedCoce: company.name})}
                            />
                        )
                    })}
                </div>

                <input type={"text"}
                       name={"search"}
                       className={"sidebar-search-box"}
                       placeholder={"Zoek een collega..."}
                       value={searchValue}
                       onChange={(e) => this.setState({searchValue: e.target.value})}
                />
            </div>
        )
    }
}


const mapStateToProps = ({authReducer, userReducer, coceReducer}) => {
    const {permissions, user} = authReducer;
    const {users, usersLoading} = userReducer;
    const {coces, companies, isLoading} = coceReducer;
    return {permissions, user, users, usersLoading, coces, companies, isLoading};
};


export default connect(mapStateToProps, {loadCoces, loadCompanies})(injectIntl(Employee));
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BTN_CLASSES} from "../../../css_constants";

/**
 * Sidebar item component
 * @param props
 * @returns {*}
 * @constructor
 */
const SidebarItem = (props) => {
    /** Return accordion header and child component */
    return (
        <div className={`sidebar__section ${props.openAccordion ? 'accordion__active' : ''} ${props.openSlider ? 'slider__active' : ''}`}>
            <button className={`sidebar__button ${BTN_CLASSES.BTN} ${BTN_CLASSES.SECONDARY}`} onClick={props.onToggle}>
                <FontAwesomeIcon icon={props.icon}/>
                <div className="sidebar__title">{props.title}</div>
            </button>
            <div className={"sidebar__content"}>
                <div className="sidebar__text">{props.children}</div>
            </div>
        </div>
    )
};


export default SidebarItem;
import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {faChalkboardTeacher, faEdit, faFileSignature, faMapPin, faPowerOff, faTrash, faUserFriends} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {BTN_CLASSES} from "../../css_constants";
import './OverviewItem.scss';
import DateUtility from "../../utils/dateUtils";
import {FormattedMessage, injectIntl} from "react-intl";
import Button from "../../utils/Buttons/Button";
import Modal from "../../utils/Modal/Modal";

/**
 * Overview item component
 * @param id
 * @param slug
 * @param name
 * @param location
 * @param days
 * @param route
 * @param instructor
 * @param hasForm
 * @param hasInvitations
 * @param isAddedToCv
 * @param organisers
 * @param logo
 * @param publishDate
 * @param admin
 * @param intl
 * @returns {*}
 * @constructor
 */
const OverviewItem = ({id, slug, name, location, days, route, instructor, hasForm, hasInvitations, isAddedToCv, organisers, logo, publishDate, admin, intl}) => {
    const {lastDate, isCurrent} = DateUtility;
    const {messages} = intl;

    const [showPublicationModal, setShowPublicationModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);

    /** Returns the overview item */
    return (
        <div className={"overview-item"}>
            {(logo || name) &&
            <div className={"header"}>
                {logo && <img src={logo} alt={name}/>}
                {name &&
                <h2 className={`title ${logo ? "" : "no-img"}`}>
                    {name}
                    {days.length !== 0 && !isCurrent(lastDate(days)) && <FormattedMessage id={"OverviewItem.archive"}>{txt => <span>{txt}</span>}</FormattedMessage>}
                </h2>
                }
            </div>
            }

            {days.length > 0 &&
            (days.length === 1
                ? DateTime(days[0])
                : DateTimeArray(days)
            )}

            {organisers && organisers.length > 0 &&
            <div className={"organisers"}>
                <FontAwesomeIcon icon={faUserFriends}/>
                <ul className={"organiser"}>{organisers.map((organiser, i) => <li key={i}><Link to={`${messages['routes.employees']}/${organiser.id}`}>{`${organiser.firstName} ${organiser.lastName}`}</Link></li>)}</ul>
            </div>}

            {instructor && <div className={"instructor"}><FontAwesomeIcon icon={faChalkboardTeacher}/> <Link to={`${messages['routes.employees']}/${instructor.id}`}>{`${instructor.firstName} ${instructor.lastName}`}</Link></div>}
            {isAddedToCv && <div className={"up-cv"}><FontAwesomeIcon icon={faFileSignature}/> toegevoegd aan je UP-CV</div>}
            {location && <div className={"location"}><FontAwesomeIcon icon={faMapPin}/> <span dangerouslySetInnerHTML={{__html: location}}/></div>}


            {((admin && Object.keys(admin).length > 1) || id) &&
            <div className={`${BTN_CLASSES.GROUP} flex`}>
                {admin && admin.published && (days.length === 0 || (days.length !== 0 && isCurrent(lastDate(days)))) &&
                <>
                    <Button buttonStyle={BTN_CLASSES.PRIMARY}
                            buttonSize={BTN_CLASSES.SMALL}
                            className={publishDate ? 'active' : null}
                            onClick={() => publishDate ? setShowPublicationModal(true) : admin.published(id)}
                    >
                        <FontAwesomeIcon icon={faPowerOff} fixedWidth/>
                    </Button>

                    <Modal cancel={false}
                           show={showPublicationModal}
                           close={() => setShowPublicationModal(!showPublicationModal)}
                           title="Weet je het zeker?"
                           actions={[{
                               label: "Ja",
                               action: () => {
                                   admin.published(id);
                                   setShowPublicationModal(false);
                               },
                               disabled: true
                           }, {
                               label: "Nee",
                               action: () => setShowPublicationModal(false),
                               buttonType: 'btn-secondary',
                               disabled: true
                           }]}>
                        Door op <i>Ja</i> te klikken, zal de publicatie ongedaan gemaakt worden. Alle uitgenodigden zullen hiervan op de hoogte gebracht worden.<br />
                        Weet je zeker dat je wil doorgaan?
                    </Modal>
                </>
                }

                {admin && admin.update &&
                <Link to={admin.update}
                      className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`}
                >
                    <FontAwesomeIcon icon={faEdit} fixedWidth/>
                </Link>
                }

                {admin && admin.delete &&
                <>
                    <Button buttonStyle={BTN_CLASSES.PRIMARY}
                            buttonSize={BTN_CLASSES.SMALL}
                            onClick={() => setShowRemoveModal(true)}
                    >
                        <FontAwesomeIcon icon={faTrash} fixedWidth/>
                    </Button>

                    <Modal cancel={false}
                           show={showRemoveModal}
                           close={() => setShowRemoveModal(!showRemoveModal)}
                           title="Weet je het zeker?"
                           actions={[{
                               label: "Ja",
                               action: () => {
                                   admin.delete(id);
                                   setShowRemoveModal(false);
                               },
                               disabled: true
                           }, {
                               label: "Nee",
                               action: () => setShowRemoveModal(false),
                               buttonType: 'btn-secondary',
                               disabled: true
                           }]}>
                        Door op <i>Ja</i> te klikken, zal dit item verwijderd worden. {publishDate ? 'Alle ingeschrevenen zullen hiervan op de hoogte gebracht worden en de inschrijvingen worden verwijderd. ' : ''}Deze actie kan niet ongedaan gemaakt worden.<br />
                        Weet je zeker dat je wil doorgaan?
                    </Modal>
                </>
                }

                {/*admin && admin.add && !admin.translated && (days.length === 0 || (days.length !== 0 && isCurrent(lastDate(days)))) &&
                <Link to={admin.add} className={`first ${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`}><FontAwesomeIcon icon={faClone} fixedWidth/></Link>
                */}

                {admin && admin.results && (hasForm || hasInvitations) &&
                <Link to={admin.results} className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`}>Inschrijvingen</Link>
                }

                {slug && <Link to={route + "/" + slug} className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL} ${!admin || Object.keys(admin).length === 1 ? 'single' : ''}`}><FormattedMessage id={"overviewItem.button.text"}/></Link>}
            </div>
            }
        </div>
    )
};


/**
 * DateTime Component
 * @param startDate
 * @param endDate
 * @returns {*}
 * @constructor
 */
const DateTime = ({startDate, endDate}) => {
    const {toLongDate, toTime} = DateUtility;

    /** Returns the date and time of en event or training */
    return (
        <div>
            <div className={"date"}>{toLongDate(startDate)}</div>
            <div className={"time"}><FontAwesomeIcon icon={faClock}/> {toTime(startDate)} {endDate && endDate !== startDate && ' - ' + toTime(endDate)}</div>
        </div>
    )
};


/**
 * DateTime Component
 * @param days
 * @returns {*}
 * @constructor
 */
const DateTimeArray = (days) => {
    const {toLongDate, toTime} = DateUtility;

    /** Returns a mapping of times */
    return (
        <div>
            {days.map((day, i) => {
                const {startDate, endDate} = day;

                return (
                    <div key={i} className={"date-array"}>
                        <div className={"date"}>{toLongDate(startDate)}</div>
                        <div className={"time"}><FontAwesomeIcon icon={faClock}/> {toTime(startDate)} {endDate && endDate !== startDate && ' - ' + toTime(endDate)}</div>
                    </div>
                )
            })}
        </div>
    )
};


export default injectIntl(OverviewItem);
import React, {Component} from 'react';
import Load from "../../utils/Loading/Load";
import ItemDetail from "../ItemDetail/ItemDetail";
import {deleteTraining, loadTraining, publishTraining} from "../../redux/actions/training.actions";
import {getMedia} from "../../redux/actions/media.actions";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";

/**
 * Training detail component
 */
class TrainingDetail extends Component {
    /**
     * Load training when none is loaded on component mount
     */
    componentDidMount() {
        const {loadTraining, match} = this.props;
        const {id} = match.params;
        loadTraining(id);
    }

    /**
     * Select the training and load its media is non is selected
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {selectedTraining} = this.props;

        document.title = `UniPartners group | Knowledge meets passion | ${selectedTraining ? selectedTraining.name : 'loading...'}`;

        if (selectedTraining && selectedTraining.media && selectedTraining.media.length > 0) {
            selectedTraining.media.map(obj => {
                const {getMedia} = this.props;
                getMedia(obj);
                return null;
            });
        }
    }

    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {isLoading, selectedTraining} = this.props;

        /** Return renderTraining when training is selected */
        return (
            <div>
                {isLoading
                    ? <Load/>
                    : (selectedTraining &&
                        this.renderTraining()
                    )
                }
            </div>
        )
    }


    /**
     * Component renderTraining method
     * @returns {*}
     */
    renderTraining() {
        const {role, user, permissions, selectedTraining, publishTraining, deleteTraining, intl} = this.props;
        const {id, name, slug, description, creator, publishedAt, location, days, coce, tags, media, attachments, links, instructor, translated, isAddedToCv, organisors, hasInvitations} = selectedTraining;

        const isAuthor = user.id === creator.id || organisors.find(x => x.id === user.id);
        const hasPermission = role.name === 'admin' || role.name === 'dev' || role.name === 'editor' || role.name === 'staff' || (role.name === 'coceco' && isAuthor);
        /** Set admin variables */
        const admin = {
            publish: hasPermission && permissions.includes("trainings_publish") && ((id) => publishTraining(id)),
            delete: hasPermission && permissions.includes("trainings_delete") && ((id) => deleteTraining(id, this.props.history)),
            add: hasPermission && permissions.includes("trainings_add") && ('/opleiding-toevoegen/' + id),
            update: hasPermission && permissions.includes("trainings_update") && ('/opleiding-wijzigen/' + id),
            results: hasPermission && permissions.includes("trainings_see_results") && (`/opleidingen/${slug}/inschrijvingen`),
            translated: translated
        };

        /** Return ItemDetail */
        return (
            <ItemDetail id={id}
                        title={name}
                        body={description}
                        media={media}
                        attachments={attachments}
                        links={links}
                        tags={tags}
                        coce={coce}
                        author={creator}
                        publishDate={publishedAt}
                        messages={intl.messages}
                        obj={selectedTraining}
                        objType={"trainings"}
                        admin={admin}
                        instructor={instructor}
                        isAddedToCv={isAddedToCv}
                        organisors={organisors}
                        days={days}
                        location={location}
                        hasForm={true}
                        hasInvitation={hasInvitations}
            />
        )
    }
}


const mapStateToProps = ({authReducer, trainingReducer, mediaReducer, attachmentReducer}) => {
    const {role, user, permissions} = authReducer;
    const {selectedTraining, isLoading} = trainingReducer;
    const {blobs} = mediaReducer;
    const {attachmentBlobs} = attachmentReducer;
    return {role, user, permissions, selectedTraining, isLoading, blobs, attachmentBlobs};
};


const mapDispatchToProps = {
    loadTraining, getMedia, publishTraining, deleteTraining
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TrainingDetail));
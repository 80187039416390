import React from "react";
import {PDFDownloadLink, Document, Page, Text, View, Image, Font, StyleSheet} from '@react-pdf/renderer';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {BTN_CLASSES} from "../../../css_constants";
import Logo from "../../../assets/images/logo-up.jpg";
import background from '../../../assets/images/cevora.jpg';
import DateUtility from "../../../utils/dateUtils";

/*Font.register({
    family: 'OpenSans', fonts: [
        {src: 'fonts/OpenSans-Light.ttf', fontWeight: 300},
        {src: 'fonts/OpenSans-LightItalic.ttf', fontWeight: 300, fontStyle: 'italic'},
        {src: 'fonts/OpenSans-Regular.ttf', fontWeight: 400},
        {src: 'fonts/OpenSans-Italic.ttf', fontWeight: 400, fontStyle: 'italic'},
        {src: 'fonts/OpenSans-SemiBold.ttf', fontWeight: 600},
        {src: 'fonts/OpenSans-SemiBoldItalic.ttf', fontWeight: 600, fontStyle: 'italic'},
        {src: 'fonts/OpenSans-Bold.ttf', fontWeight: 700},
        {src: 'fonts/OpenSans-BoldItalic.ttf', fontWeight: 700, fontStyle: 'italic'},
        {src: 'fonts/OpenSans-ExtraBold.ttf', fontWeight: 800},
        {src: 'fonts/OpenSans-ExtraBoldItalic.ttf', fontWeight: 800, fontStyle: 'italic'},
    ]
});*/

const styles = StyleSheet.create({
    page: {
        //fontFamily: 'OpenSans',
        fontSize: 12,
        fontWeight: 400
    },
    background: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
    },
    logo: {
        height: 50
    },
    section: {
        flexDirection: 'col',
        margin: 0,
        padding: 40,
        borderLeftWidth: 10,
        borderColor: '#AB182D',
        height: '100%'
    },
    header: {
        flexDirection: 'row',
        borderBottomWidth: 2,
        borderColor: '#9D9694',
        paddingBottom: 15
    },
    contact: {
        marginLeft: 120,
        color: '#9D9694',
    },



    tableHeader: {
        fontWeight: 700,
        borderBottomWidth: 2,
        fontSize: 11,
        marginTop:50,
        borderColor: '#9D9694',
        flexDirection: 'row'
    },
    tableRow: {
        borderBottomWidth: 1,
        fontSize: 10,
        borderColor: '#9D9694',
        flexDirection: 'row'
    },
    tableFooter: {
        fontWeight: 700,
        borderTopWidth: 2,
        fontSize: 11,
        borderColor: '#9D9694',
        flexDirection: 'row'
    }
});

const AttendingPdf = ({user, results, objectType, object}) => {
    const renderEvent = () => {
        return (
            <Page pageNumber={1} size="A4" orientation="portrait" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.header}>
                        <Image src={Logo} style={styles.logo}/>
                        <View style={styles.contact}>
                            <Text>UniPartners {user.department.name === 'IT' ? 'IT services' : (user.department.name === 'Finance' ? 'Finance' : 'Group')} NV</Text>
                            <Text>Kardinaal Cardijnstraat 7</Text>
                            <Text>2840 Terhagen</Text>
                        </View>
                    </View>

                    <View style={styles.tableHeader}>
                        <Text style={{flexGrow: 0, width: '50%'}}>Naam</Text>
                        <Text style={{flexGrow: 0, width: '50%'}}>Opmerking</Text>
                    </View>

                    {results.filter(x => x.user.confirmed === 1).map((result, i) => {
                        return (
                            <View key={i} style={styles.tableRow}>
                                <View style={{flexGrow: 0, width: '50%', flexDirection: 'row'}}>
                                    <Text style={{fontSize: 8}}>{`${result.user.lastName} ${result.user.firstName}`}</Text>
                                </View>
                                <Text style={{flexGrow: 0, width: '50%'}}/>
                            </View>
                        )
                    })}
                </View>
            </Page>
        );
    }

    const renderTraining = () => {
        let breaking = false;
        let breakNext = false;
        results = results.filter(x => x.user.confirmed === 1 && x.user.role !== 'instructor')

        let pageCount = Math.ceil(object.days.length / 5);
        let userPageCount = Math.ceil(results.length / 7);

        let returnObject = [];
        for (let userPage = 1; userPage <= userPageCount; userPage++) {
            for (let page = 1; page <= (pageCount ?? 1); page++) {

                let dayObject = [];
                for (let dayCount = 1; dayCount <= 5; dayCount++) {
                    const index = ((page - 1) * 5) + dayCount - 1
                    if (object.days[index]) {
                        dayObject.push(
                            <View style={{fontWeight: "500", marginLeft: (dayCount === 1 ? 28 : 29), paddingTop: 7}}>
                                <Text>{`van ${DateUtility.toTime(object.days[index].startDate)}u`}</Text>
                                <Text style={{marginTop: 4}}>{`tot ${DateUtility.toTime(object.days[index].endDate)}u`}</Text>
                            </View>
                        )
                    }
                }

                let userObject = [];
                for (let userCount = 1; userCount <= 7; userCount++) {
                    const index = ((userPage - 1) * 7) + userCount -1;
                    if (results[index]) {
                        userObject.push(
                            <View>
                                <View style={{flexDirection: 'row', marginTop: (pageCount === 1 ? 12 : 12.5)}}>
                                    <Text style={{width: 111}}>{`${results[index].user.firstName} ${results[index].user.lastName}`}</Text>
                                    <Text>{DateUtility.toShortDate(results[index].user.birthday)}</Text>
                                </View>
                            </View>
                        )
                    }
                }

                returnObject.push(
                    <View style={{width: '100%', height: '100%'}}>
                        <Image style={styles.background} src={background} fixed/>
                        <Text style={{position: 'absolute', marginLeft: 76.41, marginTop: 40.06, fontSize: 23.1, color: '#0092d2'}} fixed>AANWEZIGHEIDSLIJST {new Date().getFullYear()}</Text>

                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                            <View style={{marginTop: 92, marginLeft: 75, fontSize: 8, color: '#000'}} fixed>
                                <Text>Naam onderneming :</Text>
                                <Text style={{marginTop: 13}}>RSZ-nummer van de onderneming :</Text>
                                <View style={{marginTop: 13, flexDirection: 'row'}}>
                                    <Text>Titel van de opleiding :</Text>
                                    <Text style={{fontSize: 10, marginLeft: 20}}>{object.name}</Text>
                                </View>
                                <View style={{marginTop: 13, flexDirection: 'column'}}>
                                    <Text>Datum van de opleiding (iedere dag dient apart vermeld te worden) :</Text>
                                    <Text style={{fontSize: 10, marginTop: 4, flexWrap: 'wrap'}}>
                                        {object.days.map(({startDate}, dI) => <Text key={`d-${dI}`}>{DateUtility.toShortDate(startDate) + ',  '}</Text>)}
                                    </Text>
                                </View>
                            </View>

                            <View style={{fontSize: 9, color: '#000', fontWeight: "900", marginTop: 105, background: '#00ff00', marginRight: 95}} fixed>
                                <Text style={{paddingLeft: 7}}>Kopieer dit exemplaar !</Text>
                                <Text style={{marginTop: 5}}>Invullen in drukletters aub</Text>
                            </View>
                        </View>

                        <View style={{marginLeft:75, marginTop: 5, fontSize: 9, color: '#000'}}>
                            <View style={{flexDirection: 'row', fontWeight: "900", color: '#0092d2'}}>
                                <Text style={{marginLeft: 200}}>Dag {((page - 1) * 5) + 1}</Text>
                                <Text style={{marginLeft: 49}}>Dag {((page - 1) * 5) + 2}</Text>
                                <Text style={{marginLeft: 49}}>Dag {((page - 1) * 5) + 3}</Text>
                                <Text style={{marginLeft: 49}}>Dag {((page - 1) * 5) + 4}</Text>
                                <Text style={{marginLeft: 49}}>Dag {((page - 1) * 5) + 5}</Text>
                            </View>

                            <View style={{flexDirection: 'row'}}>
                                <View style={{fontWeight: "500", marginTop: 7}}>
                                    <Text>Naam + voornaam</Text>
                                    <Text style={{marginTop: 4}}>van de deelnemers</Text>
                                </View>
                                <Text style={{fontWeight: "500", marginLeft: 33, paddingTop: 21}}>Geboortedatum</Text>
                                {dayObject.map((t, tI) => <View key={`t-${tI}`}>{t}</View>)}
                                <View style={{position: 'absolute', fontSize: 9, color: '#000', fontWeight: "500", marginTop: 7, left: 569}}>
                                    <Text>Handtekening</Text>
                                    <Text style={{marginTop: 14}}>van de bediende</Text>
                                </View>
                            </View>
                        </View>

                        <View style={{height:176, fontSize: 9, color: '#000', marginLeft: 75}}>
                            {userObject.map((u, uI) => <View key={`u-${uI}`}>{u}</View>)}
                        </View>

                        <View style={{flexDirection: 'column',fontSize: 9, color: '#000', fontWeight: "500", marginLeft: 75}}>
                            <Text>
                                * Enkel indien afgeweken wordt van de vooropgestelde uren, dient de bediende het exact aantal gevolgde uren in te vullen.
                            </Text>
                            <View style={{flexDirection: 'row', fontWeight: "900", marginTop: 4}}>
                                <Text>Legende : </Text>
                                <View style={{flexDirection: 'column'}}>
                                    <Text>A = aanwezig</Text>
                                    <Text style={{marginTop: 4}}>NA = niet aanwezig</Text>
                                </View>
                            </View>
                            <Text style={{fontWeight: "900", marginTop: 4}}>
                                Iedere aanwezigheidslijst dient in de rechtse kolom door de betrokken bedienden ondertekend te worden.
                            </Text>

                            <Text style={{marginTop: 22}} fixed>De ondergetekende verklaart dat bovenstaande gegevens correct zijn ingevuld</Text>
                            <Text style={{marginTop: 4}} fixed>Voor waar en echt verklaard door ondergetekende</Text>

                            <View style={{flexDirection: 'column', marginTop: 12}}>
                                <Text>Naam :</Text>
                                <View style={{flexDirection: 'row', marginTop: 12}}>
                                    <Text>Functie :</Text>
                                    <Text style={{marginLeft: 170}}>Datum :</Text>
                                    <Text style={{marginLeft: 170}}>Handtekening vd persoon:</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                );
            }
        }

        return returnObject.map((x, i) => <Page key={i} orientation="landscape" size="A4" style={{width: '100%', height: '100%'}} wrap>{x}</Page>);
    }

    return (
        <div>
            <PDFDownloadLink document={(

                <Document>
                    {objectType === 'trainings'
                        ? renderTraining()
                        : renderEvent()
                    }
                </Document>

            )} className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`} fileName="aanwezigheidslijst.pdf">
                {({blob, url, loading, error}) => (loading ? <span><FontAwesomeIcon icon={faSpinner} spin fixedWidth/> deelnemers</span> : <span><FontAwesomeIcon icon={faDownload} fixedWidth/> deelnemers</span>)}
            </PDFDownloadLink>
        </div>
    )
}

export default AttendingPdf;
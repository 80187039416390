import React from 'react'
import './FormInput.scss'


const ProgressBar = ({steps, currentStep}) => {
    const items = [];
    const width = 100 / steps;

    for (let i = 1; i <= steps; i++) {
        items.push(<li key={i} className={i <= currentStep ? 'active' : ''} style={{width: `${width}%`}}/>)
    }

    return (
        <div className="wrapper-progressBar">
            <ul className="progressBar">
                {items.map(x => x)}
            </ul>
        </div>
    )
}


export default ProgressBar;
import {CLOSE_NAVBAR, TOGGLE_NAVBAR} from "../constants/view.constants";

let defaultState = {
    showNavbar: false
};

const toggleNavbar = (state) => {
    return {...state, showNavbar: !state.showNavbar}
};

const closeNavbar = (state) => (
    {...state, showNavbar: false}
);

const viewReducer = (state = defaultState, {type}) => {
    switch (type) {
        case TOGGLE_NAVBAR:
            return toggleNavbar(state);
        case CLOSE_NAVBAR:
            return closeNavbar(state);
        default:
            return state;
    }
};

export default viewReducer;

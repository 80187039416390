import React, {Component} from "react";
import {Col, Row} from "../../../utils/Grid/Grid";
import InstructorList from "./InstructorList";
import InstructorForm from "./InstructorForm";

class Instructors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedInstructor: null
        }
    }

    render() {
        const {selectedInstructor} = this.state;

        return (
            <Row>
                <Col lg={8}>
                    <InstructorForm selectedInstructor={selectedInstructor} unsetSelected={() => this.setState({selectedInstructor: null})}/>
                </Col>

                <Col lg={4} className="instructors-admin">
                    <InstructorList select={selectedInstructor => this.setState({selectedInstructor})}/>
                </Col>
            </Row>
        )
    }
}

export default Instructors;
import {ADD_ARTICLE, DELETE_ARTICLE, DELETE_ARTICLE_MEDIA, LOAD_ARTICLE, LOAD_NEWS, LOAD_RANDOM, PIN_ARTICLE, RELOAD_NEWS, UPDATE_ARTICLE} from "../constants/news.constrants";

let defaultState = {
    newsList: [],
    randomList: [],
    isLoading: false,
    hasMore: true,
    selectedArticle: null,
};


const loadNews = (state, {articles, isLoading}) => {
    const array = articles.map(article => ({
        ...article,
        isLoaded: false
    }));

    const newsList = [
        ...state.newsList,
        ...array
    ];

    const hasMore = articles ? !(articles.length === 0 || articles.length < 18) : true;
    return {...state, newsList, hasMore, isLoading};
};

const reloadNews = (state, {articles, isLoading}) => {
    const array = articles.map(article => ({
        ...article,
        isLoaded: false
    }));

    const newsList = [
        ...array
    ];

    const hasMore = articles ? !(articles.length === 0 || articles.length < 18) : true;
    return {...state, newsList, hasMore, isLoading};
};

const loadRandom = (state, {articles, isLoading}) => {
    return {...state, randomList: articles, isLoading}
}

const loadArticle = (state, {selectedArticle, isLoading}) => {
    let {newsList} = state;

    if (!isLoading) {
        newsList = newsList.map(x => {
            if (x.id !== selectedArticle.id) return x;
            return {
                ...selectedArticle,
                isLoaded: true,
            }
        });
    }

    return {...state, newsList, selectedArticle, isLoading};
};

const pinArticle = (state, {article}) => {
    const list = state.newsList.map(x => {
        if (x.id === article.id) return article;

        return x;
    });

    return {...state, newsList: list, selectedArticle: (state.selectedArticle ? article : state.selectedArticle)};
};


const addArticle = (state, {article}) => {
    const newsList = [article, ...state.newsList];

    return {...state, newsList};
};

const updateArticle = (state, {article}) => {
    const temp = state.newsList.map(x => {
        if (x.id !== article.id) return x;
        return article;
    });

    return {...state, newsList: temp, selectedArticle: article};
};

const deleteArticle = (state, {id}) => {
    const temp = state.newsList.filter(x => x.id !== id);

    return {state, newsList: temp, selectedArticle: null};
};

const deleteArticleMedia = (state, {id, type}) => {
    type = type === 'images' ? 'media' : type;
    const newsList = state.newsList.map(x => {
        if (x.id !== state.selectedArticle.id) return x;

        return {
            ...x,
            [type]: x[type].filter(y => y.id !== id)
        }
    });

    let media = state.selectedArticle[type];
    media = media.filter(x => x.id !== id);

    return {
        ...state,
        newsList: newsList,
        selectedArticle: {
            ...state.selectedArticle,
            [type]: media
        }
    };
};


const newsReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_NEWS:
            return loadNews(state, payload);
        case RELOAD_NEWS:
            return reloadNews(state, payload)
        case LOAD_ARTICLE:
            return loadArticle(state, payload);
        case LOAD_RANDOM:
            return loadRandom(state, payload);
        case PIN_ARTICLE:
            return pinArticle(state, payload);
        case ADD_ARTICLE:
            return addArticle(state, payload);
        case UPDATE_ARTICLE:
            return updateArticle(state, payload);
        case DELETE_ARTICLE:
            return deleteArticle(state, payload);
        case DELETE_ARTICLE_MEDIA:
            return deleteArticleMedia(state, payload);
        default:
            return state;
    }
};

export default newsReducer;
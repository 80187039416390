import React, {Component} from "react";
import {Col, Row} from "../../../utils/Grid/Grid";
import {BTN_CLASSES, BTN_TYPES} from "../../../css_constants";
import Button from "../../../utils/Buttons/Button";
import './CustomForm.scss';
import Form from "../../../utils/Forms/Form";
import Modal from "../../../utils/Modal/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCogs, faListUl, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {faListAlt} from "@fortawesome/free-regular-svg-icons";

class CustomForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: props.customForm,
            formValid: false,

            showModal: false,
            showAddModal: false,
            showSettings: false,
            showGroupModal: false,
            showFieldModal: false,

            settingsFormValid: false,
            settingsForm: {
                format: [
                    {
                        type: "col",
                        name: "group",
                        breakpoint: 12,
                        fields: ["name"],
                    }, {
                        type: "col",
                        name: "group",
                        breakpoint: 6,
                        fields: ["type"]
                    }, {
                        type: "col",
                        name: "group",
                        breakpoint: 6,
                        fields: ["size"]
                    }
                ],
                fields: [
                    {
                        type: "text",
                        name: "name",
                        label: "Formulier naam",
                        placeholder: "Geef de naam op van het formulier",
                        required: true,
                        value: props.customForm.formName
                    }, {
                        type: "select",
                        name: "type",
                        label: "Formulier type",
                        required: true,
                        options: [{value: "group", label: "Simpel formulier"}, {value: "col", label: "Raster formulier"}/*, {value: "step", label: "Stappen formulier"}*/],
                        value: {value: "group", label: "Simpel formulier"},
                        description: "Velden worden verwijderen"
                    }, {
                        type: "select",
                        name: "size",
                        label: "Formulier formaat",
                        required: true,
                        options: [{value: "small", label: "Klein formulier"}, {value: "medium", label: "Medium formulier"}, {value: "large", label: "Groot formulier"}],
                        value: {value: "medium", label: "Medium formulier"}
                    }
                ]
            },

            groupField: null,
            fieldsField: null,
            parentGroup: null,
            fieldFormValid: false
        }

        this.handleUpdateSettings = this.handleUpdateSettings.bind(this);
        this.handleUpdateGroup = this.handleUpdateGroup.bind(this);
        this.handleUpdateField = this.handleUpdateField.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleAddBaseElement = this.handleAddBaseElement.bind(this);
    }


    handleUpdateSettings() {
        const {settingsForm} = this.state;
        let format;

        switch (settingsForm.fields.find(x => x.name === "type").value.value) {
            case 'step':
                format = {
                    type: "step",
                    name: "step1",
                    fields: [],
                    options: []
                }
                break;
            case 'col':
                format = {
                    type: "col",
                    name: "column1",
                    breakpoint: 12,
                    fields: []
                }
                break;
            default:
                format = {
                    type: "group",
                    name: "group1",
                    direction: "horizontal",
                    fields: []
                }
                break;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                formName: settingsForm.fields.find(x => x.name === "name").value,
                formSize: settingsForm.fields.find(x => x.name === "size").value.value,
                format: prevState.form.format[0].type !== format.type ? [format] : prevState.form.format,
                fields: prevState.form.format[0].type !== format.type ? [] : prevState.form.fields,
            },

            settingsForm,
            showSettings: false
        }));
    }

    handleUpdateGroup(groupField, update) {
        switch (groupField.type) {
            case 'col':
                this.setState(prevState => ({
                    ...prevState,
                    form: {
                        ...prevState.form,
                        format: prevState.form.format.map(x => {
                            if (x.name !== groupField.name) return x;

                            return {
                                ...x,
                                breakpoint: update
                            }
                        })
                    },
                    groupField: groupField,
                    showGroupModal: groupField.breakpoint === update
                }));
                break;
            case 'step':
                break;
            default:
                this.setState(prevState => ({
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields: prevState.form.fields.map(x => {
                            if (x.name !== groupField.name) return x;

                            return {
                                ...x,
                                direction: update
                            }
                        })
                    },
                    groupField: groupField,
                    showGroupModal: groupField.direction === update
                }));
                break;
        }
    }

    handleUpdateField() {
        const fieldsField = this.state.fieldsField;

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                fields: prevState.form.fields.map(x => {
                    if ((x.type !== 'group' || x.type !== 'col' || x.type !== 'tab') && x.fields && x.fields.find(y => y === fieldsField.oldName)) {
                        const i = x.fields.findIndex(y => y === fieldsField.oldName)
                        let array = [...x.fields];
                        array[i] = fieldsField.name.value;

                        return {
                            ...x,
                            fields: array,
                        }
                    }

                    if (x.name !== fieldsField.oldName) return x;

                    return {
                        ...x,
                        type: fieldsField.type.value,
                        name: fieldsField.name.value,
                        label: fieldsField.label.value,
                        multiple: fieldsField.multiple.value === '' ? 0 : fieldsField.multiple.value,
                        options: fieldsField.options ? fieldsField.options.options : [],
                        min: fieldsField.min ? fieldsField.min.value : null,
                        max: fieldsField.max ? fieldsField.max.value : null,
                        step: fieldsField.step ? fieldsField.step.value : null,
                        placeholder: fieldsField.placeholder.value,
                        description: fieldsField.description.value,
                        required: fieldsField.required.value === '' ? 0 : fieldsField.required.value,
                    }
                }),
                format: prevState.form.format.map(x => {
                    if (x.fields.find(y => y === fieldsField.oldName)) {
                        const i = x.fields.findIndex(y => y === fieldsField.oldName)
                        let array = [...x.fields];
                        array[i] = fieldsField.name.value;

                        return {
                            ...x,
                            fields: array,
                        }
                    }

                    return x;
                })
            },
            fieldsField: null,
            showFieldModal: false
        }));
    }

    handleFieldChange(update) {
        this.setState(prevState => ({
            fieldsField: {
                oldName: prevState.fieldsField.oldName ?? update.fields.find(x => x.name === "name").value,

                type: {
                    value: update.fields.find(x => x.name === "type").value.value ?? update.fields.find(x => x.name === "type").value,
                    error: update.fields.find(x => x.name === "type").error,
                    touched: update.fields.find(x => x.name === "type").touched,
                    valid: update.fields.find(x => x.name === "type").valid
                },
                name: {
                    value: update.fields.find(x => x.name === "name").value,
                    error: update.fields.find(x => x.name === "name").error,
                    touched: update.fields.find(x => x.name === "name").touched,
                    valid: update.fields.find(x => x.name === "name").valid
                },
                label: {
                    value: update.fields.find(x => x.name === "label").value,
                    error: update.fields.find(x => x.name === "label").error,
                    touched: update.fields.find(x => x.name === "label").touched,
                    valid: update.fields.find(x => x.name === "label").valid
                },
                placeholder: {
                    value: update.fields.find(x => x.name === "placeholder").value,
                    error: update.fields.find(x => x.name === "placeholder").error,
                    touched: update.fields.find(x => x.name === "placeholder").touched,
                    valid: update.fields.find(x => x.name === "placeholder").valid
                },
                description: {
                    value: update.fields.find(x => x.name === "description").value,
                    error: update.fields.find(x => x.name === "description").error,
                    touched: update.fields.find(x => x.name === "description").touched,
                    valid: update.fields.find(x => x.name === "description").valid
                },
                required: {
                    value: update.fields.find(x => x.name === "required").value,
                    error: update.fields.find(x => x.name === "required").error,
                    touched: update.fields.find(x => x.name === "required").touched,
                    valid: update.fields.find(x => x.name === "required").valid
                },
                multiple: {
                    value: update.fields.find(x => x.name === "multiple") ? update.fields.find(x => x.name === "multiple").value : "",
                    error: update.fields.find(x => x.name === "multiple") ? update.fields.find(x => x.name === "multiple").error : "",
                    touched: update.fields.find(x => x.name === "multiple") ? update.fields.find(x => x.name === "multiple").touched : false,
                    valid: update.fields.find(x => x.name === "multiple") ? update.fields.find(x => x.name === "multiple").valid : true
                },
                options: {
                    value: update.fields.find(x => x.name === "options") ? (update.fields.find(x => x.name === "options").value ?? []) : [],
                    options: update.fields.find(x => x.name === "options") ? (update.fields.find(x => x.name === "options").value ?? []) : [],
                    error: update.fields.find(x => x.name === "options") ? update.fields.find(x => x.name === "options").error : "",
                    touched: update.fields.find(x => x.name === "options") ? update.fields.find(x => x.name === "options").touched : true,
                    valid: update.fields.find(x => x.name === "options") ? update.fields.find(x => x.name === "options").valid : true
                },
                min: {
                    value: update.fields.find(x => x.name === "min") ? (update.fields.find(x => x.name === "min").value ?? null) : null,
                    error: update.fields.find(x => x.name === "min") ? update.fields.find(x => x.name === "min").error : "",
                    touched: update.fields.find(x => x.name === "min") ? update.fields.find(x => x.name === "min").touched : true,
                    valid: update.fields.find(x => x.name === "min") ? update.fields.find(x => x.name === "min").valid : true
                },
                max: {
                    value: update.fields.find(x => x.name === "max") ? (update.fields.find(x => x.name === "max").value ?? null) : null,
                    error: update.fields.find(x => x.name === "max") ? update.fields.find(x => x.name === "max").error : "",
                    touched: update.fields.find(x => x.name === "max") ? update.fields.find(x => x.name === "max").touched : true,
                    valid: update.fields.find(x => x.name === "max") ? update.fields.find(x => x.name === "max").valid : true
                },
                step: {
                    value: update.fields.find(x => x.name === "step") ? (update.fields.find(x => x.name === "step").value ?? null) : null,
                    error: update.fields.find(x => x.name === "step") ? update.fields.find(x => x.name === "step").error : "",
                    touched: update.fields.find(x => x.name === "step") ? update.fields.find(x => x.name === "step").touched : true,
                    valid: update.fields.find(x => x.name === "step") ? update.fields.find(x => x.name === "step").valid : true
                }
            },
        }));
    }

    handleAddBaseElement(breakpoint) {
        const {format} = this.state.form;

        let element;
        switch (format[0].type) {
            case 'col':
                element = {
                    type: "col",
                    name: `column${format.length + 1}`,
                    breakpoint: breakpoint,
                    fields: [],
                }
                break;
            case 'step':
                element = {
                    type: "step",
                    name: `step${format.length + 1}`,
                    fields: [],
                    options: [],
                }
                break;
            default:
                break;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                format: [
                    ...prevState.form.format,
                    element
                ]
            }
        }));
    }

    handleAddGroup(parentGroup) {
        const {format, fields} = this.state.form;

        const newFormat = format.map(x => {
            if (x.name !== parentGroup.name) return x;

            return {
                ...x,
                fields: [
                    ...x.fields,
                    `${parentGroup.name}-${parentGroup.fields.length + 1}`
                ]
            }
        });

        let newFields = fields.map(x => {
            if (x.name !== parentGroup.name) return x;

            return {
                ...x,
                fields: [
                    ...x.fields,
                    `${parentGroup.name}-${parentGroup.fields.length + 1}`
                ]
            }
        });

        newFields.push({
            type: "group",
            name: `${parentGroup.name}-${parentGroup.fields.length + 1}`,
            direction: "horizontal",
            fields: [],
        });

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                format: newFormat,
                fields: newFields
            },
            parentGroup: null,
            showAddModal: false,
        }));
    }

    handleAddField(parentGroup) {
        const {format, fields} = this.state.form;

        const newFormat = format.map(x => {
            if (x.name !== parentGroup.name) return x;

            return {
                ...x,
                fields: [
                    ...x.fields,
                    `${parentGroup.name}-veld${parentGroup.fields.length + 1}`
                ]
            }
        });

        let newFields = fields.map(x => {
            if (x.name !== parentGroup.name) return x;

            return {
                ...x,
                fields: [
                    ...x.fields,
                    `${parentGroup.name}-veld${parentGroup.fields.length + 1}`
                ]
            }
        });

        newFields.push({
            type: "text",
            name: `${parentGroup.name}-veld${parentGroup.fields.length + 1}`,
            label: "Nieuw veld",
            placeholder: "Placeholder van je nieuwe veld",
            description: "Beschrijving van je nieuwe veld",
            required: 0,
        });

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                format: newFormat,
                fields: newFields
            },
            fieldsField: {
                type: "text",
                name: `${parentGroup.name}-veld${parentGroup.fields.length + 1}`,
                label: "Nieuw veld",
                placeholder: "Placeholder van je nieuwe veld",
                description: "Beschrijving van je nieuwe veld",
                required: false,
            },
            showFieldModal: true,
            parentGroup: null,
            showAddModal: false,
        }));
    }

    handleRemoveGroup(groupField) {
        const fields = this.state.form.fields.filter(x => {
            return groupField.fields.indexOf(x.name) === -1
        });

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                fields: fields.filter(x => x.name !== groupField.name).map(x => {
                    if (x.type !== 'group' && x.type !== 'step' && x.type !== 'col') return x;

                    return {
                        ...x,
                        fields: x.fields.filter(y => y !== groupField.name)
                    }
                }),
                format: prevState.form.format.map(x => {
                    return {
                        ...x,
                        fields: x.fields.filter(y => y !== groupField.name)
                    }
                })
            },
            showGroupModal: false,
            groupField: null
        }));
    }

    handleRemoveField() {
        const fieldsField = this.state.fieldsField;

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                fields: prevState.form.fields.filter(x => x.name !== fieldsField.oldName).map(x => {
                    if (x.type !== 'group' && x.type !== 'step' && x.type !== 'col') return x;

                    return {
                        ...x,
                        fields: x.fields.filter(y => y !== fieldsField.oldName)
                    }
                }),
                format: prevState.form.format.map(x => {
                    return {
                        ...x,
                        fields: x.fields.filter(y => y !== fieldsField.oldName)
                    }
                })
            },
            showFieldModal: false,
            fieldsField: null
        }));
    }

    render() {
        const {form} = this.state;
        const {formSize, formName, format} = form;

        let breakpoint = 12;
        if (format[0].type === 'col') {
            if (format.length > 1) {
                let temp = 0;
                format.map(x => temp = x.breakpoint !== 12 ? (temp + x.breakpoint >= 12 ? 0 : temp + x.breakpoint) : 0);
                breakpoint = 12 - temp;
            } else {
                breakpoint = format[format.length - 1].breakpoint && format[format.length - 1].breakpoint !== 12 ? 12 - format[format.length - 1].breakpoint : 12;
            }
        }

        return (
            <Row>
                <Col md={12} className="form-area">
                    <div className="work-area">
                        <div className="header">
                            <div><b>Formulier naam:</b> {formName}</div>
                            <div><b>Formulier type: </b>{format[0].type === "group" ? "Simpel formulier" : (format[0].type === "col" ? "Raster formulier" : "Stappen formulier")}</div>
                            <div><b>Formulier formaat:</b> {formSize === "small" ? "Klein formulier" : (formSize === "large" ? "Groot formulier" : "Medium formulier")}</div>
                            <Button buttonSize={BTN_CLASSES.SMALL} buttonStyle={BTN_CLASSES.SECONDARY} label={<FontAwesomeIcon icon={faCogs}/>} onClick={() => this.setState(prevState => ({showSettings: !prevState.showSettings}))}/>
                        </div>

                        <Row className="content">
                            {format.map((el, i) => (
                                this.renderField(i, el)
                            ))}

                            {format[0].type !== 'group' &&
                            <Col lg={breakpoint}>
                                <Button type={BTN_TYPES.BUTTON}
                                        buttonStyle={BTN_CLASSES.PRIMARY}
                                        block={true}
                                        label={<FontAwesomeIcon icon={faPlusCircle}/>}
                                        onClick={() => this.handleAddBaseElement(breakpoint)}
                                />
                            </Col>
                            }
                        </Row>
                    </div>
                </Col>

                <Col md={12} style={{display: "flex", justifyContent: "space-between"}}>
                    {this.renderPreview()}

                    {this.renderButtons()}
                </Col>

                {this.renderSettingsModal()}
                {this.renderGroupModal()}
                {this.renderFieldModal()}
                {this.renderAddModal()}

            </Row>
        )
    }

    renderButtons() {
        const {form} = this.state;

        return (
            <div className={BTN_CLASSES.GROUP}>
                <Button buttonStyle={BTN_CLASSES.SECONDARY}
                        buttonSize={BTN_CLASSES.SMALL}
                        type={BTN_TYPES.BUTTON}
                        onClick={() => this.props.handleForm(null)}
                        label="Annuleren"/>

                <Button buttonStyle={BTN_CLASSES.PRIMARY}
                        buttonSize={BTN_CLASSES.SMALL}
                        type={BTN_TYPES.BUTTON}
                        onClick={() => this.props.handleForm(form)}
                        label="Bewaar formulier"/>
            </div>
        )
    }

    renderPreview() {
        const {form, showModal} = this.state;

        return (
            <div className={BTN_CLASSES.GROUP}>
                <Button buttonStyle={BTN_CLASSES.SECONDARY}
                        buttonSize={BTN_CLASSES.SMALL}
                        type={BTN_TYPES.BUTTON}
                        onClick={() => this.setState({showModal: true})}
                        label="Preview formulier"/>

                <Modal show={showModal}
                       close={() => this.setState({showModal: false})}
                       title={form.formName}
                       medium={form.formSize === "medium"}
                       large={form.formSize === "large"}
                >
                    {form && form.format.length !== 0 && form.format[0].type === 'col'
                        ? <Row><Form form={form} formValid={(bool) => this.setState({formValid: bool})} updateFields={(form) => this.setState({form: form})}/></Row>
                        : <Form form={form} formValid={(bool) => this.setState({formValid: bool})} updateFields={(form) => this.setState({form: form})}/>}
                </Modal>
            </div>
        )
    }

    renderField(i, field, element) {
        let breakpoint = element && element.type === 'group' && element.direction === 'vertical' ? element.fields.length > 3 ? 4 : 12 / element.fields.length : 12;
        breakpoint = field.type === 'col' ? field.breakpoint : breakpoint;
        const type = field.type === 'group' || field.type === 'col' || field.type === 'step' ? 'group' : 'field';

        return (
            <Col key={i} lg={breakpoint} className={type}>
                <div className="element-wrapper">
                    <div className="header">
                        <div><b>{field.type === 'group' || field.type === 'step' || field.type === 'col' ? 'Groep' : 'Veld'} naam:</b> {field.name}</div>
                        <div><b>{field.type === 'group' || field.type === 'step' || field.type === 'col' ? 'Groep' : 'Veld'} type:</b> {field.type}</div>
                        {field.direction ? <div><b>Richting:</b> {field.direction} </div> : null}
                        {field.breakpoint ? <div><b>Breedte:</b> {field.breakpoint} </div> : null}
                        {field.type === 'tab' ? <div><b>Stap:</b> {this.state.form.format.findIndex(x => x === field) + 1} </div> : null}

                        {(field.type !== "group" || (field.type === "group" && i !== 0)) &&
                        <Button buttonSize={BTN_CLASSES.SMALL}
                                buttonStyle={BTN_CLASSES.SECONDARY}
                                label={<FontAwesomeIcon icon={faCogs}/>}
                                onClick={
                                    type === "group"
                                        ? () => this.setState(prevState => ({showGroupModal: !prevState.showGroupModal, groupField: field}))
                                        : () => this.setState(prevState => ({showFieldModal: !prevState.showFieldModal, fieldsField: field}))
                                }
                        />
                        }
                    </div>

                    <Row className="content">
                        <Col lg={12}>{field.label} {field.required ? <code>*</code> : null}</Col>
                        {field.fields && field.fields.map((x, a) => this.renderField(`${i}-${a}`, this.state.form.fields.find(field => field.name === x), field))}

                        {type === 'group' &&
                        <Col lg={12}>
                            <Button type={BTN_TYPES.BUTTON}
                                    buttonStyle={BTN_CLASSES.PRIMARY}
                                    block={true}
                                    label={<FontAwesomeIcon icon={faPlusCircle}/>}
                                    onClick={() => this.setState(prevState => ({showAddModal: !prevState.showAddModal, parentGroup: field}))}
                            />
                        </Col>
                        }
                    </Row>
                </div>
            </Col>
        )
    }

    renderSettingsModal() {
        const {showSettings, settingsFormValid, settingsForm} = this.state;

        return (
            <Modal show={showSettings}
                   close={() => this.setState({showSettings: false})}
                   title="Formulier instellingen"
                   actions={[{
                       label: "Bewaar",
                       action: this.handleUpdateSettings,
                       disabled: settingsFormValid
                   }]}
            >
                <Row>
                    <Form form={settingsForm}
                          formValid={settingsFormValid => this.setState({settingsFormValid})}
                          updateFields={settingsForm => this.setState({settingsForm})}
                    />
                </Row>
            </Modal>
        )
    }

    renderGroupModal() {
        const {showGroupModal, groupField} = this.state;
        let label,
            value,
            options;

        if (!groupField) return null;

        switch (groupField.type) {
            case 'col':
                label = "Breedte";
                options = [{value: 4, label: "4/12 blokken"}, {value: 6, label: "6/12 blokken"}, {value: 8, label: "8/12 blokken"}, {value: 12, label: "12/12 blokken"}];
                value = {value: groupField.breakpoint, label: groupField.breakpoint === 4 ? '4/12 blokken' : (groupField.breakpoint === 6 ? '6/12 blokken' : (groupField.breakpoint === 8 ? '8/12 blokken' : '12/12 blokken'))}
                break;
            case 'step':
                label = "Overslaan";
                break;
            default:
                label = "Richting";
                options = [{value: "horizontal", label: "Horizontaal"}, {value: "vertical", label: "Verticaal"}];
                value = {value: groupField.direction, label: groupField.direction === "horizontal" ? "Horizontaal" : "Verticaal"}
                break;
        }

        const form = {
            format: [
                {
                    type: "group",
                    name: "group",
                    direction: "horizontal",
                    fields: ["type"],
                }
            ],
            fields: [
                {
                    type: "select",
                    name: "type",
                    label: label,
                    required: true,
                    options: options,
                    value: value,
                }
            ]
        }

        return (
            <Modal show={showGroupModal}
                   close={() => this.setState({showGroupModal: false, groupField: null})}
                   title="Group instellingen"
                   actions={[{
                       label: "Verwijder",
                       className: "btn-danger",
                       action: () => this.handleRemoveGroup(groupField),
                       disabled: true
                   }]}
            >
                <Form form={form}
                      formValid={() => ""}
                      updateFields={field => this.handleUpdateGroup(groupField, field.fields[0].value.value)}
                />
            </Modal>
        )
    }

    renderFieldModal() {
        const {showFieldModal, fieldsField, fieldFormValid} = this.state;
        let format = [];

        if (!fieldsField) return null;

        const fieldOptions = [
            {value: "text", label: "Tekst veld"},
            {value: "email", label: "E-mail veld"},
            {value: "number", label: "Nummer veld"},
            {value: "textarea", label: "Tekst gebied"},
            {value: "select", label: "Select box"},
            {value: "radio", label: "keuzerondje"},
            {value: "checkbox", label: "Selectievinkje"},
        ]

        switch (fieldsField.type.value) {
            case 'select':
                format = [
                    {
                        type: "col",
                        name: "col1",
                        breakpoint: "12",
                        fields: ["type"],
                    }, {
                        type: "col",
                        name: "col2",
                        breakpoint: "6",
                        fields: ["label", "name"],
                    }, {
                        type: "col",
                        name: "col3",
                        breakpoint: "6",
                        fields: ["placeholder", "description"],
                    }, {
                        type: "col",
                        name: "col4",
                        breakpoint: "6",
                        fields: ["required"],
                    }, {
                        type: "col",
                        name: "col5",
                        breakpoint: "6",
                        fields: ["multiple"],
                    }, {
                        type: "col",
                        name: "col6",
                        breakpoint: "12",
                        fields: ["options"],
                    }
                ];
                break;
            case 'radio':
                format = [
                    {
                        type: "col",
                        name: "col1",
                        breakpoint: "12",
                        fields: ["type"],
                    }, {
                        type: "col",
                        name: "col2",
                        breakpoint: "6",
                        fields: ["label", "required"],
                    }, {
                        type: "col",
                        name: "col3",
                        breakpoint: "6",
                        fields: ["name", "description"],
                    }, {
                        type: "col",
                        name: "col4",
                        breakpoint: "12",
                        fields: ["options"],
                    }
                ];
                break;
            case 'checkbox':
                format = [
                    {
                        type: "col",
                        name: "col1",
                        breakpoint: "12",
                        fields: ["type"],
                    }, {
                        type: "col",
                        name: "col2",
                        breakpoint: "6",
                        fields: ["label"],
                    }, {
                        type: "col",
                        name: "col3",
                        breakpoint: "6",
                        fields: ["name"],
                    }, {
                        type: "col",
                        name: "col4",
                        breakpoint: "6",
                        fields: ["required"],
                    }
                ];
                break;
            case 'number':
                format = [
                    {
                        type: "col",
                        name: "col1",
                        breakpoint: "12",
                        fields: ["type"],
                    }, {
                        type: "col",
                        name: "col2",
                        breakpoint: "6",
                        fields: ["label", "name"],
                    }, {
                        type: "col",
                        name: "col3",
                        breakpoint: "6",
                        fields: ["placeholder", "description"],
                    }, {
                        type: "col",
                        name: "col5",
                        breakpoint: "4",
                        fields: ["min"],
                    }, {
                        type: "col",
                        name: "col6",
                        breakpoint: "4",
                        fields: ["max"],
                    }, {
                        type: "col",
                        name: "col7",
                        breakpoint: "4",
                        fields: ["step"],
                    }, {
                        type: "col",
                        name: "col4",
                        breakpoint: "6",
                        fields: ["required"],
                    }
                ];
                break;
            default:
                format = [
                    {
                        type: "col",
                        name: "col1",
                        breakpoint: "12",
                        fields: ["type"],
                    }, {
                        type: "col",
                        name: "col2",
                        breakpoint: "6",
                        fields: ["label", "name"],
                    }, {
                        type: "col",
                        name: "col3",
                        breakpoint: "6",
                        fields: ["placeholder", "description"],
                    }, {
                        type: "col",
                        name: "col4",
                        breakpoint: "6",
                        fields: ["required"],
                    }
                ];
                break;
        }

        const fields = Object.keys(fieldsField).map(field => {
            if (field === "type") {
                return {
                    name: "type",
                    type: "select",
                    required: true,
                    multiple: false,
                    placeholder: "Selecteer het veld type",
                    label: "Veld type",
                    autocomplete: false,
                    description: null,
                    value: fieldOptions.find(x => x.value === (fieldsField.type.value ?? fieldsField.type)),
                    options: fieldOptions.map(x => ({value: x.value, label: x.label})),
                    touched: fieldsField.type.touched ?? false,
                    error: fieldsField.type.error ?? "",
                    valid: fieldsField.type.valid ?? false
                }
            }
            if (field === 'name') {
                return {
                    type: "text",
                    name: "name",
                    label: "Naam",
                    placeholder: "Vul de naam in",
                    description: "gebruik geen spacies of hoofdletters",
                    value: fieldsField.name.value ?? fieldsField.name,
                    required: true,
                    touched: fieldsField.name.touched ?? false,
                    error: fieldsField.name.error ?? "",
                    valid: fieldsField.name.valid ?? false
                }
            }
            if (field === 'label') {
                return {
                    type: "text",
                    name: "label",
                    label: "Label",
                    placeholder: "Vul het label in",
                    description: null,
                    value: fieldsField.label ? (fieldsField.label.value ?? fieldsField.label) : null,
                    required: true,
                    touched: fieldsField.label.touched ?? false,
                    error: fieldsField.label.error ?? "",
                    valid: fieldsField.label.valid ?? false
                }
            }
            if (field === 'description') {
                return {
                    type: "text",
                    name: "description",
                    label: "Beschrijving",
                    placeholder: "Vul de beschrijving in",
                    description: "Beschrijving onder het veld",
                    value: fieldsField.description.value ?? fieldsField.description,
                    required: false,
                    touched: fieldsField.description.touched ?? false,
                    error: fieldsField.description.error ?? "",
                    valid: fieldsField.description.valid ?? false
                }
            }
            if (field === 'placeholder') {
                return {
                    type: "text",
                    name: "placeholder",
                    label: "Placeholder",
                    placeholder: "Vul de placeholder in",
                    description: null,
                    value: fieldsField.placeholder.value ?? fieldsField.placeholder,
                    required: false,
                    touched: fieldsField.placeholder.touched ?? false,
                    error: fieldsField.placeholder.error ?? "",
                    valid: fieldsField.placeholder.valid ?? false
                }
            }
            if (field === 'required') {
                return {
                    name: "required",
                    type: "checkbox",
                    required: false,
                    value: fieldsField.required.value ?? fieldsField.required,
                    label: "Het veld is verplicht in te vullen",
                    touched: fieldsField.required.touched ?? false,
                    error: fieldsField.required.error ?? "",
                    valid: fieldsField.required.valid ?? false
                }
            }
            if (field === 'multiple') {
                return {
                    name: "multiple",
                    type: "checkbox",
                    required: false,
                    value: fieldsField.multiple ? (fieldsField.multiple.value ?? fieldsField.multiple) : false,
                    label: "Meerdere opties zijn mogelijk",
                    touched: fieldsField.multiple ? (fieldsField.multiple.touched ?? false) : false,
                    error: fieldsField.multiple ? (fieldsField.multiple.error ?? "") : "",
                    valid: fieldsField.multiple ? (fieldsField.multiple.valid ?? false) : false
                }
            }
            if (field === 'options') {
                return {
                    name: "options",
                    type: "creatable",
                    required: fieldsField.type === 'select',
                    multiple: true,
                    placeholder: "Geef de opties van het veld",
                    label: "Opties",
                    autocomplete: false,
                    description: null,
                    value: fieldsField.options.options ? fieldsField.options.options : fieldsField.options,
                    options: fieldsField.options.options ? fieldsField.options.options : fieldsField.options,
                    touched: fieldsField.options.touched ? (fieldsField.options.touched ?? false) : false,
                    error: fieldsField.options.error ? (fieldsField.options.error ?? "") : "",
                    valid: fieldsField.options.valid ? (fieldsField.options.valid ?? false) : false
                }
            }
            if (field === 'min') {
                return {
                    name: "min",
                    type: "number",
                    min: 0,
                    step: 0.01,
                    required: fieldsField.type === 'min',
                    label: "Minimum",
                    autocomplete: false,
                    description: null,
                    value: fieldsField.min && fieldsField.min.value ? fieldsField.min.value : (fieldsField.min ?? null),
                    touched: fieldsField.min && fieldsField.min.touched ? (fieldsField.min.touched ?? false) : false,
                    error: fieldsField.min && fieldsField.min.error ? (fieldsField.min.error ?? "") : "",
                    valid: fieldsField.min && fieldsField.min.valid ? (fieldsField.min.valid ?? false) : false
                }
            }
            if (field === 'max') {
                return {
                    name: "max",
                    type: "number",
                    min: 0,
                    step: 0.01,
                    required: fieldsField.type === 'max',
                    label: "Maximum",
                    autocomplete: false,
                    description: null,
                    value: fieldsField.max && fieldsField.max.value ? fieldsField.max.value : (fieldsField.max ?? null),
                    touched: fieldsField.max && fieldsField.max.touched ? (fieldsField.max.touched ?? false) : false,
                    error: fieldsField.max && fieldsField.max.error ? (fieldsField.max.error ?? "") : "",
                    valid: fieldsField.max && fieldsField.max.valid ? (fieldsField.max.valid ?? false) : false
                }
            }
            if (field === 'step') {
                return {
                    name: "step",
                    type: "number",
                    min: 0,
                    step: 0.01,
                    required: fieldsField.type === 'step',
                    label: "Stap",
                    autocomplete: false,
                    description: null,
                    value: fieldsField.step && fieldsField.step.value ? fieldsField.step.value : (fieldsField.step ?? null),
                    touched: fieldsField.step && fieldsField.step.touched ? (fieldsField.step.touched ?? false) : false,
                    error: fieldsField.step && fieldsField.step.error ? (fieldsField.step.error ?? "") : "",
                    valid: fieldsField.step && fieldsField.step.valid ? (fieldsField.step.valid ?? false) : false
                }
            }

            return null;
        }).filter(x => x !== null);


        const form = {
            format: format,
            fields: fields
        }

        return (
            <Modal show={showFieldModal}
                   close={() => this.setState({showFieldModal: false, fieldsField: null})}
                   title="Veld instellingen"
                   actions={[{
                       label: "Verwijder",
                       className: "btn-danger",
                       action: () => this.handleRemoveField(),
                       disabled: true
                   }, {
                       label: "Bewaar",
                       action: () => this.handleUpdateField(),
                       disabled: fieldFormValid
                   }]}
            >
                <Row><Form form={form}
                           formValid={fieldFormValid => this.setState({fieldFormValid})}
                           updateFields={field => this.handleFieldChange(field)}
                /></Row>
            </Modal>
        )
    }

    renderAddModal() {
        const {showAddModal, parentGroup} = this.state;

        return (
            <Modal show={showAddModal}
                   close={() => this.setState({showAddModal: false, parentGroup: null})}
                   title="Veld of groep toevoegen"
            >
                <Row className="add_field">
                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.handleAddField(parentGroup)}
                        >
                            <FontAwesomeIcon icon={faListUl}/>
                            Veld aanmaken
                        </Button>
                    </div>

                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.handleAddGroup(parentGroup)}
                        >
                            <FontAwesomeIcon icon={faListAlt}/>
                            Groep aanmaken
                        </Button>
                    </div>
                </Row>
            </Modal>
        )
    }
}

export default CustomForm;
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import Carousel from "../../../utils/Carousel/Carousel";
import Stickers from "../../Stickers/Stickers";
import Comments from "../../Comments/Comments";

/**
 * Media main feed component
 */
const MediaMainFeedItem = ({id, title, elementMedia, route, stickers, comments, feedItem, highlightCommentId, ...props}) => {
    const {addStickers, addComments, getMedia, type, element} = props;

    useEffect(() => {
        addStickers(type, id);
        addComments(type, id);

        elementMedia.map(obj => {
            getMedia(obj);
            return null;
        });
    })

    return (
        <div className={`feed_item ${stickers ? 'with__stickers' : 'without__stickers'} ${comments ? 'with__comments' : 'without__comments'}`}>
            <div className={`feed__body without__read-more`}>
                <div className={`feed__content no-img`}>
                    <div className={"item__header"}>
                        <Link to={`${route}/${element.slug ? element.slug : element.id}`} className={"item__title"}>{title}</Link>
                    </div>
                    <div className={"item__body"}>
                        <Carousel objMedia={elementMedia}/>
                    </div>
                </div>
            </div>

            <Stickers stickers={stickers} addButton={true} obj={feedItem} objType={"mainfeed"}/>
            <Comments highlightCommentId={highlightCommentId} comments={comments} addButton={true} obj={feedItem} objType={"mainfeed"}/>

        </div>
    )
}


export default MediaMainFeedItem;
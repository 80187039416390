import React from 'react';
import './Button.scss'
import {BTN_CLASSES, BTN_TYPES} from "../../css_constants";

/**
 * Button component
 * @param type
 * @param label
 * @param buttonStyle
 * @param buttonSize
 * @param styles
 * @param block
 * @param className
 * @param disabled
 * @param onClick
 * @param children
 * @returns {*}
 * @constructor
 */
const Button = ({
                    type,
                    label,
                    buttonStyle,
                    buttonSize,
                    styles,
                    block,
                    className,
                    disabled,
                    onClick,
                    children
                }) => {
    let string = [BTN_CLASSES.BTN];
    buttonStyle && string.push(buttonStyle);
    buttonSize && string.push(buttonSize);
    block && string.push(BTN_CLASSES.BLOCK);
    className && string.push(className);

    /** Return a button */
    return (
        <button type={type}
                className={string.join(' ')}
                style={styles}
                disabled={disabled}
                onClick={onClick}>{label}{children}
        </button>
    );
};


Button.defaultProps = {
    type: BTN_TYPES.BUTTON, styles: {}, block: false, disabled: false
};


export default Button;

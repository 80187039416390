import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Modal.scss";
import * as ReactDOM from "react-dom";
import Button from "../Buttons/Button";
import { BTN_CLASSES } from "../../css_constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { injectIntl } from "react-intl";

/** Get modal root at index.html */
const modalRoot = document.getElementById("modal-root");

/**
 * Modal component
 */
class Modal extends Component {
  constructor(props) {
    super(props);

    this.el = document.createElement("div");
  }

  /**
   * Add div to modal root
   */
  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  /**
   * Remove div from modal root
   */
  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  /**
   * Component render method
   * @returns {{children: *, implementation: *, containerInfo: *, $$typeof: symbol | number, key: (null|string)}}
   */
  render() {
    /** Returns the modal to the modal root */
    return ReactDOM.createPortal(this.renderModal(), this.el);
  }

  renderPopOver(infoContent) {
    return (
      <div className={"wrapper"}>
        <FontAwesomeIcon size="2x" icon={faInfoCircle} />
        <div
          className={"pop-over"}
          style={{
            bottom: 60,
            right: 100,
          }}
        >
          <div className={"pop-over__arrow"}></div>
          <div className={"pop-over__body"}>{infoContent}</div>
        </div>
      </div>
    );
  }

  /**
   * Component renderModal method
   * @returns {null|*}
   */
  renderModal() {
    const {
      show,
      showInfo,
      infoContent,
      close,
      large,
      medium,
      children,
      title,
      className,
      style,
      actions,
      cancel,
      intl,
    } = this.props;
    const { messages } = intl;

    /** should modal be shown */
    if (!show) return null;

    /** Return the modal window */
    return (
      <div className={"modal"}>
        <div
          className={`modal__content${large ? " large" : ""}${
            medium ? " medium" : ""
          }${className ? ` ${className}` : ""}`}
          style={style}
        >
          <h2>{title ? title : "Modal window"}</h2>
          <div className={"modal__body"}>{children}</div>
          <div className={"modal__actions"}>
            {showInfo ? this.renderPopOver(infoContent) : ""}
            <div className={"btn-group"}>
              {cancel ? (
                <Button
                  buttonSize={BTN_CLASSES.SMALL}
                  buttonStyle={BTN_CLASSES.SECONDARY}
                  onClick={close}
                  label={messages["form.button.cancel"]}
                />
              ) : (
                ""
              )}

              {actions.map((x, i) => (
                <Button
                  key={i}
                  buttonSize={BTN_CLASSES.SMALL}
                  buttonStyle={x.buttonType ?? BTN_CLASSES.PRIMARY}
                  className={x.className}
                  onClick={x.action}
                  styles={x.styles}
                  label={x.label}
                  disabled={!x.disabled}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  close: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  cancel: PropTypes.bool,
};

Modal.defaultProps = {
  large: false,
  actions: [],
  cancel: true,
};

export default injectIntl(Modal);

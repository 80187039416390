import React, {Component} from "react";
import {Col, Row} from "../../../utils/Grid/Grid";
import {BTN_CLASSES} from "../../../css_constants";
import Button from "../../../utils/Buttons/Button";
import {FormattedMessage} from "react-intl";
import Form from "../../../utils/Forms/Form";
import {connect} from "react-redux";
import {postInstructor, updateInstructor} from "../../../redux/actions/instructor.actions";

const initialState = {
    selected: null,
    form: {
        format: [
            {
                type: "col",
                breakpoint: 6,
                fields: ["firstName"]
            }, {
                type: "col",
                breakpoint: 6,
                fields: ["lastName"]
            }, {
                type: "col",
                breakpoint: 12,
                fields: ["email"]
            }, {
                type: "col",
                breakpoint: 12,
                fields: ["invited"]
            }
        ],
        fields: [
            {
                name: "firstName",
                type: "text",
                required: true,
                placeholder: "",
                label: "Voornaam",
                autocomplete: false,
                description: null
            }, {
                name: "lastName",
                type: "text",
                required: true,
                placeholder: "",
                label: "Naam",
                autocomplete: false,
                description: null
            }, {
                name: "email",
                type: "email",
                required: false,
                placeholder: "",
                label: "E-mailadres",
                autocomplete: false,
                description: null
            }, {
                name: "invited",
                type: "checkbox",
                required: false,
                label: "Heeft toegang tot het CoCe-Center voor lesgevers",
            }
        ]
    },
    formValid: false
}

class InstructorForm extends Component {
    constructor(props) {
        super(props);

        //TODO: invited in 2 states, require email...
        this.state = {
            selected: null,
            form: {
                format: [
                    {
                        type: "col",
                        breakpoint: 6,
                        fields: ["firstName"]
                    }, {
                        type: "col",
                        breakpoint: 6,
                        fields: ["lastName"]
                    }, {
                        type: "col",
                        breakpoint: 12,
                        fields: ["email"]
                    }, {
                        type: "col",
                        breakpoint: 12,
                        fields: ["invited"]
                    }
                ],
                fields: [
                    {
                        name: "firstName",
                        type: "text",
                        required: true,
                        placeholder: "",
                        label: "Voornaam",
                        autocomplete: false,
                        description: null
                    }, {
                        name: "lastName",
                        type: "text",
                        required: true,
                        placeholder: "",
                        label: "Naam",
                        autocomplete: false,
                        description: null
                    }, {
                        name: "email",
                        type: "email",
                        required: false,
                        placeholder: "",
                        label: "E-mailadres",
                        autocomplete: false,
                        description: null
                    }, {
                        name: "invited",
                        type: "checkbox",
                        required: false,
                        label: "Heeft toegang tot de coce-tool",
                    }
                ]
            },
            formValid: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedInstructor} = this.props;
        const {selected} = this.state;

        if (selectedInstructor && (!selected || selected !== selectedInstructor)) {
            this.setState({selected: selectedInstructor})
            Object.keys(selectedInstructor).map(name => {
                this.setState(prevState => ({
                    form: {
                        ...prevState.form,
                        fields: prevState.form.fields.map(x => {
                            if (x.name !== name) return x;

                            return {
                                ...x,
                                value: selectedInstructor[name],
                                valid: true,
                                error: ''
                            }
                        })
                    }
                }))
                return null;
            });
        }
    }

    handleSubmit() {
        const {postInstructor, updateInstructor} = this.props;
        const {form, selected} = this.state;

        const data = {
            firstName: form.fields.find(x => x.name === 'firstName').value,
            lastName: form.fields.find(x => x.name === 'lastName').value,
            email: form.fields.find(x => x.name === 'email').value,
            invited: form.fields.find(x => x.name === 'invited').value === '1',
        }

        if (selected) {
            updateInstructor(data, selected.id);
        } else {
            postInstructor(data);
        }

        this.handleCancel();
    }

    handleCancel() {
        this.setState({...initialState}, this.props.unsetSelected());
    }

    render() {
        return (
            <div>
                <h1>Lesgevers</h1>

                {this.renderForm()}
                {this.renderButtons()}
            </div>
        )
    }

    renderForm() {
        const {form} = this.state;

        return (
            <Row>
                <Form form={form}
                      formValid={(bool) => this.setState({formValid: bool})}
                      updateFields={(form) => this.setState({form: form})}
                />
            </Row>
        )
    }

    renderButtons() {
        const {formValid, selected} = this.state;

        /** Return the form buttons */
        return (
            <Col md={12} className={"right"}>
                <div className={BTN_CLASSES.GROUP}>
                    {selected &&
                    <Button buttonStyle={BTN_CLASSES.SECONDARY}
                            onClick={this.handleCancel}
                    >
                        <FormattedMessage id="form.button.cancel"/>
                    </Button>
                    }

                    <Button buttonStyle={BTN_CLASSES.PRIMARY}
                            onClick={this.handleSubmit}
                            disabled={!formValid}
                    >
                        <FormattedMessage id="form.button.save"/>
                    </Button>
                </div>
            </Col>
        )
    }
}

export default connect(null, {postInstructor, updateInstructor})(InstructorForm);
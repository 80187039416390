import React from 'react';
import './FormInput.scss'
import PropTypes from "prop-types";

/**
 * Checkbox component
 * @param index
 * @param name
 * @param label
 * @param description
 * @param checked
 * @param valid
 * @param onClick
 * @param onChange
 * @param onBlur
 * @param error
 * @param className
 * @param required
 * @param disabled
 * @param value
 * @param children
 * @returns {*}
 * @constructor
 */
const FormCheckbox = ({index, name, label, description, checked, valid, onClick, onChange, onBlur, error, className, required, disabled, value, children}) => (
    <div className={'form-group'}>
        <div className={`checkbox ${className} ${(valid === '' ? '' : (valid ? 'is-valid' : 'is-invalid'))}`}>
            <input type={"checkbox"}
                   name={name}
                   id={`${name}${index}`}
                   onBlur={onBlur}
                   checked={checked}
                   onClick={onClick}
                   onChange={onChange}
                   value={value}
                   disabled={disabled}
            />

                <label htmlFor={`${name}${index}`}>{label} {required ? <code>*</code> : null}</label>
            </div>
            <div className="description">{description}</div>
            <small className="invalid-feedback">{error}</small>

        {children}
    </div>
);


FormCheckbox.defaultProps = {
    label: '', checked: '', error: '', valid: '', required: false, disabled: false, className: '', value: '1'
};


FormCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};


export default FormCheckbox;
import React, {Component} from "react";
import {connect} from "react-redux";
import {deleteArticle, loadArticle, loadNews, loadRandom, pinArticle, publishArticle} from "../../redux/actions/news.actions";
import {getMedia} from "../../redux/actions/media.actions";
import Load from "../../utils/Loading/Load";
import ItemDetail from "../ItemDetail/ItemDetail";
import {Link} from "react-router-dom";
import {Col, Row} from "../../utils/Grid/Grid";
import {FormattedMessage, injectIntl} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import EmptyList from "../EmptyList/EmptyList";

/**
 * News article detail component
 */
class newsDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            randomTag: null
        }

        this.selectRandomArticles = this.selectRandomArticles.bind(this);
    }

    /**
     * Load news articles when none is loaded on component mount
     */
    componentDidMount() {
        const {loadArticle, match} = this.props;
        const {slug} = match.params;
        loadArticle(slug);
    }


    /**
     * Select the article and load its media is non is selected
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {loadArticle, selectedArticle, match} = this.props;
        const {randomTag} = this.state;
        const {slug} = match.params;

        document.title = `UniPartners Group | Close UP | ${selectedArticle ? selectedArticle.title : 'loading...'}`;

        if (selectedArticle && selectedArticle.slug !== slug) {
            loadArticle(slug);
        } else {
            if (selectedArticle) {
                if (!randomTag) {
                    this.selectRandomArticles()
                }

                if (selectedArticle.media && selectedArticle.media.length > 0) {
                    selectedArticle.media.map(obj => {
                        const {getMedia} = this.props;
                        return getMedia(obj);
                    });
                }
            }
        }
    }

    /**
     * Select a maximum of 3 articles with one of the same tags
     * @returns {null|{tag: *, articles: *[]}}
     */
    selectRandomArticles() {
        const {loadRandom, selectedArticle} = this.props;

        const randomTags = selectedArticle.tags;
        const randomTag = randomTags[Math.floor(Math.random() * randomTags.length)];


        // TODO RANDOM TAGS GEVEN FOUT BIJ %20 IN URL
        if (!randomTag) return null;
        loadRandom(randomTag)

        this.setState({randomTag});
    }

    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {isLoading, randomList, selectedArticle, intl} = this.props;
        const {messages} = intl;

        /** Return renderArticle when article is selected and render renderRandom */
        return (
            <div>
                {
                    isLoading
                        ? <Load/>
                        : (selectedArticle
                            ? this.renderArticle()
                            : (
                                <div>
                                    <div className={"right"}>
                                        <Link to={messages["routes.news"]}><FontAwesomeIcon icon={faLongArrowAltLeft}/> <FormattedMessage id={"General.backToOverview"}/></Link>
                                    </div>
                                    <div><EmptyList id={"404.news"}/></div>
                                </div>
                            )
                        )

                }

                {selectedArticle && randomList.length > 0 &&
                this.renderRandom()
                }
            </div>
        );
    };


    /**
     * Component renderRandom method
     * @returns {null|*}
     */
    renderRandom() {
        const {randomList} = this.props;
        const {randomTag} = this.state;

        /** Return null when no articles are selected */
        if (randomList.length === 0) return null;

        randomList.map(article => {
            const {getMedia} = this.props;
            if (article.media && article.media.length > 0)
                getMedia(article.media[0]);

            return null;
        });


        /** Return overview of random articles */
        return (
            <div className={"related-articles"}>
                <FormattedMessage id={"NewsDetail.randomTag"} values={{name: randomTag}}>{txt => <h3>{txt}</h3>}</FormattedMessage>

                <Row>
                    {randomList.map((article, i) => {
                        const {media, intl} = this.props;
                        const {messages} = intl;
                        const {title, slug} = article;

                        let image = null;
                        if (article.media && article.media.length > 0) {
                            const mediaId = article.media[0].id;
                            image = media.find(x => x.id === mediaId);
                        }

                        return (
                            <Col key={i} sm={i === 2 ? 12 : 6} md={4} className={"related-article"}>
                                <Link to={messages["routes.news"] + "/" + slug}>
                                    {image && image.file &&
                                    <div className={"img-wrapper"}>
                                        <img src={image.file} alt={image.description} className={"img"} onError={this.addDefaultSrc}/>
                                    </div>
                                    }
                                    <div className={(image && image.file ? '' : 'no-img ') + "title"}>
                                        <h2 className={image && image.file ? '' : 'no-img'}>{title}</h2>
                                    </div>
                                </Link>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        )
    }


    /**
     * Component renderArticle method
     * @returns {*}
     */
    renderArticle() {
        const {role, permissions, selectedArticle, intl, publishArticle, deleteArticle, pinArticle} = this.props;
        const {id, title, slug, body, author, tags, media, attachments, links, publishedAt, pinned, translated, hasForm} = selectedArticle;

        const hasPermission = role.name === 'admin' || role.name === 'dev' || role.name === 'editor' || role.name === 'staff';
        /** Set admin variables */
        const admin = {
            publish: hasPermission && permissions.includes("news_publish") && ((id) => publishArticle(id)),
            delete: hasPermission && permissions.includes("news_delete") && ((id) => deleteArticle(id, this.props.history)),
            add: hasPermission && permissions.includes("news_add") && ('/artikel-toevoegen/' + id),
            update: hasPermission && permissions.includes("news_update") && ('/artikel-wijzigen/' + id),
            results: hasPermission && permissions.includes("news_see_results") && (`/nieuws/${slug}/resultaten`),
            translated: translated
        };


        /** Return ItemDetail */
        return (
            <ItemDetail id={id}
                        title={title}
                        pinned={pinned}
                        pinArticle={pinArticle}
                        body={body}
                        media={media}
                        attachments={attachments}
                        links={links}
                        tags={tags}
                        author={author}
                        publishDate={publishedAt}
                        messages={intl.messages}
                        obj={selectedArticle}
                        objType={"news"}
                        admin={admin}
                        hasForm={hasForm}
            />
        )
    }
}


const mapStateToProps = ({authReducer, newsReducer, tagReducer, mediaReducer}) => {
    const {user, role, permissions} = authReducer;
    const {selectedArticle, randomList, isLoading} = newsReducer;
    const {tags} = tagReducer;
    const {media} = mediaReducer;
    return {user, role, permissions, isLoading, randomList, tags, selectedArticle, media};
};


const mapDispatchToProps = {
    loadArticle, loadNews, getMedia, publishArticle, deleteArticle, pinArticle, loadRandom
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(newsDetail));
import {DELETE_DOCUMENT_BLOB, LOAD_DOCUMENT_BLOB, LOAD_DOCUMENTS} from "../constants/document.constants";

let defaultState = {
    documentCategories: [],
    documents: [],
    documentBlobs: []
};

const loadDocuments = (state, {documents}) => {
    const categories = documents.map(document => document.category);
    return ({...state, documents, documentCategories: Array.from(new Set(categories))});
}

const loadBlob = (state, {attachment, url}) => {
    const search = state.documentBlobs.find(x => x.rel === url);

    let temp = [];
    if (search) {
        temp = state.documentBlobs.map(x => {
            if (x.rel === url) return {rel: url, ...attachment, loading: !attachment};
            return x;
        });
    } else {
        temp = [
            ...state.documentBlobs,
            {
                rel: url,
                ...attachment,
                loading: !attachment
            }
        ];
    }

    return ({...state, documentBlobs: temp})
};


const deleteBlob = (state, {url}) => {
    let arr = state.documentBlobs.slice();
    const index = arr.indexOf(state.documentBlobs.find(x => x.rel === url));
    arr.splice(index, 1);

    return { ...state, documentBlobs: arr };
};


const documentReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_DOCUMENTS:
            return loadDocuments(state, payload);
        case LOAD_DOCUMENT_BLOB:
            return loadBlob(state, payload);
        case DELETE_DOCUMENT_BLOB:
            return deleteBlob(state, payload);
        default:
            return state;
    }
};

export default documentReducer;

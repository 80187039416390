import React from 'react';
import './FormInput.scss'
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {DefaultModifiers} from '../datepicker-settings';
import 'moment/locale/nl';
import 'moment/locale/fr';
import MomentLocaleUtils from 'react-day-picker/moment';

/**
 * Date picker component
 * @param name
 * @param label
 * @param value
 * @param valid
 * @param onChange
 * @param onBlur
 * @param error
 * @param className
 * @param required
 * @param disabledDays
 * @param children
 * @param locale
 * @param intl
 * @returns {*}
 * @constructor
 */
const FormDatepicker = ({name, label, value, valid, onChange, onBlur, error, className, required, disabledDays, children, locale, intl}) => (
    <div className={'form-group ' + className}>
        {label && <div><label htmlFor={name}>{typeof label === 'object' ? intl.formatMessage({id: label.label}, label.value) : label} {required ? <code>*</code> : null}</label></div>}

        <DayPicker localeUtils={MomentLocaleUtils}
                   locale={locale}
                   modifiers={DefaultModifiers}
                   selectedDays={value.map(({day}) => new Date(day))}
                   onDayClick={(date, modifiers) => {
                       onChange({target: {name}}, date, modifiers)
                   }}
                   disabledDays={disabledDays}
        />

        {valid &&
        <div className="invalid-feedback">{error}</div>
        }

        {children}
    </div>
);


FormDatepicker.defaultProps = {
    label: '', value: '', error: '', valid: '', required: false, className: '', disabledDays: null, locale: 'nl'
};


FormDatepicker.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};


export default injectIntl(FormDatepicker);
import React, {Component, createRef} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationTriangle, faBell, faUser, faSignOutAlt} from '@fortawesome/free-solid-svg-icons'
import {Link, NavLink} from "react-router-dom";
import {BTN_CLASSES, BTN_TYPES, SHARED_CLASSES} from "../../../css_constants";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import NotificationDropdown from "./NotificationDropdown";
import Button from "../../../utils/Buttons/Button";
import AlertDropdown from "./AlertDropdown";

/**
 * Top nav bar component
 * @param props
 * @returns {*}
 * @constructor
 */
class TopBar extends Component {
    constructor(props) {
        super(props);

        this.notificationRef = createRef();
        this.alertRef = createRef();

        this.handleDropdown = this.handleDropdown.bind(this);
    }

    handleDropdown(ref) {
        ref.current.handleDropdown();
    }

    render() {
        const {user, notificationList, updateNotifications, logout, intl} = this.props;
        const {messages} = intl;

        let alerts = [];

        if (user) {
            alerts = user.alerts;
        }

        const filteredNotificationList = notificationList.filter(notification => !notification.seen);

        /** Returns the user navigation links */
        return (
            <ul className={"navbar iconbar"}>

                <li className={SHARED_CLASSES.NAV_ITEM}>
                    <Button buttonStyle={BTN_CLASSES.LINK}
                            type={BTN_TYPES.BUTTON}
                            onClick={() => this.handleDropdown(this.alertRef)}
                            className={SHARED_CLASSES.NAV_LINK}>
                        {alerts.length > 0 &&
                        <span className="alert">{alerts.length}</span>
                        }
                        <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth/>
                    </Button>

                    <AlertDropdown ref={this.alertRef} list={alerts}/>
                </li>

                <li className={SHARED_CLASSES.NAV_ITEM}>
                    <Button buttonStyle={BTN_CLASSES.LINK}
                            type={BTN_TYPES.BUTTON}
                            onClick={() => this.handleDropdown(this.notificationRef)}
                            className={`${SHARED_CLASSES.NAV_LINK}`}>
                        {filteredNotificationList.length > 0 &&
                        <span>{filteredNotificationList.length}</span>
                        }

                        <FontAwesomeIcon icon={faBell} fixedWidth/>
                    </Button>

                    <NotificationDropdown ref={this.notificationRef}
                                          list={notificationList}
                                          updateNotifications={updateNotifications}/>
                </li>

                <li className={SHARED_CLASSES.NAV_ITEM}>
                    <NavLink to={messages["routes.user"]}
                             className={SHARED_CLASSES.NAV_LINK}>
                        <FontAwesomeIcon icon={faUser} fixedWidth/>
                    </NavLink>
                </li>

                <li className={SHARED_CLASSES.NAV_ITEM}>
                    <Link to={"#"}
                          onClick={logout}
                          className={SHARED_CLASSES.NAV_LINK}>
                        <FontAwesomeIcon icon={faSignOutAlt} fixedWidth/>
                    </Link>
                </li>
            </ul>
        )
    }
}

const mapStateToProps = ({authReducer}) => {
    const {user} = authReducer;
    return {user};
};

export default connect(mapStateToProps)(injectIntl(TopBar));
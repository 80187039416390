import axios from "axios";
import {API_URL} from "../constants/defaults";
import {ADD_STICKER, ADD_STICKERS, DELETE_STICKER, DELETE_STICKERS, LOAD_STICKERS, UPDATE_STICKER} from "../constants/stickers.constants";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";

export const loadStickers = () => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.get(API_URL + '/stickers', header)
        .then(({data}) => {
            dispatch({type: LOAD_STICKERS, payload: {stickers: data}});
        })
        .catch(error => {
            console.log(error);
            const message = handleLoadErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        });
};

export const addStickers = (type, id) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;
    const {stickers} = state.stickerReducer;

    if (!stickers[type][id]) {
        dispatch({type: ADD_STICKERS, payload: {type, id, stickers: {stickers: []}}});
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(`${API_URL}/${type}/${id}/stickers`, header)
            .then(({data}) => {
                dispatch({type: ADD_STICKERS, payload: {type, id, stickers: data}})
            })
            .catch(error => {
                const message = handleLoadErrors(messages, error);
                dispatch({type: DELETE_STICKERS, payload: {type, id}});
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
                console.log(error);
            });
    }
};

export const addSticker = (body) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(API_URL + '/stickers', body, header)
        .then(({data}) => {
            dispatch({type: ADD_STICKER, payload: {type: body.type, id: body.id, sticker: data}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["stickers.add.response.success"], type: 'success'})}});
        })
        .catch(error => {
            const message = handleAddErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        }) ;
};

export const updateSticker = (body, id) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(API_URL + '/stickers/' + id, body, header)
        .then(({data}) => {
            dispatch({type: UPDATE_STICKER, payload: {type: body.type, id: body.id, sticker: data}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["stickers.update.response.success"], type: 'success'})}});
        })
        .catch(error => {
            const message = handleUpdateErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        }) ;
};

export const deleteSticker = (body, id) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.delete(API_URL + '/stickers/' + body.type + '/' + body.id, header)
        .then(() => {
            dispatch({type: DELETE_STICKER, payload: {type: body.type, id: body.id, stickerId: id}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["stickers.delete.response.success"], type: 'success'})}});
        })
        .catch(error => {
            const message = handleDeleteErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        }) ;
};

const handleLoadErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["stickers.load.response.error"];
};

const handleAddErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["stickers.add.response.error"];
};

const handleUpdateErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["stickers.update.response.error"];
};

const handleDeleteErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["stickers.delete.response.error"];
};
import React, {Component} from "react";
import {Link} from "react-router-dom";

/**
 * Calendar event component
 */
class CalendarEvent extends Component {
    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {event} = this.props;

        /** return singe event */
        return (
            <Link to={`/${event.type === 'training' ? 'opleidingen' : 'evenementen'}/${event.slug}`}>
                {event.status && <div className={`status ${event.status}`}/>}
                {event.title}
            </Link>
        )
    }
}

export default CalendarEvent
import {LOAD_SUB_FEED} from "../constants/subFeed.constants";

let defaultState = {
    subFeed: [],
    hasMore: true,
    isLoading: false,
};


const loadSubFeed = (state, { feeds, isLoading }) => {
    let subFeed = state.subFeed;
    if (feeds)
        subFeed = [
            ...state.subFeed,
            ...feeds
        ];

    const hasMore = feeds ? !(feeds.length === 0 || feeds.length < 10) : true;
    return { ...state, subFeed, isLoading, hasMore };
};


const subFeedReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_SUB_FEED:
            return loadSubFeed(state, payload);
        default:
            return state;
    }
};

export default subFeedReducer;
import React, {useState, useRef} from "react";
import {BTN_CLASSES} from "../../css_constants";

const DocumentDetail = (props) => {
    const {title, children, pill} = props;
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");

    const content = useRef(null);

    function toggle() {
        setActiveState(setActive === "" ? "accordion__active" : "");
        setHeightState(setActive === "accordion__active" ? "0px" : `${content.current.scrollHeight}px`)
    }

    return (
        <div className={`accordion ${setActive}`}>
            {children
                ? (
                    <div className={`accordion__button ${BTN_CLASSES.BTN} ${BTN_CLASSES.SECONDARY}`}>
                        <div className="accordion__title" onClick={toggle}>{title}</div>
                        {pill ? pill : ''}
                    </div>
                )
                : (
                    <div className={`accordion__div ${BTN_CLASSES.BTN} ${BTN_CLASSES.SECONDARY}`}>
                        <div className="accordion__title">{title}</div>
                        {pill ? pill : ''}
                    </div>
                )
            }


            {children &&
            <div className={"accordion__content"} ref={content} style={{maxHeight: `${setHeight}`}}>
                <div className="accordion__text">{children}</div>
            </div>
            }

        </div>
    );
};

export default DocumentDetail;
import React, {Component} from "react";
import Modal from "../../../utils/Modal/Modal";
import Form from "../../../utils/Forms/Form";
import {Row} from "../../../utils/Grid/Grid";
import {loadCoces} from "../../../redux/actions/coce.actions";
import {connect} from "react-redux";
import './Invitations.scss'
import moment from "moment";

class Invitations extends Component {
    constructor(props) {
        super(props);

        this.initialState = {
            form: {
                validateForm: true,
                steps: 1,
                step: 1,
                currentIndex: 0,
                previousIndex: 0,
                nextIndex: 0,
                skipped: [],
                format: [
                    {
                        type: "tab",
                        name: "tab1",
                        fields: ["coces"],
                        options: [
                            {
                                field: "coces",
                                value: "",
                                skip: []
                            }
                        ]
                    }, {
                        type: "tab",
                        name: "tab2",
                        fields: ["group"],
                        options: []
                    }, {
                        type: "tab",
                        name: "tab3",
                        fields: ["users"],
                        options: []
                    }
                ],
                fields: [
                    {
                        name: "coces",
                        type: "select",
                        required: false,
                        multiple: true,
                        placeholder: "Selecteer de CoCe's die je wil uitnodigen",
                        label: "Competence Centers",
                        autocomplete: false,
                        description: null,
                        options: [],
                        isFixed: true,
                    }, {
                        name: "users",
                        type: "select",
                        required: false,
                        multiple: true,
                        placeholder: "Selecteer de gebruikers die je wil uitnodigen",
                        label: "Gebruikers",
                        autocomplete: false,
                        description: null,
                        options: [],
                        isFixed: true,
                        value: []
                    }, {
                        name: "maxAttendees",
                        type: "number",
                        min: 0,
                        step: 1,
                        required: false,
                        placeholder: "",
                        label: "Maximaal aantal inschrijvingen",
                        autocomplete: false,
                        description: "Indien geen maximaal aantal, laat het veld leeg"
                    }, {
                        name: "maxAttendDate",
                        type: "datepicker",
                        value: [{day: new Date()}],
                        multiple: false,
                        required: true,
                        placeholder: "De naam van het evenement",
                        label: "Laatste inschrijvingsdatum",
                        autocomplete: false,
                        description: null,
                    }, {
                        name: "group",
                        type: "group",
                        direction: "vertical",
                        fields: ["maxAttendDate", "maxAttendees"]
                    }
                ]
            },
            modal: false,
            valid: false,
        }
        this.state = this.initialState;

        this.handleShow = this.handleShow.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this._next = this._next.bind(this);
        this._prev = this._prev.bind(this);
    }

    componentDidMount() {
        const {loadCoces} = this.props;

        /**
         * Load coces
         */
        loadCoces(false);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const {coces, users, invitations} = this.props;
        /**
         * Set Coces
         */
        if (coces && prevState.form.fields.find(x => x.name === 'coces').options.length !== coces.length) {
            const fields = this.state.form.fields.map(field => {
                if (field.name !== "coces") return field;

                return {
                    ...field,
                    options: coces.map(coce => (coce.name))
                }
            });

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields
                    }
                }
            });
        }

        /**
         * Set Users
         */
        const count = users.filter(user => user.role.name !== 'instructor');
        if (users && prevState.form.fields.find(x => x.name === 'users').options.length !== count.length) {
            const fields = this.state.form.fields.map(field => {
                if (field.name !== "users") return field;

                return {
                    ...field,
                    options: users.filter(user => {
                        return user.role.name !== 'instructor' && users.filter(u => u.label !== user.label).length <= 0;
                    }).map(user => ({value: user.id, label: `${user.firstName} ${user.lastName}`, coces: user.coces}))
                }
            });

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields
                    }
                }
            });
        }

        /**
         * Set invitations
         */
        if (invitations && prevState.form.format.length === 3) {
            const fields = this.state.form.fields.map(field => {
                if (field.name !== "users" && field.name !== "maxAttendees" && field.name !== "maxAttendDate") return field;

                switch (field.name) {
                    case 'users':
                        return {
                            ...field,
                            value: invitations.users
                        }
                    case 'maxAttendees':
                        return {
                            ...field,
                            value: invitations.maxAttendees
                        }
                    case 'maxAttendDate':
                        return {
                            ...field,
                            value: [{day: invitations.maxAttendDate}]
                        }
                    default:
                        return null;
                }
            });

            const format = this.state.form.format.filter(x => x.name === 'tab2' || x.name === 'tab3')

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields,
                        format,
                        currentIndex: 0,
                        nextIndex: 1,
                        steps: 2,
                        step: 1,
                    }
                }
            });
        }

        if (this.state.form.step === 3 && prevState.form.step < 3) {
            if (this.state.form.fields.find(x => x.name === 'coces').value) {
                let usersVal = [];
                this.state.form.fields.find(x => x.name === 'coces').value.forEach(value => {
                    usersVal = usersVal.concat(users.filter(user => (
                        user.coces.find(coce => coce === value.value)
                    )).map(user => ({value: user.id, label: `${user.firstName} ${user.lastName}`})));
                });

                this.setState(prevState => {
                    return {
                        ...prevState,
                        form: {
                            ...prevState.form,
                            fields: prevState.form.fields.map(x => {
                                console.log(x)
                                if (x.name !== 'users') return x;

                                return {
                                    ...x,
                                    value: usersVal
                                }
                            })
                        }
                    }
                });
            }
        }
    }

    handleShow() {
        this.setState(prevState => ({modal: !prevState.modal}))
    }

    handleSave() {
        const {fields} = this.state.form;

        const invitations = {
            maxAttendees: fields.find(x => x.name === 'maxAttendees').value,
            maxAttendDate: moment(fields.find(x => x.name === 'maxAttendDate').value[0].day).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            users: fields.find(x => x.name === 'users').value,
        }

        this.props.setInvitations(invitations);
        this.handleShow();
    }

    render() {
        const {modal, valid, form} = this.state;
        const {step, steps, currentIndex, nextIndex, format, fields} = form;

        /** Returns the form modal */
        return (
            <Modal show={modal}
                   className="invitations"
                   close={this.handleShow}
                   title={"Inschrijvingen beheren"}
                   large={false}
                   medium={true}
                   actions={[{
                       label: "vorige",
                       action: () => this._prev(),
                       styles: {display: (step !== 1 ? 'inline-block' : 'none')},
                       disabled: true
                   }, {
                       label: "volgende",
                       action: () => this._next(),
                       styles: {display: (step < steps ? 'inline-block' : 'none')},
                       disabled: fields.filter(x => {
                           if (!nextIndex) return false;

                           const fields = format[currentIndex].fields;
                           return fields.includes(x.name);
                       }).find(x => x.valid)
                   }, {
                       label: "Toevoegen",
                       action: this.handleSave,
                       styles: {display: (step === steps ? 'inline-block' : 'none')},
                       disabled: valid
                   }]}
            >
                <Row>
                    <Form form={form}
                          formValid={(bool) => this.setState({valid: bool})}
                          updateFields={(form) => this.setState({form: form})}
                    />
                </Row>
            </Modal>
        )
    }

    _next() {
        const {form} = this.state;
        let {step, steps, nextIndex, currentIndex} = form;

        step = step >= steps - 1 ? steps : step + 1;

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                step,
                currentIndex: nextIndex,
                previousIndex: currentIndex,
                validateForm: true
            }
        }));
    }

    _prev() {
        const {form} = this.state;
        let {step, previousIndex, skipped, format} = form;

        step = step <= 1 ? 1 : step - 1;

        const previousTabs = format.filter((x, i) => {
            return i < previousIndex && !skipped.includes(x.name);
        });

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                step,
                currentIndex: previousIndex,
                previousIndex: format.indexOf(previousTabs[previousTabs.length - 1]),
                validateForm: true,
            }
        }));
    }
}

const mapStateToProps = ({coceReducer, userReducer}) => {
    const {coces} = coceReducer;
    const {users} = userReducer;
    return {coces, users};
};

const mapDispatchToProps = {
    loadCoces
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(Invitations)
import {LOAD_FORM_RESULTS, UPDATE_FORM_RESULTS} from "../constants/subscriptionResult.constants";

let defaultState = {
    results: [],
    resultsLoading: false,
};


const loadFormResults = (state, {resultsLoading, results}) => {
    return {...state, resultsLoading, results};
}

const updateFormResult = (state, {invitation}) => {
    return {
        ...state,
        results: state.results.map(result => {
            if (result.user.id !== invitation.user.id) return result;

            return {
                ...result,
                user: {
                    ...result.user,
                    confirmed: invitation.confirmed ? 1 : 0
                }
            }
        })
    }
}


const subscriptionResultReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_FORM_RESULTS:
            return loadFormResults(state, payload);
        case UPDATE_FORM_RESULTS:
            return updateFormResult(state, payload);
        default:
            return state;
    }
};

export default subscriptionResultReducer;
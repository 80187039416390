import {
    ADD_TRAINING,
    DELETE_TRAINING,
    LOAD_TRAINING,
    LOAD_TRAININGS,
    UPDATE_TRAINING,
    DELETE_TRAINING_MEDIA,
    RELOAD_TRAININGS
} from "../constants/training.constants";

let defaultState = {
    trainingList: [],
    selectedTraining: null,
    isLoading: false,
};

const loadTrainings = (state, {trainings, isLoading}) => {
    let trainingList = [];
    trainings.map(training => {
        return trainingList.push({
            ...training,
            isLoaded: false
        });
    });

    return {...state, trainingList, isLoading};
};

const reloadTrainings = (state, {trainings, isLoading}) => {
    const array = trainings.map(training => ({
        ...training,
        isLoaded: false
    }));

    const trainingList = [
        ...array
    ];

    return {...state, trainingList, isLoading};
};

const loadTraining = (state, {selectedTraining, isLoading}) => {
    let {trainingList} = state;

    if (!isLoading) {
        trainingList = trainingList.map(x => {
            if (x.id !== selectedTraining.id) return x;
            return {
                ...selectedTraining,
                isLoaded: true
            }
        });
    }

    return {...state, trainingList, selectedTraining, isLoading};
};


const addTraining = (state, {training}) => {
    const trainingList = [training, ...state.trainingList];

    return {...state, trainingList};
};

const updateTraining = (state, {training}) => {
    const temp = state.trainingList.map(x => {
        if (x.id !== training.id) return x;
        return training;
    });

    return {...state, trainingList: temp, selectedTraining: training};
};

const deleteTraining = (state, {id}) => {
    const temp = state.trainingList.filter(x => x.id !== id);

    return {state, trainingList: temp, selectedTraining: null};
};

const deleteTrainingMedia = (state, {id, type}) => {
    type = type === 'images' ? 'media' : type;
    const trainingList = state.trainingList.map(x => {
        if (x.id !== state.selectedTraining.id) return x;

        return {
            ...x,
            [type]: x[type].filter(y => y.id !== id)
        }
    });

    let media = state.selectedTraining[type];
    media = media.filter(x => x.id !== id);

    return {
        ...state,
        trainingList: trainingList,
        selectedTraining: {
            ...state.selectedTraining,
            [type]: media
        }
    };
};


const trainingReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_TRAININGS:
            return loadTrainings(state, payload);
        case RELOAD_TRAININGS:
            return reloadTrainings(state, payload);
        case LOAD_TRAINING:
            return loadTraining(state, payload);
        case ADD_TRAINING:
            return addTraining(state, payload);
        case UPDATE_TRAINING:
            return updateTraining(state, payload);
        case DELETE_TRAINING:
            return deleteTraining(state, payload);
        case DELETE_TRAINING_MEDIA:
            return deleteTrainingMedia(state, payload);
        default:
            return state;
    }
};

export default trainingReducer;
import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.scss"
import userImage from "../../assets/images/user.png";
import Load from "../Loading/Load";

/**
 * Carousel component
 */
class Carousel extends Component {
    /**
     * Loading the default user image
     * @param ev
     */
    addDefaultSrc(ev) {
        ev.target.src = userImage;
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {objMedia, media} = this.props;

        /** Slider settings */
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            prevArrow: <PrevArrow/>,
            nextArrow: <NextArrow/>
        };

        /** Return the Slick Slider */
        return (
            <Slider {...settings}>
                {objMedia.map((obj, i) => {
                    const image = media.find(x => x.id === obj.id);
                    if (image) {
                        return (
                            <div key={i}>
                                {image.loading
                                    ? <Load/>
                                    : <img src={image.file} alt={image.description} style={{width: '200px'}} onError={this.addDefaultSrc}/>
                                }
                            </div>
                        )
                    }
                    return null;
                })}
            </Slider>
        )
    }
}


/**
 * Previous arrow component
 * @param props
 * @returns {*}
 * @constructor
 */
function PrevArrow(props) {
    const {onClick} = props;
    return (
        <div className={"slick-arrows prev"} onClick={onClick}>
            <FontAwesomeIcon icon={faChevronLeft}/>
        </div>
    );
}


/**
 * Next arrow component
 * @param props
 * @returns {*}
 * @constructor
 */
function NextArrow(props) {
    const {onClick} = props;
    return (
        <div className={"slick-arrows next"} onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight}/>
        </div>
    );
}


const mapStateToProps = ({mediaReducer}) => {
    const {media} = mediaReducer;
    return {media};
};


export default connect(mapStateToProps)(Carousel)
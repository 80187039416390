import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { createStore, applyMiddleware, compose } from 'redux';

import NL from "../../langs/nl";
import FR from "../../langs/fr";

let lang = navigator.language.split(/[-_]/)[0];
let messages = null;
switch (lang) {
    case 'nl': lang = 'nl'; messages = NL; break;
    case 'fr': lang = 'fr'; messages = FR; break;
    default: lang = 'nl'; messages = NL; break;
}

const initialState = {
    intl: {
        defaultLocale: 'nl',
        locale: lang,
        messages: messages
    }
};

//process.env.REACT_APP_API_URL;
export let composeEnhancers;
export let store;
setStore();

function setStore() {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        store = createStore(rootReducer, initialState, applyMiddleware(thunk));
    } else {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        store = createStore(rootReducer, initialState, composeEnhancers(
            applyMiddleware(thunk)
        ));
    }
}


import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadToppings} from "../../../redux/actions/topping.actions";
import Load from "../../../utils/Loading/Load";
import EmptyList from "../../EmptyList/EmptyList";
import SandwichSingle from "./SandwichSingle";
import FormInput from "../../../utils/Forms/FormInput";

const SandwichList = ({select}) => {
    const dispatch = useDispatch();
    const toppings = useSelector(state => state.toppingReducer.toppings);
    const isLoading = useSelector(state => state.toppingReducer.toppingsLoading);
    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch(loadToppings());
        document.title = `UniPartners Group | Admin broodjes`;
    }, [dispatch]);

    return (
        <div>
            {isLoading
                ? <Load/>
                : (toppings.length === 0
                    ? <EmptyList id="toppingList.empty"/>
                    : renderList(toppings, select, search, setSearch))
            }
        </div>
    );
}

const renderList = (toppings, select, search, setSearch) => {
    return (
        <>
            <FormInput type={"text"}
                       placeholder={"zoeken..."}
                       name={"search"}
                       onChange={(e) => setSearch(e.target.value)}
                       value={search}
                       autocomplete={"off"}
            />

            {toppings.filter(x => x.topping.toLowerCase().includes(search.toLowerCase())).map((x, i) => <SandwichSingle key={i} select={select} topping={x}/>)}
        </>
    )
}

export default SandwichList;
import React, {Component} from "react";
import {Col, Row} from "../../../utils/Grid/Grid";
import SandwichForm from "./SandwichForm";
import SandwichList from "./SandwichList";

class Sandwich extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTopping: null
        }
    }

    render() {
        const {selectedTopping} = this.state;

        return (
            <Row>
                <Col lg={8}>
                    <SandwichForm selectedTopping={selectedTopping} unsetSelected={() => this.setState({selectedTopping: null})}/>
                </Col>

                <Col lg={4} className="sandwichBar">
                    <SandwichList select={selectedTopping => this.setState({selectedTopping})}/>
                </Col>
            </Row>
        )
    }
}

export default Sandwich;
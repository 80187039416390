import React from 'react';
import { htmlToText, isEmpty } from "../../../utils/helperFunctions";
import Load from "../../../utils/Loading/Load";
import EmptyList from "../../EmptyList/EmptyList";

const NotificationList = (props) => {
    const {isLoading, notificationList} = props;

    const notificationTypes = notificationList.map(notification => {
        switch (notification.type) {
            case 'comment':
                return (
                    <div key={notification.id} style={{border: 'solid 1px #ccc', boxShadow: '1px 2px 4px #aaa', margin: '10px 0', padding: '10px 10px 0'}}>
                        <p>Type: {notification.type.toUpperCase()}</p>
                        <p>Author {notification.comment.author.firstName} {notification.comment.author.lastName} entered a new comment:</p>
                        <p>"{notification.comment.body}"</p>
                    </div>
                );
            case 'mention':
                return (
                    <div key={notification.id} style={{border: 'solid 1px #ccc', boxShadow: '1px 2px 4px #aaa', margin: '10px 0', padding: '10px 10px 0'}}>
                        <p>Type: {notification.type.toUpperCase()}</p>
                        <p>Author {notification.comment.author.firstName} {notification.comment.author.lastName} mentioned you in a comment with id: {notification.comment.id}</p>
                        <p>"{htmlToText(notification.comment.body)}"</p>
                    </div>
                );
            case 'sticker':
                return (
                    <div key={notification.id} style={{border: 'solid 1px #ccc', boxShadow: '1px 2px 4px #aaa', margin: '10px 0', padding: '10px 10px 0'}}>
                        <p>Type: {notification.type.toUpperCase()}</p>

                    </div>
                );
            default:
                return <p key={notification.id}>Type: UNKNOWN</p>;
        }
    });

    return (
        <div>
            {isLoading
                ? <Load />
                : isEmpty(notificationList)
                    ? <div><EmptyList id={"404.notification"}/></div>
                    : notificationTypes
            }
        </div>
    );
}

export default NotificationList;
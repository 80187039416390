import React from 'react';
import DayPicker from "react-day-picker";
import './FormInput.scss';
import 'react-day-picker/lib/style.css';
import PropTypes from "prop-types";
import {DefaultModifiers} from '../datepicker-settings';
import 'moment/locale/nl';
import 'moment/locale/fr';
import MomentLocaleUtils from 'react-day-picker/moment';

/**
 * Period day picker component
 * @param label
 * @param required
 * @param error
 * @param className
 * @param onChange
 * @param value
 * @param disabledDays
 * @param locale
 * @param modifiers
 * @returns {*}
 * @constructor
 */
export const FormPeriodpicker = ({label, required, error, className, onChange, value, disabledDays, locale, modifiers}) => (
    <div className={'form-group'}>
        {label && <div><label>{label} {required ? <code>*</code> : null}</label></div>}

        <DayPicker
            locale={locale}
            className={`${className}`}
            localeUtils={MomentLocaleUtils}
            modifiers={{...modifiers, ...DefaultModifiers}}
            disabledDays={disabledDays}
            selectedDays={value}
            onDayClick={onChange}
            numberOfMonths={2}
        />
        <small className="invalid-feedback">{error}</small>
    </div>
);


FormPeriodpicker.defaultProps = {
    label: '', error: '', valid: '', required: false, className: '', selectedDays: [], todayButton: false, locale: 'nl'
};


FormPeriodpicker.propTypes = {
    onChange: PropTypes.func.isRequired,
};
import React, {Component} from "react";
import {Col, Row} from "../../utils/Grid/Grid";
import EmptyList from "../EmptyList/EmptyList";
import Button from "../../utils/Buttons/Button";
import {BTN_CLASSES, BTN_TYPES, SHARED_CLASSES, UNITS} from "../../css_constants";
import DateUtility from "../../utils/dateUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCarCrash, faCheck, faEdit, faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import './Claims.scss';
import DamageDetail from "./DamageDetail";
import {connect} from "react-redux";
import {selectDamage} from "../../redux/actions/app.actions";
import {FormattedMessage, injectIntl} from "react-intl";
import { Link } from "react-router-dom";
import Modal from "../../utils/Modal/Modal";

/**
 * Damage list component
 */
class DamageList extends Component {
    constructor(props) {
        super(props);

        /** State of the component */
        this.state = {
            openDamages: props.user.damages.filter(x => !x.status),
            historyDamages: props.user.damages.filter(x => x.status),
            viewHistory: false,

            viewDamage: 'accident',

            injuries: false,
            noInjuries: false,
            counterparty: false,
            noCounterparty: false,
            collisionForm: false
        }
    }

    componentDidMount() {
        const {selectDamage, match} = this.props;
        const {id} = match.params;

        if (id) selectDamage(parseInt(id));

        document.title = `UniPartners Group | Safe on wheels`;
    }

    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {openDamages, historyDamages, viewHistory} = this.state;
        const {selectedDamage} = this.props;

        /** Return tabs and renderAccident, renderBreakdown or renderGlassDamage depending on component state */
        return (
            <Row>
                <Col lg={8}>
                    {selectedDamage
                        ? <DamageDetail {...this.props}/>
                        : this.renderDirective()
                    }
                </Col>

                <Col lg={4} md={6} className="claims">
                    {(!viewHistory && openDamages && openDamages.length > 0) || (viewHistory && historyDamages && historyDamages.length > 0)
                        ? !viewHistory
                            ? (
                                openDamages.map((x, i) => (
                                    this.renderDamage(x, i)
                                ))
                            ) : (
                                historyDamages.map((x, i) => (
                                    this.renderDamage(x, i)
                                ))
                            )
                        : <EmptyList id={"Damage.empty"}/>
                    }

                    {historyDamages && historyDamages.length !== 0 &&
                    <Button type={BTN_TYPES.BUTTON}
                            buttonStyle={BTN_CLASSES.PRIMARY}
                            block={true}
                            label={viewHistory ? <FormattedMessage id="claims.current"/> : <FormattedMessage id="claims.history"/>}
                            onClick={() => this.setState({viewHistory: !viewHistory})}
                    />
                    }
                </Col>
            </Row>
        );
    };


    /**
     * Component renderDirective method
     * @returns {*}
     */
    renderDirective() {
        const {viewDamage} = this.state;
        const {messages} = this.props.intl;

        /** Return the view depending on state */
        return (
            <div className="damage-overview">
                <h1><FormattedMessage id="damage.topTitle"/></h1>
                <p><FormattedMessage id="damage.description">
                    {message => <span dangerouslySetInnerHTML={{__html: message}}/>}
                </FormattedMessage></p>

                <div className={`${BTN_CLASSES.GROUP}`} style={{width: "100%", marginBottom: '50px', flexWrap: 'wrap'}}>
                    <Button buttonStyle={BTN_CLASSES.PRIMARY} className={viewDamage === 'accident' ? 'active' : ''} onClick={() => this.setState({viewDamage: "accident"})} block={false} label={messages["damage.accident"]}/>
                    <Button buttonStyle={BTN_CLASSES.PRIMARY} className={viewDamage === 'glass' ? 'active' : ''} onClick={() => this.setState({viewDamage: "glass"})} block={false} label={messages["damage.glassDamage"]}/>
                    <Button buttonStyle={BTN_CLASSES.PRIMARY} className={viewDamage === 'breakdown' ? 'active' : ''} onClick={() => this.setState({viewDamage: "breakdown"})} block={false} label={messages["damage.breakdown"]}/>
                </div>

                {viewDamage === "glass"
                    ? this.renderGlassDamage()
                    : viewDamage === "breakdown"
                        ? this.renderBreakdown()
                        : this.renderAccident()
                }
            </div>
        )
    }


    /**
     * Component renderDamage method
     * @param damage
     * @param i
     * @returns {*}
     */
    renderDamage(damage, i) {
        const {date, status, id} = damage;
        const {intl, selectDamage} = this.props;
        const {messages} = intl;

        return (
            <div className={"damage"} key={i}>
                <div className={"damage__icon"}><FontAwesomeIcon icon={faCarCrash}/></div>

                <div className={"damage__header"}>
                   <Link to={messages["routes.damage"] + `/${id}`} className={`${BTN_CLASSES.LINK} damage__title`} onClick={() => selectDamage(id)}>{DateUtility.toLongDate(date)}</Link>
                </div>

                <Link to={messages["routes.damage"] + `/${id}`} className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`} onClick={() => selectDamage(id)}><FontAwesomeIcon icon={status ? faSearch : faEdit}/></Link>
            </div>
        )
    }

    renderAccident() {
        /** Returns renderCarTypeSelection and DamageDetail component */
        return (
            <div>
                <h2>Heb je een ongeval?</h2>
                <p>Heb je kort na het ongeval ergens pijn? Ga zo snel mogelijk naar de dokter. Dit moet zo snel mogelijk geregistreed en doorgegeven worden.</p>

                <div className={BTN_CLASSES.GROUP}>
                    <Button type={BTN_TYPES.BUTTON} buttonStyle={BTN_CLASSES.PRIMARY} buttonSize={BTN_CLASSES.SMALL} label={"Met gewonden"} onClick={() => this.setState({injuries: true})}/>
                    <Button type={BTN_TYPES.BUTTON} buttonStyle={BTN_CLASSES.PRIMARY} buttonSize={BTN_CLASSES.SMALL} label={"Zonder gewonden"} onClick={() => this.setState({noInjuries: true})}/>
                </div>

                <p><br /><b>Ken je de tegenpartij?</b></p>

                <div className={BTN_CLASSES.GROUP}>
                    <Button type={BTN_TYPES.BUTTON} buttonStyle={BTN_CLASSES.PRIMARY} buttonSize={BTN_CLASSES.SMALL} label={"Ja"} onClick={() => this.setState({counterparty: true})}/>
                    <Button type={BTN_TYPES.BUTTON} buttonStyle={BTN_CLASSES.PRIMARY} buttonSize={BTN_CLASSES.SMALL} label={"Nee"} onClick={() => this.setState({noCounterparty: true})}/>
                </div>

                <p><br /><b>Hulp nodig met je aanrijdingsformulier?</b></p>

                <div className={BTN_CLASSES.GROUP}>
                    <Button type={BTN_TYPES.BUTTON} buttonStyle={BTN_CLASSES.PRIMARY} buttonSize={BTN_CLASSES.SMALL} label={"Het aanrijdingsformulier"} onClick={() => this.setState({collisionForm: true})}/>
                </div>

                {this.renderModals()}

                <DamageDetail {...this.props}/>
            </div>
        )
    }

    renderGlassDamage() {
        const {car} = this.props;

        let directive;
        switch(car.insurance) {
            case 'Ethias':
                directive = 'Jouw leasingwagen is verzekerd voor glasschade bij <b>Ethias</b>. Voor herstellingen of vervangingen neem je contact op met <b>Carglass</b>.<br/>Je kan hen contacteren op het nummer <b><a href=\"tel:080013636\">0800 1 36 36</a></b>.';
                break;
            case 'Service Rent':
                directive = 'Je hebt een huurwagen van <b>Service Rent</b>. Voor herstellingen of vervangingen contacteer je <b>Touring Glass</b>.<br/>Je kan hen contacteren op het nummer <b><a href=\"tel:080095555\">0800 95 555</a></b>.';
                break;
            case 'All Car Lease':
                directive = 'Je hebt een huurwagen van <b>All Car Lease/Rent</b>. Voor herstellingen of vervangingen contacteer je <b>Autoglas Clinic</b>.<br/>Je kan hen contacteren op het nummer <b><a href=\"tel:080023332\">0800 2 333 2</a></b>.';
                break;
            case 'KBC':
            default:
                directive = 'Jouw leasingwagen is verzekerd voor glasschade bij <b>KBC Assistance</b>. Voor herstellingen of vervangingen neem je contact op met <b>Carglass</b> of <b>Autoglass Clinic</b>.<br/>Je kan hen respectievelijk contacteren op het nummer <b><a href=\"tel:080013636\">0800 1 36 36</a></b> of <b><a href=\"tel:080023332\">0800 23 332</a></b></b>.';
                break;
        }

        /** Returns renderCarTypeSelection and procedure to follow according to component state */
        return (
            <div>
                <p dangerouslySetInnerHTML={{__html: directive}}/>
            </div>
        )
    }

    renderBreakdown() {
        const {car} = this.props;

        let directive;
        switch(car.insurance) {
            case 'Service Rent':
                directive = 'Je hebt een huurwagen van <b>Service Rent</b>. Voor pechverhelping bel je naar <b>Touring</b>.<br/>Je kan hen contacteren op het nummer <b><a href=\"tel:022863556\">02 286 35 56</a></b>';
                break;
            case 'All Car Lease':
                directive = 'Je hebt een huurwagen van <b>All Car Lease/Rent</b>. Voor pechverhelping bel je naar het assistance nummer van het merk zelf. Het nummer zou je moeten terugvinden <b>tussen de papieren</b> van de wagen.';
                break;
            case 'Ethias':
            case 'KBC':
            default:
                directive = '<p>Voor pechverhelping kan je bellen naar het assistance nummer van jouw merk:</p><ul><li>Mercedes : <a href={\"tel: 0080017777777\"}>00800 1 777 7777</a> (dit nr klopt)</li><li>BMW : <a href={\"tel: 080016633\"}>0800 16 633</a></li><li>Audi Assistance : <a href={\"tel: 027568681\"}>02 756 86 81</a></li><li>VW Assistance : <a href={\"tel: 027568688\"}>02 756 86 88</a></li><li>Skoda Assistance : <a href={\"tel: 027568689\"}>02 756 86 89</a></li><li>Peugeot Assistance : <a href={\"tel: 026276120\"}>02 627 61 20</a></li></ul>';
                //directive = '<p>Voor pechverhelping kan je bellen naar <b>KBC Assistance</b>.<br />Je kan hen contacteren op het nummer <a href={\"tel: 023473902\"}>02 347 39 02</a></li></ul>';
                break;
        }

        /** Returns renderCarTypeSelection and procedure to follow according to component state */
        return (
            <div>
                <p dangerouslySetInnerHTML={{__html: directive}}/>
            </div>
        )
    }

    renderModals () {
        const {injuries, noInjuries, counterparty, noCounterparty, collisionForm} = this.state;
        const {car: {insurance}} = this.props;

        return (
            <>
                <Modal show={injuries} close={() => this.setState({injuries: false})} title="Ongeval met gewonden">
                    <ul>
                        <li>Bel indien nodig de <b><a href="tel: 100" className="btn btn-link danger">100</a></b> of <b><a
                            href="tel: 112" className="btn btn-link danger">112</a></b> (Europees alarmnummer)
                        </li>
                        <li>Vermeld de identiteit en het adres van de gewonden aan de achterzijde van het
                            aanrijdingsformulier
                        </li>
                    </ul>
                </Modal>
                <Modal show={noInjuries} close={() => this.setState({noInjuries: false})} title="Enkel materiële schade">
                    <ul>
                        <li>Hinder het verkeer niet.</li>
                        <li>Zet de voertuigen aan de kant <b>NADAT</b> je <b>foto’s</b> van de situatie hebt genomen.</li>
                    </ul>
                </Modal>
                <Modal show={counterparty} close={() => this.setState({counterparty: false})} title="Je kent de tegenpartij wel">
                    <p>Kijk bij <b>'Het aanrijdingsformulier'</b> voor alle standaardinfo die je in een aangifte moet invullen.</p>
                    <p><FontAwesomeIcon icon={faCheck} style={{color: '#2eab18', marginRight: 10}}/><b>De tegenpartij wil WEL tekenen</b></p>
                    <ul>
                        <li>Vul samen het aanrijdingsformulier in.</li>
                    </ul>

                    <p><FontAwesomeIcon icon={faTimes} style={{color: '#ab182d', marginRight: 10}}/><b>De tegenpartij wil NIET tekenen</b></p>
                    <ul>
                        <li>Noteer je de nummerplaat van de tegenpartij.</li>
                        <li>Neem duidelijke foto’s van de schade aan jouw wagen én die van de tegenpartij.</li>
                        <li>Neem foto's van de situatie.</li>
                    </ul>
                </Modal>
                <Modal show={noCounterparty} close={() => this.setState({noCounterparty: false})} title="Je kent de tegenpartij niet">
                    <ul>
                        <li>Je vult het aanrijdingsformulier alleen in. Kijk bij <b>'Het aanrijdingsformulier'</b> voor alle standaardinfo die je in een aangifte moet invullen.</li>
                        <li>Laat een Proces Verbaal opmaken bij de politie.</li>
                    </ul>
                </Modal>
                <Modal show={collisionForm} close={() => this.setState({collisionForm: false})} title="Gegevens voor het invullen van de ongevalsaangifte" large>
                    <h3>Voorzijde</h3>
                    <br />
                    <p><b>6. Verzekeringsnemer:</b></p>
                    <ul>
                        <li><b>Naam:</b> Unipartners Group</li>
                        <li><b>Adres:</b> Kardinaal Cardijnstraat 7</li>
                        <li><b>Postcode:</b> 2840&nbsp;&nbsp;&nbsp;&nbsp;<b>Land:</b> België</li>
                        <li><b>Tel. of e-mail:</b> 03/202 43 10 en/of ehbo@unipartners.be</li>
                    </ul>
                    <p><b>8. Verzekeringsonderneming:</b> (zie verzekeringsattest)</p>
                    <ul>
                        <li><b>Naam:</b> {insurance === 'KBC' ? 'KBC Verzekeringen' : (insurance === 'Ethias' ? 'ETHIAS' : 'zie verzekeringsattest')}</li>
                        <li><b>Contractnummer:</b> {insurance === 'KBC' ? 'niet in te vullen' : (insurance === 'Ethias' ? '21.002.441' : 'zie verzekeringsattest')}</li>
                        <li>Nr van groene kaart</li>
                        <li>Verzekeringsattest of ‘groene’ kaart geldig vanaf ... tot ...</li>
                    </ul>
                    <h3>Achterzijde</h3>
                    <br />
                    <p>
                        De achterzijde moet ingevuld worden voor de aangifte.<br />
                        Dit mag je doen als je terug thuis bent. Je hebt voor deze verklaringen geen goedkeuring van de tegenpartij nodig.
                    </p>
                    <p><b>De B.T.W.:</b></p>
                    <ul>
                        <li><b>Beroep van de eigenaar:</b> mag je open laten</li>
                        <li><b>Wat is zijn registratienummer:</b> BE0454.644.839</li>
                        <li><b>BTW aftrekbaar:</b> JA</li>
                        <li><b>Zo ja:</b> gedeeltelijk 50%</li>
                    </ul>
                    <p><b>Verzekeringen die voor je voertuig afgesloten werden:</b> mag je open laten</p>
                    <p><b>Nummer van Post- of bankrekening:</b></p>
                    <ul>
                        <li><b>IBAN:</b> BE34 2200 3700 1290</li>
                        <li><b>BIC:</b> GEBABEBB</li>
                    </ul>
                    <p>Bij vragen: <a href="mailto: ehbo@unipartners.be">ehbo@unipartners.be</a></p>
                </Modal>
            </>
        )
    }
}


const mapStateToProps = ({authReducer, appReducer}) => {
    const {user, car} = authReducer;
    const {selectedDamage} = appReducer;
    return {user, car, selectedDamage};
};


export default connect(mapStateToProps, {selectDamage})(injectIntl(DamageList));
import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {SHARED_CLASSES} from "../../../css_constants";
import {connect} from "react-redux";
import {closeNavbar} from "../../../redux/actions/view.actions";
import {FormattedMessage, injectIntl} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faFileAlt, faFileInvoiceDollar, faGlassCheers, faGraduationCap, faHome, faNewspaper, faTimes, faUser, faUsers} from "@fortawesome/free-solid-svg-icons";
import {faCalendarAlt} from "@fortawesome/free-regular-svg-icons";

/**
 * Hamburger menu component
 * @param props
 * @returns {*}
 * @constructor
 */
class Hamburger extends Component {
    constructor(props) {
        super(props)

        this.ref = React.createRef();

        this.handleClickOutsideDropdown = this.handleClickOutsideDropdown.bind(this);
        this.handleScrollOutsideDropdown = this.handleScrollOutsideDropdown.bind(this);
    }

    /**
     * Load stickers when none are loaded when component mounts
     */
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideDropdown, false);
        document.addEventListener('wheel', this.handleScrollOutsideDropdown, false);
    }


    /**
     * Remove eventlisteners when component unmounts
     */
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideDropdown, false);
        document.removeEventListener('wheel', this.handleScrollOutsideDropdown, false);
    }


    /**
     * Close add screen when user clicks outside box
     * @param e
     * @returns {boolean}
     */
    handleClickOutsideDropdown(e) {
        if (this.ref.current && this.ref.current.contains(e.target)) return true;
        this.props.closeNavbar();
    }


    /**
     * Close add screen when user clicks outside box
     * @param e
     * @returns {boolean}
     */
    handleScrollOutsideDropdown(e) {
        if (this.ref.current && this.ref.current.contains(e.target)) return true;
        this.props.closeNavbar();
    }

    render() {
        const {permissions, openWellBeing, closeNavbar, showNavbar, user, efficyUrl, intl} = this.props;
        const {messages} = intl;

        let string = ["hamburger"];
        showNavbar && string.push('hamburger-show');

        /** Returns the links of the hamburger menu */
        return (
            <ul className={string.join(' ')} ref={this.ref}>

                <li className={SHARED_CLASSES.NAV_ITEM + " button"}>
                    <button onClick={closeNavbar}><FontAwesomeIcon icon={faTimes}/></button>
                </li>

                <li className={SHARED_CLASSES.NAV_ITEM}>
                    <NavLink onClick={closeNavbar}
                             to={messages["routes.home"]}
                             activeClassName={SHARED_CLASSES.ACTIVE}
                             className={SHARED_CLASSES.NAV_LINK}>
                        <FontAwesomeIcon icon={faHome} fixedWidth={true}/>
                        <FormattedMessage id={"menu.home"}/>
                        <FontAwesomeIcon icon={faChevronRight} className={"chevron"}/>
                    </NavLink>
                </li>

                {user.showBarometer
                    ? (
                        <li className={SHARED_CLASSES.NAV_ITEM}>
                            <button className={SHARED_CLASSES.NAV_LINK}
                                    onClick={openWellBeing}
                            >
                                <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth={true}/>
                                <FormattedMessage id={"menu.timesheet"}/>
                                <FontAwesomeIcon icon={faChevronRight} className={"chevron"}/>
                            </button>
                        </li>
                    ) : (
                        <li className={SHARED_CLASSES.NAV_ITEM}>
                            <a onClick={closeNavbar}
                               href={efficyUrl}
                               className={SHARED_CLASSES.NAV_LINK}
                               target="_blank"
                               rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth={true}/>
                                <FormattedMessage id={"menu.timesheet"}/>
                                <FontAwesomeIcon icon={faChevronRight} className={"chevron"}/>
                            </a>
                        </li>
                    )
                }

                {<li className={SHARED_CLASSES.NAV_ITEM}>
                    <NavLink onClick={closeNavbar}
                             to={messages["routes.calendar"]}
                             activeClassName={SHARED_CLASSES.ACTIVE}
                             className={SHARED_CLASSES.NAV_LINK}>
                        <FontAwesomeIcon icon={faCalendarAlt} fixedWidth={true}/>
                        <FormattedMessage id={"menu.calendar"}/>
                        <FontAwesomeIcon icon={faChevronRight} className={"chevron"}/>
                    </NavLink>
                </li>}

                {permissions.includes("trainings_see_published") &&
                <li className={SHARED_CLASSES.NAV_ITEM}>
                    <NavLink onClick={closeNavbar}
                             to={messages["routes.trainings"]}
                             activeClassName={SHARED_CLASSES.ACTIVE}
                             className={SHARED_CLASSES.NAV_LINK}>
                        <FontAwesomeIcon icon={faGraduationCap} fixedWidth={true}/>
                        <FormattedMessage id={"menu.trainings"}/>
                        <FontAwesomeIcon icon={faChevronRight} className={"chevron"}/>
                    </NavLink>
                </li>
                }

                {permissions.includes("events_see_published") &&
                <li className={SHARED_CLASSES.NAV_ITEM}>
                    <NavLink onClick={closeNavbar}
                             to={messages["routes.events"]}
                             activeClassName={SHARED_CLASSES.ACTIVE}
                             className={SHARED_CLASSES.NAV_LINK}>
                        <FontAwesomeIcon icon={faGlassCheers} fixedWidth={true}/>
                        <FormattedMessage id={"menu.events"}/>
                        <FontAwesomeIcon icon={faChevronRight} className={"chevron"}/>
                    </NavLink>
                </li>
                }

                {permissions.includes("news_see_published") &&
                <li className={SHARED_CLASSES.NAV_ITEM}>
                    <NavLink onClick={closeNavbar}
                             to={messages["routes.news"]}
                             activeClassName={SHARED_CLASSES.ACTIVE}
                             className={SHARED_CLASSES.NAV_LINK}>
                        <FontAwesomeIcon icon={faNewspaper} fixedWidth={true}/>
                        <FormattedMessage id={"menu.news"}/>
                        <FontAwesomeIcon icon={faChevronRight} className={"chevron"}/>
                    </NavLink>
                </li>
                }

                {permissions.includes("users_see_overview") &&
                <li className={SHARED_CLASSES.NAV_ITEM}>
                    <NavLink onClick={closeNavbar}
                             to={messages["routes.employees"]}
                             activeClassName={SHARED_CLASSES.ACTIVE}
                             className={SHARED_CLASSES.NAV_LINK}>
                        <FontAwesomeIcon icon={faUsers} fixedWidth={true}/>
                        <FormattedMessage id={"menu.employees"}/>
                        <FontAwesomeIcon icon={faChevronRight} className={"chevron"}/>
                    </NavLink>
                </li>
                }

                {permissions.includes("documents_see") &&
                <li className={SHARED_CLASSES.NAV_ITEM}>
                    <NavLink onClick={closeNavbar}
                             to={messages["routes.documents"]}
                             activeClassName={SHARED_CLASSES.ACTIVE}
                             className={SHARED_CLASSES.NAV_LINK}>
                        <FontAwesomeIcon icon={faFileAlt} fixedWidth={true}/>
                        <FormattedMessage id={"menu.documents"}/>
                        <FontAwesomeIcon icon={faChevronRight} className={"chevron"}/>
                    </NavLink>
                </li>
                }

                <li className={SHARED_CLASSES.NAV_ITEM}>
                    <NavLink onClick={closeNavbar}
                             to={messages["routes.user"]}
                             activeClassName={SHARED_CLASSES.ACTIVE}
                             className={SHARED_CLASSES.NAV_LINK}>
                        <FontAwesomeIcon icon={faUser} fixedWidth={true}/>
                        <FormattedMessage id={"menu.user"}/>
                        <FontAwesomeIcon icon={faChevronRight} className={"chevron"}/>
                    </NavLink>
                </li>

            </ul>
        );
    };
}


const mapStateToProps = ({authReducer, viewReducer}) => {
    const {permissions, user} = authReducer;
    const {showNavbar} = viewReducer;
    return {showNavbar, permissions, user};
};


export default connect(mapStateToProps, {closeNavbar})(injectIntl(Hamburger));
import React, {Component} from "react";
import Form from "../../../utils/Forms/Form";
import Button from "../../../utils/Buttons/Button";
import {BTN_CLASSES} from "../../../css_constants";
import {Col, Row} from "../../../utils/Grid/Grid";
import {connect} from "react-redux";
import {loadTags} from "../../../redux/actions/tag.actions";
import {Link} from "react-router-dom";
import {getMedia} from "../../../redux/actions/media.actions";
import Attachments from "../../../utils/Attachments/Attachments";
import EmptyList from "../../EmptyList/EmptyList";
import Load from "../../../utils/Loading/Load";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDrumstickBite,
    faEdit, faExclamationTriangle,
    faGlassCheers,
    faGlobeEurope, faTimes,
    faTools,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import '../Admin.scss';
import {deleteMedia, loadEvent, updateEvent} from "../../../redux/actions/event.actions";
import DateUtility from "../../../utils/dateUtils";
import Invitations from "../Invitations/Invitations";
import {loadInvitation} from "../../../redux/actions/invitation.actions";
import {loadForm} from "../../../redux/actions/form.actions";
import CustomForm from "../CustomForm/CustomForm";
import Modal from "../../../utils/Modal/Modal";
import UrlModal from "../UrlModal/UrlModal";
import {loadStaff} from "../../../redux/actions/user.actions";

class UpdateEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                format: [],
                fields: []
            },
            formValid: false,
            links: [],

            showModal: false,

            invitations: null,
            customForm: {
                formSize: "medium",
                formName: "Nieuw formulier",

                validateForm: false,
                steps: 1,
                step: 1,
                currentIndex: 0,
                previousIndex: 0,
                nextIndex: 0,
                skipped: [],

                format: [
                    {
                        type: "group",
                        name: "group1",
                        direction: "horizontal",
                        fields: [],
                    }
                ],
                fields: []
            },
            updateCustomForm: true,

            showCustomForm: false,
            loading: false
        };

        this.invitations = React.createRef();
        this.urls = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const {loadTags, loadStaff, loadEvent, loadInvitation, loadForm, match} = this.props;
        const {id} = match.params;

        /**
         * Load tags
         */
        loadTags();

        /**
         *  Load staff
         */
        loadStaff();

        /**
         * Load original article
         */
        loadEvent(id);

        /**
         * Load Invitations
         */
        loadInvitation('events', id);

        loadForm('events', id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tags, staff, selectedEvent} = this.props;
        const {updateCustomForm} = this.state;
        let {invitations, forms} = this.props;
        const {toMoment, isCurrent, lastDate} = DateUtility;

        document.title = `UniPartners Group | Admin evenementen | ${selectedEvent ? selectedEvent.name : 'loading...'}`;

        /**
         * Set form
         */
        if (prevState.form.fields.length === 0 && selectedEvent) {
            const {days} = selectedEvent;

            let fields, format;
            if (!days || (days && (days.length === 0 || (days.length !== 0 && isCurrent(lastDate(days)))))) {
                format = [
                    {
                        type: "col",
                        breakpoint: 8,
                        fields: ["name"]
                    }, {
                        type: "col",
                        breakpoint: 4,
                        fields: ["published"]
                    }, {
                        type: "col",
                        breakpoint: 12,
                        fields: ["location", "description"]
                    }, {
                        type: "col",
                        breakpoint: 6,
                        fields: ["date"]
                    }, {
                        type: "col",
                        breakpoint: 6,
                        fields: ["attachments", "organizers", "tags"]
                    }
                ];

                fields = [
                    {
                        name: "name",
                        type: "text",
                        required: true,
                        placeholder: "De naam van het evenement",
                        label: "Titel",
                        autocomplete: false,
                        description: null,
                        error: "",
                        valid: true,
                        value: selectedEvent.name
                    }, {
                        name: "description",
                        type: "tiny",
                        required: false,
                        placeholder: "De beschrijving van het evenement",
                        label: "Beschrijving",
                        autocomplete: false,
                        description: (
                            <div style={{display: 'flex', marginTop: 5, marginBottom: 5}}>
                                <FontAwesomeIcon icon={faExclamationTriangle} style={{height: 42.5, width: 'auto', margin: '5px 10px 5px 0', color: '#ef7d00'}} />
                                <div>
                                    <b>OPGELET!</b><br />
                                    De inhoud van dit evenement wordt via intranet en de nieuwsbrief binnen heel UniPartners gedeeld. Deel hier geen gevoelige informatie.<br />
                                    Gebruik het mail-formulier op het inschrijvingen-overzicht om belangrijke informatie te delen met de genodigden.
                                </div>
                            </div>
                        ),
                        error: "",
                        valid: true,
                        value: selectedEvent.description
                    }, {
                        name: "published",
                        type: "checkbox",
                        required: false,
                        label: "Publiceer het evenement",
                        error: "",
                        valid: true,
                        value: selectedEvent.publishedAt ? 1 : 0
                    }, {
                        name: "attachments",
                        type: "dropzone",
                        required: false,
                        label: "",
                        description: null,
                        error: "",
                        valid: true,
                        files: []
                    }, {
                        name: "tags",
                        type: "creatable",
                        required: false,
                        multiple: true,
                        placeholder: "De tags van het evenement",
                        label: "Tags",
                        autocomplete: false,
                        description: null,
                        options: tags.map(tag => (tag.tag)),
                        error: "",
                        valid: true,
                        value: selectedEvent.tags
                    }, {
                        name: "location",
                        type: "creatable",
                        required: true,
                        multiple: false,
                        placeholder: "De locatie van het evenement",
                        label: "Locatie",
                        autocomplete: false,
                        description: null,
                        options: [
                            "Grote vergaderzaal, Gent",
                            "Kleine vergaderzaal, Gent",
                            "Opleidingslokaal PC, Terhagen",
                            "Opleidingslokaal Theater, Terhagen",
                            "Vergaderzaal 1e verdieping, Terhagen",
                            "Vergaderzaal Orangerie, Terhagen"
                        ],
                        error: "",
                        valid: true,
                        value: selectedEvent.location.replace('<br />', ', ')
                    }, {
                        name: "organizers",
                        type: "select",
                        required: false,
                        multiple: true,
                        placeholder: "De organizatoren van het evenement",
                        label: "Organizatoren",
                        autocomplete: false,
                        description: null,
                        options: staff.map(member => ({value: member.id, label: `${member.firstName} ${member.lastName}`})),
                        error: "",
                        valid: true,
                        value: selectedEvent.organisors.map(x => {
                            return {
                                value: x.id,
                                label: `${x.firstName} ${x.lastName}`
                            }
                        })
                    }, {
                        name: "date",
                        type: "datepicker",
                        required: true,
                        label: "",
                        description: null,
                        value: selectedEvent.days.map(day => {
                            const {startDate, endDate} = day;

                            return {
                                day: toMoment(startDate),
                                startTime: toMoment(startDate),
                                endTime: toMoment(endDate),
                            }
                        }),
                        include: ["time"],
                        error: "",
                        valid: true,
                    }, {
                        name: "time",
                        type: "timerange",
                        required: false,
                        label: `Start -en eindtijd voor `,
                        description: "Voor een open einde, maak start- en eindtijd gelijk",
                        sameIsValid: true,
                        valid: true
                    }
                ];
            } else {
                format = [
                    {
                        type: "col",
                        breakpoint: 6,
                        fields: ["attachments"]
                    }
                ];

                fields = [
                    {
                        name: "attachments",
                        type: "dropzone",
                        required: false,
                        label: "",
                        description: null,
                        error: "",
                        valid: true,
                        files: []
                    }
                ];
            }



            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields,
                        format
                    }
                }
            });
        }

        /**
         * Load media and attachments for article
         */
        if (selectedEvent && selectedEvent.media && selectedEvent.media.length > 0) {
            selectedEvent.media.map(obj => {
                const {getMedia} = this.props;
                return getMedia(obj);
            });
        }

        /** Set invitations */
        if (selectedEvent && invitations && invitations.length !== 0 && this.state.invitations === null) {
            invitations = invitations ? invitations["events"][selectedEvent.id] : invitations;

            if (invitations && invitations.length !== 0) {
                this.setState({
                    invitations: {
                        maxAttendees: selectedEvent.maxAttendees,
                        maxAttendDate: DateUtility.toMoment(selectedEvent.maxAttendDate),
                        users: invitations.map(x => ({value: x.user.id, label: `${x.user.firstName} ${x.user.lastName}`, confirmed: x.confirmed}))
                    }
                });
            }
        }

        /** Set customForm */
        if (selectedEvent && forms && forms.length !== 0) {
            const form = forms["events"][selectedEvent.id];

            if (form && form.length !== 0 && form !== this.state.customForm && updateCustomForm) {
                this.setState({
                    customForm: forms["events"][selectedEvent.id]
                });
            }
        }
    }

    handleSubmit() {
        const {updateEvent, match} = this.props;
        const {form, invitations, links, customForm} = this.state;
        const {id} = match.params;

        this.setState({loading: true});

        const data = new FormData();
        for (const field of form.fields) {

            if (field.type !== 'dropzone' && !(field.type === 'creatable' || field.type === 'select') && field.type !== 'datepicker' && field.type !== 'timerange') {
                data.set(field.name, field.value)
            } else if ((field.type === 'creatable' || field.type === 'select') && field.multiple) {
                for (const value of field.value) {
                    data.append(`${field.name}[]`, (value.hasOwnProperty('value') ? value.value : value))
                }
            } else if ((field.type === 'creatable' || field.type === 'select') && !field.multiple) {
                data.append(`${field.name}`, (field.value ? (field.value.hasOwnProperty('value') ? field.value.value : field.value) : field.value))
            } else if (field.type === 'datepicker') {
                for (const value of field.value) {
                    const date = new Date(value.day);
                    const start = new Date(value.startTime)
                    const end = new Date(value.endTime)

                    const object = {
                        day: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
                        startTime: `${start.getHours()}:${start.getMinutes()}:00`,
                        endTime: `${end.getHours()}:${end.getMinutes()}:00`,
                    };

                    data.append(`${field.name}[]`, JSON.stringify(object));
                }
            } else if (field.type !== 'timerange') {
                for (const file of field.files) {
                    data.append(`${field.name}[]`, file)
                }
            }
        }

        if (links.length !== 0) {
            for (let i = 0; i <= links.length - 1; i++) {
                data.append(`links[${i}][name]`, links[i].name)
                data.append(`links[${i}][url]`, links[i].url)
            }
        }

        if (invitations) {
            data.append('invitations[maxAttendDate]', invitations.maxAttendDate);
            data.append('invitations[maxAttendees]', invitations.maxAttendees);

            for (const user of invitations.users) {
                data.append(`invitations[invitees][]`, (user.hasOwnProperty('value') ? user.value : user))
            }
        }

        if (customForm && customForm.fields.length !== 0) {
            data.append('form[id]', customForm.id);
            data.append('form[name]', customForm.formName);
            data.append('form[size]', customForm.formSize);

            for (const format of customForm.format) {
                data.append('form[format][]', JSON.stringify(format));
            }

            for (const field of customForm.fields) {
                data.append('form[fields][]', JSON.stringify(field));
            }
        }

        updateEvent(data, this.props.history, id);
    }

    render() {
        const {customForm, showCustomForm, loading} = this.state;

        return !showCustomForm
            ? (
                loading
                    ? <Load showPatience/>
                    : this.renderFormBody()
            )
            : <CustomForm customForm={customForm} handleForm={(form) => {
                this.setState({showCustomForm: false, customForm: form ? form : customForm, updateCustomForm: false})
            }}/>
    }

    renderFormBody() {
        const {form, links, customForm, formValid, invitations} = this.state;
        const {selectedEvent, media} = this.props;
        const {name, days} = selectedEvent || [];
        const {isCurrent, lastDate} = DateUtility;

        return (
            <Row>
                {!(!days || (days && (days.length === 0 || (days.length !== 0 && isCurrent(lastDate(days)))))) &&
                <Col md={12}>
                    <h1>{name}</h1>
                </Col>
                }

                <Form form={form}
                      formValid={(bool) => this.setState({formValid: bool})}
                      updateFields={(form) => this.setState({form: form})}
                />


                <Col xl={4} lg={6} md={7}>
                    {links.length !== 0 &&
                    <div className="uploaded-files">
                        {links.map((link, index) => (
                            <div className="file" key={index}>
                                <div>
                                    <FontAwesomeIcon icon={faGlobeEurope} fixedWidth/>
                                    {link.name && link.name !== '' ? link.name : link.url}
                                </div>
                                <Button buttonStyle={BTN_CLASSES.LINK}
                                        buttonSize={BTN_CLASSES.SMALL}
                                        className={BTN_CLASSES.DANGER}
                                        onClick={() => this.setState(prevState => ({links: prevState.links.filter(x => x.name !== link.name && x.url !== link.url)}))}
                                >
                                    <FontAwesomeIcon icon={faTimes}/>
                                </Button>
                            </div>

                        ))}

                        <div className="delete">
                            <Button buttonStyle={BTN_CLASSES.LINK}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    className={BTN_CLASSES.DANGER}
                                    onClick={() => this.setState({links: []})}
                            >
                                <FontAwesomeIcon icon={faTrash}/> Verwijder alles
                            </Button>
                        </div>
                    </div>
                    }

                    <div className={`${BTN_CLASSES.GROUP} flex`}>
                        <Button label={"URL's als bijlage toevoegen"}
                                buttonStyle={BTN_CLASSES.SECONDARY}
                                buttonSize={BTN_CLASSES.SMALL}
                                onClick={() => this.urls.current.handleShow()}
                                styles={{width: '100%'}}
                        />

                        {(!days || (days && (days.length === 0 || (days.length !== 0 && isCurrent(lastDate(days)))))) &&
                            <>
                                <Button label={"Deelnemers uitnodigen"}
                                        buttonStyle={BTN_CLASSES.SECONDARY}
                                        buttonSize={BTN_CLASSES.SMALL}
                                        className={invitations ? BTN_CLASSES.SUCCESS : ''}
                                        onClick={() => this.invitations.current.handleShow()}
                                />

                                <Button label={"Formulier maken"}
                                        buttonStyle={BTN_CLASSES.SECONDARY}
                                        buttonSize={BTN_CLASSES.SMALL}
                                        onClick={() => this.setState({showModal: true})}
                                        className={customForm && customForm.fields.length !== 0  ? BTN_CLASSES.SUCCESS : ''}
                                        disabled={(customForm.submissions !== undefined && customForm.submissions !== 0) || (invitations && invitations.users && invitations.users.filter(x => x.confirmed).length !== 0)}
                                />
                            </>
                        }

                        {selectedEvent && (selectedEvent.hasForm || selectedEvent.hasInvitations) &&
                        <Link to={`/evenementen/${selectedEvent.slug}/inschrijvingen`} className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`}>Inschrijvingen</Link>}
                    </div>
                </Col>

                <Col xl={3} lg={1} mdHide smHide xsHide/>

                {selectedEvent && this.renderAttachments()}

                <Col md={12} style={{margin: ".5rem 0", textAlign: "right"}}>
                    <div className={BTN_CLASSES.GROUP}>
                        <Link to={`/evenementen/${selectedEvent ? selectedEvent.slug : ''}`}
                              className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.SECONDARY}`}
                        >
                            Annuleren
                        </Link>

                        <Button buttonStyle={BTN_CLASSES.PRIMARY}
                                disabled={!formValid}
                                onClick={this.handleSubmit}
                        >
                            Bewaar
                        </Button>
                    </div>
                </Col>

                {selectedEvent && selectedEvent.media.length !== 0 && media && media.length !== 0 &&
                this.renderImages()
                }

                {this.renderDefaultFormModal()}

                <Invitations ref={this.invitations} setInvitations={(invitations) => this.setState({invitations})} invitations={invitations}/>
                <UrlModal ref={this.urls} handleSave={(obj) => this.setState(prevState => ({links: [...prevState.links, obj]}))}/>
            </Row>
        )
    }

    renderAttachments() {
        const {selectedEvent} = this.props;
        const {attachments, links} = selectedEvent;

        return (
            <Col md={5}>
                {((attachments && attachments.length > 0) || (links && links.length > 0))
                    ? <Attachments attachments={attachments}
                                   links={links}
                                   removeAttachment={(this.props.deleteMedia)}
                    />
                    : <EmptyList id={"Attachments.empty"}/>
                }

            </Col>
        )
    }

    renderImages() {
        const {media, selectedEvent} = this.props;

        return (
            <Col md={12}>
                <div className="images">
                    {selectedEvent.media.map((obj, i) => {
                        const image = media.find(x => x.id === obj.id);
                        if (image) {
                            return (
                                <div className={"image"} key={i}>
                                    {image.loading
                                        ? <Load/>
                                        : <img src={image.file} alt={image.description} onError={this.addDefaultSrc}/>
                                    }
                                    <Button buttonStyle={BTN_CLASSES.DANGER} onClick={() => this.props.deleteMedia(image.id, 'images')}><FontAwesomeIcon icon={faTrash}/></Button>
                                </div>
                            )
                        }
                        return null;
                    })}
                </div>

                <div className={"description"}>Afbeeldingen worden gedeeld in alle talen van dit artikel</div>
            </Col>
        )
    }

    loadCustomForm(type) {
        const {customForm} = this.state;
        let form;

        switch (type) {
            case 'bbq':
                form = {
                    formSize: "medium",
                    formName: "Schrijf je in voor onze barbecue",

                    format: [
                        {
                            type: "group",
                            name: "group1",
                            direction: "horizontal",
                            fields: ["qty", "allergies", "vegetarian"],
                        }
                    ],
                    fields: [
                        {
                            type: "number",
                            name: "qty",
                            label: "Aantal personen",
                            placeholder: "",
                            description: "",
                            required: true,
                            step: 1,
                            min: 1
                        }, {
                            type: "text",
                            name: "allergies",
                            label: "Allergieën",
                            placeholder: "",
                            description: "Heb je allergieën? Laat het ons weten",
                            required: false,
                        }, {
                            type: "checkbox",
                            name: "vegetarian",
                            label: "Ik ben vegetarisch",
                            placeholder: "",
                            description: "",
                            required: false,
                        },
                    ]
                }
                break;
            case 'party':
                form = {
                    formSize: "medium",
                    formName: "Schrijf je in voor ons personeelsfeest",

                    format: [
                        {
                            type: "group",
                            name: "group1",
                            direction: "horizontal",
                            fields: ["partner", "vegetarian", "allergies"],
                        }
                    ],
                    fields: [
                        {
                            type: "checkbox",
                            name: "partner",
                            label: "Ik breng mijn partner mee",
                            placeholder: "",
                            description: "",
                            required: false,
                        }, {
                            type: "checkbox",
                            name: "vegetarian",
                            label: "Ik ben vegetarisch",
                            placeholder: "",
                            description: "",
                            required: false,
                        }, {
                            type: "text",
                            name: "allergies",
                            label: "Allergieën",
                            placeholder: "",
                            description: "Heb je allergieën? Laat het ons weten",
                            required: false,
                        }
                    ]
                }
                break;
            case 'edit':
                form = customForm;
                break;
            default:
                form = {
                    formSize: "medium",
                    formName: "Nieuw formulier",

                    validateForm: false,
                    steps: 1,
                    step: 1,
                    currentIndex: 0,
                    previousIndex: 0,
                    nextIndex: 0,
                    skipped: [],

                    format: [
                        {
                            type: "group",
                            name: "group1",
                            direction: "horizontal",
                            fields: [],
                        }
                    ],
                    fields: []
                }
                break;
        }

        this.setState({customForm: form, showModal: false, showCustomForm: true});
    }

    renderDefaultFormModal() {
        const {showModal, customForm} = this.state;

        return (
            <Modal show={showModal}
                   close={() => this.setState({showModal: false})}
                   title="Formuliertype kiezen"
                   medium={!!customForm}
            >
                <Row className="add_field">
                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.loadCustomForm("bbq")}
                        >
                            <FontAwesomeIcon icon={faDrumstickBite}/>
                            Barbecue
                        </Button>
                    </div>

                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.loadCustomForm("party")}
                        >
                            <FontAwesomeIcon icon={faGlassCheers}/>
                            Personeelsfeest
                        </Button>
                    </div>

                    {customForm &&
                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.loadCustomForm("edit")}
                        >
                            <FontAwesomeIcon icon={faEdit}/>
                            Verder werken
                        </Button>
                    </div>
                    }

                    <div>
                        <Button type="button"
                                className="form_add_button"
                                onClick={() => this.loadCustomForm("custom")}
                        >
                            <FontAwesomeIcon icon={faTools}/>
                            {customForm ? 'Nieuw formulier' : 'Eigen formulier'}
                        </Button>
                    </div>
                </Row>
            </Modal>
        )
    }
}

const mapStateToProps = ({tagReducer, eventReducer, mediaReducer, invitationReducer, formReducer, userReducer}) => {
    const {tags} = tagReducer;
    const {selectedEvent} = eventReducer;
    const {media} = mediaReducer;
    const {invitations} = invitationReducer;
    const {forms} = formReducer;
    const {staff} = userReducer;
    return {tags, selectedEvent, media, invitations, forms, staff};
};

const mapDispatchToProps = {
    loadEvent, updateEvent, loadTags, getMedia, deleteMedia, loadInvitation, loadForm, loadStaff
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEvent);
import React, {Component} from "react";
import {Col, Container, Row} from "../../utils/Grid/Grid";
import queryString from 'query-string'
import {connect} from "react-redux";
import {ALIGNMENT, BTN_CLASSES, BTN_TYPES, INPUT_TYPES, MARGINS} from "../../css_constants";
import Button from "../../utils/Buttons/Button";
import {injectIntl} from "react-intl";
import Logo from "../../assets/images/logo-up.png";
import {registerAccount} from "../../redux/actions/auth.actions";
import FormInput from "../../utils/Forms/FormInput";
import {loginValidator} from "./LoginValidator";
import Toasts from "../../utils/Toasts/Toasts";

class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: queryString.parse(this.props.location.search).ref,
            email: queryString.parse(this.props.location.search).email,
            password: '',

            passwordValid: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    /**
     * Handle change of the input fields
     * @param e
     */
    handleChange(e) {
        const {value} = e.target;
        this.setState({password: value}, this.validateField);
    }


    /**
     * Validate the input fields
     */
    validateField() {
        this.setState(loginValidator(this.state, this.props, 'password', this.state.password));
    }


    /**
     * Submit the login form
     */
    handleSubmit(e) {
        e.preventDefault();

        const {token, email, password} = this.state;
        const {registerAccount} = this.props;

        registerAccount({token, email, password});
    }

    render() {
        const {token, email, password, passwordValid} = this.state;
        const {formErrors, intl} = this.props;
        const {messages} = intl;

        return (
            <Container>
                <Row style={{height: '100vh', paddingTop: '0'}}>
                    <Col xl={7} lg={8} md={9} sm={10} xs={11} style={{margin: MARGINS.AUTO}}>
                        <img src={Logo} alt={"UniPartners, united around values"} className={"login-logo"}/>

                        {token && email
                            ? (
                                <div>
                                    <form name={"form"} onSubmit={this.handleSubmit} noValidate={true}>
                                        <FormInput
                                            type={INPUT_TYPES.EMAIL}
                                            name={"email"}
                                            placeholder={messages["login.email.placeholder"]}
                                            label={messages["login.email.label"]}
                                            required={true}
                                            value={email}
                                            error={formErrors.email}
                                            valid={!formErrors.email}
                                            readOnly={true}
                                        />

                                        <FormInput
                                            type={INPUT_TYPES.PASSWORD}
                                            name={"password"}
                                            label={messages["login.password.label"]}
                                            placeholder={messages["login.password.placeholder"]}
                                            required={true}
                                            value={password}
                                            onChange={this.handleChange}
                                            error={formErrors.password}
                                            valid={!formErrors.password}
                                        />

                                        <div className={`${BTN_CLASSES.GROUP} ${ALIGNMENT.RIGHT} login`}>
                                            <Button
                                                type={BTN_TYPES.SUBMIT}
                                                label={"Registreer"}
                                                buttonStyle={BTN_CLASSES.PRIMARY}
                                                styles={{marginTop: 20}}
                                                disabled={!passwordValid}
                                            />
                                        </div>
                                    </form>
                                </div>
                            )
                            : <div className="empty-list" style={{color: "#9D9694"}}>Je gegevens zijn niet correct<br />Volg de link die je van ons kreeg.</div>
                        }
                    </Col>
                </Row>

                <Toasts/>
            </Container>
        );
    };
}

const mapStateToProps = ({authReducer}) => {
    const {formErrors} = authReducer;
    return {formErrors};
};

export default connect(mapStateToProps, {registerAccount})(injectIntl(Register));
import React, { Component } from 'react';
import Button from "../../utils/Buttons/Button";
import { BTN_CLASSES, BTN_TYPES } from "../../css_constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faComments } from "@fortawesome/free-solid-svg-icons";
import userImage from "../../assets/images/user.png";
import './Comments.scss';
import DateUtility from "../../utils/dateUtils";
import Stickers from "../Stickers/Stickers";
import { connect } from "react-redux";
import { addComment } from "../../redux/actions/comment.actions";
import { addStickers } from "../../redux/actions/stickers.actions";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { MentionsInput, Mention } from "react-mentions"
import { loadCoces } from "../../redux/actions/coce.actions";
import { isNil } from "../../utils/helperFunctions";


/**
 * Default Comment-component
 */
class Comments extends Component {
    constructor(props) {
        super(props);
        this.highlightedComment = React.createRef();

        /** Initial state of the component */
        this.initialState = {
            value: '',
            formValid: false,
            rows: 1,
            minRows: 1,
            maxRows: 5,
        };

        /** State of the component */
        this.state = {
            showComments: !!props.highlightCommentId,
            ...this.initialState
        };

        // this.highlightRefs = props.comments.reduce((acc, value) => {
        //     acc[ value.id ] = React.createRef();
        //     return acc;
        // }, {});

        this.commentRefs = [];


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const {loadCoces, coces} = this.props;
        if ( coces && coces.length === 0 )
            loadCoces();

        if ( !isNil(this.props.highlightCommentId) ) this.executeScroll(this.props.highlightCommentId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( !isNil(this.props.highlightCommentId) ) this.executeScroll(this.props.highlightCommentId);
    }

    setRef = (ref) => {
        this.commentRefs[ this.props.highlightCommentId ] = ref;
    }

    /**
     * Loading the default user image
     * @param ev
     */
    addDefaultSrc(ev) {
        ev.target.src = userImage;
    }


    /**
     * Handle change of the comment textarea
     * @param e
     */
    handleChange(e) {
        const textareaLineHeight = 24;
        const {minRows, maxRows} = this.state;
        const {value} = e.target;

        const previousRows = e.target.rows;
        e.target.rows = minRows;

        const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

        if ( currentRows === previousRows ) {
            e.target.rows = currentRows;
        }

        if ( currentRows >= maxRows ) {
            e.target.rows = maxRows;
            e.target.scrollTop = e.target.scrollHeight;
        }

        this.setState({
            value: value,
            rows: currentRows < maxRows ? currentRows : maxRows,
            formValid: value.length > 0
        });
    };


    /**
     * Handle submitting the comment form
     */
    handleSubmit() {
        const {addComment, obj, objType} = this.props;
        let {value} = this.state;
        let ids = [];

        const tmpStr = value.match(/@__(.+?)\^\^\^__/g);
        if ( tmpStr ) ids = tmpStr.map(x => x.replace('@__', '').replace("^^^__", ""))

        value = value.split('@__').join("<a href=\"/medewerkers/");
        value = value.split('^^^__').join("\">");
        value = value.split('@@@^^^').join("</a>");

        const data = {
            type: objType,
            id: obj.id,
            body: value,
            tagged: ids
        };

        addComment(data);

        this.setState({...this.initialState})
    }

    executeScroll = (id) => {
        if ( !this.commentRefs[ id ] ) return;
        this.commentRefs[ id ].scrollIntoView({behavior: "smooth", inline: "start"});
    }

    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {comments, highlightCommentId, addButton, user, intl, permissions} = this.props;
        const {showComments, value, rows, formValid} = this.state;
        const {messages} = intl;

        const users = this.props.users.map(x => ({id: x.id, display: `${x.firstName} ${x.lastName}`}))

        /** returning comment component */
        return (
            <div className={"comments"}>
                {(addButton !== false && user) &&
                <div>
                    {permissions.includes("comments_see") &&
                    <Button type={BTN_TYPES.BUTTON}
                            buttonStyle={BTN_CLASSES.PRIMARY}
                            buttonSize={BTN_CLASSES.SMALL}
                            className={`toggle ${showComments && comments.length > 0 ? 'active' : ''}`}
                            onClick={() => this.setState({showComments: !showComments})}
                    >
                        <FontAwesomeIcon icon={faComments}/> <FormattedMessage id={"comments.button"}
                                                                               values={{count: (comments && comments.length ? comments.length : 0)}}/>
                    </Button>
                    }

                    {permissions.includes("comments_add") &&
                        <div className={"form"}>
                            <img onError={this.addDefaultSrc} src={user.picture ? user.picture : ""}
                                 alt={`${user.firstName} ${user.lastName}`}/>


                            <div className={"form-group"}>
                                <MentionsInput value={value} onChange={this.handleChange} className={"textarea"}
                                               name={"comment"} rows={rows} placeholder={messages[ "comments.message" ]}>
                                    <Mention trigger="@"
                                             data={permissions.includes("comments_mention") ? users : []}
                                             markup="@____id__^^^____display__@@@^^^"
                                             style={{
                                                 backgroundColor: "rgba(239, 125, 0, .1)",
                                                 borderRadius: 3
                                             }}
                                    />
                                </MentionsInput>
                            </div>


                            <Button type={BTN_TYPES.SUBMIT}
                                    buttonStyle={BTN_CLASSES.PRIMARY}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    disabled={!formValid}
                                    label={<FontAwesomeIcon icon={faComment}/>}
                                    onClick={this.handleSubmit}
                            />
                        </div>
                    }
                </div>
                }


                {permissions.includes("comments_see") && comments && comments.length > 0 &&
                <ul className={`comment-wrapper ${showComments || addButton === false ? 'show' : 'hide'}`}>
                    {comments.map((comment, i) => {
                        const {addStickers, stickers} = this.props;
                        const {id, author, body, createdAt} = comment;
                        const {firstName, lastName, picture} = author;

                        if ( showComments || addButton === false )
                            addStickers('comments', id);

                        return (
                            <li ref={this.setRef} key={i}
                                className={`comment ${comment.id === highlightCommentId ? 'highlight' : ''} `}>
                                <img onError={this.addDefaultSrc} src={picture ? picture : ""}
                                     alt={`${firstName} ${lastName}`}/>
                                <div className={"comment__header"}>
                                    <div className={"commemt__user"}>{`${firstName} ${lastName}`}</div>
                                    <div className={"comment__date"}>{DateUtility.toShortDate(createdAt)}</div>
                                </div>
                                <div className={"comment__body"}
                                     dangerouslySetInnerHTML={{__html: body.replaceAll(/\n\r?/g, '<br/>')}}/>

                                <Stickers stickers={stickers.comments[ id ]} addButton={addButton} obj={comment}
                                          objType={"comments"}/>
                            </li>
                        )
                    })}
                </ul>
                }
            </div>
        )
    }
}

Comments.defaultProps = {
    comments: [], addButton: true, showComments: false
};

Comments.propTypes = {
    comments: PropTypes.array.isRequired,
    addButton: PropTypes.bool.isRequired
};


const mapStateToProps = ({authReducer, coceReducer, userReducer, stickerReducer}) => {
    const {user, permissions} = authReducer;
    const {users} = userReducer;
    const {coces} = coceReducer;
    const {stickers} = stickerReducer;
    return {stickers, user, users, coces, permissions};
};


const mapDispatchToProps = {
    addComment, addStickers, loadCoces
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Comments));
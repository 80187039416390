import React from 'react';
import './FormInput.scss'
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import TimeRange from "./TimeRange";
import {faClock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

let options = {year: 'numeric', month: 'long', day: 'numeric'};

/**
 * Time range component
 * @param name
 * @param label
 * @param value
 * @param valid
 * @param description
 * @param onChange
 * @param onBlur
 * @param error
 * @param className
 * @param required
 * @param sameIsValid
 * @param intl
 * @returns {*}
 * @constructor
 */
const FormTimeRange = ({name, label, value, valid, description, onChange, onBlur, error, className, disabled, required, sameIsValid, intl}) => (
    <div className={'form-group time ' + className}>
        <label htmlFor={name}>{label} {new Date(value.day).toLocaleDateString('nl-BE', options)}</label>
        <TimeRange use24Hours={true}
                   startLabel={(<FontAwesomeIcon icon={faClock}/>)}
                   endLabel="tot"
                   startMoment={value.startTime}
                   endMoment={value.endTime}
                   onChange={({startTime, endTime}) => onChange(startTime, endTime)}
                   onStartTimeClick={() => onBlur({target: name})}
                   onEndTimeClick={() => onBlur({target: name})}
                   equalTimeError="Begin -en einduur kunnen niet gelijk zijn."
                   endTimeError="Einduur kan niet voor startuur zijn."
                   sameIsValid={sameIsValid}
                   disabled={disabled}
        />
        <div className="description">{description}</div>
    </div>
);


FormTimeRange.defaultProps = {
    label: '', value: '', error: '', valid: '', required: false, className: '',
};


FormTimeRange.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};


export default injectIntl(FormTimeRange);
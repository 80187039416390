import {LOAD_USER, LOGIN_USER, LOGOUT_USER, UPDATE_ACCOUNT} from '../constants/auth.constants';
import {API_URL} from "../constants/defaults";
import axios from "axios";
import {UPDATE_USER} from "../constants/user.constants";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";

export const login = (body) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;

    dispatch({type: LOGIN_USER, payload: {userId: null, token: null, userLoading: true, formErrors: []}});
    axios.post(API_URL + '/login', body)
        .then(({data}) => {
            const {userId, token} = data;
            const formErrors = {email: '', password: ''};

            // TODO: change sessionStorage to localStorage
            sessionStorage.setItem('userId', userId);
            sessionStorage.setItem('apiToken', token);
            dispatch({type: LOGIN_USER, payload: {userId, token, userLoading: true, formErrors}});
            loadProfile(userId, token, dispatch, messages)
        })
        .catch(error => {
            const message = handleErrors(messages, error);
            dispatch({type: LOGOUT_USER, payload: {formErrors: {'email': message}}});
        });
};

export const registerAccount = (body) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;

    dispatch({type: LOGIN_USER, payload: {userId: null, token: null, userLoading: true, formErrors: []}});
    axios.post(API_URL + '/register', body)
        .then(({data}) => {
            const {userId, token} = data;
            const formErrors = {email: '', password: ''};

            sessionStorage.setItem('userId', userId);
            sessionStorage.setItem('apiToken', token);
            dispatch({type: LOGIN_USER, payload: {userId, token, userLoading: true, formErrors}});
            loadProfile(userId, token, dispatch, messages);
        })
        .catch(error => {
            const message = handleErrors(messages, error);
            dispatch({type: LOGOUT_USER, payload: {formErrors: {'email': message}}});
        });
};

export const loadUser = () => (dispatch, getState) => {
    const userId = sessionStorage.getItem('userId');
    const token = sessionStorage.getItem('apiToken');
    const formErrors = {email: '', password: ''};

    if (userId && token) {
        const state = getState();
        const {messages} = state.intl;

        dispatch({type: LOGIN_USER, payload: {userId, token, userLoading: true, formErrors}});
        loadProfile(userId, token, dispatch, messages);
    }
};

export const getUser = (body) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;

    dispatch({type: LOGIN_USER, payload: {userId: null, token: null, userLoading: true, formErrors: []}});
    axios.post(API_URL + '/get-user', body)
        .then(({data}) => {
            dispatch({type: LOAD_USER, payload: {user: data, userLoading: false}});
        })
        .catch(error => {
            console.log(error)
            const message = handleErrors(error, messages);
            dispatch({type: LOGOUT_USER, payload: {formErrors: {'email': message}}});
        });
}


export const logout = () => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};

    axios.get(API_URL + '/logout', header)
        .then(({data}) => {
            const formErrors = {email: '', password: ''};
            dispatch({type: LOGOUT_USER, payload: {formErrors}});
            clearSessionStorage();
        })
        .catch(error => {
            const formErrors = {email: '', password: ''};
            dispatch({type: LOGOUT_USER, payload: {formErrors}});
            clearSessionStorage();
        });
};


const loadProfile = (userId, token, dispatch, messages) => {
    const header = {headers: {'Authorization': "Bearer " + token}};

    axios.get(API_URL + `/users/${userId}`, header)
        .then(({data}) => {
            dispatch({type: LOAD_USER, payload: {user: data, userLoading: false}});
        })
        .catch(error => {
            const message = handleErrors(error, messages);
            dispatch({type: LOGOUT_USER, payload: {formErrors: {'email': message}}});
        });
};

export const updateAccount = (data) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token, user} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(`${API_URL}/users/${user.id}`, data, header)
        .then(({data}) => {
            dispatch({type: UPDATE_ACCOUNT, payload: {user: data}});
            dispatch({type: UPDATE_USER, payload: {user: data}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["account.response.success"], type: 'success'})}})
        })
        .catch(error => {
            console.log(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["account.response.error"], type: 'error'})}})
        });
};

export const saveWellBeing = data => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(`${API_URL}/well-being`, data, header)
        .then(({data}) => {
            dispatch({type: UPDATE_ACCOUNT, payload: {user: data}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "Welzijnsbarometer succesvol bewaard", type: 'success'})}})
        })
        .catch(error => {
            console.log(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "Barometer verzenden mislukt vanwege een onverwachte fout.", type: 'error'})}})
        });
}


const handleErrors = (messages, error) => {
    if (error.response) {
        if (error.response.status && error.response.status === 401) {
            return messages["login.response.credentials.invalid"];
        }

        if (error.response.status && error.response.status === 404) {
            return "Gegevens niet correct. Volg de link uit de e-mail die je van ons kreeg.";
        }
    }
    return messages["login.response.error"];
};

const clearSessionStorage = () => {
    sessionStorage.clear();
};

import React, {Component} from "react";
import Form from "../../../utils/Forms/Form";
import Button from "../../../utils/Buttons/Button";
import {BTN_CLASSES} from "../../../css_constants";
import {Col, Row} from "../../../utils/Grid/Grid";
import {connect} from "react-redux";
import {loadArticle, postArticle} from "../../../redux/actions/news.actions";
import {loadTags} from "../../../redux/actions/tag.actions";
import {Link} from "react-router-dom";
import CustomForm from "../CustomForm/CustomForm";
import Load from "../../../utils/Loading/Load";
import UrlModal from "../UrlModal/UrlModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faGlobeEurope, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";

class AddArticle extends Component {
    constructor(props) {
        super(props);
        document.title = `UniPartners Group | Admin nieuws | Nieuw`;

        this.state = {
            form: {
                format: [
                    {
                        type: "col",
                        breakpoint: 8,
                        fields: ["title"]
                    }, {
                        type: "col",
                        breakpoint: 4,
                        fields: ["published", "urgent"]
                    }, {
                        type: "col",
                        breakpoint: 12,
                        fields: ["body"]
                    }, {
                        type: "col",
                        breakpoint: 6,
                        fields: ["tags"]
                    }, {
                        type: "col",
                        breakpoint: 6,
                        fields: ["attachments"]
                    }
                ],
                fields: [
                    {
                        name: "title",
                        type: "text",
                        required: true,
                        placeholder: "De titel van het artikel",
                        label: "Titel",
                        autocomplete: false,
                        description: null
                    }, {
                        name: "body",
                        type: "tiny",
                        required: false,
                        placeholder: "De inhoud van het artikel",
                        label: "Inhoud",
                        autocomplete: false,
                        description: (
                            <div style={{display: 'flex', marginTop: 5, marginBottom: 5}}>
                                <FontAwesomeIcon icon={faExclamationTriangle} style={{height: 25, width: 'auto', margin: '5px 10px 5px 0', color: '#ef7d00'}} />
                                <div>
                                    <b>OPGELET!</b><br />
                                    De inhoud van dit nieuwsbericht wordt via intranet en de nieuwsbrief binnen heel UniPartners gedeeld. Deel hier geen gevoelige informatie.
                                </div>
                            </div>
                        )
                    }, {
                        name: "published",
                        type: "checkbox",
                        required: false,
                        label: "Publiceer het artikel",
                    }, {
                        name: "urgent",
                        type: "checkbox",
                        required: false,
                        label: "Dringend bericht",
                        description: "De nieuwsbrief zal meteen verzonden worden"
                    }, {
                        name: "attachments",
                        type: "dropzone",
                        required: false,
                        label: "",
                        description: null,
                        files: []
                    }, {
                        name: "language",
                        type: "radio",
                        value: "Nederlands",
                        required: false,
                        label: "De taal van het artikel",
                        options: ["Nederlands", "Frans"]
                    }, {
                        name: "tags",
                        type: "creatable",
                        required: false,
                        multiple: true,
                        placeholder: "De tags van het artikel",
                        label: "Tags",
                        autocomplete: false,
                        description: null,
                        options: []
                    },
                ]
            },
            formValid: false,
            links: [],

            customForm: {
                formSize: "medium",
                formName: "Nieuw formulier",

                validateForm: false,
                steps: 1,
                step: 1,
                currentIndex: 0,
                previousIndex: 0,
                nextIndex: 0,
                skipped: [],

                format: [
                    {
                        type: "group",
                        name: "group1",
                        direction: "horizontal",
                        fields: [],
                    }
                ],
                fields: []
            },
            showCustomForm: false,
            loading: false
        };

        this.urls = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const {loadTags, loadArticle, match} = this.props;
        const {id} = match.params;

        /**
         * Load tags
         */
        loadTags();

        /**
         * Load original article if you want to clone
         */
        if (id) loadArticle(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tags, selectedArticle, match} = this.props;
        const {id} = match.params;

        /**
         * Set tags
         */
        if (prevState.form.fields.find(x => x.name === "tags").options.length !== tags.length) {
            const fields = this.state.form.fields.map(field => {
                if (field.name !== "tags") return field;

                return {
                    ...field,
                    options: tags.map(tag => (tag.tag ))
                }
            });

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields
                    }
                }
            });
        }

        /**
         * Set single language
         */
        if (selectedArticle && id && prevState.form.fields.find(x => x.name === 'language').options.length ===  2) {
            const fields = this.state.form.fields.map(field => {
                if (field.name !== "language" && field.type !== "dropzone") return field;

                if (field.type === "dropzone") {
                    return {
                        ...field,
                        description: "Afbeeldingen zijn geladen bij het basisartikel"
                    }
                }

                const language = selectedArticle.language === 'nl' ? 'Frans' : 'Nederlands';
                return {
                    ...field,
                    options: [language],
                    value: language
                }
            });

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields
                    }
                }
            });
        }
    }

    handleSubmit() {
        const {postArticle, match} = this.props;
        const {form, links, customForm} = this.state;
        const {id} = match.params;

        this.setState({loading: true})

        const data = new FormData();
        for(const field of form.fields) {

            if (field.type !== 'dropzone' && !(field.type === 'creatable' || field.type === 'select')) {
                data.set(field.name, field.value)
            } else if ((field.type === 'creatable' || field.type === 'select') && field.multiple) {
                for (const value of field.value) {
                    data.append(`${field.name}[]`, (value.hasOwnProperty('value') ? value.value : value))
                }
            } else if ((field.type === 'creatable' || field.type === 'select') && !field.multiple) {
                data.append(`${field.name}`, (field.value.hasOwnProperty('value') ? field.value.value : field.value))
            } else {
                for (const file of field.files) {
                    data.append(`${field.name}[]`, file)
                }
            }
        }

        if (customForm.fields.length !== 0) {
            data.append('form[name]', customForm.formName);
            data.append('form[size]', customForm.formSize);

            for (const format of customForm.format) {
                data.append('form[format][]', JSON.stringify(format));
            }

            for (const field of customForm.fields) {
                data.append('form[fields][]', JSON.stringify(field));
            }
        }

        if (links.length !== 0) {
            for (let i = 0; i <= links.length - 1; i++) {
                data.append(`links[${i}][name]`, links[i].name)
                data.append(`links[${i}][url]`, links[i].url)
            }
        }

        postArticle(data, this.props.history, id);
    }

    render() {
        const {form, showCustomForm, customForm, loading} = this.state;

        return !showCustomForm
            ? (
                loading
                    ? <Load showPatience/>
                    : (
                        <Row>
                            <Form form={form}
                                  formValid={(bool) => this.setState({formValid: bool})}
                                  updateFields={(form) => this.setState({form: form})}
                            />

                            {this.renderButtons()}

                            <UrlModal ref={this.urls} handleSave={(obj) => this.setState(prevState => ({links: [...prevState.links, obj]}))}/>
                        </Row>
                    )
            )
            : <CustomForm customForm={customForm} handleForm={(form) => this.setState({showCustomForm: false, customForm: form ? form : customForm})}/>
    }

    renderButtons() {
        const {formValid, links} = this.state;

        return (
            <Col md={12}>
                <Row>
                    <Col xl={4} lg={6} md={8}>
                        {links.length !== 0 &&
                        <div className="uploaded-files">
                            {links.map((link, index) => (
                                <div className="file" key={index}>
                                    <div>
                                        <FontAwesomeIcon icon={faGlobeEurope} fixedWidth/>
                                        {link.name && link.name !== '' ? link.name : link.url}
                                    </div>
                                    <Button buttonStyle={BTN_CLASSES.LINK}
                                            buttonSize={BTN_CLASSES.SMALL}
                                            className={BTN_CLASSES.DANGER}
                                            onClick={() => this.setState(prevState => ({links: prevState.links.filter(x => x.name !== link.name && x.url !== link.url)}))}
                                    >
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </Button>
                                </div>

                            ))}

                            <div className="delete">
                                <Button buttonStyle={BTN_CLASSES.LINK}
                                        buttonSize={BTN_CLASSES.SMALL}
                                        className={BTN_CLASSES.DANGER}
                                        onClick={() => this.setState({links: []})}
                                >
                                    <FontAwesomeIcon icon={faTrash}/> Verwijder alles
                                </Button>
                            </div>
                        </div>
                        }

                        <div className={`${BTN_CLASSES.GROUP} flex`}>
                            <Button label={"URL's als bijlage toevoegen"}
                                    buttonStyle={BTN_CLASSES.SECONDARY}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    onClick={() => this.urls.current.handleShow()}
                                    styles={{width: '100%'}}
                            />

                            <Button label={"Formulier maken"}
                                    buttonStyle={BTN_CLASSES.SECONDARY}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    onClick={() => this.setState({showCustomForm: true})}
                            />
                        </div>
                    </Col>

                    <Col md={12} style={{margin: "1rem 0", textAlign: "right"}}>
                        <div className={BTN_CLASSES.GROUP}>
                            <Link to={`/nieuws`}
                                  className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.SECONDARY}`}
                            >
                                Annuleren
                            </Link>

                            <Button buttonStyle={BTN_CLASSES.PRIMARY}
                                    disabled={!formValid}
                                    onClick={this.handleSubmit}
                            >
                                Bewaar
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Col>
        )
    }
}

const mapStateToProps = ({tagReducer, newsReducer}) => {
    const {tags} = tagReducer;
    const {selectedArticle} = newsReducer;
    return {tags, selectedArticle};
};

const mapDispatchToProps = {
    loadArticle, postArticle, loadTags
};

export default connect(mapStateToProps, mapDispatchToProps)(AddArticle);
import React, {useState} from "react";
import Button from "../../utils/Buttons/Button";
import {BTN_CLASSES} from "../../css_constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPowerOff, faThumbtack, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Col} from "../../utils/Grid/Grid";
import userImage from "../../assets/images/user.png";
import Modal from "../../utils/Modal/Modal";

const SingleArticle = ({id, title, image, pinned, publishedAt, slug, ...props}) => {
    const {sm, md, lg, pinArticle, publishArticle, deleteArticle, permissions, role, isAuthor, messages} = props;

    const [showPublicationModal, setShowPublicationModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);

    const addDefaultSrc = (ev) => {
        ev.target.src = userImage;
    }

    const hasPermission = role.name === 'admin' || role.name === 'dev' || role.name === 'editor' || role.name === 'staff';

    return (
        <Col sm={sm} md={md} lg={lg} className={"article"}>
            <Button buttonStyle={BTN_CLASSES.LINK}
                    className={`pin ${pinned ? 'active' : ''} ${image && image.file ? '' : 'no-img'}`}
                    onClick={() => pinArticle(id, !pinned)}
            >
                <FontAwesomeIcon icon={faThumbtack}/>
            </Button>

            {(permissions.includes("news_publish") || permissions.includes("news_update") || permissions.includes("news_delete") || permissions.includes("news_add")) && hasPermission &&
            <div className={"buttonStack"}>
                {permissions.includes("news_publish") &&
                <>
                    <Button buttonStyle={BTN_CLASSES.LINK}
                            className={publishedAt ? 'active' : null}
                            onClick={() => publishedAt ? setShowPublicationModal(true) : publishArticle(id)}
                    >
                        <FontAwesomeIcon icon={faPowerOff} fixedWidth/>
                    </Button>

                    <Modal cancel={false}
                           show={showPublicationModal}
                           close={() => setShowPublicationModal(!showPublicationModal)}
                           title="Weet je het zeker?"
                           actions={[{
                               label: "Ja",
                               action: () => {
                                   publishArticle(id);
                                   setShowPublicationModal(false);
                               },
                               disabled: true
                           }, {
                               label: "Nee",
                               action: () => setShowPublicationModal(false),
                               buttonType: 'btn-secondary',
                               disabled: true
                           }]}>
                        Door op <i>Ja</i> te klikken, zal de publicatie ongedaan gemaakt worden.<br />
                        Weet je zeker dat je wil doorgaan?
                    </Modal>
                </>
                }

                {permissions.includes("news_update") &&
                <Link to={"/artikel-wijzigen/" + id} className={BTN_CLASSES.LINK}><FontAwesomeIcon icon={faEdit} fixedWidth/></Link>
                }

                {permissions.includes("news_delete") &&
                <>
                    <Button buttonStyle={BTN_CLASSES.LINK}
                            onClick={() => setShowRemoveModal(true)}
                    >
                        <FontAwesomeIcon icon={faTrash} fixedWidth/>
                    </Button>

                    <Modal cancel={false}
                           show={showRemoveModal}
                           close={() => setShowRemoveModal(!showRemoveModal)}
                           title="Weet je het zeker?"
                           actions={[{
                               label: "Ja",
                               action: () => deleteArticle(id, props.history),
                               disabled: true
                           }, {
                               label: "Nee",
                               action: () => setShowRemoveModal(false),
                               buttonType: 'btn-secondary',
                               disabled: true
                           }]}>
                        Door op <i>Ja</i> te klikken, zal dit item verwijderd worden. Deze actie kan niet ongedaan gemaakt worden.<br />
                        Weet je zeker dat je wil doorgaan?
                    </Modal>
                </>
                }


                {/*permissions.includes("news_add") && !translated &&
                    <Link to={"/artikel-toevoegen/" + id} className={`first ${BTN_CLASSES.BTN} ${BTN_CLASSES.LINK}`}><FontAwesomeIcon icon={faClone} fixedWidth/></Link>
                    */}
            </div>
            }

            <Link to={messages["routes.news"] + "/" + slug}>
                {image && image.file &&
                <div className={"img-wrapper"}>
                    <img src={image.file} alt={image.description} className={"img"} onError={addDefaultSrc}/>
                </div>
                }
                <div className={(image && image.file ? '' : 'no-img ') + "title"}>
                    <h2>{title}</h2>
                </div>
            </Link>
        </Col>
    )
}

export default SingleArticle;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { downloadAttachment, getAllDamageClaims, getAllIllnessClaims, toggleClaimState } from '../../../redux/actions/claimsAdmin.actions';
import ClaimsList from "./ClaimsList";
import Load from "../../../utils/Loading/Load";

class ClaimsAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPathElement: '',
            showClaimsModal: false,
            selectedIllnessClaim: null,
            selectedDamageClaim: null,
        }
    }

    componentDidMount() {
        const {getAllDamageClaims, getAllIllnessClaims, location} = this.props;

        const pathElements = location.pathname.split("/");
        this.setState({currentPathElement: pathElements[2]});
        if ( pathElements[2].toUpperCase() === 'ZIEKTE' ) getAllIllnessClaims();
        if ( pathElements[2].toUpperCase() === 'SCHADE' ) getAllDamageClaims();

        document.title = `UniPartners Group | Admin ${pathElements[2]}`;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    openModalHandler = (item, claimType) => {
        claimType.toUpperCase() === 'ILLNESS'
            ? this.setState({showClaimsModal: true, selectedIllnessClaim: item})
            : this.setState({showClaimsModal: true, selectedDamageClaim: item})
    }

    closeModalHandler = (claimType) => {
        claimType.toUpperCase() === 'ILLNESS'
            ? this.setState({showClaimsModal: false, selectedIllnessClaim: null})
            : this.setState({showClaimsModal: false, selectedDamageClaim: null})
    }

    toggleClaimHandler = (item, claimType) => {
        const {toggleClaimState} = this.props;

        toggleClaimState(item.id, claimType, !item.status);
    }

    render() {
        const {isLoading, illnessList, damageList, downloadAttachment} = this.props;
        const claimList = this.state.currentPathElement.toUpperCase() === 'ZIEKTE' ? illnessList : damageList;
        const selectedClaim = this.state.currentPathElement.toUpperCase() === 'ZIEKTE'
            ? this.state.selectedIllnessClaim
            : this.state.selectedDamageClaim;

        return (
            <div>
                <h1>{this.state.currentPathElement.toUpperCase()}</h1>
                {isLoading
                    ? <Load/>
                    : <ClaimsList
                        topic={this.state.currentPathElement.toUpperCase()}
                        itemList={claimList}
                        selectedClaim={selectedClaim}
                        openModal={this.openModalHandler}
                        closeModal={this.closeModalHandler}
                        toggleClaim={this.toggleClaimHandler}
                        showClaimsModal={this.state.showClaimsModal}
                        downloadClaimAttachment={downloadAttachment}/>
                }
            </div>

        );
    }
}

const mapStateToProps = ({claimsAdminReducer}) => {
    const {isLoading, illnessList, damageList} = claimsAdminReducer;
    return {isLoading, illnessList, damageList};
}

const mapDispatchToProps = {
    getAllIllnessClaims, getAllDamageClaims, toggleClaimState, downloadAttachment
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsAdmin);
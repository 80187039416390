import axios from 'axios';
import {
    DOWNLOAD_CLAIM_ATTACHMENT,
    GET_ALL_DAMAGE_CLAIMS,
    GET_ALL_ILLNESS_CLAIMS,
    LOADING_ADMIN_CLAIMS, TOGGLE_ADMIN_CLAIM_STATE
} from "../constants/claimsAdmin.constants";
import { API_URL } from "../constants/defaults";
import { handleErrors } from "../../utils/helperFunctions";
import { ADD_TOAST } from "../constants/toast.constants";
import createToast from "../../factories/createToast";

export const getAllIllnessClaims = () => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const _HEADER = {headers: {'Authorization': `Bearer ${token}`}};

    dispatch({type: LOADING_ADMIN_CLAIMS});
    axios.get(`${API_URL}/illness/admin`, _HEADER)
        .then(({data}) => dispatch({type: GET_ALL_ILLNESS_CLAIMS, payload: {illnesses: data}}))
        .catch(error => {
            const message = handleErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        });
}

export const getAllDamageClaims = () => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const _HEADER = {headers: {'Authorization': `Bearer ${token}`}};

    dispatch({type: LOADING_ADMIN_CLAIMS});
    axios.get(`${API_URL}/damage/admin`, _HEADER)
        .then(({data}) => dispatch({type: GET_ALL_DAMAGE_CLAIMS, payload: {damages: data}}))
        .catch(error => {
            const message = handleErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        });
}

export const toggleClaimState = (id, claimType, claimState) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const _HEADER = {headers: {'Authorization': `Bearer ${token}`}};

    dispatch({type: LOADING_ADMIN_CLAIMS});
    axios.post(`${API_URL}/${claimType}/admin/${id}`, {status: claimState}, _HEADER)
        .then(({data}) => dispatch({type: TOGGLE_ADMIN_CLAIM_STATE, payload: {claim: data, claimType}}))
        .catch(error => {
            const message = handleErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        });
}

export const downloadAttachment = (attachment, claimType) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const _HEADER = {headers: {'Authorization': `Bearer ${token}`}};

    axios.get(`${API_URL}/${claimType}/${attachment.id}/data`, _HEADER)
        .then(({data}) => {
            dispatch({type: DOWNLOAD_CLAIM_ATTACHMENT});

            // Create a hidden a tag and click it to trigger the download
            let a = document.createElement('a');
            a.href = data.file;
            a.download = data.filename;
            a.click();
        })
        .catch(error => {
            const message = handleErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        });
}
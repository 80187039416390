import React from 'react';
import './FormInput.scss'
import PropTypes from "prop-types";

/**
 * Form radio component
 * @param name
 * @param label
 * @param options
 * @param value
 * @param valid
 * @param onClick
 * @param onChange
 * @param onBlur
 * @param error
 * @param className
 * @param required
 * @returns {*}
 * @constructor
 */
const FormRadio = ({name, label, options, value, valid, onClick, onChange, onBlur, error, className, required}) => (
    <div className={'form-group ' + className}>
        {label && <label htmlFor={name}>{label} { required ? <code>*</code> : null }</label>}

        {options.map((option, i) => {
            return (
                <div key={i} className={"radio " + (valid === '' ? '' : (valid ? 'is-valid' : 'is-invalid'))}>
                    <input type={"radio"}
                           name={name}
                           id={name + (option.value ? option.value : option)}
                           onBlur={onBlur}
                           checked={value === (option.value ? option.value : option)}
                           onClick={onClick}
                           onChange={onChange}
                           value={option.value ? option.value : option}
                    />

                    <label htmlFor={name + (option.value ? option.value : option)} className={name + (option.value ? option.value : option) + ' ' + (option.value ? option.value : option)}>{option.label ? option.label : (option.value ? option.value : option)}</label>
                </div>
            )
        })}
        <small className="invalid-feedback">{error}</small>
    </div>
);


FormRadio.defaultProps = {
    label: '', checked: '', error: '', valid: '', required: false, className: '',
};


FormRadio.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};


export default FormRadio;
import React, {Component} from 'react';
import {Container} from "../../utils/Grid/Grid";
import {Redirect, Route, Switch} from "react-router-dom";
import Header from "./Header/Header";
import {injectIntl} from "react-intl";
import CrownBar from "../CrownBar/CrownBar";
import TrainingList from "../Trainings/TrainingList";
import NewsList from "../News/NewsList";
import NewsDetail from "../News/NewsDetail";
import EventList from "../Events/EventList";
import EventDetail from "../Events/EventDetail";
import TrainingDetail from "../Trainings/TrainingDetail";
import Employee from "../Employee/Employee";
import Home from "../Home/Home";
import Account from "../Account/Account";
import EmployeeDetail from "../Employee/EmployeeDetail";
import Toasts from "../../utils/Toasts/Toasts";
import AddArticle from "../Admin/News/AddArticle";
import Sandwich from "../Admin/Sandwich/Sandwich";
import UpdateArticle from "../Admin/News/UpdateArticle";
import AddEvent from "../Admin/Events/AddEvent";
import UpdateEvent from "../Admin/Events/UpdateEvent";
import AddTraining from "../Admin/Trainings/AddTraining";
import UpdateTraining from "../Admin/Trainings/UpdateTraining";
import {connect} from "react-redux";
import IllnessList from "../Claims/IllnessList";
import DamageList from "../Claims/DamageList";
import Calendar from "../Calendar/Calendar";
import Notification from "../Notification/Notification";
import Documents from "../Documents/Documents";
import Permissions from "../Admin/Permissions/Permissions";
import ClaimsAdmin from "../Admin/Claims/ClaimsAdmin";
import SubmissionResults from "../Admin/SubmissionResults/SubmissionResults";
import Instructors from "../Admin/Instructors/Instructors";

/**
 * Layout component
 */
class Layout extends Component {
    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {permissions, intl} = this.props;
        const {messages} = intl;

        /** Returns LanguageSwitcher, Header and Toasts and holds the websites routes */
        return (
            <div>
                <CrownBar permissions={permissions}/>
                <Header/>

                <main>
                    <Container>
                        <Switch>
                            <Route exact={true}
                                   path={messages["routes.home"]}
                                   component={() => <Home/>}
                            />

                            {permissions.includes("users_see_overview") &&
                            <Route exact={true}
                                   path={messages["routes.employees"]}
                                   component={(props) => <Employee {...props}/>}
                            />
                            }

                            {permissions.includes("users_see_detail") &&
                            <Route exact={true}
                                   path={messages["routes.employees"] + '/:id'}
                                   component={(props) => <EmployeeDetail {...props}/>}
                            />
                            }

                            {permissions.includes("news_see_published") &&
                            <Route exact={true}
                                   path={messages["routes.news"]}
                                   component={(props) => <NewsList {...props}/>}
                            />
                            }

                            {permissions.includes("news_see_published") &&
                            <Route exact={true}
                                   path={messages["routes.news"] + "/:slug"}
                                   component={(props) => <NewsDetail {...props}/>}
                            />
                            }

                            {permissions.includes("events_see_published") &&
                            <Route exact={true}
                                   path={messages["routes.events"]}
                                   component={(props) => <EventList {...props}/>}
                            />
                            }

                            {permissions.includes("events_see_published") &&
                            <Route exact={true}
                                   path={messages["routes.events"] + '/:slug'}
                                   component={(props) => <EventDetail {...props}/>}
                            />
                            }

                            {permissions.includes("events_see_results") &&
                            <Route exact={true}
                                   path={'/:type/:slug/inschrijvingen'}
                                   component={(props) => <SubmissionResults {...props}/>}
                            />
                            }

                            {permissions.includes("trainings_see_results") &&
                            <Route exact={true}
                                   path={'/:type/:slug/inschrijvingen'}
                                   component={(props) => <SubmissionResults {...props}/>}
                            />
                            }

                            {permissions.includes("news_see_results") &&
                            <Route exact={true}
                                   path={'/:type/:slug/resultaten'}
                                   component={(props) => <SubmissionResults {...props}/>}
                            />
                            }

                            {permissions.includes("trainings_see_published") &&
                            <Route exact={true}
                                   path={messages["routes.trainings"]}
                                   component={(props) => <TrainingList {...props}/>}
                            />
                            }

                            {permissions.includes("trainings_see_published") &&
                            <Route exact={true}
                                   path={messages["routes.trainings"] + '/:id'}
                                   component={(props) => <TrainingDetail {...props}/>}
                            />
                            }

                            {permissions.includes("claims_illnesses_see") && permissions.includes("claims_illnesses_add") &&
                            <Route exact={true}
                                   path={messages[ "routes.illness" ] + '/:id?'}
                                   component={(props) => <IllnessList {...props}/>}
                            />
                            }

                            {permissions.includes("claims_damages_see") && permissions.includes("claims_damages_add") &&
                            <Route exact={true}
                                   path={messages[ "routes.damage" ] + '/:id?'}
                                   component={(props) => <DamageList {...props}/>}
                            />
                            }

                            <Route exact={true}
                                   path={messages["routes.user"]}
                                   component={(props) => <Account {...props}/>}
                            />

                            <Route exact={true}
                                   path={messages["routes.calendar"]}
                                   component={props => <Calendar {...props}/>}
                            />


                            {permissions.includes("news_add") &&
                            <Route exact={true}
                                   path={"/artikel-toevoegen/:id?"}
                                   component={(props) => <AddArticle {...props}/>}
                            />
                            }

                            {permissions.includes("news_update") &&
                            <Route exact={true}
                                   path={"/artikel-wijzigen/:id"}
                                   component={(props) => <UpdateArticle {...props}/>}
                            />
                            }

                            {permissions.includes("events_add") &&
                            <Route exact={true}
                                   path={"/evenement-toevoegen/:id?"}
                                   component={(props) => <AddEvent {...props}/>}
                            />
                            }

                            {permissions.includes("events_update") &&
                            <Route exact={true}
                                   path={"/evenement-wijzigen/:id"}
                                   component={(props) => <UpdateEvent {...props}/>}
                            />
                            }

                            {permissions.includes("trainings_add") &&
                            <Route exact={true}
                                   path={"/opleiding-toevoegen/:id?"}
                                   component={(props) => <AddTraining {...props}/>}
                            />
                            }

                            {permissions.includes("trainings_update") &&
                            <Route exact={true}
                                   path={"/opleiding-wijzigen/:id"}
                                   component={(props) => <UpdateTraining {...props}/>}
                            />
                            }

                            {permissions.includes("documents_see") &&
                            <Route exact={true}
                                   path={messages["routes.documents"]}
                                   component={props => <Documents {...props}/>}
                            />
                            }

                            <Route exact
                                   path={'/notifications/:id?'}
                                   component={(props) => <Notification {...props}/>}
                            />

                            {permissions.includes("claims_illnesses_admin") &&
                            <Route exact
                                   path={'/admin/ziekte'}
                                   component={(props) => <ClaimsAdmin {...props}/>}
                            />
                            }

                            {permissions.includes("claims_damages_admin") &&
                            <Route exact
                                   path={'/admin/schade'}
                                   component={(props) => <ClaimsAdmin {...props}/>}
                            />
                            }

                            {permissions.includes("sandwiches_admin") &&
                            <Route exact
                                   path={'/admin/broodjes'}
                                   component={(props) => <Sandwich {...props}/>}
                            />
                            }

                            {permissions.includes("instructors_admin") &&
                            <Route exact={true}
                                   path={"/admin/lesgevers"}
                                   component={(props) => <Instructors {...props}/>}
                            />
                            }

                            {permissions.includes("permissions_admin") &&
                            <Route exact
                                   path={'/admin/permissies'}
                                   component={(props) => <Permissions {...props}/>}
                            />}

                            <Redirect from={messages["routes.redirect.calendar"]} to={messages["routes.calendar"]}/>
                            <Redirect from={messages["routes.redirect.trainings"] + '/:id'} to={messages["routes.trainings"] + '/:id'}/>
                            <Redirect from={messages["routes.redirect.trainings"]} to={messages["routes.trainings"]}/>
                            <Redirect from={messages["routes.redirect.events"] + '/:id'} to={messages["routes.events"] + '/:id'}/>
                            <Redirect from={messages["routes.redirect.events"]} to={messages["routes.events"]}/>
                            <Redirect from={messages["routes.redirect.news"] + '/:id'} to={messages["routes.news"] + '/:id'}/>
                            <Redirect from={messages["routes.redirect.news"]} to={messages["routes.news"]}/>
                            <Redirect from={messages["routes.redirect.employees"] + '/:id'} to={messages["routes.employees"] + '/:id'}/>
                            <Redirect from={messages["routes.redirect.employees"]} to={messages["routes.employees"]}/>
                            <Redirect from={messages["routes.redirect.documents"]} to={messages["routes.documents"]}/>
                            <Redirect from={messages["routes.redirect.user"]} to={messages["routes.user"]}/>
                            <Redirect from={"/"} to={messages["routes.home"]}/>
                        </Switch>
                    </Container>
                </main>

                <Toasts/>
            </div>
        );
    };
}


const mapStateToProps = ({authReducer}) => {
    const {permissions} = authReducer;
    return {permissions};
};


export default connect(mapStateToProps)(injectIntl(Layout));
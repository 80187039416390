import React, {Component} from "react";
import FormTextarea from "../../utils/Forms/FormTextarea";
import {Col, Row} from "../../utils/Grid/Grid";
import Button from "../../utils/Buttons/Button";
import {BTN_CLASSES} from "../../css_constants";
import Dropzone from "../../utils/Forms/Dropzone";
import DateUtility from "../../utils/dateUtils";
import moment from "moment";
import Attachments from "../../utils/Attachments/Attachments";
import {saveDamage} from "../../redux/actions/claims.actions";
import {selectDamage} from "../../redux/actions/app.actions";
import {connect} from "react-redux";
import FormDatepicker from "../../utils/Forms/FormDatepicker";
import FormCheckbox from "../../utils/Forms/FormCheckbox";
import FormInput from "../../utils/Forms/FormInput";
import {FormattedMessage, injectIntl} from "react-intl";
import { downloadAttachment } from "../../redux/actions/claimsAdmin.actions";

class DamageDetail extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            id: null,
            date: [{day: new Date()}],
            description: null,
            files: [],
            status: false,
            service: false,
            formValid: true,
        };

        /** State of the component */
        this.state = this.defaultState;

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    /**
     * Set damage details to state if they are not on mount
     */
    componentDidMount() {
        this.handleSetState();
    }


    /**
     * Set damage details to state if they are not set or clear the form
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.handleSetState();
    }


    /**
     * Set state when component mounts or updates
     */
    handleSetState() {
        const {id} = this.state;
        const {match, selectDamage, selectedDamage} = this.props;
        const {toMoment} = DateUtility;

        if (!selectedDamage || (selectedDamage && selectedDamage.id !== match.params.id)) selectDamage(parseInt(match.params.id));

        if (selectedDamage && (!id || id !== selectedDamage.id)) {
            this.setState({
                date: [{day: new Date(toMoment(selectedDamage.date))}],
                description: selectedDamage.description,
                id: selectedDamage.id,
                status: selectedDamage.status,
                service: selectedDamage.service,
                carType: selectedDamage.carType,
                formValid: true,
            })
        } else if (!selectedDamage && id) {
            this.setState(this.defaultState)
        }
    }


    /**
     * Handle date change
     * @param e
     * @param date
     * @param modifier
     */
    handleDateChange(e, date, modifier) {
        if (!modifier.disabled) {
            this.setState({date: [{day: date}]}, this.validateForm);
        }
    }


    /**
     * Handle checkbox change
     * @param e
     */
    handleCheckbox(e) {
        const {name, value} = e.target;
        this.setState(prevState => ({
            [name]: prevState[name] ? 0 : value
        }), this.validateForm);
    }


    /**
     * Validate the form
     */
    validateForm() {
        const {date} = this.state;
        this.setState({formValid: !!date})
    }


    /**
     * Handle submitting the form
     */
    handleSubmit() {
        const {date, description, files, status, service, id} = this.state;
        const {saveDamage, car} = this.props;

        const data = new FormData();
        data.set('date', moment(date[0].day).format("YYYY-MM-DD"));
        data.set('description', description ? description : '');
        data.set('status', (status ? 1 : 0).toString());
        data.set('service', (service ? 1 : 0).toString());
        data.set('car', car.id);
        for (const file of files) {
            data.append(`attachments[]`, file)
        }

        saveDamage(data, (id ? id : null));
    }

    handleCancel = () => {
        const {history, intl, selectDamage} = this.props;
        const {messages} = intl;

        selectDamage(null);
        history.push(messages["routes.damage"]);
    }

    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {selectedDamage} = this.props;

        /** Return renderHistory or renderForm according to state and render renderButtons */
        return (
            <div>
                {selectedDamage && selectedDamage.status
                    ? this.renderHistory()
                    : this.renderForm()
                }


                {this.renderButtons()}
            </div>
        );
    }


    /**
     * Component renderInfo method
     * @returns {*}
     */
    renderInfo() {
        const {car} = this.props;

        let directive;
        switch (car.insurance) {
            case 'Ethias':
                directive = '<p>Vul onderstaand formulier in of bel naar Service Center Schade <a href=\"tel:011282400\" target=\"_blank\">011 28 24 00</a> (binnenland) of <a href=\"tel:+3277282400\" target=\"_blank\">+32 77 282 400</a> (buitenland).</p><ul><li>van 08.00 u. tot 20.00 u. van maandag tot vrijdag</li><li>van 08.30 u. tot 12.30 u. op zaterdag</li></ul><p>Indien nodig geven zij hulp bij het invullen van het aanrijdingsformulier. Zij zorgen voor depannage of slepen van het voertuig ongeacht je al dan niet aansprakelijk bent. Zij zorgen ook voor het ter beschikking stellen van een vervangvoertuig gedurende 3 dagen.</p><p><b>Service Center Schade gebeld?</b><br />Vul steeds het formulier in en geef aan dat je met het Service Center hebt gebeld.</p>';
                break;
            case 'Service Rent':
                directive = '<p>Wagens van Service Rent zijn verzekerd via Service Rent. Indien je niet kan verder rijden, neem je contact op met <b>Touring</b> via <b><a href="tel: 022863556">02 286 35 56</a></b></p><p><b>All Car Lease gebeld?</b><br />Vul steeds het formulier in en geef aan dat je met All Car Lease hebt gebeld.</p>';
                break;
            case 'All Car Lease':
                directive = '<p>Wagens van All Car Rent/Lease zijn verzekerd via All Car Rent/Lease.<br />Voor hulp kan je hiervoor bellen naar <a href=\"tel:036419611\" target=\"_blank\">03 641 96 11</a> of <a href=\"tel:036419623\" target=\"_blank\">03 641 96 23</a></p><p><b>All Car Lease gebeld?</b><br />Vul steeds het formulier in en geef aan dat je met All Car Lease hebt gebeld.</p>';
                break;
            case 'KBC':
            default:
                directive = '<p>Vul onderstaand formulier in of bel naar KBC Assistance <a href=\"tel:023473902\" target=\"_blank\">02 347 39 02</a>. Indien nodig geven zij hulp bij het invullen van het aanrijdingsformulier. Zij zorgen voor depannage of slepen van het voertuig ongeacht je al dan niet aansprakelijk bent. Zij zorgen ook voor het ter beschikking stellen van een vervangvoertuig gedurende 7 dagen.</p></p><p><b>KBC Assistance gebeld?</b><br />Vul steeds het formulier in en geef aan dat je met KBC Assistance hebt gebeld.</p>';
                break
        }

        /** Returns information about what to do */
        return (
            <div dangerouslySetInnerHTML={{__html: directive}}/>
        )
    }


    /**
     * Component renderForm method
     * @returns {*}
     */
    renderForm() {
        const {description, files, date, status, service} = this.state;
        const {selectedDamage, car, intl} = this.props;
        const {messages, locale} = intl;



        /** return the damage form */
        return (
            <Row>
                <Col md={12}>
                    <h2>Meld schade</h2>

                    {this.renderInfo()}
                </Col>

                <Col md={6}>
                    <FormDatepicker name="date"
                                    label={messages["damage.date"]}
                                    value={date}
                                    onChange={this.handleDateChange}
                                    required={true}
                                    disabledDays={day => day > (new Date())}
                                    locale={locale}
                    />
                </Col>

                <Col md={6}>
                    <label>&nbsp;</label>
                    <FormCheckbox name="status"
                                  checked={status}
                                  label={messages["damage.complete"]}
                                  onChange={this.handleCheckbox}
                    />

                    <FormCheckbox name="service"
                                  checked={service}
                                  label={`Er was contact met ${car.insurance === 'KBC' ? 'KBC Assistance' : (car.insurance === 'Ethias' ? 'Service Center Schade' : (car.insurance === 'Service Rent' ? 'Service Rent' : 'All Car Rent/Lease'))}`}
                                  onChange={this.handleCheckbox}
                    />
                </Col>

                <Col md={12}>
                    <Dropzone onFilesAdded={(files) => this.setState({files})}
                              files={files}
                              label={messages["damage.certificate"]}
                              count={files.length}
                              description={""}
                    />

                    {selectedDamage && selectedDamage.attachments && selectedDamage.attachments.length !== 0 &&
                    <Attachments attachments={selectedDamage.attachments}/>
                    }
                </Col>

                <Col md={12}>
                    <FormTextarea name={"description"}
                                  label={messages["claims.remark"]}
                                  onChange={(e) => this.setState({description: e.target.value})}
                                  value={description}
                                  rows={5}
                                  placeholder={messages["claims.moreInfo"]}/>
                </Col>
            </Row>
        )
    }


    /**
     * Component renderHistory method
     * @returns {*}
     */
    renderHistory() {
        const {selectedDamage, intl, downloadAttachment} = this.props;
        const {description, service, carType, date, attachments, car} = selectedDamage;
        const {toLongDate} = DateUtility;
        const {messages} = intl;

        /** Return the damage history detail */
        return (
            <Row className={"claim"}>
                <Col md={12}>
                    <h2><FormattedMessage id="claims.yourHistory"/></h2>

                    <div className="info">
                        <b><FormattedMessage id={"damage.accidentDate"}/>: </b>
                        {toLongDate(date)}
                    </div>

                    {car
                        ? (
                            <div className="info">
                                <b>Gegevens van de wagen: </b>
                                <ul>
                                    <li><b style={{display: 'inline-block', width: 125}}>Nummerplaat:</b> {car.licensePlate}</li>
                                    <li><b style={{display: 'inline-block', width: 125}}>Type:</b> {car.kind}</li>
                                    <li><b style={{display: 'inline-block', width: 125}}>verzekering:</b> {car.insurance}</li>
                                </ul>
                            </div>
                        ) : (
                            <div className="info">
                                <b><FormattedMessage id={"damage.carType"}/>: </b>
                                {carType === 'leasing' ? messages["damage.leasing"] : messages["damage.walkIn"]}
                            </div>
                        )
                    }



                    <div className="info">
                        <b><FormattedMessage id={"damage.serviceCenter"}/>: </b>
                        {service ? messages["damage.serviceCenterYes"] : messages["damage.serviceCenterNo"]}
                    </div>


                    {attachments && attachments.length !== 0 &&
                    <Attachments
                        attachments={attachments}
                        claimType="damage"
                        downloadClaimAttachment={downloadAttachment}/>
                    }


                    <div className="info">
                        <b><FormattedMessage id="claims.remark"/></b>
                        <p dangerouslySetInnerHTML={{__html: description ? description.replaceAll("\n", <br/>) : messages["claims.emptyRemark"]}}/>
                    </div>
                </Col>
            </Row>
        )
    }


    /**
     * Component renderButtons method
     * @returns {*}
     */
    renderButtons() {
        const {formValid} = this.state;
        const {selectedDamage} = this.props;

        /** Return the form buttons */
        return (
            <Col md={12} className={"right"}>
                <div className={BTN_CLASSES.GROUP}>
                    {selectedDamage &&
                    <Button buttonStyle={BTN_CLASSES.SECONDARY}
                            onClick={this.handleCancel}
                    >
                        <FormattedMessage id="form.button.cancel"/>
                    </Button>
                    }

                    {(!selectedDamage || !selectedDamage.status) &&
                    <Button buttonStyle={BTN_CLASSES.PRIMARY}
                            onClick={this.handleSubmit}
                            disabled={!formValid}
                    >
                        <FormattedMessage id="form.button.save"/>
                    </Button>
                    }
                </div>
            </Col>
        )
    }
}


const mapStateToProps = ({appReducer}) => {
    const {selectedDamage} = appReducer;
    return {selectedDamage};
};


const mapDispatchToProps = {
    saveDamage, selectDamage, downloadAttachment
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DamageDetail));
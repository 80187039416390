import React from 'react';
import {render} from 'react-dom';
import {Provider} from "react-intl-redux";
import './assets/scss/main.scss';
import App from './components/App/App';
import {store} from "./redux/store";
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';


const TagManagerArgs = {
    gtmId: 'GTM-PMSXW3J'
}

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    TagManager.initialize(TagManagerArgs)
}


render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


import React, {Component} from 'react';
import './Stickers.scss';
import Button from "../../utils/Buttons/Button";
import {BTN_CLASSES} from "../../css_constants";
import Emoji from "../../utils/Emoji/Emoji";
import {connect} from "react-redux";
import {loadStickers, addSticker, deleteSticker, updateSticker} from "../../redux/actions/stickers.actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGrinAlt} from "@fortawesome/free-solid-svg-icons";

/**
 * Stickers component
 */
class Stickers extends Component {
    constructor(props) {
        super(props);

        /** State of the component */
        this.state = {
            showAdd: false,
            locationS: {x: 0, y: 0, width: 0, height: 0},
            locationP: {x: 0, y: 0, width: 0, height: 0},
            locationAddButton: {x: 0, y: 0, width: 0, height: 0},
            locationAddMenu: {x: 0, y: 0, width: 0, height: 0}
        };

        this.addStickerElement = React.createRef();
        this.addStickerMenu = React.createRef();
        this.addStickerWrapper = React.createRef();
        this.sticker = React.createRef();
        this.popOver = React.createRef();

        this.handleClickOutsideAddMenu = this.handleClickOutsideAddMenu.bind(this);
        this.handlePopOver = this.handlePopOver.bind(this);
        this.handleAddMenu = this.handleAddMenu.bind(this);
    }


    /**
     * Load stickers when none are loaded when component mounts
     */
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideAddMenu, false);
        document.addEventListener('wheel', () => this.setState({showAdd: false}), false);

        const {availableStickers, loadStickers} = this.props;
        if (availableStickers.length === 0)
            loadStickers();
    }


    /**
     * Remove eventlisteners when component unmounts
     */
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideAddMenu, false);
        document.removeEventListener('wheel', () => this.setState({showAdd: false}), false);
    }


    /**
     * Close add screen when user clicks outside box
     * @param e
     * @returns {boolean}
     */
    handleClickOutsideAddMenu(e) {
        if (this.addStickerElement.current && this.addStickerElement.current.contains(e.target)) return true;

        this.setState({showAdd: false});
    }


    /**
     * Show popover
     */
    handlePopOver() {
        this.setState({
            locationS: this.sticker.current.getBoundingClientRect(),
            locationP: this.popOver.current.getBoundingClientRect()
        });
    }


    /**
     * Show add menu
     */
    handleAddMenu() {
        this.setState(prevState => ({
            showAdd: !prevState.showAdd,
            locationAddButton: this.addStickerWrapper.current.getBoundingClientRect(),
            locationAddMenu: this.addStickerMenu.current.getBoundingClientRect()
        }));
    }


    /**
     * Add, update or delete sticker
     * @param id
     * @param action
     * @returns {*}
     */
    toggleSticker(id, action) {
        const {addSticker, updateSticker, deleteSticker, obj, objType} = this.props;
        const data = {
            type: objType,
            id: obj.id,
            stickerId: id
        };

        this.setState({showAdd: false});

        switch (action) {
            case "add":
                return addSticker(data);
            case "update":
                return updateSticker(data, id);
            case "delete":
                return deleteSticker(data, id);
            default:
                break;
        }
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {stickers, addButton, availableStickers, user, className, permissions} = this.props;
        const {showAdd, locationS, locationP, locationAddMenu, locationAddButton} = this.state;

        let array = [],
            userSticker = null;

        if (stickers && stickers.length > 0 && user) {
            userSticker = stickers.find(x => x.author.id === user.id);
            stickers.map(sticker => {
                if (array.find(x => x.id === sticker.sticker.id)) {
                    return array = array.map(obj => {
                        if (obj.id !== sticker.sticker.id) return obj;

                        return {
                            ...obj,
                            authors: [
                                ...obj.authors,
                                sticker.author
                            ]
                        }
                    });
                } else {
                    return array.push({
                        id: sticker.sticker.id,
                        content: sticker.sticker.content,
                        image: sticker.sticker.image,
                        authors: [sticker.author]
                    });
                }
            });
        }

        /** Returns stickers, add button and popover */
        return (
            <div className={`stickers ${className}`} ref={this.addStickerWrapper}>

                <div className={"wrapper"} ref={this.sticker} onMouseOver={this.handlePopOver}>
                    {array.length > 0
                        ? array.map((x, i) => {
                            return <div className={"sticker"} key={i}><Emoji image={x.image} symbol={x.content}/></div>
                        })
                        : "\u00a0"}
                    <div className={"sticker sticker-count"}>{stickers ? stickers.length : 0}</div>

                    <div className={"pop-over"} style={{top: locationS.y - locationP.height - 10, left: locationS.x - (locationP.width / 2) + (locationS.width / 2)}} ref={this.popOver}>
                        <div className={"popOver__arrow"}/>
                        <ul className={"popOver__body"}>
                            {array.length > 0
                                ? array.map(x => (
                                    x.authors.map((author, i) => (
                                        <li key={i}>{x.image ? <img src={x.image} alt={x.content} /> : x.content} {`${author.firstName} ${author.lastName}`}</li>
                                    ))
                                ))
                                : <li>geen stickers geplaatst</li>}
                        </ul>
                    </div>
                </div>


                {permissions.includes("stickers_add") && addButton && user &&
                <span ref={this.addStickerElement}>
                    <ul className={`add-sticker ${showAdd ? 'open' : 'closed'}`}
                        style={{top: locationAddButton.y + locationAddButton.height - locationAddMenu.height - 34, left: className === 'left' ? locationAddButton.x + 10 : locationAddButton.x - 5 + locationAddButton.width - locationAddMenu.width}} ref={this.addStickerMenu}>
                        {availableStickers.map((x, i) => {
                            const active = userSticker ? userSticker.sticker.id === x.id : false;
                            const action = userSticker && active ? "delete" : (userSticker ? "update" : "add");
                            return <li key={i} className={active ? "active" : ""} onClick={() => this.toggleSticker(x.id, action)}>{x.image ? <img src={x.image} alt={x.content} /> : x.content}</li>
                        })}
                    </ul>

                    <Button buttonSize={BTN_CLASSES.SMALL}
                            buttonStyle={BTN_CLASSES.PRIMARY}
                            label={<FontAwesomeIcon icon={faGrinAlt}/>} onClick={this.handleAddMenu}

                    />
                </span>
                }
            </div>
        );
    };
}


const mapStateToProps = ({authReducer, stickerReducer}) => {
    const {user, permissions} = authReducer;
    const {availableStickers} = stickerReducer;
    return {user, permissions, availableStickers};
};


export default connect(mapStateToProps, {loadStickers, addSticker, updateSticker, deleteSticker})(Stickers);
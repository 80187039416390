import {LOAD_EVENTS, LOAD_EVENT, ADD_EVENT, UPDATE_EVENT, DELETE_EVENT, DELETE_EVENT_MEDIA, RELOAD_EVENTS} from "../constants/event.constants";
import {API_URL} from "../constants/defaults";
import axios from "axios";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";

export const loadEvents = () => (dispatch, getState) => {
    dispatch({type: LOAD_EVENTS, payload: {events: [], isLoading: true}});
    const state = getState();
    const {locale, messages} = state.intl;
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.get(`${API_URL}/events?lang=${locale}`, header)
        .then(({data}) => {
            dispatch({type: LOAD_EVENTS, payload: {events: data, isLoading: false}});
        })
        .catch(error => {
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["events.response.error"], type: 'error'})}});
            console.log(error)
        });
};

export const reloadEvents = (type) => (dispatch, getStore) => {
    const store = getStore();
    const {messages} = store.intl;
    const {token} = store.authReducer;

    dispatch({type: RELOAD_EVENTS, payload: {events: [], isLoading: true}});
    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.get(`${API_URL}/events${type === "unpublished" ? "/unpublished" : ""}`, header)
        .then(({data}) => {
            dispatch({type: RELOAD_EVENTS, payload: {events: data, isLoading: false}});
        })
        .catch(error => {
            dispatch({type: RELOAD_EVENTS, payload: {events: [], isLoading: false}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["events.response.error"], type: 'error'})}});
        });
}

export const loadEvent = (id) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const {locale, messages} = state.intl;
    const {eventList} = state.eventReducer;
    dispatch({type: LOAD_EVENT, payload: {selectedEvent: null, isLoading: true}});

    const event = eventList.find(x => x.id === parseInt(id));
    const header = {headers: {'Authorization': "Bearer " + token}};
    if (event && event.isLoaded) {
        dispatch({type: LOAD_EVENT, payload: {selectedEvent: event, isLoading: false}});
    } else {
        axios.get(`${API_URL}/events/${id}?lang=${locale}`, header)
            .then(({data}) => {
                dispatch({type: LOAD_EVENT, payload: {selectedEvent: data, isLoading: false}});
            })
            .catch(error => {
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["event.response.error"], type: 'error'})}});
                console.log(error);
            })
    }
};


export const postEvent = (data, history, id) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = { headers: {'Content-Type': 'multipart/form-data', 'Authorization': "Bearer " + token }};
    axios.post(API_URL + '/events' + (id ? `/${id}` : ''), data, header)
        .then(({data}) => {
            dispatch({type: ADD_EVENT, payload: {event: data}});
            //dispatch({type: ADD_FEEDITEM, payload: {feeditem: data.mainfeed}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: ('Je evenement werd succesvol bewaard.'), type: 'success'})}});
            history.push('/evenementen/' + data.slug);
        })
        .catch(error => {
            const message = handlePostErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};

export const updateEvent = (data, history, id) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = { headers: {'Content-Type': 'multipart/form-data', 'Authorization': "Bearer " + token }};
    axios.post(API_URL + '/events/' + id + '/update', data, header)
        .then(({data}) => {
            dispatch({type: UPDATE_EVENT, payload: {event: data}});
            //dispatch({type: ADD_FEEDITEM, payload: {feeditem: data.mainfeed}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: ('Je evenement werd succesvol bewaard.'), type: 'success'})}});
            history.push('/evenementen/' + data.slug);
        })
        .catch(error => {
            const message = handlePostErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};

export const publishEvent = (id) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = { headers: {'Authorization': "Bearer " + token} };
    axios.put(API_URL + '/events/' + id + '/publish', {}, header)
        .then(({data}) => {
            dispatch({type: UPDATE_EVENT, payload: {event: data}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "Je evenement werd succesvol gepubliseerd.", type: 'success'})}});
        })
        .catch(error => {
            const message = handlePublishErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};

export const deleteEvent = (id, history) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = { headers: {'Authorization': "Bearer " + token} };
    axios.delete(API_URL + '/events/' + id, header)
        .then(() => {
            dispatch({type: DELETE_EVENT, payload: {id}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "Je evenement werd succesvol verwijderd.", type: 'success'})}});
            history.push('/evenementen');
        })
        .catch(error => {
            const message = handleDeleteErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};

export const deleteMedia = (id, type) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;

    const header = { headers: {'Authorization': "Bearer " + token} };
    axios.delete(API_URL + '/' + type + '/' + id, header)
        .then(() => {
            dispatch({type: DELETE_EVENT_MEDIA, payload: {id, type}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: "Je bijlage werd succesvol verwijderd.", type: 'success'})}});
        })
        .catch(error => {
            const message = handleDeleteMediaErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            console.log(error)
        })
};


const handlePostErrors = (error) => {
    if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.code && error.response.data.error.code === 403) {
            return "Je hebt niet voldoende rechten om een evenement te plaatsen.";
        }
        if (error.response.data.error.code && error.response.data.error.code === 422) {
            return "De opgegeven data was ongeldig.";
        }
    }
    return "Evenement plaatsen mislukt vanwegen een onverwachte fout.";
};

const handlePublishErrors = (error) => {
    if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.code && error.response.data.error.code === 403) {
            return "Je hebt niet voldoende rechten om een evenement te publiseren.";
        }
        if (error.response.data.error.code && error.response.data.error.code === 422) {
            return "De opgegeven data was ongeldig.";
        }
    }
    return "Evenement publiseren mislukt vanwegen een onverwachte fout.";
};

const handleDeleteErrors = (error) => {
    if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.code && error.response.data.error.code === 403) {
            return "Je hebt niet voldoende rechten om een evenement te verwijderen.";
        }
        if (error.response.data.error.code && error.response.data.error.code === 422) {
            return "De opgegeven data was ongeldig.";
        }
    }
    return "Evenement verwijderen mislukt vanwegen een onverwachte fout.";
};

const handleDeleteMediaErrors = (error) => {
    if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.code && error.response.data.error.code === 403) {
            return "Je hebt niet voldoende rechten om een bijlage te verwijderen.";
        }
        if (error.response.data.error.code && error.response.data.error.code === 422) {
            return "De opgegeven data was ongeldig.";
        }
    }
    return "Bijlage verwijderen mislukt vanwegen een onverwachte fout.";
};
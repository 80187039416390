import React, {Component} from "react";
import SidebarItem from "./SidebarItem";
import {SHARED_CLASSES} from "../../../css_constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import './Sidebar.scss';
import Claims from "./Claims/Claims";
import {faAddressCard, faRss, faUserMd} from "@fortawesome/free-solid-svg-icons";
import Contacts from "./Contacts/Contacts";
import SubFeed from "./SubFeed/SubFeed";
import {injectIntl} from "react-intl";

/**
 * Sidebar component
 */
class Sidebar extends Component {
    constructor(props) {
        super(props);

        /** State of the component */
        this.state = {
            openAccordion: 2,
            openSlider: null
        };

        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.toggleSlider = this.toggleSlider.bind(this);
    }


    /**
     * Toggle accordion in medium view
     * @param idx
     */
    toggleAccordion(idx) {
        this.setState(state => ({
            openAccordion: idx,
        }));
    }


    /**
     * Toggle slider in mobile view
     * @param idx
     */
    toggleSlider(idx) {
        this.setState(state => ({
            openSlider: state.openSlider === idx ? null : idx
        }));
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {intl, permissions, car} = this.props;
        const {messages} = intl;

        /** Return SidebarItem for slider with sub-component as children */
        return (
            <div className={`sidebar ${this.state.openSlider !== null ? 'open' : ''} ${this.props.showNavbar ? 'menu-open' : ''}`}>
                <ul className={"responsive__menu"}>
                    <li><button onClick={() => this.toggleSlider(0)} className={this.state.openSlider === 0 ? SHARED_CLASSES.ACTIVE : ''}><FontAwesomeIcon icon={faUserMd}/></button></li>
                    <li><button onClick={() => this.toggleSlider(1)} className={this.state.openSlider === 1 ? SHARED_CLASSES.ACTIVE : ''}><FontAwesomeIcon icon={faAddressCard}/></button></li>
                    <li><button onClick={() => this.toggleSlider(2)} className={this.state.openSlider === 2 ? SHARED_CLASSES.ACTIVE : ''}><FontAwesomeIcon icon={faRss}/></button></li>
                </ul>

                <SidebarItem
                    icon={faUserMd}
                    openAccordion={this.state.openAccordion === 0}
                    openSlider={this.state.openSlider === 0}
                    onToggle={() => this.toggleAccordion(0)}
                    title={messages["home.tab.claims"]}
                >
                    <Claims permissions={permissions} car={car}/>
                </SidebarItem>

                <SidebarItem
                    icon={faAddressCard}
                    openAccordion={this.state.openAccordion === 1}
                    openSlider={this.state.openSlider === 1}
                    onToggle={() => this.toggleAccordion(1)}
                    title={messages["home.tab.contact"]}
                >
                    <Contacts/>
                </SidebarItem>

                <SidebarItem
                    icon={faRss}
                    openAccordion={this.state.openAccordion === 2}
                    openSlider={this.state.openSlider === 2}
                    onToggle={() => this.toggleAccordion(2)}
                    title={messages["home.tab.subFeed"]}
                >
                    <SubFeed/>
                </SidebarItem>
            </div>
        );
    };
}


const mapStateToProps = ({viewReducer, authReducer}) => {
    const {showNavbar} = viewReducer;
    const {permissions, car} = authReducer;
    return {showNavbar, permissions, car};
};


export default connect(mapStateToProps)(injectIntl(Sidebar));
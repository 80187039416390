import {combineReducers} from 'redux';
import authReducer from "./auth.reducers";
import userReducer from "./user.reducers";
import mainFeedReducer from "./mainFeed.reducres";
import viewReducer from "./view.reducer";
import mediaReducer from "./media.reducers";
import tagReducer from "./tag.reducers";
import commentReducer from "./comment.reducers";
import attachmentReducer from "./attachment.reducers";
import documentReducer from "./document.reducers";
import toppingReducer from "./topping.reducer";
import trainingReducer from "./training.reducer";
import coceReducer from "./coce.reducers";
import {intlReducer} from "react-intl-redux";
import eventReducer from "./event.reducers";
import newsReducer from "./news.reducers";
import subFeedReducer from "./subFeed.reducres";
import appReducer from "./app.reducers";
import stickerReducer from "./stickers.reducers";
import invitationReducer from "./invitation.reducers";
import formReducer from "./form.reducers";
import toastReducer from "./toast.reducers";
import notificationReducer from "./notification.reducers";
import permissionsAdminReducer from "./permissionsAdmin.reducer";
import claimsAdminReducer from "./claimsAdmin.reducers";
import subscriptionResultReducer from "./subscriptionResult.reducer";

export default combineReducers({
    intl: intlReducer,
    appReducer,
    authReducer,
    userReducer,
    toastReducer,
    toppingReducer,
    trainingReducer,
    newsReducer,
    eventReducer,
    coceReducer,
    mainFeedReducer,
    subFeedReducer,
    invitationReducer,
    formReducer,
    tagReducer,
    commentReducer,
    stickerReducer,
    viewReducer,
    mediaReducer,
    attachmentReducer,
    documentReducer,
    notificationReducer,
    permissionsAdminReducer,
    claimsAdminReducer,
    subscriptionResultReducer
});
export default class FieldValidator {
    static isUrl(state, name, value) {
        let {socialMedia} = state.socialMedia;

        const pattern = new RegExp('^(https?:\\/\\/)' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            '(\\?[;&amp;a-z\\d%_.~+=-]*)?' +
            '(\\#[-a-z\\d_]*)?$', 'i');

        socialMedia = state.socialMedia.map(x => {
            if (x.type !== name) return x;
            const valid = value === '' || !!pattern.test(value);

            return {
                ...x,
                valid: valid,
                error: valid ? "" : "Vul een correcte url in."
            }
        });

        return {
            ...state,
            socialMedia
        };
    }

    static validateField(field) {
        let valid = (field.type === 'checkbox' || field.type === 'radio' ? (field.required ? (!!field.value) : true) : (!(field.required && field.value.length <= 0)));
        let error = !valid ? `${field.name} is een verplicht veld.` : '';

        if (valid) {
            switch (field.type) {
                case 'email':
                    valid = !!field.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                    error = valid ? '' : `${field.name} is ongelig.`;
                    break;
                case 'number':
                    const minValid = field.min || field.min === 0 ? field.value >= field.min : true;
                    const maxValid = field.max || field.max === 0 ? field.value <= field.max : true;
                    const decimalsValid = field.step ? (Number.isInteger(field.step) ? Number.isInteger(Number(field.value)) : function(value) {
                        const decimals = field.step.toString().split(".")[1] ? field.step.toString().split(".")[1].length : 0;
                        const valDecimals = value.toString().split(".");
                        return value ? (Math.floor(value) === Number(value) ? true : valDecimals[1].length <= decimals) : true;
                    }(field.value)) : true;

                    valid = minValid && maxValid && decimalsValid;
                    error = !valid ? `${field.name} moet een getal${field.min || field.min === 0 ? ` groter dan ${field.min}` : ''}${field.max || field.max === 0 ? `${field.min || field.min === 0 ? ' en' : ''} kleiner dan ${field.max}` : ''} zijn${field.step ? ` ${function() { return Math.floor(field.step) === Number(field.step) ? 'zonder' : `met ${field.step.toString().split(".")[1].length}`}()} decimalen` : ''}.` : '';
                    break;
                case 'url':
                    const pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/gm
                    const regEx = new RegExp(pattern, 'gm');

                    valid = field.value === '' || !!regEx.test(field.value);
                    error = !valid ? `${field.name} is ongeldig` : '';
                    break;
                default: break;
            }
        }

        return {
            valid: valid,
            error: error
        }
    }
}
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import Attachments from "../../../utils/Attachments/Attachments";
import Stickers from "../../Stickers/Stickers";
import Comments from "../../Comments/Comments";

/**
 * Attachment main feed component
 */
const MediaMainFeedItem = ({id, title, attachments, links, comments, stickers, route, feedItem, highlightCommentId, ...props}) => {
    const {addStickers, addComments, type, element} = props;

    useEffect(() => {
        addStickers(type, id);
        addComments(type, id);
    })

    /** return feed item */
    return (
        <div className={`feed_item ${stickers ? 'with__stickers' : 'without__stickers'} ${comments ? 'with__comments' : 'without__comments'}`}>
            <div className={`feed__body without__read-more`}>
                <div className={`feed__content no-img`}>
                    <div className={"item__header"}>
                        <Link to={`${route}/${element.slug ? element.slug : element.id}`} className={"item__title"}>{title}</Link>
                    </div>
                    <div className={"item__body"}>
                        {console.log(attachments)}
                        {console.log(links)}
                        <Attachments attachments={attachments} links={links}/>
                    </div>
                </div>
            </div>

            <Stickers stickers={stickers} addButton={true} obj={feedItem} objType={"mainfeed"}/>

            <Comments highlightCommentId={highlightCommentId} comments={comments} addButton={true} obj={feedItem} objType={"mainfeed"}/>

        </div>
    )
}


export default MediaMainFeedItem;
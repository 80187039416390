import React from "react";
import {removeToast} from "../../redux/actions/toast.actions";
import {connect} from "react-redux";
import Toast from "./Toast";

/**
 * Snackbar component
 * @param removeToast
 * @param toasts
 * @returns {*}
 * @constructor
 */
const Toasts = ({removeToast, toasts}) => {
    /** Return mapping of Toast */
    return (
        <div className="toasts">
            {toasts.map(toast => {
                const {id} = toast;
                return (
                    <Toast {...toast} key={id} onDismissClick={() => removeToast(id)}/>
                );
            })}
        </div>
    );
};


const mapStateToProps = ({toastReducer}) => {
    const {toasts} = toastReducer;
    return {toasts};
};


export default connect(mapStateToProps, {removeToast})(Toasts);
import React from 'react';
import './FormInput.scss'
import {Col, Row} from "../Grid/Grid";

/**
 * Form group component
 * @param direction
 * @param name
 * @param label
 * @param children
 * @returns {*}
 * @constructor
 */
const FormGroup = ({direction, name, label, children}) => (
    <Row className={name}>
        <Col lg={12}>{label && <label htmlFor={name}>{label}</label>}</Col>
        {direction === "vertical"
            ? children.map((child, i) => {
                const breakpoint = children.length > 3 ? 4 : 12 / children.length;

                return (
                    <Col md={breakpoint} key={i}>
                        {child}
                    </Col>
                )
            })

            : <Col lg={12}>{children}</Col>
        }
    </Row>
);

export default FormGroup;
import {API_URL} from "../constants/defaults";
import axios from "axios";
import {DELETE_MEDIA, LOAD_MEDIA} from "../constants/media.constrants";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";

export const getBlob = () => () => {

};

export const getMedia = (object) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;
    const {media} = state.mediaReducer;
    const {id} = object;

    const obj = media.find(x => x.id === id);

    if (!obj) {
        dispatch({type: LOAD_MEDIA, payload: {media: null, id}});
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(API_URL + '/images/' + id + '/data', header)
            .then(({data}) => {
                dispatch({type: LOAD_MEDIA, payload: {media: data, id}});
            })
            .catch(error => {
                console.log(error);
                const message = handleErrors(messages, error);
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
                dispatch({type: DELETE_MEDIA, payload: {id}});
            })
    }
};

const handleErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["blob.response.error"];
};
import React, {Component} from "react";
import FormTextarea from "../../utils/Forms/FormTextarea";
import {Col, Row} from "../../utils/Grid/Grid";
import {FormPeriodpicker} from "../../utils/Forms/FormPeriodpicker";
import Button from "../../utils/Buttons/Button";
import {BTN_CLASSES} from "../../css_constants";
import {DateUtils} from 'react-day-picker';
import Dropzone from "../../utils/Forms/Dropzone";
import {connect} from "react-redux";
import {saveIllness} from "../../redux/actions/claims.actions";
import moment from "moment";
import {selectIllness} from "../../redux/actions/app.actions";
import DateUtility from "../../utils/dateUtils";
import Attachments from "../../utils/Attachments/Attachments";
import {FormattedMessage, injectIntl} from "react-intl";
import { isNil } from "../../utils/helperFunctions";
import { downloadAttachment } from "../../redux/actions/claimsAdmin.actions";

/**
 * Illness form component
 */
class IllnessDetail extends Component {
    constructor(props) {
        super(props);

        /** State of the component */
        this.state = {
            from: null,
            to: null,
            description: null,
            files: [],
            formValid: false,
            id: null
        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const {selectIllness, match} = this.props;
        const {id} = match.params;

        if (!isNil(id)) selectIllness(parseInt(id));
    }

    /**
     * Set Illness details to state if they are not set or clear the form
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {id} = this.state;
        const {match, selectIllness, selectedIllness} = this.props;
        const {toMoment} = DateUtility;

        if (!selectedIllness || (selectedIllness && selectedIllness.id !== match.params.id)) {
            selectIllness(parseInt(match.params.id));
        }

        if (selectedIllness && (!id || id !== selectedIllness.id)) {
            this.setState({
                from: new Date(toMoment(selectedIllness.period[0])),
                to: new Date(toMoment(selectedIllness.period[1])),
                description: selectedIllness.description,
                id: selectedIllness.id,
                formValid: true,
            })
        } else if (!selectedIllness && id) {
            this.setState({
                from: null,
                to: null,
                description: null,
                files: [],
                formValid: false,
                id: null
            })
        }
    }

    componentWillUnmount() {
        const {selectIllness} = this.props;

        this.setState({id: null});
        selectIllness(null);
    }

    /**
     * Handle date change
     * @param day
     */
    handleDateChange(day) {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(prevState => ({
            ...prevState,
            ...range
        }), this.validateForm);
    }


    /**
     * Validate the form
     */
    validateForm() {
        const {from} = this.state;
        this.setState({formValid: !!from})
    }


    /**
     * Handle submitting the form
     */
    handleSubmit() {
        const {from, to, description, files, id} = this.state;
        const {saveIllness} = this.props;

        const data = new FormData();
        data.set('from', moment(from).format("YYYY-MM-DD"));
        data.set('to', to ? moment(to).format("YYYY-MM-DD") : moment(from).format("YYYY-MM-DD"));
        data.set('description', description ? description : '');
        for (const file of files) {
            data.append(`attachments[]`, file)
        }

        saveIllness(data, (id ? id : null));
    }

    handleCancel = () => {
        const {history, intl, selectIllness} = this.props;
        const {messages} = intl;

        selectIllness(null);
        history.push(messages["routes.illness"]);
    }

    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {selectedIllness} = this.props;

        /** Return renderHistory or renderForm according to state and render renderButtons */
        return (
            <div>
                {selectedIllness && selectedIllness.status
                    ? this.renderHistory()
                    : this.renderForm()
                }


                {this.renderButtons()}
            </div>
        );
    }


    /**
     * Component renderForm method
     * @returns {*}
     */
    renderForm() {
        const {description, files, from, to} = this.state;
        const {selectedIllness, intl} = this.props;
        const {messages, locale} = intl;

        /** return the illness form */
        return (
            <Row>
                <Col md={12}>
                    <h2><FormattedMessage id="menu.illness"/></h2>

                    <FormPeriodpicker label={
                        !from && !to
                            ? messages["illness.absent"]
                            : (
                                from && !to
                                    ? `${messages["illness.absent"]} ${from.toLocaleDateString()} ${messages["illness.until"]}`
                                    : `${messages["illness.absent"]} ${from.toLocaleDateString()} ${messages["illness.until"]} ${to.toLocaleDateString()}`
                            )}
                                      required={true}
                                      valid={true}
                                      onBlur={() => this.setState(prevState => ({startDate: {...prevState.startDate, touched: true}}))}
                                      error={""}
                                      value={[from, {from, to}]}
                                      onChange={this.handleDateChange}
                                      modifiers={{start: from, end: to}}
                                      locale={locale}
                    />
                </Col>

                <Col md={12}>
                    <Dropzone onFilesAdded={(files) => this.setState({files})}
                              files={files}
                              label={messages["illness.certificate"]}
                              count={files.length}
                              description={""}
                    />

                    {selectedIllness && selectedIllness.attachments && selectedIllness.attachments.length !== 0 &&
                    <Attachments attachments={selectedIllness.attachments}/>
                    }
                </Col>

                <Col md={12}>
                    <FormTextarea name={"description"}
                                  label={messages["claims.remark"]}
                                  onChange={(e) => this.setState({description: e.target.value})}
                                  value={description}
                                  rows={5}
                                  placeholder={messages["claims.moreInfo"]}/>
                </Col>
            </Row>
        )
    }


    /**
     * Component renderHistory method
     * @returns {*}
     */
    renderHistory() {
        const {selectedIllness, intl, downloadAttachment} = this.props;
        const {description, period, attachments} = selectedIllness;
        const {toLongDate} = DateUtility;
        const {messages} = intl;

        /** Return the illness history detail */
        return (
            <Row className={"claim"}>
                <Col md={12}>
                    <h2><FormattedMessage id="claims.yourHistory"/></h2>

                    <div className="info">
                        <b><FormattedMessage id="illness.absence"/>: </b>
                        <FormattedMessage id="illness.absencePeriod" values={{start: toLongDate(period[0]), end: toLongDate(period[1])}}/>
                    </div>


                    {attachments && attachments.length !== 0 &&
                    <Attachments
                        attachments={selectedIllness.attachments}
                        claimType="illness"
                        downloadClaimAttachment={downloadAttachment}/>
                    }


                    <div className="info">
                        <b><FormattedMessage id="claims.remark"/></b>
                        <p dangerouslySetInnerHTML={{__html: description ? description.replaceAll("\n", <br/>) : messages["claims.emptyRemark"]}}/>
                    </div>
                </Col>
            </Row>
        )
    }


    /**
     * Component renderButtons method
     * @returns {*}
     */
    renderButtons() {
        const {formValid} = this.state;
        const {selectedIllness} = this.props;

        /** Return the form buttons */
        return (
            <Col md={12} className={"right"}>
                <div className={BTN_CLASSES.GROUP}>
                    {selectedIllness &&
                    <Button buttonStyle={BTN_CLASSES.SECONDARY}
                            onClick={this.handleCancel}
                    >
                        <FormattedMessage id="form.button.cancel"/>
                    </Button>
                    }

                    {(!selectedIllness || !selectedIllness.status) &&
                    <Button buttonStyle={BTN_CLASSES.PRIMARY}
                            onClick={this.handleSubmit}
                            disabled={!formValid}
                    >
                        <FormattedMessage id="form.button.save"/>
                    </Button>
                    }
                </div>
            </Col>
        )
    }
}


const mapStateToProps = ({appReducer}) => {
    const {selectedIllness} = appReducer;
    return {selectedIllness};
};


const mapDispatchToProps = {
    saveIllness, selectIllness, downloadAttachment
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(IllnessDetail));
import {LOAD_TAGS} from "../constants/tag.constants";

let defaultState = {
    tags: []
};


const loadTags = (state, {tags}) => {
    return {
        ...state,
        tags: [...tags]
    };
};


const tagReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_TAGS:
            return loadTags(state, payload);
        default:
            return state;
    }
};

export default tagReducer;
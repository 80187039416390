import React, {Component} from 'react';
import {getBlob} from "../../../../redux/actions/media.actions";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {loadSubFeed} from "../../../../redux/actions/subFeed.actions";
import './SubFeed.scss'
import {addStickers} from "../../../../redux/actions/stickers.actions";
import EmptyList from "../../../EmptyList/EmptyList";
import SubFeedItem from "./SubFeedItem";
import PictureSubFeedItem from "./PictureSubFeedItem";
import Load from "../../../../utils/Loading/Load";

/**
 * Sub feed component
 */
class SubFeed extends Component {
    constructor(props) {
        super(props);

        /** Sub feed type function map */
        this.funcMap = {
            birthday: this.renderFeedItem,
            yearsOfService: this.renderFeedItem,
            bioUpdate: this.renderFeedItem,
            pictureUpdate: this.renderPictureFeedItem,
            interviewUpdate: this.renderFeedItem,
        };

        this.handleScroll = this.handleScroll.bind(this);
    }


    /**
     * Load sub feed items on component mount
     */
    componentDidMount() {
        document.getElementById('sub_feed').addEventListener('scroll', this.handleScroll, true);

        const {loadSubFeed, subFeed, permissions} = this.props;
        //if (permissions.includes("subfeed_see") && subFeed.length === 0) loadSubFeed(subFeed.length);
        if (permissions.includes("subfeed_see")) loadSubFeed(subFeed.length);
    }


    /**
     * remove eventlistener when component unmounts
     */
    componentWillUnmount() {
        document.getElementById('sub_feed').removeEventListener('scroll', this.handleScroll, true);
    }


    /**
     * Get render method according to type
     * @param feedItem
     * @param i
     * @returns {*}
     * @private
     */
    _getRenderMethod(feedItem, i) {
        const method = this.funcMap[feedItem.type];

        if (typeof method === 'function') {
            return method(feedItem, i, this);
        }
    }


    /**
     * Load extra feed items when user scrolled to bottom of the page and there is more to bee seen
     */
    handleScroll() {
        const wrappedElement = document.getElementById('sub_feed');

        const {loadSubFeed, hasMore, subFeed, permissions} = this.props;

        if (wrappedElement.scrollTop === (wrappedElement.scrollHeight - wrappedElement.offsetHeight)) {
            if (hasMore && permissions.includes("subfeed_see"))
                loadSubFeed(subFeed.length);
        }
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {subFeed, hasMore, isLoading} = this.props;

        /** Return _getRenderMethod is feed is loaded */
        return (
            <div className={"sub_feeds"} id={"sub_feed"}>
                {isLoading && subFeed.length === 0
                    ? <Load/>
                    : subFeed.length === 0
                        ? <EmptyList id={"EmptyList.feed"}/>
                        : subFeed.map((feedItem, i) => (
                            this._getRenderMethod(feedItem, i)
                        ))
                }

                {isLoading && subFeed.length !== 0 &&
                <Load/>
                }

                {!hasMore && subFeed.length !== 0 && !isLoading &&
                <div className={"no-more-mainfeed"}>
                    <FormattedMessage id={"feed.end"}/>
                </div>
                }
            </div>
        );
    };


    /**
     * Component renderFeedItem method
     * @returns {*}
     */
    renderFeedItem(feedItem, i, {props}) {
        const {addStickers, stickers, intl} = props;
        const {id} = feedItem;
        const uniqueKey = `${i}_${id}`;

        /** Return sub feed item */
        return (
            <SubFeedItem key={uniqueKey}
                         index={uniqueKey}
                         feedItem={feedItem}
                         messages={intl.messages}
                         stickers={stickers["subfeed"][id]}
                         addStickers={addStickers}
                         type={"subfeed"}
            />
        );
    }


    /**
     * Component renderPictureFeedItem method
     * @returns {*}
     */
    renderPictureFeedItem(feedItem, i, {props}) {
        const {addStickers, stickers, intl} = props;
        const {id} = feedItem;
        const uniqueKey = `${i}_${id}`;

        /** Return sub feed item */
        return (
            <PictureSubFeedItem key={uniqueKey}
                                index={uniqueKey}
                                feedItem={feedItem}
                                messages={intl.messages}
                                stickers={stickers["subfeed"][id]}
                                addStickers={addStickers}
                                type={"subfeed"}
            />
        )
    }
}


const mapStateToProps = ({subFeedReducer, stickerReducer, authReducer}) => {
    const {subFeed, hasMore, isLoading} = subFeedReducer;
    const {stickers} = stickerReducer;
    const {permissions} = authReducer;
    return {subFeed, hasMore, stickers, isLoading, permissions};
};


const mapDispatchToProps = {
    loadSubFeed, getBlob, addStickers
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SubFeed));
import React from 'react';

/**
 * Emoji component
 * @param label
 * @param symbol
 * @returns {*}
 * @constructor
 */
const Emoji = ({label, image, symbol}) => (
    <span
        className="emoji"
        role="img"
        aria-label={label ? label : ""}
        aria-hidden={label ? "false" : "true"}
    >
        {image
            ? <img src={image} alt={symbol} />
            : symbol
        }
    </span>
);

export default Emoji;
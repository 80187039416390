import React from 'react';
import './FormInput.scss'
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {Editor} from "@tinymce/tinymce-react";

/**
 * TineMCE component
 * @param name
 * @param label
 * @param value
 * @param valid
 * @param onChange
 * @param onBlur
 * @param error
 * @param className
 * @param required
 * @param intl
 * @returns {*}
 * @constructor
 */
const FormTinyMCE = ({name, label, value, valid, description, onChange, onBlur, error, className, required, intl}) => (
    <div className={'form-group ' + className}>
        {label && <label htmlFor={name}>{typeof label === 'object' ? intl.formatMessage({id: label.label}, label.value) : label} {required ? <code>*</code> : null}</label>}

        <input id="my-file" type="file" name="my-file" style={{display: 'none'}}/>

        <Editor value={value === null ? '' : value}
            init={{
                height: 500,
                menubar: false,
                plugins: [
                    "advlist autolink link image lists print preview hr anchor searchreplace wordcount media",
                    "table directionality paste code"
                ],
                toolbar1: "undo redo | cut copy paste | link unlink anchor | outdent indent | image | table | hr | print preview code",
                toolbar2: "bold italic underline strikethrough superscript subscript removeformat | bullist numlist | alignleft aligncenter alignright alignjustify | styleselect | forecolor backcolor",

                color_map: [
                    "fceef0", "licht rood",
                    "fffbf6", "Licht oranje",
                    "f0fcee", "Licht groen",
                    "eff0fa", "Licht blauw",
                    "f9f8f8", "Licht grijs",
                    "ea6c7e", "Medium rood",
                    "ffba6f", "Medium oranje",
                    "7fea6c", "Medium groen",
                    "7582d8", "Medium blauw",
                    "cbc7c6", "Medium grijs",
                    "ab182d", "Rood",
                    "ef7d00", "Oranje",
                    "2eab18", "Groen",
                    "27348b", "Blauw",
                    "9D9694", "Grijs",
                    "333333", "Zwart",
                    "FFFFFF", "Wit",
                    "FAFAFA", "Donker wit",
                ],

                relative_urls : false,
                remove_script_host : true,

                file_picker_types: 'image',
                file_picker_callback: function (callback, value, meta) {
                    if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                            let file = input.files[0];
                            const reader = new FileReader();

                            function resize(file) {
                                return new Promise((resolve, reject) => {
                                    let image = new Image();
                                    image.src = file;
                                    image.onload = () => {
                                        let width = image.width;
                                        let height = image.height;

                                        if (width <= 500 && height <= 500) {
                                            resolve(file);
                                        }

                                        let newWidth = file.width;
                                        let newHeight = file.height;

                                        if (width > height) {
                                            if (width > 500) {
                                                newHeight = Math.round(height *= 500 / width);
                                                newWidth = 500;
                                            }
                                        } else {
                                            if (height > 500) {
                                                newWidth = Math.round(width *= 500 / height);
                                                newHeight = 500;
                                            }
                                        }

                                        let canvas = document.createElement('canvas');
                                        canvas.width = newWidth;
                                        canvas.height = newHeight;

                                        let context = canvas.getContext('2d');
                                        context.drawImage(image, 0, 0, newWidth, newHeight);
                                        canvas.toBlob(resolve, file.type, 0.7);
                                    };
                                    image.onerror = reject;
                                });
                            }

                            reader.onload = function(e) {
                                resize(e.target.result).then(blob => {
                                    callback(URL.createObjectURL(blob), {
                                        alt: file.name,
                                    });
                                });
                            };
                            reader.readAsDataURL(file);
                        };
                    }
                },
                paste_data_images: true,
                content_style: 'h1, .h1 { font-family: "Open Sans", sans-serif; font-size: 30px; font-weight: 700; color: #9D9694; margin: 0 0 1.5em; }h2, .h2 { font-family: "Open Sans", sans-serif; font-size: 24px; font-weight: 700; color:' +
                    ' #9D9694; }h3, .h3 { font-family: "Open Sans", sans-serif; font-size: 20px; font-weight: 700; color: #EF7D00; margin-bottom: 0; }h4, .h4 { font-family: "Open Sans", sans-serif; font-size: 16px; font-weight: 700; color: #EF7D00; margin-bottom: 0; }' +
                    'h5, .h5 { font-family: "Open Sans", sans-serif; font-size: 16px; font-weight: 700; color: #9D9694; margin-bottom: 0; }p { font-family: "Open Sans", sans-serif; font-size: 16px; font-weight: 400; color: #333333; margin: 0 0 1em; }' +

                    'blockquote { font-size: 28px; font-weight: 700; color: #ab182d; text-shadow: 0 3px 6px rgba(51, 51, 51, .16); position: relative; margin: 1.5em 45% 1.5em 1.5em !important; border: 0 !important; padding: 0 !important; }' +
                    'blockquote:before { content: "\\f10e"; font-family: "Font Awesome 5 Free"; font-weight: 900; font-size: 1.2em; display: inline-block; font-style: normal; font-variant: normal; text-rendering: auto; line-height: 1; position: absolute; left: -40px; top: -25px; transform: scaleX(-1);}' +
                    'blockquote:after {content: "\\f10e";font-family: "Font Awesome 5 Free";font-weight: 900;font-size: 1.2em;display: inline-block;font-style: normal;font-variant: normal;text-rendering: auto;line-height: 1;right: -25px;bottom: -30px;position: absolute;}' +
                    '@media (min-width: 414px) {margin-right: 25%;}@media (min-width: 576px) {margin-right: 35%;}@media (min-width: 768px) {margin-right: 45%;}' +

                    'a { color: #9D9694; text-decoration: none; transition: color 0.3s ease-in; }a:visited { color: #9D9694; }a:hover, a.active { color: #EF7D00; }a:active { color: #9D9694; }.img-responsive { width: 100%; height: auto; margin: 0 0 15px 0; }.img-responsive.left, .img-responsive.right { float: none; width: 100%; margin: 0 0 15px 0; }@media (min-width: 768px) { .img-responsive.left, .img-responsive.right { width: 400px; } .img-responsive.left { float: left; margin-right: 15px; } .img-responsive.right { float: right; margin-left: 15px; } }@media (min-width: 1200px) { .img-responsive.left { width: 500px; } .img-responsive.right { width: 500px; } }',

                style_formats: [
                    {title: 'normaal', block: 'p'},
                    {title: 'h2', block: 'h2'},
                    {title: 'h3', block: 'h3'},
                    {title: 'h4', block: 'h4'},
                    {title: 'h5', block: 'h5'},
                    {title: 'code', block: 'code'},
                    {title: 'pre', block: 'pre'},
                    {title: 'Quote', block: 'blockquote'},
                ],
                image_class_list: [
                    { title: 'Geen klasse', value: '' },
                    { title: 'Responsief, volledige breedte', value: 'img-responsive' },
                    { title: 'Responsief, links uitgelijnd', value: 'img-responsive left' },
                    { title: 'Responsiev, rechts uitgelijnd', value: 'img-responsive right' }
                ]
            }}
            onEditorChange={(value) => onChange({target: {name, value}})}
            textareaName={name}
            onBlur={() => onBlur({target: {name}})}
        />
        <div className="description">{description}</div>
        <small className="invalid-feedback">{error}</small>
    </div>
);


FormTinyMCE.defaultProps = {
    label: '', name: '', value: '', error: '', valid: '', type: 'text', required: false, className: '', autoFocus: false
};


FormTinyMCE.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};


export default injectIntl(FormTinyMCE);
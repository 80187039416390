import React, {Component} from "react";
import Form from "../../../utils/Forms/Form";
import Button from "../../../utils/Buttons/Button";
import {BTN_CLASSES} from "../../../css_constants";
import {Col, Row} from "../../../utils/Grid/Grid";
import {connect} from "react-redux";
import {deleteMedia, loadArticle, updateArticle} from "../../../redux/actions/news.actions";
import {loadTags} from "../../../redux/actions/tag.actions";
import {Link} from "react-router-dom";
import {getMedia} from "../../../redux/actions/media.actions";
import Attachments from "../../../utils/Attachments/Attachments";
import EmptyList from "../../EmptyList/EmptyList";
import Load from "../../../utils/Loading/Load";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faGlobeEurope, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import '../Admin.scss';
import {loadForm} from "../../../redux/actions/form.actions";
import CustomForm from "../CustomForm/CustomForm";
import UrlModal from "../UrlModal/UrlModal";

class UpdateArticle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                format: [],
                fields: []
            },
            formValid: false,
            links: [],

            customForm: {
                formSize: "medium",
                formName: "Nieuw formulier",

                validateForm: false,
                steps: 1,
                step: 1,
                currentIndex: 0,
                previousIndex: 0,
                nextIndex: 0,
                skipped: [],

                format: [
                    {
                        type: "group",
                        name: "group1",
                        direction: "horizontal",
                        fields: [],
                    }
                ],
                fields: []
            },
            updateCustomForm: true,

            showCustomForm: false,
            loading: false
        };

        this.urls = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const {loadTags, loadArticle, loadForm, match} = this.props;
        const {id} = match.params;

        /**
         * Load tags
         */
        loadTags();

        /**
         * Load original article
         */
        loadArticle(id);

        /**
         * Load form of article
         */
        loadForm('news', id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tags, selectedArticle, forms} = this.props;
        const {updateCustomForm} = this.state;

        document.title = `UniPartners Group | Admin nieuws | ${selectedArticle ? selectedArticle.title : 'loading...'}`;

        /**
         * Set form
         */
        if (prevState.form.fields.length === 0 && selectedArticle) {
            const format = [
                {
                    type: "col",
                    breakpoint: 8,
                    fields: ["title"]
                }, {
                    type: "col",
                    breakpoint: 4,
                    fields: ["published", "urgent"]
                }, {
                    type: "col",
                    breakpoint: 12,
                    fields: ["body"]
                }, {
                    type: "col",
                    breakpoint: 7,
                    fields: ["tags", "attachments"]
                }
            ];

            const fields = [
                {
                    name: "title",
                    type: "text",
                    required: true,
                    placeholder: "De titel van het artikel",
                    label: "Titel",
                    autocomplete: false,
                    description: null,
                    error: "",
                    valid: true,
                    value: selectedArticle.title
                }, {
                    name: "body",
                    type: "tiny",
                    required: false,
                    placeholder: "De inhoud van het artikel",
                    label: "Inhoud",
                    autocomplete: false,
                    description: (
                        <div style={{display: 'flex', marginTop: 5, marginBottom: 5}}>
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{height: 25, width: 'auto', margin: '5px 10px 5px 0', color: '#ef7d00'}} />
                            <div>
                                <b>OPGELET!</b><br />
                                De inhoud van dit nieuwsbericht wordt via intranet en de nieuwsbrief binnen heel UniPartners gedeeld. Deel hier geen gevoelige informatie.
                            </div>
                        </div>
                    ),
                    error: "",
                    valid: true,
                    value: selectedArticle.body
                }, {
                    name: "published",
                    type: "checkbox",
                    required: false,
                    label: "Publiceer het artikel",
                    error: "",
                    valid: true,
                    value: selectedArticle.publishedAt ? 1 : 0
                }, {
                    name: "urgent",
                    type: "checkbox",
                    required: false,
                    label: "Dringend bericht",
                    description: "De nieuwsbrief zal meteen verzonden worden",
                    error: "",
                    valid: true,
                    value: selectedArticle.urgent ? 1 : 0
                }, {
                    name: "attachments",
                    type: "dropzone",
                    required: false,
                    label: "",
                    description: null,
                    error: "",
                    valid: true,
                    files: []
                }, {
                    name: "language",
                    type: "radio",
                    value: (selectedArticle.language === "nl" ? "Nederlands" : "Frans"),
                    required: false,
                    label: "De taal van het artikel",
                    error: "",
                    valid: true,
                    options: [selectedArticle.language === "nl" ? "Nederlands" : "Frans"]
                }, {
                    name: "tags",
                    type: "creatable",
                    required: false,
                    multiple: true,
                    placeholder: "De tags van het artikel",
                    label: "Tags",
                    autocomplete: false,
                    description: null,
                    options: tags.map(tag => (tag.tag)),
                    error: "",
                    valid: true,
                    value: selectedArticle.tags
                },
            ];

            this.setState(prevState => {
                return {
                    ...prevState,
                    form: {
                        ...prevState.form,
                        fields,
                        format
                    }
                }
            });
        }

        /**
         * Load media and attachments for article
         */
        if (selectedArticle && selectedArticle.media && selectedArticle.media.length > 0) {
            selectedArticle.media.map(obj => {
                const {getMedia} = this.props;
                return getMedia(obj);
            });
        }

        /** Set customForm */
        if (selectedArticle && forms && forms.length !== 0) {
            const form = forms["news"][selectedArticle.id];

            if (form && form.length !== 0 && form !== this.state.customForm && updateCustomForm) {
                this.setState({
                    customForm: forms["news"][selectedArticle.id]
                });
            }
        }
    }

    handleSubmit() {
        const {updateArticle, match} = this.props;
        const {form, links, customForm} = this.state;
        const {id} = match.params;

        this.setState({loading: true})

        const data = new FormData();
        for (const field of form.fields) {
            if (field.type !== 'dropzone' && !(field.type === 'creatable' || field.type === 'select')) {
                data.set(field.name, field.value)
            } else if ((field.type === 'creatable' || field.type === 'select') && field.multiple) {
                for (const value of field.value) {
                    data.append(`${field.name}[]`, (value.hasOwnProperty('value') ? value.value : value))
                }
            } else if ((field.type === 'creatable' || field.type === 'select') && !field.multiple) {
                data.append(`${field.name}`, (field.value.hasOwnProperty('value') ? field.value.value : field.value))
            } else {
                for (const file of field.files) {
                    data.append(`${field.name}[]`, file)
                }
            }
        }

        if (customForm.fields.length !== 0) {
            data.append('form[name]', customForm.formName);
            data.append('form[size]', customForm.formSize);

            for (const format of customForm.format) {
                data.append('form[format][]', JSON.stringify(format));
            }

            for (const field of customForm.fields) {
                data.append('form[fields][]', JSON.stringify(field));
            }
        }

        if (links.length !== 0) {
            for (let i = 0; i <= links.length - 1; i++) {
                data.append(`links[${i}][name]`, links[i].name)
                data.append(`links[${i}][url]`, links[i].url)
            }
        }

        updateArticle(data, this.props.history, id);
    }

    render() {
        const {customForm, showCustomForm, loading} = this.state;

        return !showCustomForm
            ? (
                loading
                    ? <Load showPatience/>
                    : this.renderFormBody()
            )
            : <CustomForm customForm={customForm} handleForm={(form) => {
                this.setState({showCustomForm: false, customForm: form ? form : customForm, updateCustomForm: false})
            }}/>
    }

    renderFormBody() {
        const {form, links, formValid, customForm} = this.state;
        const {selectedArticle, media} = this.props;

        return (
            <Row>
                <Form form={form}
                      formValid={(bool) => this.setState({formValid: bool})}
                      updateFields={(form) => this.setState({form: form})}
                />

                <Col xl={4} lg={6} md={7}>
                    {links.length !== 0 &&
                    <div className="uploaded-files">
                        {links.map((link, index) => (
                            <div className="file" key={index}>
                                <div>
                                    <FontAwesomeIcon icon={faGlobeEurope} fixedWidth/>
                                    {link.name && link.name !== '' ? link.name : link.url}
                                </div>
                                <Button buttonStyle={BTN_CLASSES.LINK}
                                        buttonSize={BTN_CLASSES.SMALL}
                                        className={BTN_CLASSES.DANGER}
                                        onClick={() => this.setState(prevState => ({links: prevState.links.filter(x => x.name !== link.name && x.url !== link.url)}))}
                                >
                                    <FontAwesomeIcon icon={faTimes}/>
                                </Button>
                            </div>

                        ))}

                        <div className="delete">
                            <Button buttonStyle={BTN_CLASSES.LINK}
                                    buttonSize={BTN_CLASSES.SMALL}
                                    className={BTN_CLASSES.DANGER}
                                    onClick={() => this.setState({links: []})}
                            >
                                <FontAwesomeIcon icon={faTrash}/> Verwijder alles
                            </Button>
                        </div>
                    </div>
                    }

                    <div className={`${BTN_CLASSES.GROUP} flex`}>
                        <Button label={"URL's als bijlage toevoegen"}
                                buttonStyle={BTN_CLASSES.SECONDARY}
                                buttonSize={BTN_CLASSES.SMALL}
                                onClick={() => this.urls.current.handleShow()}
                                styles={{width: '100%'}}
                        />

                        <Button label={"Formulier maken"}
                                buttonStyle={BTN_CLASSES.SECONDARY}
                                buttonSize={BTN_CLASSES.SMALL}
                                onClick={() => this.setState({showCustomForm: true})}
                                disabled={customForm.submissions !== undefined && customForm.submissions !== 0}
                        />

                        {selectedArticle && selectedArticle.hasForm &&
                        <Link to={`/nieuws/${selectedArticle.slug}/resultaten`} className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.PRIMARY} ${BTN_CLASSES.SMALL}`}>Resultaten</Link>}
                    </div>
                </Col>

                <Col xl={3} lg={1} mdHide smHide xsHide/>

                {selectedArticle && this.renderAttachments()}

                <Col md={12} style={{margin: ".5rem 0", textAlign: "right"}}>
                    <div className={BTN_CLASSES.GROUP}>
                        <Link to={`/nieuws/${selectedArticle ? selectedArticle.slug : ''}`}
                              className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.SECONDARY}`}
                        >
                            Annuleren
                        </Link>

                        <Button buttonStyle={BTN_CLASSES.PRIMARY}
                                disabled={!formValid}
                                onClick={this.handleSubmit}
                        >
                            Bewaar
                        </Button>
                    </div>
                </Col>

                {selectedArticle && selectedArticle.media.length !== 0 && media && media.length !== 0 &&
                this.renderImages()
                }

                <UrlModal ref={this.urls} handleSave={(obj) => this.setState(prevState => ({links: [...prevState.links, obj]}))}/>
            </Row>

        )
    }

    renderAttachments() {
        const {selectedArticle} = this.props;
        const {attachments, links} = selectedArticle;

        return (
            <Col md={5}>
                {((attachments && attachments.length > 0) || (links && links.length > 0))
                    ? <Attachments attachments={attachments} links={links} removeAttachment={(this.props.deleteMedia)}/>
                    : <EmptyList id={"Attachments.empty"}/>
                }

            </Col>
        )
    }

    renderImages() {
        const {media, selectedArticle} = this.props;

        return (
            <Col md={12}>
                <div className="images">
                    {selectedArticle.media.map((obj, i) => {
                        const image = media.find(x => x.id === obj.id);
                        if (image) {
                            return (
                                <div className={"image"} key={i}>
                                    {image.loading
                                        ? <Load/>
                                        : <img src={image.file} alt={image.description} onError={this.addDefaultSrc}/>
                                    }
                                    <Button buttonStyle={BTN_CLASSES.DANGER} onClick={() => this.props.deleteMedia(image.id, 'images')}><FontAwesomeIcon icon={faTrash}/></Button>
                                </div>
                            )
                        }
                        return null;
                    })}
                </div>

                <div className={"description"}>Afbeeldingen worden gedeeld in alle talen van dit artikel</div>
            </Col>
        )
    }
}

const mapStateToProps = ({tagReducer, newsReducer, mediaReducer, formReducer}) => {
    const {tags} = tagReducer;
    const {selectedArticle} = newsReducer;
    const {media} = mediaReducer;
    const {forms} = formReducer;
    return {tags, selectedArticle, media, forms};
};

const mapDispatchToProps = {
    loadArticle, loadForm, updateArticle, loadTags, getMedia, deleteMedia
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateArticle);
import {API_URL} from "../constants/defaults";
import axios from "axios";
import {LOAD_INSTRUCTORS, LOAD_STAFF, SELECT_USER} from "../constants/user.constants";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";

export const selectUser = (id) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const {users} = state.userReducer;
    const {locale, messages} = state.intl;
    dispatch({type: SELECT_USER, payload: {selectedUser: null, usersLoading: true}});

    const user = users.find(x => x.id === parseInt(id));
    if (user && user.isLoaded) {
        dispatch({type: SELECT_USER, payload: {selectedUser: user, usersLoading: false}});
    } else {
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(API_URL + '/users/' + id + '?lang=' + locale, header)
            .then(({data}) => {
                dispatch({type: SELECT_USER, payload: {selectedUser: data, usersLoading: false}});
            })
            .catch(error => {
                console.log(error);
                const message = handleErrors(messages, error);
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
            });
    }
};

export const loadInstructors = () => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const {messages} = state.intl;

    const header = {headers: {'Authorization': "Bearer " + token}};
    dispatch({type: LOAD_INSTRUCTORS, payload: {instructors: [], instructorsLoading: true}});
    axios.get(API_URL + '/users/instructors', header)
        .then(({data}) => {
            dispatch({type: LOAD_INSTRUCTORS, payload: {instructors: data, instructorsLoading: false}});
        })
        .catch(error => {
            console.log(error);
            const message = handleErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        });
}

export const loadStaff = () => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const {messages} = state.intl;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.get(API_URL + '/users/staff', header)
        .then(({data}) => {
            dispatch({type: LOAD_STAFF, payload: {staff: data}});
        })
        .catch(error => {
            console.log(error);
            const message = handleErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        });
}

export const resetUserAccount = (data, history) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;

    axios.post(API_URL + '/users/reset', data)
        .then(() => {
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: 'Het account is hersteld.', type: 'success'})}});

            if (history) history.push('/login');
        })
        .catch(error => {
            console.log(error);
            const message = handleErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        });
}

const handleErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.error && error.response.data.error) {
        if (error.response.data.error.code && error.response.data.error.code === 403) {
            return messages["coce.rights.error"];
        } else if (error.response.data.error.code && error.response.data.error.code === 404) {
            return "De gebruikersaccount kon niet worden hersteld.";
        }
    }
    return messages["user.response.error"];
};
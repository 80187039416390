import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATION_BY_ID,
    LOADING_NOTIFICATIONS,
    LOADED_NOTIFICATIONS,
    UPDATE_NOTIFICATIONS,
} from "../constants/notification.constants";

const defaultState = {
    isLoading: false,
    isLoaded: false,
    isSeen: false,
    isClicked: false,
    notificationList: [],
    selectedNotification: null,
}

const loadingNotifications = (state) => (
    {...state, isLoading: true}
);

const loadedNotifications = (state) => (
    {...state, isLoading: false}
);

const getNotifications = (state, {notifications}) => {
    return {
        ...state,
        notificationList: notifications ? notifications : [],
        isLoading: false,
        isLoaded: true,
    }
}
const getNotificationById = (state, {notification}) => {
    return {
        ...state,
        notificationList: state.notificationList.map(x => {
            if (notification.id === x.id) return notification;
            return x;
        }),
        selectedNotification: notification,
        isLoading: false,
        isLoaded: true,
    }
}

const updateNotifications = (state, {notifications}) => (
    {
        ...state,
        notificationList: notifications ? notifications : [],
    }
);

const notificationReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOADING_NOTIFICATIONS:
            return loadingNotifications(state);
        case LOADED_NOTIFICATIONS:
            return loadedNotifications(state);
        case GET_NOTIFICATIONS:
            return getNotifications(state, payload);
        case GET_NOTIFICATION_BY_ID:
            return getNotificationById(state, payload);
        case UPDATE_NOTIFICATIONS:
            return updateNotifications(state, payload);
        default:
            return state;
    }
}

export default notificationReducer;
import React, {Component} from "react";
import {connect} from "react-redux";
import {getDocumentBlob, loadDocuments} from "../../redux/actions/document.actions";
import DocumentDetail from "./DocumentDetail";
import "./Documents.scss"
import {faFile, faFileAlt, faFileAudio, faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileVideo, faFileWord} from "@fortawesome/free-regular-svg-icons";
import {BTN_CLASSES} from "../../css_constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EmptyList from "../EmptyList/EmptyList";

class Documents extends Component {
    componentDidMount() {
        const {loadDocuments, documents} = this.props;
        document.title = `UniPartners Group | Look UP`;

        if (documents.length === 0)
            loadDocuments();
    }

    /**
     * Handle download of attachment
     * @param attachment
     */
    downloadBlob(attachment) {
        const {getDocumentBlob} = this.props;
        getDocumentBlob(attachment);
    }

    render() {
        const {documents, documentCategories} = this.props;

        return (
            <div className="documents">
                {documentCategories.map((category, index) => (
                    <DocumentDetail title={category} key={index}>
                        {documents.filter(document => document.category === category).map((document, ind) => (
                            <div className={"document"} key={ind}>
                                <span>{document.name}</span>

                                {document.files.length > 0
                                    ? document.files.map((file, i) => {
                                        let icoon = '';
                                        switch (file.mime) {
                                            case 'text/plain':
                                                icoon = faFileAlt;
                                                break;
                                            case 'application/xls':
                                            case 'application/vnd.ms-excel':
                                            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                                                icoon = faFileExcel;
                                                break;
                                            case 'application/msword':
                                            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                                                icoon = faFileWord;
                                                break;
                                            case 'application/vnd.ms-powerpoint':
                                            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                                                icoon = faFilePowerpoint;
                                                break;
                                            case 'application/pdf':
                                                icoon = faFilePdf;
                                                break;
                                            case 'audio/mpeg':
                                                icoon = faFileAudio;
                                                break;
                                            case 'image/jpeg':
                                            case 'image/png':
                                            case 'image/bmp':
                                            case 'image/gif':
                                                icoon = faFileImage;
                                                break;
                                            case 'video/mp4':
                                            case 'video/wav':
                                            case 'video/wave':
                                            case 'video/quicktime':
                                                icoon = faFileVideo;
                                                break;
                                            default:
                                                icoon = faFile
                                        }

                                        return (
                                            <div key={i}>
                                                <button onClick={() => this.downloadBlob(file)}
                                                        className={BTN_CLASSES.BTN + " " + BTN_CLASSES.LINK}
                                                >
                                                    <FontAwesomeIcon icon={icoon} fixedWidth/>
                                                    {file.filename}
                                                </button>
                                            </div>
                                        )
                                    })
                                    : <EmptyList id={"documents.empty"}/>
                                }
                            </div>
                        ))}
                    </DocumentDetail>
                ))}
            </div>
        );
    };
}

const mapStateToProps = ({documentReducer}) => {
    const {documents, documentCategories} = documentReducer;
    return {documents, documentCategories};
};


const mapDispatchToProps = {
    loadDocuments, getDocumentBlob
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
import React from 'react';
import './FormInput.scss'
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";

/**
 * Textarea component
 * @param name
 * @param label
 * @param placeholder
 * @param value
 * @param valid
 * @param onChange
 * @param onBlur
 * @param error
 * @param className
 * @param required
 * @param autocomplete
 * @param autoFocus
 * @param rows
 * @param intl
 * @returns {*}
 * @constructor
 */
const FormTextarea = ({name, label, placeholder, value, description, valid, onChange, onBlur, error, className, required, autocomplete, autoFocus, rows, intl}) => (
    <div className={'form-group ' + className}>
        {label && <label htmlFor={name}>{typeof label === 'object' ? intl.formatMessage({id: label.label}, label.value) : label} { required ? <code>*</code> : null }</label>}

        <textarea name={name}
                  value={value === null ? '' : value}
                  placeholder={(placeholder && typeof placeholder === 'object' ? intl.formatMessage({id: placeholder.label}, placeholder.value) : placeholder) + (placeholder && required && !label ? " *" : "")}
                  className={'form-control ' + (valid === '' ? '' : (valid ? 'is-valid' : 'is-invalid'))}
                  onChange={onChange}
                  onBlur={onBlur}
                  rows={rows}
                  autoFocus={autoFocus}
                  onFocus={function(e) {
                      const val = e.target.value;
                      e.target.value = '';
                      e.target.value = val;
                  }}
                  autoComplete={autocomplete}
        >
            value={value === null ? '' : value}
        </textarea>
        <div className="description">{description}</div>
        <small className="invalid-feedback">{error}</small>
    </div>
);


FormTextarea.defaultProps = {
    label: '', name: '', placeholder: '', value: '', error: '', valid: '', type: 'text', required: false, autocomplete: 'false', className: '', rows: 1, autoFocus: false
};


FormTextarea.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};


export default injectIntl(FormTextarea);
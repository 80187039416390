import React, {Component} from "react";
import {Col, Container, Row} from "../../utils/Grid/Grid";
import {connect} from "react-redux";
import {ALIGNMENT, BTN_CLASSES, BTN_TYPES, INPUT_TYPES, MARGINS} from "../../css_constants";
import Button from "../../utils/Buttons/Button";
import {injectIntl} from "react-intl";
import Logo from "../../assets/images/logo-up.png";
import FormInput from "../../utils/Forms/FormInput";
import {loginValidator} from "./LoginValidator";
import {resetUserAccount} from "../../redux/actions/user.actions";
import Toasts from "../../utils/Toasts/Toasts";
import {Link} from "react-router-dom";

class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            emailValid: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    /**
     * Handle change of the input fields
     * @param e
     */
    handleChange(e) {
        const {value} = e.target;
        this.setState({email: value}, this.validateField);
    }


    /**
     * Validate the input fields
     */
    validateField() {
        this.setState(loginValidator(this.state, this.props, 'email', this.state.email));
    }


    /**
     * Submit the login form
     */
    handleSubmit(e) {
        e.preventDefault();

        const {email} = this.state;
        const {resetUserAccount} = this.props;

        resetUserAccount({email}, this.props.history);
    }

    render() {
        const {email, emailValid} = this.state;
        const {formErrors, intl} = this.props;
        const {messages} = intl;

        return (
            <Container>
                <Row style={{height: '100vh', paddingTop: '0'}}>
                    <Col xl={7} lg={8} md={9} sm={10} xs={11} style={{margin: MARGINS.AUTO}}>
                        <img src={Logo} alt={"UniPartners, united around values"} className={"login-logo"}/>


                        <form name={"form"} onSubmit={this.handleSubmit} noValidate={true}>
                            <FormInput
                                type={INPUT_TYPES.EMAIL}
                                name={"email"}
                                placeholder={messages["login.email.placeholder"]}
                                label={messages["login.email.label"]}
                                required={true}
                                value={email}
                                onChange={this.handleChange}
                                error={formErrors.email}
                                valid={!formErrors.email}
                            />

                            <div className={`${BTN_CLASSES.GROUP} ${ALIGNMENT.RIGHT} login`}>
                                <Button
                                    type={BTN_TYPES.SUBMIT}
                                    label={"Herstel account"}
                                    buttonStyle={BTN_CLASSES.PRIMARY}
                                    styles={{marginTop: 20}}
                                    disabled={!emailValid}
                                />

                                <Link
                                    to={"/login"}
                                    label={""}
                                    className={`${BTN_CLASSES.BTN} ${BTN_CLASSES.LINK}`}
                                    style={{marginTop: 20, padding: '8px 25px'}}
                                >Login</Link>
                            </div>
                        </form>

                    </Col>
                </Row>

                <Toasts/>
            </Container>
        );
    };
}

const mapStateToProps = ({authReducer}) => {
    const {formErrors} = authReducer;
    return {formErrors};
};

export default connect(mapStateToProps, {resetUserAccount})(injectIntl(Register));
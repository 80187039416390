import React, {Component} from "react";
import {BTN_CLASSES} from "../../css_constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCamera, faUpload } from "@fortawesome/free-solid-svg-icons";
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import {Col, Row} from "../../utils/Grid/Grid";
import Button from "../../utils/Buttons/Button";
import {connect} from "react-redux";
import {updateAccount} from "../../redux/actions/auth.actions";
import Stickers from "../Stickers/Stickers";
import userImage from "../../assets/images/user.png";
import {injectIntl} from "react-intl";

/**
 * Update user picture component
 */
class UpdatePicture extends Component {
    constructor(props) {
        super(props);

        /** Initial state of the component */
        this.initialState = {
            croppedImageUrl: null,
            fileName: null,
            file: null,
            src: null,
            blob: null,
            crop: {
                unit: '%',
                width: 100,
                aspect: 16 / 16,
            }
        };

        /** State of the component */
        this.state = {
            ...this.initialState
        };

        this.makeClientCrop = this.makeClientCrop.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    /**
     * Handle the selecting of a file
     * @param e
     */
    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({src: reader.result})
            );
            reader.readAsDataURL(file);
            this.setState({fileName: file.name, file})
        }
    };


    /**
     * Set imageRef of the image
     * @param image
     */
    onImageLoaded = image => {
        this.imageRef = image;
    };


    /**
     * Handle the finishing of crop
     * @param crop
     */
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };


    /**
     * Hanlde changing of crop
     * @param crop
     */
    onCropChange = (crop) => {
        this.setState({crop});
    };


    /**
     * Crop the image and set URL
     * @param crop
     * @returns {Promise<void>}
     */
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'profile-picture.jpg'
            );
            this.setState({croppedImageUrl});
        }
    }


    /**
     * Create cropped image
     * @param image
     * @param crop
     * @param fileName
     * @returns {Promise<unknown>}
     */
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;

                this.setState({blob});

                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }


    /**
     * Handle submitting the image
     * @param e
     */
    handleSubmit(e) {
        e.preventDefault();
        const {updateAccount} = this.props;
        const {blob} = this.state;

        const data = new FormData();
        data.append(`picture`, blob);

        updateAccount(data);
        this.setState({...this.initialState});
    }


    /**
     * Loading the default user image
     * @param ev
     */
    addDefaultSrc(ev) {
        ev.target.src = userImage;
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {src, crop, fileName, croppedImageUrl} = this.state;
        const {user, intl} = this.props;
        const {firstName, lastName, picture, subFeedItems} = user;
        const {messages} = intl;
        const update = subFeedItems.find(x => x.type === 'pictureUpdate');

        /** return upload button, crop field and cropped image */
        return (
            <Row>
                <Col lg={8} md={7} className={"upload"}>
                    <label htmlFor={"picture"}
                           className={BTN_CLASSES.BTN + " " + BTN_CLASSES.PRIMARY + " " + BTN_CLASSES.BLOCK}>
                        <span id="uploadIcon"><FontAwesomeIcon icon={faUpload}/></span>
                        <span id="cameraIcon"><FontAwesomeIcon icon={faCamera}/></span>
                        {fileName ? fileName : messages["Account.image.choose"]}
                    </label>
                    <input type="file"
                           id={"picture"}
                           accept="image/*"
                           onChange={this.onSelectFile}
                    />

                    {src && (
                        <div>
                            <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                keepSelection
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                        </div>
                    )}
                </Col>

                <Col lg={4} md={5}>
                    <div className={"account-sidebar"}>
                        {!croppedImageUrl
                            ? (
                                <div className={"image"}>
                                    <img onError={this.addDefaultSrc} src={picture ? picture : ""} alt={`${firstName} ${lastName}`}/>

                                    {update &&
                                    <Stickers stickers={update.stickers} addButton={false}/>
                                    }
                                </div>
                            ) : (
                                <div>
                                    <div className={BTN_CLASSES.GROUP}>
                                        <Button buttonStyle={BTN_CLASSES.SECONDARY}
                                                block={BTN_CLASSES.BLOCK}
                                                label={messages["form.button.cancel"]}
                                                onClick={() => this.setState({...this.initialState})}
                                        />
                                        <Button buttonStyle={BTN_CLASSES.PRIMARY}
                                                block={BTN_CLASSES.BLOCK}
                                                label={messages["form.button.save"]}
                                                onClick={this.handleSubmit}
                                        />
                                    </div>
                                    <div className={"image"}>
                                        <img alt="Crop"
                                             style={{maxWidth: '100%'}}
                                             src={croppedImageUrl}/>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </Col>
            </Row>
        )
    }
}


export default connect(null, {updateAccount})(injectIntl(UpdatePicture));
import React, {Component} from 'react';
import userImage from '../../../../assets/images/user.png';
import './Contacts.scss';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import Button from "../../../../utils/Buttons/Button";
import {BTN_CLASSES} from "../../../../css_constants";

/**
 * Contact component
 */
class Contacts extends Component {
    constructor(props) {
        super(props);

        /** State of the component */
        this.state = {
            contactBlock: false,
        };

        this.showContacts = this.showContacts.bind(this);
    }

    /**
     * Loading the default user image
     * @param ev
     */
    addDefaultSrc(ev) {
        ev.target.src = userImage;
    }


    /**
     * Handle contact 'show more' slider
     * @param e
     */
    showContacts(e) {
        e.preventDefault();
        this.setState(state => ({
            contactBlock: !state.contactBlock
        }));
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {intl, user} = this.props;
        const {contactBlock} = this.state;
        const {messages} = intl;

        let contacts = user ? user.contacts : [];

        /** Render mapping of renderUser */
        return (
            <div>
                <div className={`contacts ${contactBlock ? 'open' : ''}`}>
                    {contacts.map((contact, i) => (
                        this.renderUser(contact, i)
                    ))}
                </div>

                <nav>
                    <ul>
                        <li>
                            {contacts.length > 3 &&
                            <Button buttonStyle={BTN_CLASSES.LINK}
                                    className={"btn-show-all"}
                                    onClick={(e) => this.showContacts(e)}
                            >
                                <FormattedMessage id={"home.contacts.showAll"}/> <FontAwesomeIcon icon={contactBlock ? faChevronUp : faChevronDown}/>
                            </Button>
                            }
                        </li>
                        <li><Link to={messages["routes.employees"]}><FormattedMessage id={"home.contacts.employees"}/></Link></li>
                    </ul>
                </nav>
            </div>
        );
    }


    /**
     * Component renderUser method
     * @returns {*}
     */
    renderUser(contact, i) {
        const {permissions, intl} = this.props;
        const {messages} = intl;

        /** Return single contact user */
        return (
            <div key={i} className={"contact"}>
                <img onError={this.addDefaultSrc} src={contact.picture ? contact.picture : ""} alt={`${contact.firstName} ${contact.lastName}`}/>
                <div className={"contact__details"}>
                    {permissions.includes("users_see_detail")
                        ? <Link to={`${messages["routes.employees"]}/${contact.id}`} className={"user_name"}>{`${contact.firstName} ${contact.lastName}`}</Link>
                        : `${contact.firstName} ${contact.lastName}`
                    }

                    <div className={"function"}>{contact.function}</div>
                </div>
                <div className={"contact_contacts"}>
                    <a href={`mailto: ${contact.email}`}>{contact.email}</a>
                    {contact.phoneNumbers.map((number, i) => {
                        return <a key={i} href={`tel: ${number.number}`}>{number.number}</a>
                    })}
                </div>
            </div>
        )
    }
}


const mapStateToProps = ({authReducer}) => {
    const {permissions, user} = authReducer;
    return {user, permissions};
};


export default connect(mapStateToProps)(injectIntl(Contacts));
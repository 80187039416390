import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import DateUtility from "../../../../utils/dateUtils";
import Stickers from "../../../Stickers/Stickers";

const SubFeedItem = ({index, feedItem, questions, stickers, ...props}) => {
    const {id, title, content, createdAt, user} = feedItem;
    const {addStickers, type, messages} = props;

    useEffect(() => {
        addStickers(type, id);
    })

    return (
        <div key={index} className={`feed_item ${stickers ? 'with__stickers' : 'without__stickers'}`}>
            <div className={"item__header"}>
                <Link to={`${messages["routes.employees"]}/${user.id}`} className={"item__title"}>{title}</Link>
                <div className={"item__date"}>{DateUtility.toShortDate(createdAt)}</div>
            </div>
            <div className={"item__body"}>
                <span dangerouslySetInnerHTML={{__html: content ? content.replaceAll("\n", '<br />') : content}}/>
            </div>

            <Stickers stickers={stickers} addButton={true} obj={feedItem} objType={"subfeed"}/>
        </div>
    );
}

export default SubFeedItem;
import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {loadQuestions} from "../../redux/actions/app.actions";
import Load from "../../utils/Loading/Load";
import {Col, Row} from "../../utils/Grid/Grid";
import Button from "../../utils/Buttons/Button";
import {BTN_CLASSES, SHARED_CLASSES, UNITS} from "../../css_constants";
import UpdatePicture from "./UpdatePicture";
import UpdateInterview from "./UpdateInterview";
import './Account.scss';
import UpdateBio from "./UpdateBio";
import UpdateAccount from "./UpdateAccount";
import Welcome from "./Welcome";


/**
 * Account component
 */
class Account extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            selectedTab: "account",
            tabs: [
                {name: "account", label: "Account.tab.account"},
                {name: "biography", label: "Account.tab.biography"},
                {name: "interview", label: "Account.tab.interview"},
                {name: "picture", label: "Account.tab.image"},
                {name: "welcome", label: "Account.tab.welcome"}
            ]
        }

        /** State of the component */
        this.state = this.defaultState;

    }

    /**
     * Load questions when component mounts only when questions are not loaded already
     */
    componentDidMount() {
        const {questions, loadQuestions, intl} = this.props;
        const {locale} = intl;

        if (questions.length === 0 || (questions[0] && questions[0].language !== locale))
            loadQuestions();
    }


    /**
     * Set tabs on component update
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {questions} = this.props;
        const {selectedTab, tabs} = this.state;
        const tab = tabs.find(x => x.name === 'interview');

        document.title = `UniPartners Group | ${selectedTab === 'welcome' ? 'Welcome to our team' : (`United around ${selectedTab === 'account' ? 'you' : `your ${selectedTab}`}`)}`;

        if (questions.length !== 0 && !tab) {
            this.setState(this.defaultState);
        } else if (questions.length === 0 && tab) {
            this.setState({
                tabs: this.defaultState.tabs.filter(x => x.name !== 'interview')
            })
        }
    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {userLoading} = this.props;

        /** returning renderDetail if user is loaded */
        return (
            <div>
                {userLoading
                    ? <Load/>
                    : this.renderDetail()
                }
            </div>
        )
    }


    /**
     * Component renderDetail method
     * @returns {*}
     */
    renderDetail() {
        const {selectedTab, tabs} = this.state;
        const {user} = this.props;
        const {firstName, lastName} = user;

        /** return tabs and sub-components according to component state */
        return (
            <div className={"account"}>
                <div>
                    <div className={'coceNavBar'} style={{marginTop: '20px'}}>
                        {tabs.map((tab, i) => {
                            return (
                                <Button buttonStyle={BTN_CLASSES.SECONDARY}
                                        buttonSize={BTN_CLASSES.SMALL}
                                        className={tab.name === selectedTab ? SHARED_CLASSES.ACTIVE : null}
                                        styles={{margin: 5 + UNITS.PX}}
                                        onClick={() => this.setState({selectedTab: tab.name})}
                                        key={i}
                                >
                                    <FormattedMessage id={tab.label}/>
                                </Button>
                            )
                        })}
                    </div>

                    <Row>
                        <Col md={6}>
                            <h1>
                                {`${firstName} ${lastName}`}
                                <div>{user.function}</div>
                            </h1>
                        </Col>
                    </Row>
                </div>

                {selectedTab === 'biography'
                    ? <UpdateBio user={user}/>
                    : selectedTab === 'interview'
                        ? <UpdateInterview user={user}/>
                        : selectedTab === 'picture'
                            ? <UpdatePicture user={user}/>
                            : selectedTab === 'welcome'
                                ? <Welcome user={user}/>
                                : <UpdateAccount user={user}/>
                }

            </div>
        )
    }
}


const mapStateToProps = ({authReducer, appReducer}) => {
    const {user, userLoading} = authReducer;
    const {questions, questionsLoading} = appReducer;
    return {user, userLoading, questions, questionsLoading};
};


export default connect(mapStateToProps, {loadQuestions})(injectIntl(Account))
import React from 'react';
import './FormInput.scss'
import {Col} from "../Grid/Grid";

/**
 * Form column component
 * @param breakpoint
 * @param name
 * @param children
 * @returns {*}
 * @constructor
 */
const FormCol = ({breakpoint, name, children}) => (
    <Col lg={breakpoint} className={name}>
        {children}
    </Col>
);


export default FormCol;
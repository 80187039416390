import {API_URL} from "../constants/defaults";
import axios from "axios";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";
import {UPDATE_ACCOUNT} from "../constants/auth.constants";
import {SELECT_DAMAGE, SELECT_ILLNESS} from "../constants/app.constants";

export const saveIllness = (data, id) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(API_URL + '/illness' + (id ? `/${id}` : ''), data, header)
        .then(({data}) => {
            dispatch({type: (UPDATE_ACCOUNT), payload: {user: data}});
            dispatch({type: SELECT_ILLNESS, payload: {illness: null}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: 'Ziektemelding succesvol opgeslagen', type: 'success'})}})
        })
        .catch(error => {
            const message = handleErrors(messages, error, 'illness');
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}})
            console.log(error)
        });
};


export const saveDamage = (data, id) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(API_URL + '/damage' + (id ? `/${id}` : ''), data, header)
        .then(({data}) => {
            dispatch({type: (UPDATE_ACCOUNT), payload: {user: data}});
            dispatch({type: SELECT_DAMAGE, payload: {damage: null}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: 'Schademelding succesvol opgeslagen', type: 'success'})}})
        })
        .catch(error => {
            const message = handleErrors(messages, error, 'damage');
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}})
            console.log(error)
        });
};


const handleErrors = (messages, error, type) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages[`${type}.response.error`];
};
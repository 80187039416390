import axios from 'axios';
import {
    EDIT_PERMISSIONS,
    GET_PERMISSIONS,
    GET_ROLES,
    LOADING_ROLES_PERMISSIONS
} from "../constants/permissionsAdmin.constants";
import { API_URL } from "../constants/defaults";
import { ADD_TOAST } from "../constants/toast.constants";
import createToast from "../../factories/createToast";
import { handleErrors } from "../../utils/helperFunctions";


export const getRoles = () => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const _HEADER = {headers: {'Authorization': `Bearer ${token}`}};

    dispatch({type: LOADING_ROLES_PERMISSIONS});
    axios.get(`${API_URL}/roles`, _HEADER)
        .then(({data}) => dispatch({type: GET_ROLES, payload: {roles: data}}))
        .catch(error => {
            const message = handleErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        });
}

export const getPermissions = () => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const _HEADER = {headers: {'Authorization': `Bearer ${token}`}};

    dispatch({type: LOADING_ROLES_PERMISSIONS});
    axios.get(`${API_URL}/permissions`, _HEADER)
        .then(({data}) => dispatch({type: GET_PERMISSIONS, payload: {permissions: data}}))
        .catch(error => {
            const message = handleErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        });

}

export const editPermissions = (data) => (dispatch, getState) => {
    const state = getState();
    const {token} = state.authReducer;
    const _HEADER = {headers: {'Authorization': `Bearer ${token}`}};

    axios.post(`${API_URL}/permissions`, data, _HEADER)
        .then(({data}) => dispatch({type: EDIT_PERMISSIONS, payload: {roles: data}}))
        .catch(error => {
            const message = handleErrors(error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        });
}
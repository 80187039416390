import React, {Component} from "react";
import {BTN_CLASSES} from "../../css_constants";
import 'react-image-crop/dist/ReactCrop.css';
import {Col, Row} from "../../utils/Grid/Grid";
import Button from "../../utils/Buttons/Button";
import FormTextarea from "../../utils/Forms/FormTextarea";
import {connect} from "react-redux";
import {updateAccount} from "../../redux/actions/auth.actions";
import {injectIntl} from "react-intl";
import Stickers from "../Stickers/Stickers";

/**
 * Update interview component
 */
class UpdateInterview extends Component {
    constructor(props) {
        super(props);

        /** Initial state of the component */
        this.initialState = {
            rows: 4,
            minRows: 4,
            maxRows: 8,
            answers: []
        };

        /** State of the component */
        this.state = {
            ...this.initialState
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    /**
     * Load questions and answers on component load if the haven't been loaded already
     */
    componentDidMount() {
        const {questions, user} = this.props;
        const {answers} = user;
        let obj = [];

        questions.map(question => {
            const answer = answers.find(x => x.questionId === question.id);
            return obj.push({
                id: question.id,
                question: question.body,
                answer: answer ? answer.answer : null
            })
        });

        this.initialState = {answers: [...obj]};
        this.setState({...this.initialState});
    }


    /**
     * Handle change of the answer fields
     * @param e
     */
    handleChange(e) {
        const textareaLineHeight = 24;
        const {minRows, maxRows} = this.state;
        const {value, name} = e.target;

        const previousRows = e.target.rows;
        e.target.rows = minRows;

        const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            e.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            e.target.rows = maxRows;
            e.target.scrollTop = e.target.scrollHeight;
        }

        this.setState(prevState => (
            {
                ...prevState,
                rows: currentRows < maxRows ? currentRows : maxRows,
                answers: prevState.answers.map(x => {
                    if (x.id !== parseInt(name)) return x;
                    return {...x, answer: value};
                })
            }
        ));
    };


    /**
     * Handle the submitting of the interview form
     * @param e
     */
    handleSubmit(e) {
        e.preventDefault();
        const {updateAccount} = this.props;
        const {answers} = this.state;


        updateAccount({
            answers: answers.map(x => ({id: x.id, answer: (x.answer ? x.answer : "")}))
        });

    }


    /**
     * Component render method
     * @returns {*}
     */
    render() {
        const {questionsLoading, user, intl} = this.props;
        const {answers, rows} = this.state;
        const {subFeedItems} = user;
        const {messages} = intl;
        const update = subFeedItems.find(x => x.type === 'interviewUpdate');

        /** Return interview form */
        return (
            <div>
                {!questionsLoading &&
                <Row className={"interview"}>
                    {answers.map((answer, i) => {
                        return (
                            <Col md={6} key={i}>
                                <FormTextarea name={answer.id}
                                              label={answer.question}
                                              rows={rows}
                                              value={answer ? answer.answer : ""}
                                              required={false}
                                              onChange={this.handleChange}
                                />

                                {i === answers.length - 2 && update &&
                                <Stickers stickers={update.stickers} addButton={false}/>
                                }
                            </Col>
                        )
                    })}

                    <Col md={12} className={"right"}>
                        <div className={BTN_CLASSES.GROUP}>
                            <Button buttonStyle={BTN_CLASSES.SECONDARY}
                                    block={BTN_CLASSES.BLOCK}
                                    label={messages["form.button.cancel"]}
                                    onClick={() => this.setState({...this.initialState})}
                            />
                            <Button buttonStyle={BTN_CLASSES.PRIMARY}
                                    block={BTN_CLASSES.BLOCK}
                                    label={messages["form.button.save"]}
                                    onClick={this.handleSubmit}
                            />
                        </div>
                    </Col>
                </Row>
                }
            </div>
        )
    }
}


const mapStateToProps = ({appReducer}) => {
    const {questions, questionsLoading} = appReducer;
    return {questions, questionsLoading};
};


export default connect(mapStateToProps, {updateAccount})(injectIntl(UpdateInterview));
import moment from "moment";
import {store} from '../redux/store/index';

const {getState} = store;

export default class DateUtility {
    static isCurrent(dateTime) {
        const date = dateTime.toISOString().substring(0, 10);
        return moment(date).isAfter(moment().subtract(1, 'd'));
    }

    static isPast(timestamp) {
        return timestamp < moment().unix();
    }

    static lastDate(days) {
        days = days.map(({endDate}) => new Date(endDate * 1000));
        return days.reduce((pre, cur) => Date.parse(pre) < Date.parse(cur) ? cur : pre);
    }

    static toLongDate(timestamp) {
        const state = getState();
        const {locale} = state.intl;

        let options = {year: 'numeric', month: 'long', day: 'numeric'};
        return new Date(timestamp * 1000).toLocaleDateString(`${locale}-BE`, options);
    }

    static toShortDate(timestamp, limit = false) {
        const state = getState();
        const {locale} = state.intl;
        let options = {};

        if (limit)
            options = {month: '2-digit', day: 'numeric'};
        else
            options = {year: 'numeric', month: '2-digit', day: 'numeric'};

        return new Date(timestamp * 1000).toLocaleDateString(`${locale}-BE`, options);
    }

    static toTime(timestamp) {
        timestamp = new Date(timestamp * 1000);
        return moment.utc(timestamp).format('H:mm');
    }

    static timeBetween(timestamp) {
        const startDate = moment(timestamp * 1000);
        const timeEnd = moment();
        const diff = timeEnd.diff(startDate);
        const diffDuration = moment.duration(diff);
        return parseInt(diffDuration.asYears());
    }

    static currentDate() {
        return new Date();
    }

    static toMoment(timestamp) {
        timestamp = new Date(timestamp * 1000);
        return moment.utc(timestamp).format('YYYY-MM-DDTHH:mm:ss.SSS');
    }

    /*static getFirstDate(days) {
        days = days.map(({ day }) => day);
        return days.reduce((pre, cur) => Date.parse(pre) > Date.parse(cur) ? cur : pre);
    }

    static getLastDate(days) {
        days = days.map(({ day }) => day);
        return days.reduce((pre, cur) => Date.parse(pre) < Date.parse(cur) ? cur : pre);
    }

    static convertLongDate(dateString) {
        const state = getState();
        const {locale} = state.intl;

        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(`${locale}-BE`, options);
    }

    static convertLongWithoutDayDate(dateString) {
        const state = getState();
        const {locale} = state.intl;

        let options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString * 1000).toLocaleDateString(`${locale}-BE`, options);
    }

    static convertShortDate(dateString) {
        const state = getState();
        const {locale} = state.intl;

        let options = { year: 'numeric', month: '2-digit', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(`${locale}-BE`, options);
    }

    static convertTime(timeString) {
        return moment(timeString).format('H:mm');
    }

    static sortByDate(days) {
        return days.sort((a, b) => new Date(a.day) - new Date(b.day));
    }

    */
}
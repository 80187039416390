import axios from "axios";
import {API_URL} from "../constants/defaults";
import {ADD_COMMENT, ADD_COMMENTS, DELETE_COMMENTS} from "../constants/comment.constants";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";

export const addComments = (type, id) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token, permissions} = state.authReducer;
    const {comments} = state.commentReducer;

    if (permissions.includes("comments_see") && !comments[type][id]) {
        dispatch({type: ADD_COMMENTS, payload: {type, id, comments: []}});
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(`${API_URL}/${type}/${id}/comments`, header)
            .then(({data}) => {
                dispatch({type: ADD_COMMENTS, payload: {type, id, comments: data}})
            })
            .catch(error => {
                const message = handleLoadErrors(messages, error)
                dispatch({type: DELETE_COMMENTS, payload: {type, id}});
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}})
                console.log(error);
            });
    }
};

export const addComment = (body) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(API_URL + '/comments', body, header)
        .then(({data}) => {
            dispatch({type: ADD_COMMENT, payload: {type: body.type, id: body.id, comment: data}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["comments.post.response.success"], type: 'success'})}})
        })
        .catch(error => {
            console.log(error)
            const message = handlePostErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}})
        }) ;
};

const handleLoadErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["comments.load.response.error"];
};

const handlePostErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["comments.post.response.error"];
};
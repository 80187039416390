import {LOAD_QUESTIONS, SELECT_DAMAGE, SELECT_ILLNESS} from "../constants/app.constants";

let defaultState = {
    questions: [],
    questionsLoading: false,
    language: 'nl',
    selectedIllness: null,
    selectedDamage: null
};

const loadQuestions = (state, {questions, questionsLoading}) => (
    {...state, questions, questionsLoading}
);


const selectIllness = (state, {illness}) => (
    {...state, selectedIllness: (illness ? illness : null)}
);


const selectDamage = (state, {damage}) => (
    {...state, selectedDamage: (damage ? damage : null)}
);


const appReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_QUESTIONS:
            return loadQuestions(state, payload);
        case SELECT_ILLNESS:
            return selectIllness(state, payload);
        case SELECT_DAMAGE:
            return selectDamage(state, payload);
        default:
            return state;
    }
};

export default appReducer;
import axios from "axios";
import {API_URL} from "../constants/defaults";
import {LOAD_INVITATION, DELETE_INVITATION, CONFIRM_INVITATION} from "../constants/invitation.constants";
import {LOAD_FORM} from "../constants/form.constants";
import {ADD_TOAST} from "../constants/toast.constants";
import createToast from "../../factories/createToast";
import {UPDATE_FORM_RESULTS} from "../constants/subscriptionResult.constants";
import {REMOVE_ALERT} from "../constants/auth.constants";

export const loadInvitation = (type, id) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;
    const {invitations} = state.invitationReducer;

    if (typeof invitations[type][id] === 'undefined') {
        dispatch({type: LOAD_INVITATION, payload: {type, id, invitation: null}});
        const header = {headers: {'Authorization': "Bearer " + token}};
        axios.get(`${API_URL}/${type}/${id}/invitations`, header)
            .then(({data}) => {
                dispatch({type: LOAD_INVITATION, payload: {type, id, invitation: data}});

                return axios.get(`${API_URL}/${type}/${id}/form`, header)
            })
            .then(({data}) => {
                dispatch({type: LOAD_FORM, payload: {type, id, form: data}})
            })
            .catch(error => {
                const message = handleLoadErrors(messages, error);
                dispatch({type: DELETE_INVITATION, payload: {type, id}});
                dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
                console.log(error);
            });
    }
};

export const confirmInvitation = (body, setResult = false) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token, user} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(`${API_URL}/confirmations`, body, header)
        .then(({data}) => {
            dispatch({type: CONFIRM_INVITATION, payload: {type: body.type, id: body.id, invitation: data}});
            if (setResult) {
                dispatch({type: UPDATE_FORM_RESULTS, payload: {invitation: data}});
            }

            if (body.userId === user.id) {
                dispatch({type: REMOVE_ALERT, payload: {type: body.type, id: body.id}});
            }
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["invitations.post.response.success"], type: 'success'})}});
        })
        .catch(error => {
            console.log(error);
            const message = handlePostErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        })
};

export const sendMail = (body) => (dispatch, getState) => {
    const state = getState();
    const {messages} = state.intl;
    const {token} = state.authReducer;

    const header = {headers: {'Authorization': "Bearer " + token}};
    axios.post(`${API_URL}/ask-invitation`, body, header)
        .then(({data}) => {
            dispatch({type: LOAD_INVITATION, payload: {type: body.type, id: body.id, invitation: data}});
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: messages["invitations.mail.response.success"], type: 'success'})}});
        })
        .catch(error => {
            console.log(error);
            const message = handleMailErrors(messages, error);
            dispatch({type: ADD_TOAST, payload: {toast: createToast({text: message, type: 'error'})}});
        })
};

const handleLoadErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["invitations.load.response.error"];
};

const handlePostErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["invitations.post.response.error"];
};

const handleMailErrors = (messages, error) => {
    if (error.response && error.response.data && error.response.data.errorCode) {
        /*if (error.response.data.errorCode === 'INVALID_EMAIL') {
            return messages["login.response.email.invalid"];
        }*/
    }
    return messages["invitations.mail.response.error"];
};
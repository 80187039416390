import React, {Component} from "react";
import {Col, Row} from "../../utils/Grid/Grid";
import Sidebar from "./Sidebar/Sidebar";
import MainFeed from "./MainFeed/MainFeed";

/**
 * Home page component
 */
class Home extends Component {
    componentDidMount() {
        document.title = `UniPartners Group Intranet`;
    }

    /**
     * Component render method
     * @returns {*}
     */
    render() {
        /** Returns MainFeed and Sidebar */
        return (
            <Row>
                <Col md={12} lg={8} xl={7} xsOrder={2} lgOrder={1}>
                    <MainFeed/>
                </Col>
                <Col md={12} lg={4} xl={5} xsOrder={1} lgOrder={2}>
                    <Sidebar {...this.props}/>
                </Col>
            </Row>
        );
    };
}


export default Home;
export const loginValidator = (state, props, name, value) => {
    let {emailValid, passwordValid} = state;
    let {formErrors, intl} = props;
    let {messages} = intl;


    switch (name) {
        case 'email':
            if (value.length <= 0) {
                emailValid = false;
                formErrors.email = messages["validation.email.required"];
            } else {
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                formErrors.email = emailValid ? '' : messages["validation.email.incorrect"];
            }
            break;

        case 'password':
            if (value.length <= 0) {
                passwordValid = false;
                formErrors.password = messages["validation.password.required"];
            } else {
                passwordValid = value.length >= 4;
                formErrors.password = passwordValid ? '' : messages["validation.password.length"];
            }
            break;

        default:
            break;
    }

    return {emailValid, passwordValid, formErrors};
};


import {DELETE_ATTACHMENT_BLOB, LOAD_ATTACHMENT_BLOB} from "../constants/attachment.constants";

let defaultState = {
    attachmentBlobs: [],
    componentErrors: []
};


const loadBlob = (state, {attachment, url}) => {
    const search = state.attachmentBlobs.find(x => x.rel === url);

    let temp = [];
    if (search) {
        temp = state.attachmentBlobs.map(x => {
            if (x.rel === url) return {rel: url, ...attachment, loading: !attachment};
            return x;
        });
    } else {
        temp = [
            ...state.attachmentBlobs,
            {
                rel: url,
                ...attachment,
                loading: !attachment
            }
        ];
    }

    return ({...state, attachmentBlobs: temp})
};


const deleteBlob = (state, {url}) => {
    let arr = state.attachmentBlobs.slice();
    const index = arr.indexOf(state.attachmentBlobs.find(x => x.rel === url));
    arr.splice(index, 1);

    return { ...state, attachmentBlobs: arr };
};


const attachmentReducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case LOAD_ATTACHMENT_BLOB:
            return loadBlob(state, payload);
        case DELETE_ATTACHMENT_BLOB:
            return deleteBlob(state, payload);
        default:
            return state;
    }
};

export default attachmentReducer;
